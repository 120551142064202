import { getLocalStore } from 'next-persist';
import {
  ADD_ANNOUNCEMENT_INSTRUCTOR,
  ADD_ANNOUNCEMENT_INSTRUCTOR_FAILED,
  ADD_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED,
  ADD_ASSESSMENT,
  ADD_ASSESSMENT_ANSWER,
  ADD_ASSESSMENT_ANSWER_COMMENT,
  ADD_ASSESSMENT_ANSWER_COMMENT_FAILED,
  ADD_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED,
  ADD_ASSESSMENT_ANSWER_FAILED,
  ADD_ASSESSMENT_ANSWER_SUCCEEDED,
  ADD_ASSESSMENT_FAILED,
  ADD_ASSESSMENT_QUESTION_COMMENT,
  ADD_ASSESSMENT_QUESTION_COMMENT_FAILED,
  ADD_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED,
  ADD_ASSESSMENT_SUCCEEDED,
  ADD_ROOM_DISCUSSION,
  ADD_ROOM_DISCUSSION_COMMENT,
  ADD_ROOM_DISCUSSION_COMMENT_FAILED,
  ADD_ROOM_DISCUSSION_COMMENT_REPLIES,
  ADD_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED,
  ADD_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED,
  ADD_ROOM_DISCUSSION_COMMENT_SUCCEEDED,
  ADD_ROOM_DISCUSSION_FAILED,
  ADD_ROOM_DISCUSSION_SUCCEEDED,
  ADD_ROOM_INSTRUCTOR,
  ADD_ROOM_INSTRUCTOR_FAILED,
  ADD_ROOM_INSTRUCTOR_SUCCEEDED,
  ADD_ROOM_MEETINGS_INSTRUCTOR,
  ADD_ROOM_MEETINGS_INSTRUCTOR_FAILED,
  ADD_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED,
  CLEAR_COMMENT_DISCCUSION_DATA,
  CLEAR_COMMENT_REPLY_DATA,
  CLEAR_ROOM_DATA,
  CLEAR_ROOM_DATA_TO_INITIAL,
  CLEAR_ROOM_ERROR,
  CLEAR_ROOM_QUERY,
  DELETE_ANNOUNCEMENT_INSTRUCTOR_FAILED,
  DELETE_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED,
  DELETE_ASSESSMENT,
  DELETE_ASSESSMENT_ANSWER_COMMENT,
  DELETE_ASSESSMENT_ANSWER_COMMENT_FAILED,
  DELETE_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED,
  DELETE_ASSESSMENT_FAILED,
  DELETE_ASSESSMENT_QUESTION_COMMENT,
  DELETE_ASSESSMENT_QUESTION_COMMENT_FAILED,
  DELETE_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED,
  DELETE_ASSESSMENT_SUCCEEDED,
  DELETE_ROOM_DISCUSSION,
  DELETE_ROOM_DISCUSSION_COMMENT,
  DELETE_ROOM_DISCUSSION_COMMENT_FAILED,
  DELETE_ROOM_DISCUSSION_COMMENT_REPLIES,
  DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED,
  DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED,
  DELETE_ROOM_DISCUSSION_COMMENT_SUCCEEDED,
  DELETE_ROOM_DISCUSSION_FAILED,
  DELETE_ROOM_DISCUSSION_SUCCEEDED,
  DELETE_ROOM_INSTRUCTOR_FAILED,
  DELETE_ROOM_INSTRUCTOR_SUCCEEDED,
  DELETE_ROOM_MEETINGS_INSTRUCTOR_FAILED,
  DELETE_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED,
  EDIT_ANNOUNCEMENT_INSTRUCTOR,
  EDIT_ANNOUNCEMENT_INSTRUCTOR_FAILED,
  EDIT_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED,
  EDIT_ASSESSMENT,
  EDIT_ASSESSMENT_ANSWER,
  EDIT_ASSESSMENT_ANSWER_COMMENT,
  EDIT_ASSESSMENT_ANSWER_COMMENT_FAILED,
  EDIT_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED,
  EDIT_ASSESSMENT_ANSWER_FAILED,
  EDIT_ASSESSMENT_ANSWER_SUCCEEDED,
  EDIT_ASSESSMENT_FAILED,
  EDIT_ASSESSMENT_QUESTION_COMMENT,
  EDIT_ASSESSMENT_QUESTION_COMMENT_FAILED,
  EDIT_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED,
  EDIT_ASSESSMENT_SUCCEEDED,
  EDIT_ROOM_DISCUSSION,
  EDIT_ROOM_DISCUSSION_COMMENT,
  EDIT_ROOM_DISCUSSION_COMMENT_FAILED,
  EDIT_ROOM_DISCUSSION_COMMENT_REPLIES,
  EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED,
  EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED,
  EDIT_ROOM_DISCUSSION_COMMENT_SUCCEEDED,
  EDIT_ROOM_DISCUSSION_FAILED,
  EDIT_ROOM_DISCUSSION_SUCCEEDED,
  EDIT_ROOM_INSTRUCTOR,
  EDIT_ROOM_INSTRUCTOR_FAILED,
  EDIT_ROOM_INSTRUCTOR_SUCCEEDED,
  EDIT_ROOM_MEETINGS_INSTRUCTOR,
  EDIT_ROOM_MEETINGS_INSTRUCTOR_FAILED,
  EDIT_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED,
  GET_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_FAILED,
  GET_ANNOUNCEMENT_INSTRUCTOR,
  GET_ANNOUNCEMENT_INSTRUCTOR_FAILED,
  GET_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED,
  GET_ANNOUNCEMENT_SUCCEEDED,
  GET_ASSESSMENT,
  GET_ASSESSMENT_ANSWER,
  GET_ASSESSMENT_ANSWER_BY_ID,
  GET_ASSESSMENT_ANSWER_BY_ID_FAILED,
  GET_ASSESSMENT_ANSWER_BY_ID_SUCCEEDED,
  GET_ASSESSMENT_ANSWER_COMMENT,
  GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE,
  GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_FAILED,
  GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_SUCCEEDED,
  GET_ASSESSMENT_ANSWER_COMMENT_FAILED,
  GET_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED,
  GET_ASSESSMENT_ANSWER_FAILED,
  GET_ASSESSMENT_ANSWER_SUCCEEDED,
  GET_ASSESSMENT_BY_ID,
  GET_ASSESSMENT_BY_ID_FAILED,
  GET_ASSESSMENT_BY_ID_SUCCEEDED,
  GET_ASSESSMENT_FAILED,
  GET_ASSESSMENT_QUESTION_COMMENT,
  GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE,
  GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_FAILED,
  GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_SUCCEEDED,
  GET_ASSESSMENT_QUESTION_COMMENT_FAILED,
  GET_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED,
  GET_ASSESSMENT_SUCCEEDED,
  GET_DISCUSSION_COMMENT_REACTION_LIST,
  GET_DISCUSSION_COMMENT_REACTION_LIST_FAILED,
  GET_DISCUSSION_COMMENT_REACTION_LIST_SUCCEEDED,
  GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST,
  GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_FAILED,
  GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_SUCCEEDED,
  GET_DISCUSSION_REACTION_LIST,
  GET_DISCUSSION_REACTION_LIST_FAILED,
  GET_DISCUSSION_REACTION_LIST_SUCCEEDED,
  GET_ROOM,
  GET_ROOM_DETAIL,
  GET_ROOM_DETAIL_FAILED,
  GET_ROOM_DETAIL_SUCCEEDED,
  GET_ROOM_DISCUSSION,
  GET_ROOM_DISCUSSION_BY_PAGE,
  GET_ROOM_DISCUSSION_BY_PAGE_FAILED,
  GET_ROOM_DISCUSSION_BY_PAGE_SUCCEEDED,
  GET_ROOM_DISCUSSION_COMMENT,
  GET_ROOM_DISCUSSION_COMMENT_BY_PAGE,
  GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_FAILED,
  GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_SUCCEEDED,
  GET_ROOM_DISCUSSION_COMMENT_FAILED,
  GET_ROOM_DISCUSSION_COMMENT_REPLIES,
  GET_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED,
  GET_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED,
  GET_ROOM_DISCUSSION_COMMENT_SUCCEEDED,
  GET_ROOM_DISCUSSION_FAILED,
  GET_ROOM_DISCUSSION_SUCCEEDED,
  GET_ROOM_FAILED,
  GET_ROOM_INSTRUCTOR,
  GET_ROOM_INSTRUCTOR_BY_SLUG,
  GET_ROOM_INSTRUCTOR_BY_SLUG_FAILED,
  GET_ROOM_INSTRUCTOR_BY_SLUG_SUCCEEDED,
  GET_ROOM_INSTRUCTOR_FAILED,
  GET_ROOM_INSTRUCTOR_SUCCEEDED,
  GET_ROOM_MEETINGS,
  GET_ROOM_MEETINGS_BY_SLUG,
  GET_ROOM_MEETINGS_BY_SLUG_FAILED,
  GET_ROOM_MEETINGS_BY_SLUG_SUCCEEDED,
  GET_ROOM_MEETINGS_FAILED,
  GET_ROOM_MEETINGS_INSTRUCTOR,
  GET_ROOM_MEETINGS_INSTRUCTOR_FAILED,
  GET_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED,
  GET_ROOM_MEETINGS_SUCCEEDED,
  GET_ROOM_MEMBERS,
  GET_ROOM_MEMBERS_BY_PAGE,
  GET_ROOM_MEMBERS_BY_PAGE_FAILED,
  GET_ROOM_MEMBERS_BY_PAGE_SUCCEEDED,
  GET_ROOM_MEMBERS_FAILED,
  GET_ROOM_MEMBERS_SUCCEEDED,
  GET_ROOM_SUCCEEDED,
  INITIAL_ROOM_DISCUSSION_COMMENT_QUERY,
  INITIAL_ROOM_DISCUSSION_COMMENT_REPLIES_QUERY,
  INVITE_MEMBER_FAILED,
  INVITE_MEMBER_REQUEST,
  INVITE_MEMBER_SUCCEEDED,
  JOIN_ROOM_FAILED,
  JOIN_ROOM_REQUEST,
  JOIN_ROOM_SUCCEEDED,
  LEAVE_ROOM_FAILED,
  LEAVE_ROOM_REQUEST,
  LEAVE_ROOM_SUCCEEDED,
  LIKE_ROOM_DISCUSSION_COMMENT_REPLIES,
  LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED,
  LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED,
  MIGRATE_COURSE_ENROLLED_USERS,
  MIGRATE_COURSE_ENROLLED_USERS_FAILED,
  MIGRATE_COURSE_ENROLLED_USERS_SUCCEEDED,
  REJECT_TO_INVITATION_REQUEST,
  REJECT_TO_INVITATION_REQUEST_FAILED,
  REJECT_TO_INVITATION_REQUEST_SUCCEEDED,
  REMOVE_DISSCUSSION_COMMENT_OBJECT,
  REMOVE_ROOM_MEMBERS_FAILED,
  REMOVE_ROOM_MEMBERS_SUCCEEDED,
  RESPOND_GOING_TO_MEETING,
  RESPOND_GOING_TO_MEETING_FAILED,
  RESPOND_GOING_TO_MEETING_SUCCEEDED,
  RESPOND_TO_INVITATION_REQUEST,
  RESPOND_TO_INVITATION_REQUEST_FAILED,
  RESPOND_TO_INVITATION_REQUEST_SUCCEEDED,
  ROOM_DISCUSSION_LIKE,
  ROOM_DISCUSSION_LIKE_COMMENT,
  ROOM_DISCUSSION_LIKE_COMMENT_FAILED,
  ROOM_DISCUSSION_LIKE_COMMENT_SUCCEEDED,
  ROOM_DISCUSSION_LIKE_FAILED,
  ROOM_DISCUSSION_LIKE_SUCCEEDED,
  ROOM_DISCUSSION_REPORT,
  ROOM_DISCUSSION_REPORT_FAILED,
  ROOM_DISCUSSION_REPORT_SUCCEEDED,
  SET_ROOM_DATA,
  SET_ROOM_DISCUSSION_COMMENT_QUERY,
  SET_ROOM_EDIT_DATA,
  SET_ROOM_ERROR_DATA,
  SET_ROOM_MEMBER_ACTIVE_TAB,
  SET_ROOM_QUERY_DATA,
  UPDATE_ROOM_MEMBER,
  UPDATE_ROOM_MEMBER_FAILED,
  UPDATE_ROOM_MEMBER_SUCCEEDED,
  VOTE_ROOM_DISCUSSION_POLL,
  VOTE_ROOM_DISCUSSION_POLL_FAILED,
  VOTE_ROOM_DISCUSSION_POLL_SUCCEEDED,
} from '../actions';
import { IRoom } from '../interfaces/room';

const initialState: IRoom = {
  // For Instructor Only
  rooms: {
    data: [],
    success: false,
    loading: false,
    updateSuccess: false,
    deleteSuccess: false,
    updateLoading: false,
    updateAnnouncementInstructor: '',
  },
  roomBySlug: {
    data: {},
    loading: false,
  },
  // Frontend
  roomList: {
    data: [],
    loading: false,
  },
  roomDetail: {
    data: {},
    loading: '',
    joinRoomStatus: '',
    leaveRoomStatus: '',
  },
  // For Instructor Only
  announcements: {
    data: [],
    success: false,
    loading: false,
    updateSuccess: false,
    deleteSuccess: false,
  },
  // Frontend
  announcementList: {
    data: [],
    loading: false,
    moreLoading: false,
  },
  // For Instructor
  meetings: {
    data: [],
    success: false,
    loading: false,
    updateSuccess: false,
    deleteSuccess: false,
    updateLoading: false,
  },
  // Frontend
  meetingList: {
    data: [],
    loading: false,
    respondGoingToMeetingStatus: '',
    addMeetingStatus: '',
    updateMeetingStatus: '',
  },
  meetingDetail: {
    data: [],
    loading: false,
  },
  meetingVideo: {
    data: [],
    loading: false,
  },
  members: {
    data: [],
    loading: false,
    updateLoading: false,
    updateMemberStatus: '',
    respondToInvitationStatus: '',
  },
  discussions: {
    data: [],
    getRoomDiscussionStatus: '',
    meta: {},
    getMoreDiscussionStatus: '',
    addRoomDiscussionStatus: '',
    editRoomDiscussionStatus: '',
    deleteRoomDiscussionStatus: '',
    reportDiscussionStatus: '',
  },
  poll: {
    voteRoomDiscussionPollStatus: '',
    discussionId: null,
    commentId: null,
  },
  discussionReactionList: {
    postId: null,
    status: '',
    reactionList: {},
  },
  commentReactionList: {
    commentId: null,
    status: '',
    reactionList: {},
  },
  repliesReactionList: {
    replyId: null,
    status: '',
    reactionList: {},
  },
  discussionComments: {
    discussionId: null,
    commentId: null,
    comments: {},
    replies: {},
    // getRoomDiscussionComments: '',
    meta: {},
    // getMoreRoomDiscussionCommentsStatus: '',
    // addRoomDiscussionCommentStatus: '',
    // editRoomDiscussionCommentStatus: '',
    // deleteRoomDiscussionCommentStatus: '',
  },
  activeTab: 1,
  inviteMemberStatus: '',
  migrateCourseUsersStatus: '',
  error: {
    inviteMember: '',
    meeting: {},
    discussions: {},
    addAssessment: {},
    editAssessment: {},
    addAnswer: {},
    editAnswer: {},
  },
  query: {
    roomList: {
      page: 1,
      per_page: 10,
      search: '',
    },
    discussions: {
      page: 1,
      per_page: 5,
      room: null,
    },
    // discussionComments: {
    //     page: 1,
    //     per_page: 10,
    //     doubt: null,
    //     room: null,
    // },
  },
  addAssessment: {},
  addAnswer: {},
  assessment: {
    data: [],
    getAssessmentStatus: '',
    getAssessmentByIdStatus: '',
    meta: {},
    assessment: {},
    addAssessmentStatus: '',
    editAssessmentStatus: '',
    deleteAssessmentStatus: '',
  },
  questionComment: {
    data: [],
    getQCommentStatus: '',
    meta: {},
    addQAssessmentStatus: '',
    editQAssessmentStatus: '',
    deleteQAssessmentStatus: '',
    getMoreQCommentStatus: '',
  },
  answerComment: {
    data: [],
    getAnsAssessmentStatus: '',
    meta: {},
    addAnsAssessmentStatus: '',
    editAnsAssessmentStatus: '',
    deleteAnsAssessmentStatus: '',
    getMoreAnsCommentStatus: '',
  },
  answers: {
    data: [],
    getAnswerStatus: '',
    getAssessmentAnswerByIdStatus: '',
    meta: {},
    answer: {},
    addAnswerStatus: '',
    editAnswerStatus: '',
    deleteAnswerStatus: '',
  },
};

const persistedState = getLocalStore('roomReducer', initialState);

const roomReducer = (state = persistedState, action) => {
  switch (action.type) {
    // For Instructor Only
    case GET_ROOM_INSTRUCTOR:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          loading: true,
        },
      };
    case GET_ROOM_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          loading: false,
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ROOM_INSTRUCTOR_FAILED:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          loading: false,
        },
      };
    case GET_ROOM_INSTRUCTOR_BY_SLUG:
      return {
        ...state,
        roomBySlug: {
          ...state.roomBySlug,
          loading: true,
        },
      };
    case GET_ROOM_INSTRUCTOR_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        roomBySlug: {
          ...state.roomBySlug,
          loading: false,
          data: action.data,
        },
      };
    case GET_ROOM_INSTRUCTOR_BY_SLUG_FAILED:
      return {
        ...state,
        roomBySlug: {
          ...state.roomBySlug,
          loading: false,
        },
      };
    case ADD_ROOM_INSTRUCTOR:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          success: false,
        },
      };
    case ADD_ROOM_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          data: [action.data.data, ...state.rooms.data],
          success: true,
        },
      };
    case ADD_ROOM_INSTRUCTOR_FAILED:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          success: false,
        },
      };
    case EDIT_ROOM_INSTRUCTOR:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          updateSuccess: 'request',
          updateLoading: true,
        },
      };
    case EDIT_ROOM_INSTRUCTOR_SUCCEEDED:
      var findRoomIndex = state.rooms.data.findIndex(
        (each: any) => each.id === action.data.data.id
      );
      var newRoom = action.data.data;
      state.rooms.data[findRoomIndex] = newRoom;
      return {
        ...state,
        rooms: {
          ...state.rooms,
          data: state.rooms.data.map((each: any) =>
            each.id == action.data.data.id
              ? { ...action.data.data, permission: state.rooms.data.permission }
              : each
          ),
          updateSuccess: 'success',
          updateLoading: false,
        },
        roomBySlug: {
          ...state.roomBySlug,
          loading: false,
          data: {
            ...action.data.data,
            permission: state.roomBySlug.data.permission,
          },
        },
        roomDetail: {
          ...state.roomDetail,
          data: {
            ...action.data.data,
            permission: state.roomDetail.data.permission,
          },
        },
      };
    case EDIT_ROOM_INSTRUCTOR_FAILED:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          updateSuccess: 'failure',
          updateLoading: false,
        },
      };
    case DELETE_ROOM_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          data: state.rooms.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
          deleteSuccess: true,
        },
      };

    case DELETE_ROOM_INSTRUCTOR_FAILED:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          deleteSuccess: false,
        },
      };
    // Frontend Room
    case GET_ROOM:
      return {
        ...state,
        roomList: {
          ...state.roomList,
          loading: true,
        },
      };
    case GET_ROOM_SUCCEEDED:
      return {
        ...state,
        roomList: {
          ...state.roomList,
          loading: false,
          data: action.data,
          meta: action.data.meta,
        },
      };
    case GET_ROOM_FAILED:
      return {
        ...state,
        roomList: {
          ...state.roomList,
          loading: false,
        },
      };
    case GET_ROOM_DETAIL:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          loading: true,
        },
      };
    case GET_ROOM_DETAIL_SUCCEEDED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          loading: false,
          data: action.data,
        },
      };
    case GET_ROOM_DETAIL_FAILED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          loading: false,
          error: action.error.response.data ? action.error.response.data : '',
        },
      };

    case JOIN_ROOM_REQUEST:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          joinRoomStatus: 'request',
        },
      };
    case JOIN_ROOM_SUCCEEDED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          joinRoomStatus: 'success',
          data: action.data,
        },
      };
    case JOIN_ROOM_FAILED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          joinRoomStatus: 'failure',
        },
      };
    case RESPOND_TO_INVITATION_REQUEST:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          respondToInvitationStatus: 'request',
        },
      };
    case RESPOND_TO_INVITATION_REQUEST_SUCCEEDED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          respondToInvitationStatus: 'success',
          data: action.data,
        },
      };
    case RESPOND_TO_INVITATION_REQUEST_FAILED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          respondToInvitationStatus: 'failure',
        },
      };
    case REJECT_TO_INVITATION_REQUEST:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          rejectToInvitationStatus: 'request',
        },
      };
    case REJECT_TO_INVITATION_REQUEST_SUCCEEDED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          rejectToInvitationStatus: 'success',
          data: action.data,
        },
      };
    case REJECT_TO_INVITATION_REQUEST_FAILED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          rejectToInvitationStatus: 'failure',
        },
      };
    case LEAVE_ROOM_REQUEST:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          leaveRoomStatus: 'request',
        },
      };
    case LEAVE_ROOM_SUCCEEDED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          leaveRoomStatus: 'success',
          data: action.data,
        },
      };
    case LEAVE_ROOM_FAILED:
      return {
        ...state,
        roomDetail: {
          ...state.roomDetail,
          leaveRoomStatus: 'failure',
        },
      };

    // For Instructor Only
    case GET_ANNOUNCEMENT_INSTRUCTOR:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          loading: true,
        },
      };
    case GET_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          loading: false,
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ANNOUNCEMENT_INSTRUCTOR_FAILED:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          loading: false,
        },
      };
    case ADD_ANNOUNCEMENT_INSTRUCTOR:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          status: false,
        },
      };
    case ADD_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          data: [action.data.data, ...state.announcements.data],
          status: true,
        },
      };
    case ADD_ANNOUNCEMENT_INSTRUCTOR_FAILED:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          status: false,
        },
      };
    case EDIT_ANNOUNCEMENT_INSTRUCTOR:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          updateAnnouncementInstructor: 'request',
        },
      };
    case EDIT_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED:
      var findAnnouncementIndex = state.announcements.data.findIndex(
        (each: any) => each.id === action.data.data.id
      );
      var newAnnouncement = action.data.data;
      state.announcements.data[findAnnouncementIndex] = newAnnouncement;

      return {
        ...state,
        announcements: {
          ...state.announcements,
          data: state.announcements.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          updateAnnouncementInstructor: 'success',
        },
      };
    case EDIT_ANNOUNCEMENT_INSTRUCTOR_FAILED:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          updateAnnouncementInstructor: 'failed',
          loading: false,
        },
      };
    case DELETE_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          data: state.announcements.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
          deleteSuccess: true,
        },
      };

    case DELETE_ANNOUNCEMENT_INSTRUCTOR_FAILED:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          deleteSuccess: false,
        },
      };
    // Frontend
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        announcementList: {
          ...state.announcementList,
          loading: true,
        },
      };
    case GET_ANNOUNCEMENT_SUCCEEDED:
      return {
        ...state,
        announcementList: {
          ...state.announcementList,
          loading: true,
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ANNOUNCEMENT_FAILED:
      return {
        ...state,
        announcementList: {
          ...state.announcementList,
          loading: false,
        },
      };
    // Room Meetings for instructor only
    case GET_ROOM_MEETINGS_INSTRUCTOR:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          loading: true,
        },
      };
    case GET_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          loading: true,
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ROOM_MEETINGS_INSTRUCTOR_FAILED:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          loading: false,
        },
      };
    case ADD_ROOM_MEETINGS_INSTRUCTOR:
      return {
        ...state,
        // meetings: {
        //     ...state.meetings,
        //     success: false,
        // },
        meetingList: {
          ...state.meetingList,
          addMeetingStatus: 'request',
        },
      };
    case ADD_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        // meetings: {
        //     ...state.meetings,
        //     data: [action.data.data, ...state.meetings.data],
        //     success: true,
        // },
        meetingList: {
          ...state.meetingList,
          data: [action.data.data, ...state.meetings.data],
          addMeetingStatus: 'success',
        },
      };
    case ADD_ROOM_MEETINGS_INSTRUCTOR_FAILED:
      return {
        ...state,
        // meetings: {
        //     ...state.meetings,
        //     success: false
        // },
        meetingList: {
          ...state.meetingList,
          addMeetingStatus: 'failure',
        },
        error: {
          ...state.error,
          meeting: action.error,
        },
      };
    case EDIT_ROOM_MEETINGS_INSTRUCTOR:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          updateSuccess: false,
          loading: true,
        },
        meetingList: {
          ...state.meetingList,
          updateMeetingStatus: 'request',
        },
      };
    case EDIT_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          data: state.meetings.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          updateSuccess: true,
        },
        meetingList: {
          ...state.meetingList,
          data: state.meetingList.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          updateMeetingStatus: 'success',
        },
      };
    case EDIT_ROOM_MEETINGS_INSTRUCTOR_FAILED:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          updateSuccess: false,
          loading: false,
        },
        meetingList: {
          ...state.meetingList,
          updateMeetingStatus: 'failure',
        },
        error: {
          ...state.error,
          meeting: action.error,
        },
      };
    case DELETE_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          data: state.meetings.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
          deleteSuccess: true,
        },
      };

    case DELETE_ROOM_MEETINGS_INSTRUCTOR_FAILED:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          deleteSuccess: false,
        },
      };
    // Frontend Room Meetings
    case GET_ROOM_MEETINGS:
      return {
        ...state,
        meetingList: {
          ...state.meetingList,
          loading: true,
        },
      };
    case GET_ROOM_MEETINGS_SUCCEEDED:
      return {
        ...state,
        meetingList: {
          ...state.meetingList,
          loading: false,
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ROOM_MEETINGS_FAILED:
      return {
        ...state,
        meetingList: {
          ...state.meetingList,
          loading: false,
        },
      };

    case RESPOND_GOING_TO_MEETING:
      return {
        ...state,
        meetingList: {
          ...state.meetingList,
          respondGoingToMeetingStatus: 'request',
        },
      };
    case RESPOND_GOING_TO_MEETING_SUCCEEDED:
      return {
        ...state,
        meetingList: {
          ...state.meetingList,
          data: state.meetingList.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          respondGoingToMeetingStatus: 'success',
        },
      };
    case RESPOND_GOING_TO_MEETING_FAILED:
      return {
        ...state,
        meetingList: {
          ...state.meetingList,
          respondGoingToMeetingStatus: 'failure',
        },
      };
    case GET_ROOM_MEETINGS_BY_SLUG:
      return {
        ...state,
        meetingDetail: {
          ...state.meetingDetail,
          loading: true,
        },
      };
    case GET_ROOM_MEETINGS_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        meetingDetail: {
          ...state.meetingDetail,
          loading: false,
          data: action.data,
        },
      };
    case GET_ROOM_MEETINGS_BY_SLUG_FAILED:
      return {
        ...state,
        meetingDetail: {
          ...state.meetingDetail,
          loading: false,
        },
      };
    case GET_ROOM_MEMBERS:
      return {
        ...state,
        members: {
          ...state.members,
          loading: true,
          moreLoading: false,
        },
      };
    case GET_ROOM_MEMBERS_SUCCEEDED:
      return {
        ...state,
        members: action.data,
      };
    case GET_ROOM_MEMBERS_FAILED:
      return {
        ...state,
      };
    case UPDATE_ROOM_MEMBER:
      return {
        ...state,
        members: {
          ...state.members,
          updateMemberStatus: 'request',
        },
      };
    case UPDATE_ROOM_MEMBER_SUCCEEDED:
      return {
        ...state,
        members: {
          ...state.members,
          // Suspend status if null remove the data from array else update the index
          data:
            parseInt(state.activeTab) === 3 &&
            action.data.data.suspend_status !== null
              ? state.members.data.map((each: any) =>
                  each.id == action.data.data.id ? action.data.data : each
                )
              : state.members.data.filter(
                  (each: any) => each.id !== action.data.data.id
                ),
          updateMemberStatus: 'success',
        },
      };
    case UPDATE_ROOM_MEMBER_FAILED:
      return {
        ...state,
        members: {
          ...state.members,
          updateMemberStatus: 'failure',
        },
      };
    case GET_ROOM_MEMBERS_BY_PAGE:
      return {
        ...state,
        members: {
          ...state.members,
          moreLoading: true,
        },
      };
    case GET_ROOM_MEMBERS_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        members: {
          ...state.members,
          data: [...state.members.data, ...action.data.data],
          link: action.data.link,
          meta: action.data.meta,
          moreLoading: false,
        },
      };
    case GET_ROOM_MEMBERS_BY_PAGE_FAILED:
      return {
        ...state,
        members: {
          ...state.members,
          moreLoading: false,
        },
      };

    // Save Video After Recorded
    // case ADD_MEETING_VIDEO_SUCCEEDED:
    //     return {
    //         ...state,
    //         meetingVideo:{
    //             ...state.meetingVideo,
    //             loading:false
    //         }
    //     }
    case REMOVE_ROOM_MEMBERS_SUCCEEDED:
      return {
        ...state,
        members: {
          ...state.members,
          data: state.members.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };
    case REMOVE_ROOM_MEMBERS_FAILED:
      return {
        ...state,
        members: {
          ...state.members,
        },
      };
    case INVITE_MEMBER_REQUEST:
      return {
        ...state,
        inviteMemberStatus: 'request',
      };
    case INVITE_MEMBER_SUCCEEDED:
      return {
        ...state,
        inviteMemberStatus: 'success',
      };
    case INVITE_MEMBER_FAILED:
      return {
        ...state,
        inviteMemberStatus: 'failure',
        error: {
          ...state.error,
          inviteMember: action.error,
        },
      };
    case MIGRATE_COURSE_ENROLLED_USERS:
      return {
        ...state,
        migrateCourseUsersStatus: 'request',
      };
    case MIGRATE_COURSE_ENROLLED_USERS_SUCCEEDED:
      return {
        ...state,
        migrateCourseUsersStatus: 'success',
      };
    case MIGRATE_COURSE_ENROLLED_USERS_FAILED:
      return {
        ...state,
        migrateCourseUsersStatus: 'failure',
      };

    // Room Discussion
    case GET_ROOM_DISCUSSION:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          getRoomDiscussionStatus: 'request',
        },
      };
    case GET_ROOM_DISCUSSION_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          data: action.data.data,
          getRoomDiscussionStatus: 'success',
          meta: action.data.meta,
        },
      };
    case GET_ROOM_DISCUSSION_FAILED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          getRoomDiscussionStatus: 'failure',
        },
        error: {
          ...state.error,
          discussions: action.error,
        },
      };
    case GET_ROOM_DISCUSSION_BY_PAGE:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          getMoreDiscussionStatus: 'request',
        },
      };
    case GET_ROOM_DISCUSSION_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          getMoreDiscussionStatus: 'success',
          data: [...state.discussions.data, ...action.data.data],
          meta: action.data.meta,
        },
      };
    case GET_ROOM_DISCUSSION_BY_PAGE_FAILED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          getMoreDiscussionStatus: 'failure',
        },
        error: {
          ...state.error,
          discussions: action.error,
        },
      };

    case ADD_ROOM_DISCUSSION:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          addRoomDiscussionStatus: 'request',
        },
      };
    case ADD_ROOM_DISCUSSION_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          data: [action.data.data, ...state.discussions.data],
          addRoomDiscussionStatus: 'success',
        },
      };
    case ADD_ROOM_DISCUSSION_FAILED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          addRoomDiscussionStatus: 'failure',
        },
      };
    case EDIT_ROOM_DISCUSSION:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          editRoomDiscussionStatus: 'request',
        },
      };
    case EDIT_ROOM_DISCUSSION_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          data: state.discussions.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          editRoomDiscussionStatus: 'success',
        },
      };
    case EDIT_ROOM_DISCUSSION_FAILED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          editRoomDiscussionStatus: 'failure',
        },
      };
    case ROOM_DISCUSSION_LIKE:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          roomDiscussionLikeStatus: 'request',
        },
      };
    case ROOM_DISCUSSION_LIKE_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          data: state.discussions.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          roomDiscussionLikeStatus: 'success',
        },
      };
    case ROOM_DISCUSSION_LIKE_FAILED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          roomDiscussionLikeStatus: 'failure',
        },
      };

    case GET_DISCUSSION_REACTION_LIST:
      return {
        ...state,
        discussionReactionList: {
          ...state.discussionReactionList,
          reactionList: {
            ...state.discussionReactionList.reactionList,
            [state.discussionReactionList.postId]: {
              status: 'request',
            },
          },
        },
      };
    case GET_DISCUSSION_REACTION_LIST_SUCCEEDED:
      return {
        ...state,
        discussionReactionList: {
          ...state.discussionReactionList,
          reactionList: {
            ...state.discussionReactionList.reactionList,
            [state.discussionReactionList.postId]: {
              data: action.data.data,
              status: 'success',
            },
          },
        },
      };
    case GET_DISCUSSION_REACTION_LIST_FAILED:
      return {
        ...state,
        discussionReactionList: {
          ...state.discussionReactionList,
          reactionList: {
            ...state.discussionReactionList.reactionList,
            [state.discussionReactionList.postId]: {
              status: 'failure',
            },
          },
        },
      };

    case DELETE_ROOM_DISCUSSION:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          deleteRoomDiscussionStatus: 'request',
        },
      };
    case DELETE_ROOM_DISCUSSION_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          deleteRoomDiscussionStatus: 'success',
          data: state.discussions.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_ROOM_DISCUSSION_FAILED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          deleteRoomDiscussionStatus: 'failure',
        },
      };
    case ROOM_DISCUSSION_REPORT:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          reportDiscussionStatus: 'request',
        },
      };
    case ROOM_DISCUSSION_REPORT_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          reportDiscussionStatus: 'success',
        },
      };
    case ROOM_DISCUSSION_REPORT_FAILED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          reportDiscussionStatus: 'failure',
        },
      };

    // Room Discussion Comments
    case GET_ROOM_DISCUSSION_COMMENT:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              getRoomDiscussionComments: 'request',
            },
          },
        },
      };
    case GET_ROOM_DISCUSSION_COMMENT_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              data: action.data.data,
              getRoomDiscussionComments: 'success',
              meta: action.data.meta,
              query: {
                ...state.discussionComments.comments[
                  state.discussionComments.discussionId
                ].query,
                page: action.data.meta.current_page,
              },
            },
          },
        },
      };
    case GET_ROOM_DISCUSSION_COMMENT_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              getRoomDiscussionComments: 'failure',
            },
          },
        },
      };

    case GET_ROOM_DISCUSSION_COMMENT_BY_PAGE:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              getMoreRoomDiscussionCommentsStatus: 'request',
            },
          },
        },
      };
    case GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              data: [
                ...state.discussionComments.comments[
                  state.discussionComments.discussionId
                ].data,
                ...action.data.data,
              ],
              getMoreRoomDiscussionCommentsStatus: 'success',
              meta: action.data.meta,
            },
          },
          moreLoading: false,
        },
      };
    case GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              getMoreRoomDiscussionCommentsStatus: 'failure',
            },
          },
        },
      };

    case SET_ROOM_DISCUSSION_COMMENT_QUERY:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              query: {
                ...state.discussionComments.comments[
                  state.discussionComments.discussionId
                ].query,
                doubt: action.payload?.doubt,
                page: action.payload?.page,
              },
            },
          },
        },
      };
    case INITIAL_ROOM_DISCUSSION_COMMENT_QUERY:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [action.payload.discussionId]: {
              ...state.discussionComments.comments[action.payload.discussionId],
              query: {
                page: 1,
                per_page: 10,
                doubt: action.payload?.discussionId,
                room: action.payload?.room,
              },
            },
          },
        },
      };

    // Room Discussion Comment Add
    case ADD_ROOM_DISCUSSION_COMMENT:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              addRoomDiscussionCommentStatus: 'request',
            },
          },
        },
      };
    case ADD_ROOM_DISCUSSION_COMMENT_SUCCEEDED:
      const objIndex = state.discussions.data.findIndex(
        (obj) => obj.id === action.data.data.doubt
      );
      state.discussions.data[objIndex].no_comment += 1;
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,

            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              addRoomDiscussionCommentStatus: 'success',
              data: [
                ...state.discussionComments.comments[
                  state.discussionComments.discussionId
                ].data,
                action.data.data,
              ],
            },
          },
        },
      };
    case ADD_ROOM_DISCUSSION_COMMENT_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              addRoomDiscussionCommentStatus: 'failure',
            },
          },
        },
      };

    case EDIT_ROOM_DISCUSSION_COMMENT:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              editRoomDiscussionCommentStatus: 'request',
            },
          },
        },
      };
    case EDIT_ROOM_DISCUSSION_COMMENT_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              editRoomDiscussionCommentStatus: 'success',
              data: state.discussionComments.comments[
                state.discussionComments.discussionId
              ].data.map((each: any) =>
                each.id == action.data.data.id ? action.data.data : each
              ),
            },
          },
        },
      };
    case EDIT_ROOM_DISCUSSION_COMMENT_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              editRoomDiscussionCommentStatus: 'failure',
            },
          },
        },
      };

    case ROOM_DISCUSSION_LIKE_COMMENT:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              likeRoomDiscussionCommentStatus: 'request',
            },
          },
        },
      };
    case ROOM_DISCUSSION_LIKE_COMMENT_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              likeRoomDiscussionCommentStatus: 'success',
              data: state.discussionComments.comments[
                state.discussionComments.discussionId
              ].data.map((each: any) =>
                each.id == action.data.data.id ? action.data.data : each
              ),
            },
          },
        },
      };
    case ROOM_DISCUSSION_LIKE_COMMENT_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              likeRoomDiscussionCommentStatus: 'failure',
            },
          },
        },
      };

    // Comment Reaction List
    case GET_DISCUSSION_COMMENT_REACTION_LIST:
      return {
        ...state,
        commentReactionList: {
          ...state.commentReactionList,
          reactionList: {
            ...state.commentReactionList.reactionList,
            [state.commentReactionList.commentId]: {
              status: 'request',
            },
          },
        },
      };
    case GET_DISCUSSION_COMMENT_REACTION_LIST_SUCCEEDED:
      return {
        ...state,
        commentReactionList: {
          ...state.commentReactionList,
          reactionList: {
            ...state.commentReactionList.reactionList,
            [state.commentReactionList.commentId]: {
              data: action.data.data,
              status: 'success',
            },
          },
        },
      };
    case GET_DISCUSSION_COMMENT_REACTION_LIST_FAILED:
      return {
        ...state,
        commentReactionList: {
          ...state.commentReactionList,
          reactionList: {
            ...state.commentReactionList.reactionList,
            [state.commentReactionList.commentId]: {
              status: 'failure',
            },
          },
        },
      };

    case DELETE_ROOM_DISCUSSION_COMMENT:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              deleteRoomDiscussionCommentStatus: 'request',
            },
          },
        },
      };
    case DELETE_ROOM_DISCUSSION_COMMENT_SUCCEEDED:
      const objI = state.discussions.data.findIndex(
        (obj) => obj.id === action.data.data.doubt
      );
      state.discussions.data[objI].no_comment -= 1;
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              deleteRoomDiscussionCommentStatus: 'success',
              data: state.discussionComments.comments[
                state.discussionComments.discussionId
              ].data.filter((each: any) => each.id !== action.data.data.id),
            },
          },
        },
      };
    case DELETE_ROOM_DISCUSSION_COMMENT_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              deleteRoomDiscussionCommentStatus: 'failure',
            },
          },
        },
      };
    case REMOVE_DISSCUSSION_COMMENT_OBJECT:
      const commentObjs = {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
          },
        },
      };
      delete commentObjs[action.payload];
      return commentObjs;
    case CLEAR_COMMENT_DISCCUSION_DATA:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,
            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              editRoomDiscussionCommentStatus: '',
              deleteRoomDiscussionCommentStatus: '',
              likeRoomDiscussionCommentStatus: '',
              addRoomDiscussionCommentStatus: '',
            },
          },
        },
      };
    // Room Discussion Comment Add ends

    // Room Discussion Comment Replies
    case INITIAL_ROOM_DISCUSSION_COMMENT_REPLIES_QUERY:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [action.payload.commentDiscussionId]: {
              ...state.discussionComments.replies[
                action.payload.commentDiscussionId
              ],
              query: {
                page: 1,
                per_page: 10,
                comment: action.payload?.commentDiscussionId,
                room: action.payload?.room,
              },
            },
          },
        },
      };

    case GET_ROOM_DISCUSSION_COMMENT_REPLIES:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              getRoomDiscussionCommentReplies: 'request',
            },
          },
        },
      };
    case GET_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              data: action.data.data,
              getRoomDiscussionCommentReplies: 'success',
              meta: action.data.meta,
              query: {
                ...state.discussionComments.replies[
                  state.discussionComments.commentId
                ].query,
                page: action.data.meta.current_page,
              },
            },
          },
        },
      };
    case GET_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              getRoomDiscussionCommentReplies: 'failure',
            },
          },
        },
      };

    case ADD_ROOM_DISCUSSION_COMMENT_REPLIES:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              addRoomDiscussionCommentReplies: 'request',
            },
          },
        },
      };
    case ADD_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          comments: {
            ...state.discussionComments.comments,

            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              data: [
                ...state.discussionComments.comments[
                  state.discussionComments.discussionId
                ].data.map((each: any) =>
                  each.id === state.discussionComments.commentId
                    ? {
                        ...each,
                        total_replies: each?.total_replies + 1,
                      }
                    : each
                ),
              ],
            },
          },
          replies: {
            ...state.discussionComments.replies,

            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              addRoomDiscussionCommentReplies: 'success',
              data: [
                ...state.discussionComments.replies[
                  state.discussionComments.commentId
                ].data,
                action.data.data,
              ],
            },
          },
        },
      };
    case ADD_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              addRoomDiscussionCommentReplies: 'failure',
            },
          },
        },
      };

    case EDIT_ROOM_DISCUSSION_COMMENT_REPLIES:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              editRoomDiscussionCommentReplies: 'request',
            },
          },
        },
      };
    case EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              editRoomDiscussionCommentReplies: 'success',
              data: state.discussionComments.replies[
                state.discussionComments.commentId
              ].data.map((each: any) =>
                each.id == action.data.data.id ? action.data.data : each
              ),
            },
          },
        },
      };
    case EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              editRoomDiscussionCommentReplies: 'failure',
            },
          },
        },
      };

    case DELETE_ROOM_DISCUSSION_COMMENT_REPLIES:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              deleteRoomDiscussionCommentReplies: 'request',
            },
          },
        },
      };
    case DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED:
      // const objIdx = state.discussions.data.findIndex(
      //   (obj) => obj.id === action.data.data.doubt
      // );
      // state.discussions.data[objIdx].no_comment -= 1;
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,

          comments: {
            ...state.discussionComments.comments,

            [state.discussionComments.discussionId]: {
              ...state.discussionComments.comments[
                state.discussionComments.discussionId
              ],
              data: [
                ...state.discussionComments.comments[
                  state.discussionComments.discussionId
                ].data.map((each: any) =>
                  each.id === state.discussionComments.commentId
                    ? {
                        ...each,
                        total_replies: each?.total_replies - 1,
                      }
                    : each
                ),
              ],
            },
          },

          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              deleteRoomDiscussionCommentReplies: 'success',
              data: state.discussionComments.replies[
                state.discussionComments.commentId
              ].data.filter((each: any) => each.id !== action.data.data.id),
            },
          },
        },
      };
    case DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              deleteRoomDiscussionCommentReplies: 'failure',
              error: 'Hello',
            },
          },
        },
      };

    case LIKE_ROOM_DISCUSSION_COMMENT_REPLIES:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              likeRoomDiscussionCommentReplies: 'request',
            },
          },
        },
      };
    case LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              likeRoomDiscussionCommentReplies: 'success',
              data: state.discussionComments.replies[
                state.discussionComments.commentId
              ].data.map((each: any) =>
                each.id == action.data.data.id ? action.data.data : each
              ),
            },
          },
        },
      };
    case LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              likeRoomDiscussionCommentReplies: 'failure',
            },
          },
        },
      };

    // Comment Replies Reaction List
    case GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST:
      return {
        ...state,
        repliesReactionList: {
          ...state.repliesReactionList,
          reactionList: {
            ...state.repliesReactionList.reactionList,
            [state.repliesReactionList.replyId]: {
              status: 'request',
            },
          },
        },
      };
    case GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_SUCCEEDED:
      return {
        ...state,
        repliesReactionList: {
          ...state.repliesReactionList,
          reactionList: {
            ...state.repliesReactionList.reactionList,
            [state.repliesReactionList.replyId]: {
              data: action.data.data,
              status: 'success',
            },
          },
        },
      };
    case GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_FAILED:
      return {
        ...state,
        repliesReactionList: {
          ...state.repliesReactionList,
          reactionList: {
            ...state.repliesReactionList.reactionList,
            [state.repliesReactionList.replyId]: {
              status: 'failure',
            },
          },
        },
      };

    case CLEAR_COMMENT_REPLY_DATA:
      return {
        ...state,
        discussionComments: {
          ...state.discussionComments,
          replies: {
            ...state.discussionComments.replies,
            [state.discussionComments.commentId]: {
              ...state.discussionComments.replies[
                state.discussionComments.commentId
              ],
              editRoomDiscussionCommentReplies: '',
              deleteRoomDiscussionCommentReplies: '',
              likeRoomDiscussionCommentReplies: '',
              addRoomDiscussionCommentReplies: '',
            },
          },
        },
      };

    // Room Discussion Polls
    case VOTE_ROOM_DISCUSSION_POLL:
      return {
        ...state,
        poll: {
          ...state.poll,
          voteRoomDiscussionPollStatus: 'request',
        },
      };
    case VOTE_ROOM_DISCUSSION_POLL_SUCCEEDED:
      return {
        ...state,
        discussions: {
          ...state.discussions,
          data: state.discussions.data.map((item: any) => {
            if (item.id == state.poll.discussionId) {
              return {
                ...item,
                // poll: item.poll.map((el) => {
                //   if (el.id == state.poll.commentId) {
                //     return {
                //       ...el,
                //       vote_count: action.data.data.vote_count,
                //     };
                //   }
                //   return el;
                // }),
                poll: action.data.data,
                voted_by_user: true,
              };
            }
            return item;
          }),
        },
        poll: {
          ...state.poll,
          voteRoomDiscussionPollStatus: 'success',
        },
      };
    case VOTE_ROOM_DISCUSSION_POLL_FAILED:
      return {
        ...state,
        poll: {
          voteRoomDiscussionPollStatus: 'failure',
        },
      };

    // case CLEAR_ROOM_DISCUSSION_DOUBTS:
    //   return {
    //     ...state,
    //     poll: {
    //       doubtPollId: initialState.poll.doubtPollId,
    //       addDoubtPollIdStatus: '',
    //     },
    //   };

    //room assessment
    case GET_ASSESSMENT:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentStatus: true,
        },
      };
    case GET_ASSESSMENT_SUCCEEDED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentStatus: false,
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ASSESSMENT_FAILED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentStatus: false,
        },
      };
    case GET_ASSESSMENT_BY_ID:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentByIdStatus: true,
        },
      };
    case GET_ASSESSMENT_BY_ID_SUCCEEDED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentByIdStatus: false,
          assessment: action.data,
        },
      };
    case GET_ASSESSMENT_BY_ID_FAILED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentByIdStatus: false,
        },
      };

    case ADD_ASSESSMENT:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          addAssessmentStatus: 'request',
        },
      };
    case ADD_ASSESSMENT_SUCCEEDED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          data: [action.data.data, ...state.assessment.data],
          addAssessmentStatus: 'success',
        },
      };
    case ADD_ASSESSMENT_FAILED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          addAssessmentStatus: 'failure',
        },
        error: {
          ...state.error,
          addAssessment: action.error,
        },
      };

    case EDIT_ASSESSMENT:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          editAssessmentStatus: 'request',
        },
      };
    case EDIT_ASSESSMENT_SUCCEEDED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          data: state.assessment.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          editAssessmentStatus: 'success',
        },
      };
    case EDIT_ASSESSMENT_FAILED:
      return {
        ...state,
        assessment: {
          ...state.discussions,
          editAssessmentStatus: 'failure',
        },
      };

    case DELETE_ASSESSMENT:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          deleteAssessmentStatus: 'request',
        },
      };
    case DELETE_ASSESSMENT_SUCCEEDED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          deleteAssessmentStatus: 'success',
          data: state.assessment.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_ASSESSMENT_FAILED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          deleteAssessmentStatus: 'failure',
        },
      };
    //assessment end

    //assessment answers
    case GET_ASSESSMENT_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          getAnswerStatus: true,
        },
      };
    case GET_ASSESSMENT_ANSWER_SUCCEEDED:
      return {
        ...state,
        answers: {
          ...state.answers,
          getAnswerStatus: false,
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ASSESSMENT_ANSWER_FAILED:
      return {
        ...state,
        answers: {
          ...state.answers,
          getAnswerStatus: false,
        },
      };

    case GET_ASSESSMENT_ANSWER_BY_ID:
      return {
        ...state,
        answers: {
          ...state.answers,
          getAssessmentAnswerByIdStatus: true,
        },
      };
    case GET_ASSESSMENT_ANSWER_BY_ID_SUCCEEDED:
      return {
        ...state,
        answers: {
          ...state.answers,
          getAssessmentAnswerByIdStatus: false,
          answer: action.data,
        },
      };
    case GET_ASSESSMENT_ANSWER_BY_ID_FAILED:
      return {
        ...state,
        answers: {
          ...state.answers,
          getAssessmentAnswerByIdStatus: false,
        },
      };

    case ADD_ASSESSMENT_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          addAnswerStatus: 'request',
        },
      };
    case ADD_ASSESSMENT_ANSWER_SUCCEEDED:
      return {
        ...state,
        answers: {
          ...state.answers,
          data: [action.data.success, ...state.answers.data],
          addAnswerStatus: 'success',
        },
      };
    case ADD_ASSESSMENT_ANSWER_FAILED:
      return {
        ...state,
        answers: {
          ...state.answers,
          addAnswerStatus: 'failure',
        },
        error: {
          ...state.error,
          addAnswer: action.error,
        },
      };
    case EDIT_ASSESSMENT_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          editAnswerStatus: 'request',
        },
      };
    case EDIT_ASSESSMENT_ANSWER_SUCCEEDED:
      return {
        ...state,
        answers: {
          ...state.answers,
          answer: {
            ...state.answers.answer,
            mark_obtained: action.data?.data?.mark_obtained,
          },
          editAnswerStatus: 'success',
        },
      };
    case EDIT_ASSESSMENT_ANSWER_FAILED:
      return {
        ...state,
        answers: {
          ...state.answers,
          editAnswerStatus: 'failure',
        },
      };
    //assessment answer end

    // Assessment Question Comment starts
    case GET_ASSESSMENT_QUESTION_COMMENT:
      return {
        ...state,
        questionComment: {
          ...state.answers,
          getQCommentStatus: 'request',
        },
      };
    case GET_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          getQCommentStatus: 'success',
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ASSESSMENT_QUESTION_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          getQCommentStatus: 'failure',
        },
      };

    case GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          getMoreQCommentStatus: 'request',
        },
      };
    case GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          getMoreQCommentStatus: 'success',
          data: [...state.questionComment.data, ...action.data.data],
          meta: action.data.meta,
        },
      };
    case GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          getMoreQCommentStatus: 'failure',
        },
      };

    case ADD_ASSESSMENT_QUESTION_COMMENT:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          addQAssessmentStatus: 'request',
        },
      };
    case ADD_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          data: [action.data.data, ...state.questionComment.data],
          addQAssessmentStatus: 'success',
        },
      };
    case ADD_ASSESSMENT_QUESTION_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          addQAssessmentStatus: 'failure',
        },
        error: {
          ...state.error,
          addAnswer: action.error,
        },
      };
    case EDIT_ASSESSMENT_QUESTION_COMMENT:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          editQAssessmentStatus: 'request',
        },
      };
    case EDIT_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          data: state.questionComment.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          editQAssessmentStatus: 'success',
        },
      };
    case EDIT_ASSESSMENT_QUESTION_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.discussions,
          editQAssessmentStatus: 'failure',
        },
      };

    case DELETE_ASSESSMENT_QUESTION_COMMENT:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          deleteQAssessmentStatus: 'request',
        },
      };
    case DELETE_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          deleteQAssessmentStatus: 'success',
          data: state.questionComment.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_ASSESSMENT_QUESTION_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          deleteQAssessmentStatus: 'failure',
        },
      };
    // Assessment Question Comment Ends

    // Assessment Answer Comment Starts
    case GET_ASSESSMENT_ANSWER_COMMENT:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          getAnsAssessmentStatus: 'request',
        },
      };
    case GET_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          getAnsAssessmentStatus: 'success',
          data: action.data.data,
          meta: action.data.meta,
        },
      };
    case GET_ASSESSMENT_ANSWER_COMMENT_FAILED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          getAnsAssessmentStatus: 'failure',
        },
      };

    //
    case GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          getMoreAnsCommentStatus: 'request',
        },
      };
    case GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          getMoreAnsCommentStatus: 'success',
          data: [...state.answerComment.data, ...action.data.data],
          meta: action.data.meta,
        },
      };
    case GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_FAILED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          getMoreAnsCommentStatus: 'failure',
        },
      };
    //
    case ADD_ASSESSMENT_ANSWER_COMMENT:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          addAnsAssessmentStatus: 'request',
        },
      };
    case ADD_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          data: [action.data.data, ...state.answerComment.data],
          addAnsAssessmentStatus: 'success',
        },
      };
    case ADD_ASSESSMENT_ANSWER_COMMENT_FAILED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          addAnsAssessmentStatus: 'failure',
        },
        error: {
          ...state.error,
          addAnswer: action.error,
        },
      };

    case EDIT_ASSESSMENT_ANSWER_COMMENT:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          editAnsAssessmentStatus: 'request',
        },
      };
    case EDIT_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          data: state.answerComment.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          editAnsAssessmentStatus: 'success',
        },
      };
    case EDIT_ASSESSMENT_ANSWER_COMMENT_FAILED:
      return {
        ...state,
        answerComment: {
          ...state.discussions,
          editAnsAssessmentStatus: 'failure',
        },
      };

    case DELETE_ASSESSMENT_ANSWER_COMMENT:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          deleteAnsAssessmentStatus: 'request',
        },
      };
    case DELETE_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          deleteAnsAssessmentStatus: 'success',
          data: state.answerComment.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_ASSESSMENT_ANSWER_COMMENT_FAILED:
      return {
        ...state,
        answerComment: {
          ...state.answerComment,
          deleteAnsAssessmentStatus: 'failure',
        },
      };
    // Assessment Answer Comment Ends
    case SET_ROOM_MEMBER_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.value,
      };
    case SET_ROOM_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.value,
        },
      };
    case SET_ROOM_EDIT_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };
    case CLEAR_ROOM_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    case SET_ROOM_QUERY_DATA:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.name]: {
            ...action.payload.value,
          },
        },
      };
    case SET_ROOM_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    case CLEAR_ROOM_QUERY:
      return {
        ...state,
        query: initialState.query,
      };
    case CLEAR_ROOM_DATA:
      return {
        ...state,
        meetingList: {
          ...state.meetingList,
          addMeetingStatus: initialState.meetingList.addMeetingStatus,
          updateMeetingStatus: initialState.meetingList.updateMeetingStatus,
        },
        discussions: {
          ...state.discussions,
          getRoomDiscussionStatus: '',
          getMoreDiscussionStatus: '',
          addRoomDiscussionStatus: '',
          editRoomDiscussionStatus: '',
          deleteRoomDiscussionStatus: '',
        },
        discussionComments: {
          ...state.discussionComments,
          getRoomDiscussionComments: '',
          getMoreRoomDiscussionCommentsStatus: '',
          addRoomDiscussionCommentStatus: '',
          editRoomDiscussionCommentStatus: '',
          deleteRoomDiscussionCommentStatus: '',
        },
        roomDetail: {
          ...state.roomDetail,
          joinRoomStatus: '',
          leaveRoomStatus: '',
        },
      };

    case CLEAR_ROOM_DATA_TO_INITIAL:
      return {
        ...initialState,
        roomDetail: {
          data: {},
          loading: '',
          joinRoomStatus: '',
          leaveRoomStatus: '',
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default roomReducer;
