import { getLocalStore } from "next-persist";
import { PHelper } from "src/interfaces/payment";
import {
    CLEAR_ALL_ORDER_ITEM,
    CLEAR_PAYMENT_DATA,
    CLEAR_PAYMENT_QUERY,
    CLEAR_SINGLE_ORDER_ITEM,
    PURCHASE_HISTORY_FAILED,
    PURCHASE_HISTORY_REQUEST,
    PURCHASE_HISTORY_SUCCEEDED,
    SET_ORDER_DATA,
    SET_PAYMENT_EDIT_DATA,
    SET_PAYMENT_QUERY_DATA
} from "../actions";
import Router from 'next/router'

const initialState: PHelper = {
    orders: {},
    clearStatus: false,
    purchaseHistory: {
        data: [],
        meta: {},
        getPurchaseHistoryStatus: '',
    },
    query: {
        purchaseHistory: {
            page: 1,
            per_page: 10,
        }
    },
}

const persistedState = getLocalStore('payment', initialState);

const payment = (state = persistedState, action) => {
    switch (action.type) {
        case SET_ORDER_DATA:
            return {
                ...state,
                [action.payload.key]: [...state.orders, action.payload.value],
            };
        case SET_PAYMENT_EDIT_DATA:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        case CLEAR_ALL_ORDER_ITEM:
            var clearState = {
                ...state,
                orders: {},
                clearStatus: true,
            }
            setTimeout(() => {
                Router.push(`/course/${action.payload.slug}`)
                clearState.clearStatus = false;
            }, 2000)
            return clearState;
        case CLEAR_SINGLE_ORDER_ITEM:
            const clearSingleItem = {
                ...state,
                orders: state.orders.filter((each: any) => each.id !== action.payload.id),
                total: parseInt(state.total) - action.payload?.price ? action.payload?.price : 0
            }
            if (clearSingleItem?.orders?.length === 0) {
                Router.push(`/course/${action.payload.slug}`);
            }
            return clearSingleItem
        case CLEAR_PAYMENT_DATA:
            return {
                ...state,
                orders: {},
                clearStatus: false
            }
        case PURCHASE_HISTORY_REQUEST:
            return {
                ...state,
                purchaseHistory: {
                    ...state.purchaseHistory,
                    getPurchaseHistoryStatus: "request"
                }
            };
        case PURCHASE_HISTORY_SUCCEEDED:
            return {
                ...state,
                purchaseHistory: {
                    ...state.purchaseHistory,
                    data: action.data.data,
                    getPurchaseHistoryStatus: "success",
                    meta: action.data.meta,
                }
            };
        case PURCHASE_HISTORY_FAILED:
            return {
                ...state,
                purchaseHistory: {
                    ...state.purchaseHistory,
                    getPurchaseHistoryStatus: "failure",
                }
            };

        case SET_PAYMENT_QUERY_DATA:
            return {
                ...state,
                query: {
                    ...state.query,
                    [action.payload.name]: {
                        ...action.payload.value,
                    },
                },
            }
        case CLEAR_PAYMENT_QUERY:
            return {
                ...state,
                query: initialState.query
            }
        default:
            return state
    }
}
export default payment;