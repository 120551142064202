//landing
export const GET_LANDING: any = 'GET_LANDING';
export const GET_LANDING_SUCCEEDED: any = 'GET_LANDING_SUCCEEDED';
export const GET_LANDING_FAILED: any = 'GET_LANDING_FAILED';

export const ADD_LANDING: any = 'ADD_LANDING';
export const ADD_LANDING_SUCCEEDED: any = 'ADD_LANDING_SUCCEEDED';
export const ADD_LANDING_FAILED: any = 'ADD_LANDING_FAILED';

export const EDIT_LANDING: any = 'EDIT_LANDING';
export const EDIT_LANDING_SUCCEEDED: any = 'EDIT_LANDING_SUCCEEDED';
export const EDIT_LANDING_FAILED: any = 'EDIT_LANDING_FAILED';

export const DELETE_LANDING: any = 'DELETE_LANDING';
export const DELETE_LANDING_SUCCEEDED: any = 'DELETE_LANDING_SUCCEEDED';
export const DELETE_LANDING_FAILED: any = 'DELETE_LANDING_FAILED';

//landing section
export const GET_LANDING_SECTION: any = 'GET_LANDING_SECTION';
export const GET_LANDING_SECTION_SUCCEEDED: any = 'GET_LANDING_SECTION_SUCCEEDED';
export const GET_LANDING_SECTION_FAILED: any = 'GET_LANDING_SECTION_FAILED';

export const ADD_LANDING_SECTION: any = 'ADD_LANDING_SECTION';
export const ADD_LANDING_SECTION_SUCCEEDED: any = 'ADD_LANDING_SECTION_SUCCEEDED';
export const ADD_LANDING_SECTION_FAILED: any = 'ADD_LANDING_SECTION_FAILED';

export const EDIT_LANDING_SECTION: any = 'EDIT_LANDING_SECTION';
export const EDIT_LANDING_SECTION_SUCCEEDED: any = 'EDIT_LANDING_SECTION_SUCCEEDED';
export const EDIT_LANDING_SECTION_FAILED: any = 'EDIT_LANDING_SECTION_FAILED';

export const DELETE_LANDING_SECTION: any = 'DELETE_LANDING_SECTION';

export const DELETE_LANDING_SECTION_SUCCEEDED: any =
  'DELETE_LANDING_SECTION_SUCCEEDED';

export const DELETE_LANDING_SECTION_FAILED: any = 'DELETE_LANDING_SECTION_FAILED';
