//terms and conditions

export const GET_TERMS_CONDITIONS: any = 'GET_TERMS_CONDITIONS';
export const GET_TERMS_CONDITIONS_SUCCEEDED: any = 'GET_TERMS_CONDITIONS_SUCCEEDED';
export const GET_TERMS_CONDITIONS_FAILED: any = 'GET_TERMS_CONDITIONS_FAILED';

export const ADD_TERMS_CONDITIONS: any = 'ADD_TERMS_CONDITIONS';
export const ADD_TERMS_CONDITIONS_SUCCEEDED: any = 'ADD_TERMS_CONDITIONS_SUCCEEDED';
export const ADD_TERMS_CONDITIONS_FAILED: any = 'ADD_TERMS_CONDITIONS_FAILED';

export const EDIT_TERMS_CONDITIONS: any = 'EDIT_TERMS_CONDITIONS';
export const EDIT_TERMS_CONDITIONS_SUCCEEDED: any =
  'EDIT_TERMS_CONDITIONS_SUCCEEDED';
export const EDIT_TERMS_CONDITIONS_FAILED: any = 'EDIT_TERMS_CONDITIONS_FAILED';

export const DELETE_TERMS_CONDITIONS: any = 'DELETE_TERMS_CONDITIONS';
export const DELETE_TERMS_CONDITIONS_SUCCEEDED: any =
  'DELETE_TERMS_CONDITIONS_SUCCEEDED';
export const DELETE_TERMS_CONDITIONS_FAILED: any = 'DELETE_TERMS_CONDITIONS_FAILED';
