import { LIVE_USER_COUNT, TOGGLE_USER_LIVE } from '.';

export const toggleUserLiveStatus = (value: boolean) => ({
  type: TOGGLE_USER_LIVE,
  payload: value,
});

export const storeLiveUserData = (data) => {
  return {
    type: LIVE_USER_COUNT,
    payload: data,
  };
};
