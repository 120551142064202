import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/contact/types';
import { deleteData, fetchData, postData, putData } from './genericSagas';

function* contactWatcher() {
  yield takeLatest(actions.GET_CONTACT_INQUIRIES, fetchData);
  yield takeLatest(actions.GET_CONTACT_INQUIRIES_BY_ID, fetchData);
  yield takeLatest(actions.EDIT_CONTACT_INQUIRIES, putData);
  yield takeLatest(actions.ADD_CONTACT_INQUIRIES_REQUEST, postData);
  //Contact Inquiries comment
  yield takeLatest(actions.GET_CONTACT_COMMENTS_REQUEST, fetchData);
  yield takeLatest(actions.ADD_CONTACT_COMMENTS_REQUEST, postData);
  yield takeLatest(actions.EDIT_CONTACT_COMMENTS_REQUEST, putData);
  yield takeLatest(actions.DELETE_CONTACT_COMMENTS_REQUEST, deleteData);
  //Contact Inquiries task
  yield takeLatest(actions.GET_CONTACT_TASK_REQUEST, fetchData);
  yield takeLatest(actions.ADD_CONTACT_TASK_REQUEST, postData);
  yield takeLatest(actions.EDIT_CONTACT_TASK_REQUEST, putData);
  yield takeLatest(actions.DELETE_CONTACT_TASK_REQUEST, deleteData);
}

export default contactWatcher;
