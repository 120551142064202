import { toggleUserLiveStatus } from '@/actions/userTrack';
import { isLogin } from '@/helpers/loginHelper';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReconnectingWebSocket from 'reconnecting-websocket';

const UserLiveTrack = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state?.auth?.creds?.user_data);
  const userTrackerReducer = useSelector((state: any) => state?.userTrackReducer);
  // const { isLive } = userTrackerReducer;
  const socketRef: any = React.useRef();
  var wsStart;
  var endpoint;
  useEffect(() => {
    if (isLogin()) {
      wsStart = 'ws://';
      if (location.protocol == 'https:') {
        wsStart = 'wss://';
      }
      endpoint =
        process.env.NEXT_PUBLIC_SOCKET_URL +
        `/track/?key=${window.localStorage
          .getItem('token')
          .split('Token')[1]
          .trim()}`;
      socketRef.current = new ReconnectingWebSocket(endpoint);
      socketRef.current.onopen = (e) => {};
      socketRef.current.onmessage = (e) => {};
      socketRef.current.onerror = (e) => {};
      socketRef.current.onclose = (e) => {};
      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, []);

  const EXPIRY_TIME = 120000;

  useEffect(() => {
    socketRef?.current?.send(
      JSON.stringify({
        is_live: userTrackerReducer?.isLive,
        action: 'user_live_status',
      })
    );
  }, [userTrackerReducer?.isLive]);

  const updateExpireTime = () => {
    dispatch(toggleUserLiveStatus(true));
    const expireTime: any = Date.now() + EXPIRY_TIME;
    localStorage.setItem('expireTime', expireTime);
  };

  const checkForInactivity = () => {
    const expireTime: any = localStorage.getItem('expireTime');
    if (expireTime < Date.now()) {
      dispatch(toggleUserLiveStatus(false));
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     checkForInactivity();
  //   }, EXPIRY_TIME);
  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   updateExpireTime();
  //   window.addEventListener('click', updateExpireTime);
  //   window.addEventListener('keypress', updateExpireTime);
  //   window.addEventListener('scroll', updateExpireTime);
  //   window.addEventListener('mousemove', updateExpireTime);

  //   return () => {
  //     window.removeEventListener('click', updateExpireTime);
  //     window.removeEventListener('keypress', updateExpireTime);
  //     window.removeEventListener('scroll', updateExpireTime);
  //     window.removeEventListener('mousemove', updateExpireTime);
  //   };
  // }, []);
  return <></>;
};

export default UserLiveTrack;
