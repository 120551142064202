import {
  GET_GROUP_ANSWERS_SUCCEEDED,
  GET_GROUP_ANSWERS_FAILED,
  ADD_GROUP_ANSWER_SUCCEEDED,
  ADD_GROUP_ANSWER_FAILED,
  DELETE_GROUP_ANSWER_SUCCEEDED,
  DELETE_GROUP_ANSWER_FAILED,
  EDIT_GROUP_ANSWER_SUCCEEDED,
  EDIT_GROUP_ANSWER_FAILED,
  CLEAR_GROUP_ANSWER,
} from '../../../src/actions';
import { IGroupAnswers } from '../../interfaces/model_tests/group_answers';
import { getLocalStore } from 'next-persist';

const initialState: IGroupAnswers = {
  group_answers: {
    data: [],
  },
  group_answer_data: {},
};
const persistedState = getLocalStore('groupAnswers', initialState);

const groupAnswers = (state = persistedState, action) => {
  switch (action.type) {
    case GET_GROUP_ANSWERS_SUCCEEDED:
      return {
        ...state,
        group_answers: action.data,
      };

    case GET_GROUP_ANSWERS_FAILED:
      return {
        ...state,
      };
    case ADD_GROUP_ANSWER_SUCCEEDED:
      return {
        ...state,
        group_answers: {
          ...state.group_answers,
          data: [action.data.data, ...state.group_answers.data],
        },
        group_answer_data: action.data.data,
      };

    case ADD_GROUP_ANSWER_FAILED:
      return {
        ...state,
      };
    case EDIT_GROUP_ANSWER_SUCCEEDED:
      return {
        ...state,
        group_answers: {
          ...state.group_answers,
          data: state.group_answers.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_GROUP_ANSWER_FAILED:
      return {
        ...state,
      };
    case DELETE_GROUP_ANSWER_SUCCEEDED:
      return {
        ...state,
        group_answers: {
          ...state.group_answers,
          data: state.group_answers.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_GROUP_ANSWER_FAILED:
      return {
        ...state,
      };

    case CLEAR_GROUP_ANSWER:
      return {
        ...state,
        group_answer_data: {},
      };
    default:
      return state;
  }
};

export default groupAnswers;
