import { getLocalStore } from 'next-persist';
import { ISegment } from 'src/interfaces/segment';
import {
  GET_SEGMENT_FAILED,
  GET_SEGMENT_REQUEST,
  GET_SEGMENT_SUCCEEDED,
  SET_SEGMENT_DATA,
} from '../actions';

const initialState: ISegment = {
  segment: {
    data: [],
  },
  loading: {
    getLoading: false,
    addLoading: false,
    editLoading: false,
    deleteLoading: false,
  },
  error: {
    geterror: {},
    adderror: {},
    editerror: {},
    deleteerror: {},
  },
  helperObj: {
    getModal: false,
    editModal: false,
  },
};
const persistedState = getLocalStore('segments', initialState);

const segments = (state = persistedState, action) => {
  switch (action.type) {
    case GET_SEGMENT_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLoading: true,
        },
      };
    case GET_SEGMENT_SUCCEEDED:
      return {
        ...state,
        segment: {
          ...state.segment,
          data: action.data.data,
        },
        loading: {
          ...state.loading,
          getLoading: false,
        },
        error: {},
      };
    case GET_SEGMENT_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLoading: false,
        },
        error: {
          ...state.error,
          getError: action.error,
        },
      };
    case SET_SEGMENT_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.key],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    default:
      return { ...state };
  }
};

export default segments;
