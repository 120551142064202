//email

export const SET_EMAIL_DATA: any = 'SET_EMAIL_DATA';
export const SET_EMAIL_EDIT_DATA: any = 'SET_EMAIL_EDIT_DATA';
export const CLEAR_EMAIL_DATA: any = 'CLEAR_EMAIL_DATA';

export const SEND_COURSE_CLIENT_EMAIL: any = 'SEND_COURSE_CLIENT_EMAIL';
export const SEND_COURSE_CLIENT_EMAIL_SUCCEEDED: any =
  'SEND_COURSE_CLIENT_EMAIL_SUCCEEDED';
export const SEND_COURSE_CLIENT_EMAIL_FAILED: any =
  'SEND_COURSE_CLIENT_EMAIL_FAILED';
