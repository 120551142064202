import {
  GET_MODEL_TESTS_SUCCEEDED,
  GET_MODEL_TESTS_FAILED,
  ADD_MODEL_TEST,
  ADD_MODEL_TEST_SUCCEEDED,
  ADD_MODEL_TEST_FAILED,
  DELETE_MODEL_TEST_SUCCEEDED,
  DELETE_MODEL_TEST_FAILED,
  EDIT_MODEL_TEST_SUCCEEDED,
  EDIT_MODEL_TEST_FAILED,
  GET_MODEL_TESTS,
  GET_MODEL_TEST_BY_SLUG,
  GET_MODEL_TEST_BY_SLUG_SUCCEEDED,
  GET_MODEL_TEST_BY_SLUG_FAILED,
  GET_MODEL_TEST_CLIENTS,
  GET_MODEL_TEST_CLIENTS_SUCCEEDED,
  EDIT_MODEL_TEST,
  SET_MODEL_TESTS_EDIT_DATA,
  SET_MODEL_TEST_DATA,
  ADD_MODEL_TEST_CLIENT,
  ADD_MODEL_TEST_CLIENT_SUCCEEDED,
  ADD_MODEL_TEST_CLIENT_FAILED,
  CLEAR_MODEL_TEST_STATUS,
} from '../../../src/actions';
import { IModelTests } from '../../interfaces/model_tests/model_tests';
import { getLocalStore } from 'next-persist';

const initialState: IModelTests = {
  model_tests: {
    data: [],
    partialUpdate: false,
  },
  loading: null,
  modelTestBySlug: {},
  modelTestBySlugLoading: null,
  modelTestClients: {
    data: [],
  },
  modelTestClientsLoading: null,
  modelTestUpdateStatus: '',
  addModelTestStatus: '',
  addModelTestClientStatus: '',
};
const persistedState = getLocalStore('modelTests', initialState);

const modelTests = (state = persistedState, action) => {
  switch (action.type) {
    case GET_MODEL_TESTS:
      return {
        ...state,
        loading: true,
      };
    case GET_MODEL_TESTS_SUCCEEDED:
      return {
        ...state,
        model_tests: action.data,
        loading: false,
      };

    case GET_MODEL_TESTS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_MODEL_TEST_BY_SLUG:
      return {
        ...state,
        modelTestBySlugLoading: true,
      };
    case GET_MODEL_TEST_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        modelTestBySlug: action.data,
        modelTestBySlugLoading: false,
      };

    case GET_MODEL_TEST_BY_SLUG_FAILED:
      return {
        ...state,
        modelTestBySlugLoading: false,
      };
    case ADD_MODEL_TEST:
      return {
        ...state,
        addModelTestStatus: 'request',
      };
    case ADD_MODEL_TEST_SUCCEEDED:
      return {
        ...state,
        model_tests: {
          ...state.model_tests,
          data: [action.data.data, ...state.model_tests.data],
        },
        addModelTestStatus: 'success',
      };

    case ADD_MODEL_TEST_FAILED:
      return {
        ...state,
        addModelTestStatus: 'failure',
      };
    case EDIT_MODEL_TEST:
      return {
        ...state,
        modelTestUpdateStatus: 'request',
      };
    case EDIT_MODEL_TEST_SUCCEEDED:
      return {
        ...state,
        model_tests: {
          ...state.model_tests,
          data: state.model_tests.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          updateSuccess: true,
        },
        modelTestBySlug: action.data.data,
        modelTestUpdateStatus: 'success',
      };

    case EDIT_MODEL_TEST_FAILED:
      return {
        ...state,
        modelTestUpdateStatus: 'failure',
      };
    case DELETE_MODEL_TEST_SUCCEEDED:
      return {
        ...state,
        model_tests: {
          ...state.model_tests,
          data: state.model_tests.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_MODEL_TEST_FAILED:
      return {
        ...state,
      };
    case GET_MODEL_TEST_CLIENTS:
      return {
        ...state,
        modelTestClientsLoading: true,
      };
    case GET_MODEL_TEST_CLIENTS_SUCCEEDED:
      return {
        ...state,
        modelTestClients: action.data,
        modelTestClientsLoading: false,
      };

    case GET_MODEL_TESTS_FAILED:
      return {
        ...state,
        modelTestClientsLoading: false,
      };

    case ADD_MODEL_TEST_CLIENT:
      return {
        ...state,
        addModelTestClientStatus: 'request',
      };
    case ADD_MODEL_TEST_CLIENT_SUCCEEDED:
      return {
        ...state,
        modelTestClients: {
          data: [action.data.data, ...state.modelTestClients.data],
        },
        addModelTestClientStatus: 'success',
      };

    case ADD_MODEL_TEST_CLIENT_FAILED:
      return {
        ...state,
        addModelTestClientStatus: 'failure',
      };
    case SET_MODEL_TESTS_EDIT_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };
    case SET_MODEL_TEST_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.value,
        },
      };
    case CLEAR_MODEL_TEST_STATUS:
      return {
        ...state,
        modelTestUpdateStatus: '',
        addModelTestStatus: '',
        addModelTestClientStatus: '',
      };
    default:
      return state;
  }
};

export default modelTests;
