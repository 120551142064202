import {
  GET_QUESTION_SUCCEDED,
  GET_QUESTION_FAIL,
  ADD_QUIZ_ANSWER_SHEET_SUCCEEDED,
  GET_QUIZ_ANSWER_SHEET,
  GET_QUIZ_ANSWER_SHEET_SUCCEEDED,
  GET_QUIZ_ANSWER_SHEET_FAILED,
  GET_QUIZ_SUMMARY,
  GET_QUIZ_SUMMARY_SUCCEEDED,
  GET_QUIZ_SUMMARY_FAILED,
  GET_QUESTION,
  SET_QUIZ_EDIT_DATA,
  SET_QUIZ_COMPLETE_DATA,
} from '../actions/index';
import { IQuiz } from '../interfaces/quiz';
import { getLocalStore } from 'next-persist';

const initialState: IQuiz = {
  quiz: {
    data: [],
    loading: '',
  },
  addAnswer: {
    success: false,
  },
  myAnswer: {
    data: [],
    loading: false,
  },
  quizSummary: {
    data: [],
    loading: false,
  },
  viewResult: false,
  showAnswer: false,
  currentQuestionIndex: 0,
};
const persistedState = getLocalStore('quizReducer', initialState);

const quizReducer = (state = persistedState, action) => {
  switch (action.type) {
    case GET_QUESTION:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: true,
        },
      };
    case GET_QUESTION_SUCCEDED:
      return {
        ...state,
        quiz: { ...action.data, loading: false },
      };
    case GET_QUESTION_FAIL:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: false,
        },
      };
    case ADD_QUIZ_ANSWER_SHEET_SUCCEEDED:
      return {
        ...state,
        success: true,
      };
    case GET_QUIZ_ANSWER_SHEET:
      return {
        ...state,
        myAnswer: {
          ...state.myAnswer,
          loading: true,
        },
      };
    case GET_QUIZ_ANSWER_SHEET_SUCCEEDED:
      return {
        ...state,
        myAnswer: {
          ...state.myAnswer,
          loading: false,
          data: action.data,
        },
      };
    case GET_QUIZ_ANSWER_SHEET_FAILED:
      return {
        ...state,
        myAnswer: {
          ...state.myAnswer,
          loading: false,
        },
      };
    case GET_QUIZ_SUMMARY:
      return {
        ...state,
        quizSummary: {
          ...state.quizSummary,
          loading: true,
        },
      };
    case GET_QUIZ_SUMMARY_SUCCEEDED:
      return {
        ...state,
        quizSummary: {
          ...state.quizSummary,
          loading: false,
          data: action.data,
        },
      };
    case GET_QUIZ_SUMMARY_FAILED:
      return {
        ...state,
        quizSummary: {
          ...state.quizSummary,
          loading: false,
        },
      };
    case SET_QUIZ_EDIT_DATA:
      return {
        ...state,
        [action.payload?.data?.key]: action.payload?.data?.value,
      };
    case SET_QUIZ_COMPLETE_DATA:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          [action.payload?.data?.key]: action.payload?.data?.value,
        },
      };
    default:
      return state;
  }
};

export default quizReducer;
