//Office
export const GET_OFFICE: any = 'GET_OFFICE';
export const GET_OFFICE_SUCCEEDED: any = 'GET_OFFICE_SUCCEEDED';
export const GET_OFFICE_FAILED: any = 'GET_OFFICE_FAILED';

export const ADD_OFFICE: any = 'ADD_OFFICE';
export const ADD_OFFICE_SUCCEEDED: any = 'ADD_OFFICE_SUCCEEDED';
export const ADD_OFFICE_FAILED: any = 'ADD_OFFICE_FAILED';

export const EDIT_OFFICE: any = 'EDIT_OFFICE';
export const EDIT_OFFICE_SUCCEEDED: any = 'EDIT_OFFICE_SUCCEEDED';
export const EDIT_OFFICE_FAILED: any = 'EDIT_OFFICE_FAILED';

export const DELETE_OFFICE: any = 'DELETE_OFFICE';
export const DELETE_OFFICE_SUCCEEDED: any = 'DELETE_OFFICE_SUCCEEDED';
export const DELETE_OFFICE_FAILED: any = 'DELETE_OFFICE_FAILED';
