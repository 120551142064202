export const USER_LOGIN: any = 'USER_LOGIN';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGIN_SUCCEEDED: any = 'USER_LOGIN_SUCCEEDED';

export const USER_REGISTER: any = 'USER_REGISTER';
export const USER_REGISTER_FAILED: any = 'USER_REGISTER_FAILED';
export const USER_REGISTER_SUCCEEDED: any = 'USER_REGISTER_SUCCEEDED';

export const USER_VERIFICATION: any = 'USER_VERIFICATION';
export const USER_VERIFICATION_FAILED: any = 'USER_VERIFICATION_FAILED';
export const USER_VERIFICATION_SUCCEEDED: any = 'USER_VERIFICATION_SUCCEEDED';

export const USER_VERIFICATION_REQUEST: any = 'USER_VERIFICATION_REQUEST';
export const USER_VERIFICATION_REQUEST_FAILED: any =
  'USER_VERIFICATION_REQUEST_FAILED';
export const USER_VERIFICATION_REQUEST_SUCCEEDED: any =
  'USER_VERIFICATION_REQUEST_SUCCEEDED';

export const USER_LOGOUT: any = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCEEDED: any = 'USER_LOGOUT_SUCCEEDED';

export const GET_PROFILE: any = 'GET_PROFILE';
export const GET_PROFILE_FAILED: any = 'GET_PROFILE_FAILED';
export const GET_PROFILE_SUCCEEDED: any = 'GET_PROFILE_SUCCEEDED';

export const UPDATE_PROFILE: any = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_FAILED: any = 'UPDATE_PROFILE_FAILED';
export const UPDATE_PROFILE_SUCCEEDED: any = 'UPDATE_PROFILE_SUCCEEDED';

export const SET_DATA: any = 'SET_DATA';
export const ADD_DATA: any = 'ADD_DATA';
export const CLEAR_AUTH_DATA: any = 'CLEAR_AUTH_DATA';
export const SET_AUTH_NESTED_DATA: any = 'SET_AUTH_NESTED_DATA';

export const CHANGE_USER_PASSWORD: any = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCEEDED: any = 'CHANGE_USER_PASSWORD_SUCCEEDED';
export const CHANGE_USER_PASSWORD_FAILED: any = 'CHANGE_USER_PASSWORD_FAILED';

export const ADD_JOB_EXPERIENCE: any = 'ADD_JOB_EXPERIENCE';
export const ADD_JOB_EXPERIENCE_SUCCEEDED: any = 'ADD_JOB_EXPERIENCE_SUCCEEDED';
export const ADD_JOB_EXPERIENCE_FAILED: any = 'ADD_JOB_EXPERIENCE_FAILED';

export const EDIT_JOB_EXPERIENCE: any = 'EDIT_JOB_EXPERIENCE';
export const EDIT_JOB_EXPERIENCE_SUCCEEDED: any = 'EDIT_JOB_EXPERIENCE_SUCCEEDED';
export const EDIT_JOB_EXPERIENCE_FAILED: any = 'EDIT_JOB_EXPERIENCE_FAILED';

export const DELETE_JOB_EXPERIENCE: any = 'DELETE_JOB_EXPERIENCE';
export const DELETE_JOB_EXPERIENCE_SUCCEEDED: any =
  'DELETE_JOB_EXPERIENCE_SUCCEEDED';
export const DELETE_JOB_EXPERIENCE_FAILED: any = 'DELETE_JOB_EXPERIENCE_FAILED';

export const ADD_EDUCATION: any = 'ADD_EDUCATION';
export const ADD_EDUCATION_SUCCEEDED: any = 'ADD_EDUCATION_SUCCEEDED';
export const ADD_EDUCATION_FAILED: any = 'ADD_EDUCATION_FAILED';

export const EDIT_EDUCATION: any = 'EDIT_EDUCATION';
export const EDIT_EDUCATION_SUCCEEDED: any = 'EDIT_EDUCATION_SUCCEEDED';
export const EDIT_EDUCATION_FAILED: any = 'EDIT_EDUCATION_FAILED';

export const DELETE_EDUCATION: any = 'DELETE_EDUCATION';
export const DELETE_EDUCATION_SUCCEEDED: any = 'DELETE_EDUCATION_SUCCEEDED';
export const DELETE_EDUCATION_FAILED: any = 'DELETE_EDUCATION_FAILED';

// Forgot Password
export const REQUEST_TOKEN: any = 'REQUEST_TOKEN';
export const REQUEST_TOKEN_SUCCEEDED: any = 'REQUEST_TOKEN_SUCCEEDED';
export const REQUEST_TOKEN_FAILED: any = 'REQUEST_TOKEN_FAILED';

export const TOKEN_VALIDATION: any = 'TOKEN_VALIDATION';
export const TOKEN_VALIDATION_SUCCEEDED: any = 'TOKEN_VALIDATION_SUCCEEDED';
export const TOKEN_VALIDATION_FAILED: any = 'TOKEN_VALIDATION_FAILED';

export const RESET_PASSWORD: any = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCEEDED: any = 'RESET_PASSWORD_SUCCEEDED';
export const RESET_PASSWORD_FAILED: any = 'RESET_PASSWORD_FAILED';

//For unauthorized partner detail
export const GET_SIGNUP_PARTNER_DETAIL_REQUEST: any = 'GET_PARTNER_DETAIL_REQUEST';
export const GET_SIGNUP_PARTNER_DETAIL_SUCCEEDED: any =
  'GET_SIGNUP_PARTNER_DETAIL_SUCCEEDED';
export const GET_SIGNUP_PARTNER_DETAIL_FAILED: any =
  'GET_SIGNUP_PARTNER_DETAIL_FAILED';

// User Dashboard
export const GET_USER_DASHBOARD: any = 'GET_USER_DASHBOARD';
export const GET_USER_DASHBOARD_FAILED: any = 'GET_USER_DASHBOARD_FAILED';
export const GET_USER_DASHBOARD_SUCCEEDED: any = 'GET_USER_DASHBOARD_SUCCEEDED';

//get Address(country, city, country-code) from ip
export const GET_ADDRESS_FROM_IPADDRESS_REQUEST: any =
  'GET_ADDRESS_FROM_IPADDRESS_REQUEST';
export const GET_ADDRESS_FROM_IPADDRESS_SUCCEEDED: any =
  'GET_ADDRESS_FROM_IPADDRESS_SUCCEEDED';
export const GET_ADDRESS_FROM_IPADDRESS_FAILED: any =
  'GET_COUNTRY_FROM_IPADDRESS_FAILED';

export const NEW_DEVICE_VERIFICATION_REQUEST: any =
  'NEW_DEVICE_VERIFICATION_REQUEST';
export const NEW_DEVICE_VERIFICATION_SUCCEEDED = 'NEW_DEVICE_VERIFICATION_SUCCEEDED';
export const NEW_DEVICE_VERIFICATION_FAILED: any = 'NEW_DEVICE_VERIFICATION_FAILED';

export const RESEND_VERIFICATION_DEVICE_REQUEST: any =
  'RESEND_VERIFICATION_DEVICE_REQUEST';
export const RESEND_VERIFICATION_DEVICE_SUCCEEDED =
  'RESEND_VERIFICATION_DEVICE_SUCCEEDED';
export const RESEND_VERIFICATION_DEVICE_FAILED: any =
  'RESEND_VERIFICATION_DEVICE_FAILED';


//sign up otp

export const GET_SIGNUP_OTP: any = 'GET_SIGNUP_OTP';
export const GET_SIGNUP_OTP_FAILED: any = 'GET_SIGNUP_OTP_FAILED';
export const GET_SIGNUP_OTP_SUCCEEDED: any = 'GET_SIGNUP_OTP_SUCCEEDED';

export const VERIFY_SIGNUP_OTP: any = 'VERIFY_SIGNUP_OTP';
export const VERIFY_SIGNUP_OTP_FAILED: any = 'VERIFY_SIGNUP_OTP_FAILED';
export const VERIFY_SIGNUP_OTP_SUCCEEDED: any = 'VERIFY_SIGNUP_OTP_SUCCEEDED';

