import { useState, useEffect, FC } from 'react';

export interface IBooleanArgs {
  isGalaxyZFold?: boolean, isMobile?: boolean, isTablet?: boolean, isLaptop?: boolean, isDesktopXL?: boolean
}
const useWidth = (): IBooleanArgs => {
  const [width, setWidth] = useState<number>(
    typeof window !== 'undefined' ? window.outerWidth : 920
  );
  const initialData = {
    isMobile: false,
    isGalaxyZFold: false,
    isTablet: false,
    isLaptop: false,
    isDesktopXL: false,
  };

  const [data, setData] = useState(initialData);
  useEffect(() => {
    resetWidth();
    window.addEventListener('resize', resetWidth);
    // if (window.matchMedia("(orientation: portrait)").matches || window.matchMedia("(orientation: landscape)").matches) {
    //   resetWidth()
    // }
    return () => {
      window.removeEventListener('resize', resetWidth);
    };
  }, [typeof window !== 'undefined' && window.outerWidth]);

  const resetWidth = (): void => {
    const windowWidth = typeof window !== 'undefined' ? window.outerWidth : 920;
    setWidth(typeof window !== 'undefined' ? window.outerWidth : 920);
    var mobileOS;
    if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined')
      mobileOS = /(iPad|iPhone|iPod|webOS|Android|Windows Phone|BlackBerry)/g.test(
        navigator.userAgent
      );
    
     if (windowWidth <= device.PHONE || mobileOS) {
       setData({ ...initialData, isMobile: true });
       if (windowWidth <= device.ZFOLD)
         setData({ ...initialData, isMobile: true, isGalaxyZFold: true });
     } else if (windowWidth <= device.TABLET)
       setData({ ...initialData, isTablet: true });
     else if (windowWidth <= device.DESKTOP)
       setData({ ...initialData, isLaptop: true });
     else setData({ ...initialData, isDesktopXL: true });
  };

  //check if mobile/tab or not
  // var mobileOS;
  // if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined')
  //   mobileOS = /(iPad|iPhone|iPod|webOS|Android|Windows Phone|BlackBerry)/g.test(
  //     navigator.userAgent
  //   );

  // if (width <= device.PHONE || mobileOS) data.isMobile = true;
  // if (width <= device.ZFOLD) data.isGalaxyZFold = true;
  // else if (width <= device.TABLET) data.isTablet = true;
  // else if (width <= device.DESKTOP) data.isLaptop = true;
  // else data.isDesktopXL = true;
  return data as IBooleanArgs;
};

export default useWidth;

interface IDevice {
  ZFOLD: number
  PHONE: number,
  TABLET: number,
  DESKTOP: number,
  DESKTOP_XL: number,
}

export const device: IDevice = {
  ZFOLD: 300,
  PHONE: 600,
  TABLET: 820,
  DESKTOP: 1025,
  DESKTOP_XL: 1920
};
