import { getLocalStore } from 'next-persist';
import {
  GET_ALL_ASSESSMENT_FAILED,
  GET_ALL_ASSESSMENT_REQUEST,
  GET_ALL_ASSESSMENT_SUCCEEDED,
} from '../../src/actions';

const initialState: any = {
  assessment: {
    getAllAssessmentStatus: '',
    data: [],
  },
};
const persistedState = getLocalStore('allAssessmentReducer', initialState);

const allAssessmentReducer = (state = persistedState, action) => {
  switch (action.type) {
    case GET_ALL_ASSESSMENT_REQUEST:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentStatus: 'request',
        },
      };

    case GET_ALL_ASSESSMENT_SUCCEEDED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentStatus: 'success',
          data: action.data.data,
        },
      };
    case GET_ALL_ASSESSMENT_FAILED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          getAssessmentStatus: 'failure',
        },
      };

    default:
      return state;
  }
};

export default allAssessmentReducer;
