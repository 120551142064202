//SEO
export const GET_SEO: any = 'GET_SEO';
export const GET_SEO_SUCCEEDED: any = 'GET_SEO_SUCCEEDED';
export const GET_SEO_FAILED: any = 'GET_SEO_FAILED';

export const ADD_SEO: any = 'ADD_SEO';
export const ADD_SEO_SUCCEEDED: any = 'ADD_SEO_SUCCEEDED';
export const ADD_SEO_FAILED: any = 'ADD_SEO_FAILED';

export const EDIT_SEO: any = 'EDIT_SEO';
export const EDIT_SEO_SUCCEEDED: any = 'EDIT_SEO_SUCCEEDED';
export const EDIT_SEO_FAILED: any = 'EDIT_SEO_FAILED';

export const DELETE_SEO: any = 'DELETE_SEO';
export const DELETE_SEO_SUCCEEDED: any = 'DELETE_SEO_SUCCEEDED';
export const DELETE_SEO_FAILED: any = 'DELETE_SEO_FAILED';
