import { getLocalStore } from 'next-persist';
import { IChat, IChatMessage, IThread } from 'src/interfaces/chat';
import {
  ADD_DATA_THREAD,
  ADD_DATA_TO_CHAT,
  CHAT_STATE_MODIFY,
  GET_ALL_THREADS,
  GET_ALL_THREADS_FAILED,
  GET_ALL_THREADS_SUCCEEDED,
  GET_ALL_THREAD_CHATS,
  GET_ALL_THREAD_CHATS_FAILED,
  GET_ALL_THREAD_CHATS_SUCCEEDED,
  MODIFY_CHAT,
  READ_ALL_SEEN_MESSAGES,
  UPDATE_THREAD,
  UPDATE_TYPING_STATUS,
} from '../actions';

const initialState: IChat = {
  fetchedThreadIds: [],
  threads: {
    loading: false,
    data: [],
    meta: {},
  },
  recentThread: {},
  threadDetail: null,
  threadChats: {},
  query: {
    threads: { page: 1, per_page: 10 },
    threadChats: { page: 1, per_page: 10 },
  },
  activities: {
    typing: false,
    typingTimeout: 0,
  },
};

const persistedState = getLocalStore('chat', initialState);

const chat = (state = persistedState, action) => {
  switch (action.type) {
    case GET_ALL_THREADS:
      return {
        ...state,
        threads: {
          ...state.threads,
          loading: true,
        },
      };
    case GET_ALL_THREADS_SUCCEEDED:
      return {
        ...state,
        threads: {
          ...state.threads,
          loading: false,
          data: action.data.data,
          meta: action.meta,
        },
      };

    case GET_ALL_THREADS_FAILED:
      return {
        ...state,
        threads: {
          ...state.threads,
          loading: false,
        },
      };
    case UPDATE_THREAD:
      const manipulateThreadObjectArray = () => {
        var sortedThread: IThread[];
        if (
          action.payload.type === 'create' ||
          action.payload.type === 'new_message'
        ) {
          sortedThread = state?.threads?.data.sort(function (
            x: IThread,
            y: IThread
          ) {
            return x.id == action.payload.id
              ? -1
              : y.id == action.payload.id
              ? 1
              : 0;
          });
        } else {
          sortedThread = state?.threads?.data;
        }
        var updatedData = sortedThread?.map((each: IThread) =>
          each.id == action.payload.id
            ? { ...each, [action.payload.key]: action.payload.value }
            : each
        );
        return updatedData;
      };
      return {
        ...state,
        threads: {
          ...state.threads,
          data: manipulateThreadObjectArray(),
        },
      };
    case UPDATE_TYPING_STATUS:
      const currentThread = state.threadChats[state.recentThread?.id];
      return {
        ...state,
        threadChats: {
          ...state.threadChats,
          [state.recentThread?.id]: {
            ...currentThread,
            data: {
              ...currentThread?.data,
              data: {
                ...currentThread?.data?.data,
                thread: {
                  ...currentThread?.data?.data?.thread,
                  other_user: {
                    ...currentThread?.data?.data?.thread?.other_user,
                    typing: action.payload.typing,
                  },
                },
              },
            },
          },
        },
      };
    case READ_ALL_SEEN_MESSAGES:
      const currentThread1 = state.threadChats[state.recentThread?.id];
      return {
        ...state,
        threadChats: {
          ...state.threadChats,
          [state.recentThread?.id]: {
            ...currentThread1,
            data: {
              ...currentThread1.data,
              data: {
                ...currentThread1?.data.data,
                chats: currentThread1?.data.data?.chats?.map((each: IChatMessage) =>
                  each.read_at === null
                    ? { ...each, read_at: action.payload.current_time }
                    : each
                ),
                last_seen_message: {
                  id: action.payload.last_seen_message,
                },
              },
            },
          },
        },
      };
    case GET_ALL_THREAD_CHATS:
      return {
        ...state,
        threadChats: {
          ...state.threadChats,
          [state.recentThread?.id]: {
            ...state.threadChats[state.recentThread?.id],
            loading: true,
          },
        },
      };
    case GET_ALL_THREAD_CHATS_SUCCEEDED:
      return {
        ...state,
        threadChats: {
          ...state.threadChats,
          [state.recentThread?.id]: {
            ...state.threadChats[state.recentThread?.id],
            loading: false,
            data: action.data.data,
            meta: action.data.meta,
          },
        },
      };

    case GET_ALL_THREAD_CHATS_FAILED:
      return {
        ...state,
        threadChats: {
          ...state.threadChats,
          [state.recentThread?.id]: {
            ...state.threadChats[state.recentThread?.id],
            loading: false,
          },
        },
      };
    case ADD_DATA_THREAD:
      return {
        ...state,
        threadDetail: action.payload,
      };
    case MODIFY_CHAT:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case CHAT_STATE_MODIFY:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };
    case ADD_DATA_TO_CHAT:
      const currentThread2 = state.threadChats[state.recentThread?.id];
      return {
        ...state,
        threadChats: {
          ...state.threadChats,
          [state.recentThread?.id]: {
            data: {
              ...currentThread2.data,
              data: {
                ...currentThread2?.data.data,
                chats: [...currentThread2.data.data.chats, action.payload.data.data],
              },
            },
          },
        },
      };
    default:
      return state;
  }
};

export default chat;
