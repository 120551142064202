import useWidth from '@/helpers/widthUtil';
import React, { useEffect, useState } from 'react';

export default function GoTop() {
  const [is_visible, setIsVisible] = useState(false);
  const { isMobile } = useWidth();

  useEffect(() => {
    document.addEventListener('scroll', function (e) {
      toggleVisibility();
    });
  });

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {is_visible && !isMobile ? (
        <div className="back-to-top">
          <div className="top" onClick={() => scrollToTop()}>
            <i className="fas fa-chevron-up"></i>
          </div>
        </div>
      ) : null}
    </>
  );
}
