import { ICollege } from 'src/interfaces/college';
import {
  BECOME_COLLEGE_AMBASSADOR_REQUEST,
  BECOME_COLLEGE_AMBASSADOR_SUCCEEDED,
  BECOME_COLLEGE_AMBASSADOR_FAILED,
  GET_LANDING_NEPAL_COLLEGE_DROPDOWN_REQUEST,
  GET_LANDING_NEPAL_COLLEGE_DROPDOWN_SUCCEEDED,
  GET_LANDING_NEPAL_COLLEGE_DROPDOWN_FAILED,
  GET_COLLEGE_LIST_REQUEST,
  GET_COLLEGE_LIST_SUCCEEDED,
  GET_COLLEGE_LIST_FAILED,
  ADD_COLLEGE_REQUEST,
  ADD_COLLEGE_SUCCEEDED,
  ADD_COLLEGE_FAILED,
  EDIT_COLLEGE_REQUEST,
  EDIT_COLLEGE_SUCCEEDED,
  EDIT_COLLEGE_FAILED,
  DELETE_COLLEGE_REQUEST,
  DELETE_COLLEGE_SUCCEEDED,
  DELETE_COLLEGE_FAILED,
  SET_COLLEGE_DATA,
  SET_COLLEGE_EDIT_DATA,
  GET_COLLEGE_AMBASSADOR_FORM_LIST_REQUEST,
  GET_COLLEGE_AMBASSADOR_FORM_LIST_SUCCEEDED,
  GET_COLLEGE_AMBASSADOR_FORM_LIST_FAILED,
  EDIT_COLLEGE_AMBASSADOR_FORM_REQUEST,
  EDIT_COLLEGE_AMBASSADOR_FORM_SUCCEEDED,
  EDIT_COLLEGE_AMBASSADOR_FORM_FAILED,
  GET_COLLEGE_AMBASSADOR_FORM_BY_ID_REQUEST,
  GET_COLLEGE_AMBASSADOR_FORM_BY_ID_SUCCEEDED,
  GET_COLLEGE_AMBASSADOR_FORM_BY_ID_FAILED,
} from '../../src/actions';
import { getLocalStore } from 'next-persist';

const initialState: ICollege = {
  colleges: {
    data: [],
  },
  ambassadorRequests: {
    data: [],
  },
  collegeDropdown: {
    data: [],
  },
  ambassadorById: {},
  getCollegeDropdownStatus: '',
  ambassadorFormStatus: '',
  getCollegeStatus: '',
  addCollegeStatus: '',
  editCollegeStatus: '',
  deleteCollegeStatus: '',
  getAmbassadorListStatus: '',
  getAmbassadorByIdStatus: '',
  editAmbassadorStatus: '',
  ambassadorDetails: {},
  helperObj: {
    addCollege: false,
    editCollege: false,
    deleteCollege: false,
    editAmbassador: false,
  },
  error: {
    addCollege: {},
    editCollege: {},
    addAmbassador: {},
  },
};
const persistedState = getLocalStore('college', initialState);

const college = (state = persistedState, action) => {
  switch (action.type) {
    case BECOME_COLLEGE_AMBASSADOR_REQUEST:
      return {
        ...state,
        ambassadorFormStatus: 'request',
      };

    case BECOME_COLLEGE_AMBASSADOR_SUCCEEDED:
      return {
        ...state,
        ambassadorFormStatus: 'success',
        error: {
          ...state.error,
          addAmbassador: {},
        },
      };

    case BECOME_COLLEGE_AMBASSADOR_FAILED:
      console.log(action);

      return {
        ...state,
        ambassadorFormStatus: 'failure',
        error: {
          ...state.error,
          addAmbassador: action.error,
        },
      };

    case GET_LANDING_NEPAL_COLLEGE_DROPDOWN_REQUEST:
      return {
        ...state,
        getCollegeDropdownStatus: 'request',
      };

    case GET_LANDING_NEPAL_COLLEGE_DROPDOWN_SUCCEEDED:
      return {
        ...state,
        collegeDropdown: action.data,
        getCollegeDropdownStatus: 'success',
      };

    case GET_LANDING_NEPAL_COLLEGE_DROPDOWN_FAILED:
      return {
        ...state,
        getCollegeDropdownStatus: 'failure',
      };

    //dashboard
    case GET_COLLEGE_AMBASSADOR_FORM_LIST_REQUEST:
      return {
        ...state,
        getAmbassadorListStatus: 'request',
      };

    case GET_COLLEGE_AMBASSADOR_FORM_LIST_SUCCEEDED:
      return {
        ...state,
        ambassadorRequests: action.data,
        getAmbassadorListStatus: 'success',
      };

    case GET_COLLEGE_AMBASSADOR_FORM_LIST_FAILED:
      return {
        ...state,
        getAmbassadorListStatus: 'failure',
      };

    case GET_COLLEGE_AMBASSADOR_FORM_BY_ID_REQUEST:
      return {
        ...state,
        getAmbassadorByIdStatus: 'request',
      };

    case GET_COLLEGE_AMBASSADOR_FORM_BY_ID_SUCCEEDED:
      return {
        ...state,
        ambassadorById: action.data,
        getAmbassadorByIdStatus: 'success',
      };

    case GET_COLLEGE_AMBASSADOR_FORM_BY_ID_FAILED:
      return {
        ...state,
        getAmbassadorByIdStatus: 'failure',
      };

    case EDIT_COLLEGE_AMBASSADOR_FORM_REQUEST:
      return {
        ...state,
        editAmbassadorStatus: 'request',
      };

    case EDIT_COLLEGE_AMBASSADOR_FORM_SUCCEEDED:
      return {
        ...state,
        ambassadorRequests: {
          ...state.ambassadorRequests,
          data: state.ambassadorRequests.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        editAmbassadorStatus: 'success',
        helperObj: {
          ...state.helperObj,
          editAmbassador: false,
        },
      };

    case EDIT_COLLEGE_AMBASSADOR_FORM_FAILED:
      return {
        ...state,
        editCollegeStatus: 'failure',
      };

    case GET_COLLEGE_LIST_REQUEST:
      return {
        ...state,
        getCollegeStatus: 'request',
      };

    case GET_COLLEGE_LIST_SUCCEEDED:
      return {
        ...state,
        colleges: action.data,
        getCollegeStatus: 'success',
      };

    case GET_COLLEGE_LIST_FAILED:
      return {
        ...state,
        getCollegeStatus: 'failure',
      };

    case ADD_COLLEGE_REQUEST:
      return {
        ...state,
        addCollegeStatus: 'request',
      };

    case ADD_COLLEGE_SUCCEEDED:
      return {
        ...state,
        colleges: {
          ...state.colleges,
          data: [action.data.data, ...state.colleges.data],
        },
        addCollegeStatus: 'success',
        helperObj: {
          ...state.helperObj,
          addCollege: false,
        },
      };

    case ADD_COLLEGE_FAILED:
      return {
        ...state,
        addCollegeStatus: 'failure',
        error: {
          ...state.error,
          addCollege: action.error,
        },
      };

    case EDIT_COLLEGE_REQUEST:
      return {
        ...state,
        editCollegeStatus: 'request',
      };

    case EDIT_COLLEGE_SUCCEEDED:
      return {
        ...state,
        colleges: {
          ...state.colleges,
          data: state.colleges.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        editCollegeStatus: 'success',
        helperObj: {
          ...state.helperObj,
          editCollege: false,
        },
      };

    case EDIT_COLLEGE_FAILED:
      return {
        ...state,
        editCollegeStatus: 'failure',
        error: {
          ...state.error,
          editCollege: action.error,
        },
      };

    case DELETE_COLLEGE_REQUEST:
      return {
        ...state,
        deleteCollegeStatus: 'request',
      };

    case DELETE_COLLEGE_SUCCEEDED:
      return {
        ...state,
        colleges: {
          ...state.colleges,
          data: state.colleges.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        deleteCollegeStatus: 'success',
        helperObj: {
          ...state.helperObj,
          deleteCollege: false,
        },
      };

    case DELETE_COLLEGE_FAILED:
      return {
        ...state,
        deleteCollegeStatus: 'failure',
      };

    case SET_COLLEGE_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };

    case SET_COLLEGE_EDIT_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};

export default college;
