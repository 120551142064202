import {
  EDIT_ROLE_WISE_SECTION_PERMISSIONS,
  EDIT_ROLE_WISE_SECTION_PERMISSIONS_FAILED,
  EDIT_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED,
  EDIT_USER_ROLE,
  EDIT_USER_ROLE_FAILED,
  EDIT_USER_ROLE_SUCCEEDED,
  GET_ROLE_SECTION_LABELS,
  GET_ROLE_SECTION_LABELS_FAILED,
  GET_ROLE_SECTION_LABELS_SUCCEEDED,
  GET_ROLE_WISE_SECTION_PERMISSIONS,
  GET_ROLE_WISE_SECTION_PERMISSIONS_FAILED,
  GET_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED,
  GET_SECTION_WISE_PERMISSIONS,
  GET_SECTION_WISE_PERMISSIONS_FAILED,
  GET_SECTION_WISE_PERMISSIONS_SUCCEEDED,
  GET_ROLES_SUCCEEDED,
  GET_ROLES_FAILED,
  GET_SINGLE_ROLE_SUCCEEDED,
  GET_SINGLE_ROLE_FAILED,
  DELETE_ROLE_FAILED,
  DELETE_ROLE_SUCCEEDED,
  ADD_ROLE_SUCCEEDED,
  ADD_ROLE_FAILED,
  EDIT_ROLE_SUCCEEDED,
  EDIT_ROLE_FAILED,
  SET_ROLE_DATA,
  SET_ROLE_EDIT_DATA,
} from '@/actions/core/role/types';
import { IRole } from '../../interfaces/role';
import { getLocalStore } from 'next-persist';

const initialState: IRole = {
  roles: {
    data: [],
  },
  role: {},
  addRole: {},
  editRole: {},
  sections: [],
  sectionPermissions: {
    data: { name: '', permissions: {} },
  },
  rolePermissions: {
    data: { name: '', permissions: {} },
  },
  getSectionStatus: '',
  getSectionPermissionStatus: '',
  getRolePermissionStatus: '',
  editRolePermissionStatus: '',
  updateRoleStatus: '',
};
const persistedState = getLocalStore('role', initialState);

const role = (state = persistedState, action) => {
  switch (action.type) {
    case GET_ROLES_SUCCEEDED:
      return {
        ...state,
        roles: action.data,
      };

    case GET_ROLES_FAILED:
      return {
        ...state,
      };
    case GET_SINGLE_ROLE_SUCCEEDED:
      return {
        ...state,
        role: action.data,
      };

    case GET_SINGLE_ROLE_FAILED:
      return {
        ...state,
      };
    case ADD_ROLE_SUCCEEDED:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: [action.data.data, ...state.roles.data],
        },
      };

    case ADD_ROLE_FAILED:
      return {
        ...state,
      };
    case EDIT_ROLE_SUCCEEDED:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: state.roles.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_ROLE_FAILED:
      return {
        ...state,
      };
    case DELETE_ROLE_SUCCEEDED:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: state.roles.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_ROLE_FAILED:
      return {
        ...state,
      };

    case SET_ROLE_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_ROLE_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
      };

    //sections
    case GET_ROLE_SECTION_LABELS:
      return {
        ...state,
        getSectionStatus: 'request',
      };

    case GET_ROLE_SECTION_LABELS_SUCCEEDED:
      return {
        ...state,
        sections: action.data.data,
        getSectionStatus: 'success',
      };

    case GET_ROLE_SECTION_LABELS_FAILED:
      return {
        ...state,
        getSectionStatus: 'failure',
      };

    //section all permissions
    case GET_SECTION_WISE_PERMISSIONS:
      return {
        ...state,
        getSectionPermissionStatus: 'request',
      };

    case GET_SECTION_WISE_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        sectionPermissions: action.data,
        getSectionPermissionStatus: 'success',
      };

    case GET_SECTION_WISE_PERMISSIONS_FAILED:
      return {
        ...state,
        getSectionPermissionStatus: 'failure',
      };

    //role wise section permissions
    case GET_ROLE_WISE_SECTION_PERMISSIONS:
      return {
        ...state,
        getRolePermissionStatus: 'request',
      };

    case GET_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        rolePermissions: action.data?.data,
        getRolePermissionStatus: 'success',
      };

    case GET_ROLE_WISE_SECTION_PERMISSIONS_FAILED:
      return {
        ...state,
        getRolePermissionStatus: 'failure',
      };

    //change role wise section permissions
    case EDIT_ROLE_WISE_SECTION_PERMISSIONS:
      return {
        ...state,
        editRolePermissionStatus: 'request',
      };

    case EDIT_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED:
      return {
        ...state,
        rolePermissions: action.data?.data,
        editRolePermissionStatus: 'success',
      };

    case EDIT_ROLE_WISE_SECTION_PERMISSIONS_FAILED:
      return {
        ...state,
        editRolePermissionStatus: 'failure',
      };

    //change role wise section permissions
    case EDIT_USER_ROLE:
      return {
        ...state,
        updateRoleStatus: 'request',
      };

    case EDIT_USER_ROLE_SUCCEEDED:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: state.roles.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        updateRoleStatus: 'success',
      };

    case EDIT_USER_ROLE_FAILED:
      return {
        ...state,
        updateRoleStatus: 'failure',
      };

    default:
      return state;
  }
};

export default role;
