import {
  EDIT_LEDGER_ORDER,
  EDIT_LEDGER_ORDER_FAILED,
  EDIT_LEDGER_ORDER_SUCCEEDED,
  GET_LEDGER,
  GET_LEDGER_FAILED,
  GET_LEDGER_SUCCEEDED,
} from '../actions';
import { Ledger, LedgerApiData, LedgerState } from '../interfaces/finance';
import { getLocalStore } from 'next-persist';

const initialState: LedgerState = {
  ledger: {
    data: [],
    getLedgerStatus: '',
    editOrderStatus: '',
    meta: {
      current_page: undefined,
      last_pages: undefined,
      total: undefined,
      per_page: undefined,
    },
    links: {
      first: undefined,
      last: undefined,
      next: undefined,
      prev: undefined,
    },
  },
  error: {},
};
const persistedState = getLocalStore('ledger', initialState);

const ledger = (
  state = persistedState,
  action: {
    type: string;
    data: any;
  }
) => {
  switch (action.type) {
    case GET_LEDGER:
      return {
        ...state,
        ledger: {
          ...state.ledger,
          getLedgerStatus: 'request',
        },
      };

    case GET_LEDGER_SUCCEEDED:
      return {
        ...state,
        ledger: {
          ...state.ledger,
          data: action.data.data,
          meta: action.data.meta,
          links: action.data.links,
          getLedgerStatus: 'success',
        },
      };
    case GET_LEDGER_FAILED:
      return {
        ...state,
        ledger: {
          ...state.ledger,
          getLedgerStatus: 'failure',
        },
      };
    case EDIT_LEDGER_ORDER:
      return {
        ...state,
        ledger: {
          ...state?.ledger,
          editOrderStatus: 'request',
        },
      };
    case EDIT_LEDGER_ORDER_SUCCEEDED:
      return {
        ...state,
        ledger: {
          ...state.ledger,
          data: state.ledger?.data?.map((each) =>
            each?.order__id === action?.data?.data?.id
              ? { ...each, order_refund_display: action?.data?.data?.refund }
              : each
          ),
          editOrderStatus: 'success',
        },
      };
    case EDIT_LEDGER_ORDER_FAILED:
      return {
        ...state,
        ledger: {
          ...state.ledger,
          editOrderStatus: 'failed',
        },
      };
    default:
      return state;
  }
};

export default ledger;
