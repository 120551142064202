import {
  ADD_COUPON,
  ADD_COUPON_FAILED,
  ADD_COUPON_SUCCEEDED,
  CLEAR_FINANCE_ERROR,
  DELETE_COUPON,
  DELETE_COUPON_FAILED,
  DELETE_COUPON_SUCCEEDED,
  EDIT_COUPON,
  EDIT_COUPON_FAILED,
  EDIT_COUPON_SUCCEEDED,
  GET_COUPON,
  GET_COUPON_FAILED,
  GET_COUPON_SUCCEEDED,
  SET_FINANCE_DATA,
  SET_FINANCE_EDIT_DATA,
  SET_FINANCE_ERROR_DATA,
  USE_FREE_VOUCHER,
  USE_FREE_VOUCHER_FAILED,
  USE_FREE_VOUCHER_SUCCEEDED,
  VOUCHER_VERIFY,
  VOUCHER_VERIFY_FAILURE,
  VOUCHER_VERIFY_SUCCESS,
  GET_COUPON_BY_ID,
  GET_COUPON_BY_ID_FAILED,
  GET_COUPON_BY_ID_SUCCEEDED,
} from '../../src/actions';
import { FHelper } from '../interfaces/finance';
import { getLocalStore } from 'next-persist';

const initialState: FHelper = {
  coupon: {
    data: [],
    meta: {},
  },
  couponById: {
    data: [],
  },
  voucherVerifyData: {},
  voucher_history_id: undefined,
  getCouponStatus: '',
  addCouponStatus: '',
  editCouponStatus: '',
  deleteCouponStatus: '',
  voucherVerifyStatus: '',
  freeVoucherVerifyStatus: '',
  couponsByIdStatus: '',
  error: {
    editCoupon: {},
    addCoupon: {},
    voucherVerify: {},
    freeVoucherVerify: {},
    couponsById: {},
  },
};
const persistedState = getLocalStore('finance', initialState);

const finance = (state = persistedState, action) => {
  switch (action.type) {
    case GET_COUPON:
      return {
        ...state,
        getCouponStatus: 'request',
      };
    case GET_COUPON_SUCCEEDED:
      return {
        ...state,
        coupon: {
          ...state.coupon,
          data: action.data,
        },
        getCouponStatus: 'success',
      };
    case GET_COUPON_FAILED:
      return {
        ...state,
        getCouponStatus: 'failure',
      };
    case ADD_COUPON:
      return {
        ...state,
        addCouponStatus: 'request',
      };
    case ADD_COUPON_SUCCEEDED:
      try {
        return {
          ...state,
          coupon: {
            ...state.coupon,
            data: {
              ...state.coupon.data,
              data: [action.data.data, ...state.coupon.data.data],
            },
          },
          addCouponStatus: 'success',
        };
      } catch (e) {
        console.log(e);
      }
    case ADD_COUPON_FAILED:
      return {
        ...state,
        addCouponStatus: 'failure',
        error: {
          ...state.error,
          addCoupon: action.error,
        },
      };
    case EDIT_COUPON:
      return {
        ...state,
        editCouponStatus: 'request',
      };
    case EDIT_COUPON_SUCCEEDED:
      return {
        ...state,
        coupon: {
          ...state.coupon,
          data: {
            ...state.coupon.data,
            data: state?.coupon?.data?.data?.map((each) =>
              each.id == action.data.data.id ? action.data.data : each
            ),
          },
        },
        editCouponStatus: 'success',
      };
    case EDIT_COUPON_FAILED:
      return {
        ...state,
        editCouponStatus: 'failure',
        error: {
          ...state.error,
          editCoupon: action.error,
        },
      };
    case DELETE_COUPON:
      return {
        ...state,
        deleteCouponStatus: 'request',
      };
    case DELETE_COUPON_SUCCEEDED:
      return {
        ...state,
        deleteCouponStatus: 'success',
        coupon: {
          ...state.coupon,
          data: {
            ...state.coupon.data,
            data: state.coupon.data.data.filter(
              (each) => each.id !== action.data.data.id
            ),
          },
        },
      };
    case DELETE_COUPON_FAILED:
      return {
        ...state,
        deleteCouponStatus: 'failure',
      };
    case VOUCHER_VERIFY:
      return {
        ...state,
        voucherVerifyStatus: 'request',
      };
    case VOUCHER_VERIFY_SUCCESS:
      return {
        ...state,
        voucherVerifyStatus: 'success',
        voucherVerifyData: action.data.data,
        voucher_history_id: action.data.voucher_history_id,
      };
    case VOUCHER_VERIFY_FAILURE:
      return {
        ...state,
        voucherVerifyStatus: 'failure',
        error: {
          ...state.error,
          voucherVerify: action.error,
        },
        voucherVerifyData: {
          ...state.voucherVerifyData,
          is_free: false,
        },
      };
    case USE_FREE_VOUCHER:
      return {
        ...state,
        freeVoucherVerifyStatus: 'request',
      };
    case USE_FREE_VOUCHER_SUCCEEDED:
      return {
        freeVoucherVerifyStatus: 'success',
      };
    case USE_FREE_VOUCHER_FAILED:
      return {
        freeVoucherVerifyStatus: 'failure',
        error: {
          ...state.error,
          freeVoucherVerify: action.error,
        },
        voucherVerifyData: {
          ...state.voucherVerifyData,
          is_free: false,
        },
      };
    case SET_FINANCE_EDIT_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SET_FINANCE_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.name]: {
            ...state.error[action.payload.name],
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case SET_FINANCE_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };
    case CLEAR_FINANCE_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    case GET_COUPON_BY_ID:
      return {
        ...state,
        couponsByIdStatus: 'request',
      };
    case GET_COUPON_BY_ID_SUCCEEDED:
      return {
        ...state,
        couponById: {
          ...state.couponById,
          data: action.data,
        },
        couponsByIdStatus: 'success',
      };
    case GET_COUPON_BY_ID_FAILED:
      return {
        ...state,
        couponsByIdStatus: 'failure',
      };
    default:
      return state;
  }
};

export default finance;
