import { getLocalStore } from 'next-persist';
import { ITask } from 'src/interfaces/task';
import {
  ADD_TASK_COMMENTS_FAILED,
  ADD_TASK_COMMENTS_REQUEST,
  ADD_TASK_COMMENTS_SUCCEEDED,
  ADD_TASK_FAILED,
  ADD_TASK_REQUEST,
  ADD_TASK_SUCCEEDED,
  DELETE_TASK_COMMENTS_FAILED,
  DELETE_TASK_COMMENTS_REQUEST,
  DELETE_TASK_COMMENTS_SUCCEEDED,
  DELETE_TASK_FAILED,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCEEDED,
  EDIT_TASK_COMMENTS_FAILED,
  EDIT_TASK_COMMENTS_REQUEST,
  EDIT_TASK_COMMENTS_SUCCEEDED,
  EDIT_TASK_FAILED,
  EDIT_TASK_REQUEST,
  EDIT_TASK_SUCCEEDED,
  GET_CONTACT_US_USER_DETAIL,
  GET_CONTACT_US_USER_DETAIL_FAILED,
  GET_CONTACT_US_USER_DETAIL_SUCCEEDED,
  GET_TASK_BY_ID_FAILED,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_SUCCEEDED,
  GET_TASK_COMMENTS_FAILED,
  GET_TASK_COMMENTS_REQUEST,
  GET_TASK_COMMENTS_SUCCEEDED,
  GET_TASK_FAILED,
  GET_TASK_REQUEST,
  GET_TASK_SUCCEEDED,
  SET_TASK_DATA,
} from '../actions';

const initialState: ITask = {
  helperObj: {
    showAddTask: false,
    showEditTask: false,
    showEdit: false,
  },
  task: {},
  taskById: {
    data: {},
    getTaskByIdStatus: '',
  },
  comments: {
    data: {},
  },
  contactUs: {},
  status: {
    getTaskStatus: '',
    addTaskStatus: '',
    editTaskStatus: '',
    deleteTaskStatus: '',
    getComment: '',
    addComment: '',
    editComment: '',
    deleteComment: '',
    contactUsDetailComment: '',
  },
  error: {
    getTaskError: {},
    addTaskError: {},
    editTaskError: {},
    deleteTaskError: {},
    getCommentError: {},
    addCommentError: {},
    editCommentError: {},
    deleteCommentError: {},
    contactUsDetailError: {},
  },
};
const persistedState = getLocalStore('task', initialState);

const task = (state = persistedState as ITask, action): ITask => {
  switch (action.type) {
    case GET_TASK_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          getTaskStatus: 'request',
        },
        error: {
          ...state.error,
          getTaskError: {},
        },
      };
    case GET_TASK_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          getTaskStatus: 'success',
        },
        error: {
          ...state.error,
          getTaskError: {},
        },
        task: action.data,
      };
    case GET_TASK_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          getTaskStatus: 'failed',
        },
        error: {
          ...state.error,
          getTaskError: action.error,
        },
      };

    case GET_TASK_BY_ID_REQUEST:
      return {
        ...state,
        taskById: {
          ...state.taskById,
          getTaskByIdStatus: 'request',
        },
      };
    case GET_TASK_BY_ID_SUCCEEDED:
      return {
        ...state,
        taskById: {
          data: action.data,
          getTaskByIdStatus: 'success',
        },
      };
    case GET_TASK_BY_ID_FAILED:
      return {
        ...state,
        taskById: {
          ...state.taskById,
          getTaskByIdStatus: 'failed',
        },
      };

    case SET_TASK_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case ADD_TASK_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          addTaskStatus: 'request',
        },
        error: {
          ...state.error,
          addTaskError: {},
        },
      };
    case ADD_TASK_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          addTaskStatus: 'success',
        },
        task: {
          ...state.task,
          data: [action?.data?.data, ...state.task.data],
        },
        error: {
          ...state.error,
          addTaskError: {},
        },
        helperObj: {
          ...state.helperObj,
          showAddTask: false,
        },
      };
    case ADD_TASK_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          editTaskStatus: 'failed',
        },
        error: {
          ...state.error,
          editTaskError: action?.error,
        },
      };
    case EDIT_TASK_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          editTaskStatus: 'request',
        },
        error: {
          ...state.error,
          editTaskError: {},
        },
      };
    case EDIT_TASK_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          editTaskStatus: 'success',
        },
        error: {
          ...state.error,
          editTaskError: {},
        },
        task: {
          ...state.task,
          data: state?.task?.data?.map((each: any) =>
            each?.id === action.data.data.id ? action?.data?.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          // showEditTask: false,
        },
      };
    case EDIT_TASK_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          editTaskStatus: 'failed',
        },
        error: {
          ...state.error,
          editTaskError: action.error,
        },
      };
    case DELETE_TASK_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          deleteTaskStatus: 'request',
        },
        error: {
          ...state.error,
          deleteTaskError: {},
        },
      };
    case DELETE_TASK_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          deleteTaskStatus: 'success',
        },
        error: {
          ...state.error,
          deleteTaskError: {},
        },
        task: {
          ...state.task,
          data: state.task.data.filter((each) => each.id !== action.data.data.id),
        },
      };
    case DELETE_TASK_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          deleteTaskStatus: 'failed',
        },
        error: {
          ...state.error,
          deleteTaskError: action.error,
        },
      };
    case ADD_TASK_COMMENTS_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          addComment: 'request',
        },
        error: {
          ...state.error,
          addCommentError: {},
        },
      };
    case ADD_TASK_COMMENTS_SUCCEEDED:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            data: [action.data.data, ...state.comments.data.data],
          },
        },
        status: {
          ...state.status,
          addComment: 'success',
        },
        error: {
          ...state.error,
          addCommentError: {},
        },
      };
    case ADD_TASK_COMMENTS_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          addComment: 'failed',
        },
        error: {
          ...state.error,
          addCommentError: action.error,
        },
      };
    case GET_TASK_COMMENTS_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          getComment: 'request',
        },
        error: {
          ...state.error,
          getCommentError: {},
        },
      };
    case GET_TASK_COMMENTS_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          getComment: 'success',
        },
        comments: {
          data: action.data,
        },
        error: {
          ...state.error,
          getCommentError: {},
        },
      };
    case GET_TASK_COMMENTS_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          getComment: 'failed',
        },
        error: {
          ...state.error,
          getCommentError: action.error,
        },
      };
    case EDIT_TASK_COMMENTS_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          editComment: 'request',
        },
        error: {
          ...state.error,
          editCommentError: {},
        },
      };
    case EDIT_TASK_COMMENTS_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          editComment: 'success',
        },
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            data: state.comments.data?.data?.map((each) =>
              each?.id === action.data.data.id ? action?.data?.data : each
            ),
          },
        },
        error: {
          ...state.error,
          editCommentError: {},
        },
      };
    case EDIT_TASK_COMMENTS_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          editComment: 'failed',
        },
        error: {
          ...state.error,
          editCommentError: action.error,
        },
      };
    case DELETE_TASK_COMMENTS_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          deleteComment: 'request',
        },
        error: {
          ...state.error,
          deleteCommentError: {},
        },
      };
    case DELETE_TASK_COMMENTS_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          deleteComment: 'success',
        },
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            data: state?.comments?.data?.data?.filter(
              (each) => each?.id != action.data.data.id
            ),
          },
        },
        error: {
          ...state.error,
          deleteCommentError: action.error,
        },
      };
    case DELETE_TASK_COMMENTS_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          deleteComment: 'failed',
        },
        error: {
          ...state.error,
          deleteCommentError: action.error,
        },
      };
    case GET_CONTACT_US_USER_DETAIL:
      return {
        ...state,
        status: {
          ...state.status,
          contactUsDetailComment: 'request',
        },
        error: {
          ...state.error,
          contactUsDetailError: {},
        },
      };
    case GET_CONTACT_US_USER_DETAIL_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          contactUsDetailComment: 'success',
        },
        contactUs: action.data,
      };
    case GET_CONTACT_US_USER_DETAIL_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          contactUsDetailComment: 'failed',
        },
        error: {
          ...state.error,
          contactUsDetailError: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default task;
