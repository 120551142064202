import {
  GET_QUIZ_SUCCEEDED,
  GET_QUIZ_FAILED,
  GET_QUIZ_BY_SLUG,
  GET_QUIZ_BY_SLUG_SUCCEEDED,
  GET_QUIZ_BY_SLUG_FAILED,
  ADD_QUIZ_SUCCEEDED,
  ADD_QUIZ_FAILED,
  DELETE_QUIZ_SUCCEEDED,
  DELETE_QUIZ_FAILED,
  EDIT_QUIZ_SUCCEEDED,
  EDIT_QUIZ_FAILED,
  GET_QUIZ_QUESTIONS_SUCCEEDED,
  GET_QUIZ_QUESTIONS_FAILED,
  ADD_QUIZ_QUESTION,
  ADD_QUIZ_QUESTION_SUCCEEDED,
  ADD_QUIZ_QUESTION_FAILED,
  DELETE_QUIZ_QUESTION_SUCCEEDED,
  DELETE_QUIZ_QUESTION_FAILED,
  EDIT_QUIZ_QUESTION_SUCCEEDED,
  EDIT_QUIZ_QUESTION_FAILED,
  GET_QUIZ_ANSWERS_SUCCEEDED,
  GET_QUIZ_ANSWERS_FAILED,
  ADD_QUIZ_ANSWER_SUCCEEDED,
  ADD_QUIZ_ANSWER_FAILED,
  DELETE_QUIZ_ANSWER_SUCCEEDED,
  DELETE_QUIZ_ANSWER_FAILED,
  EDIT_QUIZ_ANSWER_SUCCEEDED,
  EDIT_QUIZ_ANSWER_FAILED,
  SET_QUIZ_DATA,
  ADD_QUIZ_ANSWER,
  CLEAR_QUIZ_QUESTION,
  CHANGE_QUIZ_STATE,
  EDIT_QUIZ_QUESTION,
} from '../../src/actions';
import { IQuiz } from '../interfaces/quiz/quiz';
import { getLocalStore } from 'next-persist';

const initialState: IQuiz = {
  quizes: {
    data: [],
  },
  newQuiz: {
    data: {},
    addedQuizQuestion: false,
  },
  quizBySlug: {
    question: [],
    status: false,
  },
  quizQuestions: {
    data: [],
    status: false,
  },
  quizAnswers: {
    data: [],
  },
  addQuizAnswer: '',
  emptyAnswersObjs: [],
  getQuizAnswerStatus: '',
  deleteQuizAnswer: '',
  addQuizQuestionStatus: '',
  editQuizQuestionStatus: '',
  deleteQuizQuestionStatus: '',
  isNewQuestionAdded: false,
};
const persistedState = getLocalStore('quiz', initialState);

const quiz = (state = persistedState, action) => {
  switch (action.type) {
    case GET_QUIZ_SUCCEEDED:
      return {
        ...state,
        quizes: action.data,
      };

    case GET_QUIZ_FAILED:
      return {
        ...state,
      };

    case GET_QUIZ_BY_SLUG:
      return {
        ...state,
        quizBySlug: {
          question: [],
          status: false,
        },
      };

    case GET_QUIZ_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        quizBySlug: {
          ...action.data,
          status: true,
        },
        emptyAnswersObjs: action?.data?.question?.filter(
          (quiz: any) => quiz?.answer?.length === 0
        ),
      };

    case GET_QUIZ_BY_SLUG_FAILED:
      return {
        ...state,
        quizBySlug: {
          ...state.quizBySlug,
          status: false,
        },
      };
    case ADD_QUIZ_SUCCEEDED:
      return {
        ...state,
        quizes: {
          ...state.quizes,
          data: [action.data.data, ...state.quizes.data],
        },
        newQuiz: {
          ...state.newQuiz,
          addedQuizQuestion: true,
          data: action.data.data,
        },
      };

    case ADD_QUIZ_FAILED:
      return {
        ...state,
      };
    case EDIT_QUIZ_SUCCEEDED:
      return {
        ...state,
        quizes: {
          ...state.quizes,
          data: state.quizes.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_QUIZ_FAILED:
      return {
        ...state,
      };
    case DELETE_QUIZ_SUCCEEDED:
      return {
        ...state,
        quizes: {
          ...state.quizes,
          data: state.quizes.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_QUIZ_FAILED:
      return {
        ...state,
      };

    case GET_QUIZ_QUESTIONS_SUCCEEDED:
      return {
        ...state,
        quizQuestions: {
          ...state.quizQuestions,
          data: action.data.data,
        },
      };

    case GET_QUIZ_QUESTIONS_FAILED:
      return {
        ...state,
      };

    case ADD_QUIZ_QUESTION:
      return {
        ...state,
        quizQuestions: {
          data: [...state.quizQuestions.data],
          status: false,
        },
        addQuizQuestionStatus: 'request',
      };

    case ADD_QUIZ_QUESTION_SUCCEEDED:
      return {
        ...state,
        quizQuestions: {
          ...state.quizQuestions,
          data: [action.data.data, ...state.quizQuestions.data],
          status: true,
        },
        addQuizQuestionStatus: 'success',
        isNewQuestionAdded: true,
      };

    case ADD_QUIZ_QUESTION_FAILED:
      return {
        ...state,
        addQuizQuestionStatus: 'failure',
      };
    case EDIT_QUIZ_QUESTION:
      return {
        ...state,
        editQuizQuestionStatus: 'request',
      };

    case EDIT_QUIZ_QUESTION_SUCCEEDED:
      return {
        ...state,
        quizQuestions: {
          ...state.quizQuestions,
          data: state.quizQuestions.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        quizBySlug: {
          ...state.quizBySlug,
          question: state.quizBySlug.question.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        editQuizQuestionStatus: 'success',
      };

    case EDIT_QUIZ_QUESTION_FAILED:
      return {
        ...state,
        editQuizQuestionStatus: 'failure',
      };
    case DELETE_QUIZ_QUESTION_SUCCEEDED:
      return {
        ...state,
        quizBySlug: {
          ...state.quizBySlug,
          question: state.quizBySlug?.question?.filter(
            (each: any) => each.slug !== action.data.data.slug
          ),
        },
        quizQuestions: {
          ...state.quizQuestions,
          data: state.quizQuestions.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_QUIZ_QUESTION_FAILED:
      return {
        ...state,
        getQuizAnswerStatus: 'request',
      };

    case GET_QUIZ_ANSWERS_SUCCEEDED:
      return {
        ...state,
        quizAnswers: action.data,
        getQuizAnswerStatus: 'success',
      };

    case GET_QUIZ_ANSWERS_FAILED:
      return {
        ...state,
        getQuizAnswerStatus: 'failure',
      };

    case ADD_QUIZ_ANSWER:
      return {
        ...state,
        addQuizAnswer: 'request',
      };

    case ADD_QUIZ_ANSWER_SUCCEEDED:
      //for quiz in add page
      const qIndex = state?.quizQuestions?.data?.findIndex(
        (each) => each?.id === action.data?.data?.question
      );
      let tempQuiz = state?.quizQuestions?.data;
      tempQuiz[qIndex] = {
        ...state?.quizQuestions?.data[qIndex],
        answer: [action.data.data, ...state?.quizQuestions?.data[qIndex].answer],
      };

      //for edit quiz page
      const qBySlugIndex = state?.quizBySlug?.question?.findIndex(
        (each) => each?.id === action.data?.data?.question
      );
      let tempQuizSlug = state?.quizBySlug?.question;
      tempQuizSlug[qBySlugIndex] = {
        ...state?.quizQuestions?.data[qIndex],
        answer: [
          action.data.data,
          ...state?.quizBySlug?.question[qBySlugIndex].answer,
        ],
      };
      // console.log({ qIndex, tempQuiz });

      return {
        ...state,
        quizAnswers: {
          ...state.quizAnswers,
          data: [action.data.data, ...state.quizAnswers.data],
        },
        quizQuestions: {
          ...state.quizQuestions,
          data: tempQuiz,
        },
        quizBySlug: {
          ...state.quizBySlug,
          question: tempQuizSlug,
        },
        addQuizAnswer: 'success',
      };

    case ADD_QUIZ_ANSWER_FAILED:
      return {
        ...state,
        addQuizAnswer: 'failure',
      };
    case EDIT_QUIZ_ANSWER_SUCCEEDED:
      return {
        ...state,
        quizAnswers: {
          ...state.quizAnswers,
          data: state.quizAnswers.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        // quizBySlug: {
        //   ...state.quizBySlug,
        //   question: {
        //     ...state.quizBySlug?.question,
        //     answer: state.quizBySlug.question.answer.map((each: any) =>
        //       each.id == action.data.data.id ? action.data.data : each
        //     ),
        //   },
        // },
        addQuizAnswer: 'success',
      };

    case EDIT_QUIZ_ANSWER_FAILED:
      return {
        ...state,
        addQuizAnswer: 'failure',
      };
    case DELETE_QUIZ_ANSWER_SUCCEEDED:
      return {
        ...state,
        quizAnswers: {
          ...state.quizAnswers,
          data: state.quizAnswers.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        deleteQuizAnswer: 'success',
        // quizBySlug: {
        //   ...state.quizBySlug,
        //   question: {
        //     ...state.quizBySlug?.question,
        //     answer: state.quizBySlug.question.answer.filter(
        //       (each: any) => each.id !== action.data.data.id
        //     ),
        //   },
        // },
      };

    case DELETE_QUIZ_ANSWER_FAILED:
      return {
        ...state,
        deleteQuizAnswer: 'failure',
      };

    case SET_QUIZ_DATA:
      return {
        ...state,
        // [action.payload.key]: {
        //   ...action.payload.value,
        // },
        quizBySlug: { question: [] },
        // newQuiz: {},
      };
    case CHANGE_QUIZ_STATE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case CLEAR_QUIZ_QUESTION:
      return {
        ...state,
        quizQuestions: {
          data: [],
        },
      };
    default:
      return state;
  }
};

export default quiz;
