import {
  GET_SUBJECTS_SUCCEEDED,
  GET_SUBJECTS_FAILED,
  ADD_SUBJECT_SUCCEEDED,
  ADD_SUBJECT_FAILED,
  DELETE_SUBJECT_SUCCEEDED,
  DELETE_SUBJECT_FAILED,
  EDIT_SUBJECT_SUCCEEDED,
  EDIT_SUBJECT_FAILED,
  GET_SUBJECT_BY_SLUG_SUCCEEDED,
  GET_SUBJECT_BY_SLUG_FAILED,
  GET_SUBJECTS,
  SET_SUBJECT_DATA,
  SET_SUBJECT_EDIT_DATA,
  SET_SUBJECT_ERROR_DATA,
  GET_SUBJECT_BY_SLUG,
  COPY_SUBJECT_CONTENT_SUCCEEDED,
  COPY_SUBJECT_CONTENT,
  COPY_SUBJECT_CONTENT_FAILED,
  GET_SUBJECTS_BY_PAGE,
  GET_SUBJECTS_BY_PAGE_SUCCEEDED,
  GET_SUBJECTS_BY_PAGE_FAILED,
  EDIT_SUBJECT,
  ADD_SUBJECT,
} from '../../src/actions';
import { ISubjects } from '../interfaces/subjects';
import { getLocalStore } from 'next-persist';

const initialState: ISubjects = {
  subjects: {
    data: [],
  },
  query: {
    subject: {
      page: 1,
      per_page: 10,
    },
  },
  subject: {},
  loading: false,
  subjectBySlugLoaded: false,
  error: {
    addSubject: {},
    editSubject: {},
  },
  helperObj: {
    addSubject: false,
    editSubject: false,
    addSubjectStatus: '',
    editSubjectStatus: '',
    copySubjectContentStatus: '',
    getMoreSubjectsStatus: '',
  },
};
const persistedState = getLocalStore('subjects', initialState);

const subjects = (state = persistedState, action) => {
  switch (action.type) {
    case GET_SUBJECTS:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBJECTS_SUCCEEDED:
      return {
        ...state,
        subjects: action.data,
        loading: false,
      };

    case GET_SUBJECTS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_SUBJECT_BY_SLUG:
      return {
        ...state,
        subjectBySlugLoaded: false,
      };
    case GET_SUBJECT_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        subject: action.data,
        subjectBySlugLoaded: true,
      };

    case GET_SUBJECT_BY_SLUG_FAILED:
      return {
        ...state,
        subjectBySlugLoaded: false,
      };
    case GET_SUBJECTS_BY_PAGE:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          getMoreSubjectsStatus: 'request',
        },
        loading: true,
      };
    case GET_SUBJECTS_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          getMoreSubjectsStatus: 'success',
        },
        loading: false,
        subjects: {
          ...state.subjects,
          data: [...state.subjects?.data, ...action.data.data],
          meta: action.data.meta,
        },
      };

    case GET_SUBJECTS_BY_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        helperObj: {
          ...state.helperObj,
          getMoreSubjectsStatus: 'failure',
        },
      };
    case ADD_SUBJECT:
      return {
        ...state,
        helperObj: { ...state.helperObj, addSubjectStatus: 'request' },
      };
    case ADD_SUBJECT_SUCCEEDED:
      const mySubjectArr = [action.data.data, ...state.subjects.data];
      const sortedSubjects = mySubjectArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });
      return {
        ...state,
        subjects: {
          ...state.subjects,
          data: sortedSubjects,
        },
        helperObj: {
          ...state.helperObj,
          addSubject: false,
          addSubjectStatus: 'success',
        },
        error: { ...state.error, addSubject: {} },
      };

    case ADD_SUBJECT_FAILED:
      return {
        ...state,
        error: { ...state.error, addSubject: action.error },
        helperObj: {
          ...state.helperObj,
          addSubjectStatus: 'failure',
        },
      };
    case EDIT_SUBJECT:
      return {
        ...state,
        helperObj: { ...state.helperObj, editSubjectStatus: 'request' },
      };
    case EDIT_SUBJECT_SUCCEEDED:
      const mySubjectEditArr = state.subjects.data.map((each: any) =>
        each.id == action.data.data.id ? action.data.data : each
      );
      const sortedEditSubjects = mySubjectEditArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });
      return {
        ...state,
        subjects: {
          ...state.subjects,
          data: sortedEditSubjects,
        },
        helperObj: {
          ...state.helperObj,
          editSubject: false,
          editSubjectStatus: 'success',
        },
        error: { ...state.error, editSubject: {} },
      };

    case EDIT_SUBJECT_FAILED:
      return {
        ...state,
        error: { ...state.error, editSubject: action.error },
        helperObj: { ...state.helperObj, editSubjectStatus: 'failure' },
      };
    case DELETE_SUBJECT_SUCCEEDED:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          data: state.subjects.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_SUBJECT_FAILED:
      return {
        ...state,
      };
    case COPY_SUBJECT_CONTENT:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          copySubjectContentStatus: 'request',
        },
      };
    case COPY_SUBJECT_CONTENT_SUCCEEDED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          copySubjectContentStatus: 'success',
        },
      };
    case COPY_SUBJECT_CONTENT_FAILED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          copySubjectContentStatus: 'failure',
        },
      };

    case SET_SUBJECT_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_SUBJECT_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: {
          ...state[action.payload.data.value],
        },
      };
    case SET_SUBJECT_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    default:
      return state;
  }
};

export default subjects;
