import {
  ADD_CLIENT_NOTES_FAILED,
  ADD_CLIENT_NOTES_REQUEST,
  ADD_CLIENT_NOTES_SUCCEEDED,
  ADD_CLIENT_TASKS_FAILED,
  ADD_CLIENT_TASKS_REQUEST,
  ADD_CLIENT_TASKS_SUCCEEDED,
  ADD_TUTOR_EDUCATION_INFO,
  ADD_TUTOR_EDUCATION_INFO_FAILED,
  ADD_TUTOR_EDUCATION_INFO_SUCCEEDED,
  ADD_TUTOR_EXPERIENCE_INFO,
  ADD_TUTOR_EXPERIENCE_INFO_FAILED,
  ADD_TUTOR_EXPERIENCE_INFO_SUCCEEDED,
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCEEDED,
  CLEAR_USER_STATUS,
  DELETE_CLIENT_NOTES_FAILED,
  DELETE_CLIENT_NOTES_REQUEST,
  DELETE_CLIENT_NOTES_SUCCEEDED,
  DELETE_CLIENT_TASKS_FAILED,
  DELETE_CLIENT_TASKS_REQUEST,
  DELETE_CLIENT_TASKS_SUCCEEDED,
  DELETE_TUTOR_EDUCATION_INFO_FAILED,
  DELETE_TUTOR_EDUCATION_INFO_SUCCEEDED,
  DELETE_TUTOR_EXPERIENCE_INFO_FAILED,
  DELETE_TUTOR_EXPERIENCE_INFO_SUCCEEDED,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCEEDED,
  EDIT_CLIENT_NOTES_FAILED,
  EDIT_CLIENT_NOTES_REQUEST,
  EDIT_CLIENT_NOTES_SUCCEEDED,
  EDIT_CLIENT_TASKS_FAILED,
  EDIT_CLIENT_TASKS_REQUEST,
  EDIT_CLIENT_TASKS_SUCCEEDED,
  EDIT_TUTOR,
  EDIT_TUTOR_EDUCATION_INFO_FAILED,
  EDIT_TUTOR_EDUCATION_INFO_SUCCEEDED,
  EDIT_TUTOR_EXPERIENCE_INFO_FAILED,
  EDIT_TUTOR_EXPERIENCE_INFO_SUCCEEDED,
  EDIT_TUTOR_FAILED,
  EDIT_TUTOR_SUCCEEDED,
  EDIT_USER,
  EDIT_USER_FAILED,
  EDIT_USER_SUCCEEDED,
  GET_CLIENT_NOTES_FAILED,
  GET_CLIENT_NOTES_REQUEST,
  GET_CLIENT_NOTES_SUCCEEDED,
  GET_CLIENT_PAYMENT_HISTORY_FAILED,
  GET_CLIENT_PAYMENT_HISTORY_REQUEST,
  GET_CLIENT_PAYMENT_HISTORY_SUCCEEDED,
  GET_CLIENT_TASKS_FAILED,
  GET_CLIENT_TASKS_REQUEST,
  GET_CLIENT_TASKS_SUCCEEDED,
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCEEDED,
  GET_USER_DETAIL,
  GET_USER_DETAIL_FAILED,
  GET_USER_DETAIL_SUCCEEDED,
  GET_USER_REVIEWS,
  GET_USER_REVIEWS_FAILED,
  GET_USER_REVIEWS_SUCCEEDED,
  SEARCH_USER,
  SEARCH_USER_FAILED,
  SEARCH_USER_SUCCEEDED,
  SET_USER_DATA,
  SET_USER_EDIT_DATA,
  SET_USER_ERROR_DATA,
} from '../../../actions/index';
import { IUsers } from '../../../interfaces/admin/user';
import { getLocalStore } from 'next-persist';

const initialState: IUsers = {
  users: {
    data: [],
    loading: false,
  },
  register: {
    status: false,
    error: {},
  },
  userDetail: {
    data: {},
    loading: false,
  },
  userReviews: {
    data: [],
    loading: false,
  },
  education: {
    data: [],
  },
  experience: {
    data: [],
  },
  clientTask: {
    data: {},
    loading: false,
    error: {},
  },
  clientNote: {
    data: {},
    loading: false,
    error: {},
  },
  PaymentHistory: {
    data: {},
    loading: false,
    error: {},
  },
  helperObj: {
    addEducation: false,
    editEducation: false,
    addExperience: false,
    editExperience: false,
    addTask: false,
    editTask: false,
    addNote: false,
    editNote: false,
    showAddPartnerStaff: false,
    showEditPartnerStaff: false,
  },
  error: {
    addEducation: {},
    editEducation: {},
    addExperience: {},
    editExperience: {},
    addTask: {},
    editTask: {},
    addNote: {},
    editNote: {},
  },
  addEducationStatus: '',
  editEducationStatus: '',
  deleteEducationStatus: '',
  addExperienceStatus: '',
  editExperienceStatus: '',
  deleteExperienceStatus: '',
  updateUserStatus: '',
  addTaskStatus: '',
  editTaskStatus: '',
  deleteTaskStatus: '',
  addNoteStatus: '',
  editNoteStatus: '',
  deleteNoteStatus: '',
  addUserStatus: '',
  editUserStatus: '',
};
const persistedState = getLocalStore('userReducer', initialState);

const userReducer = (state = persistedState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case GET_USERS_SUCCEEDED:
      return {
        ...state,
        users: action.data,
      };
    case GET_USERS_FAILED:
      return {
        ...state,
      };
    case SEARCH_USER:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case SEARCH_USER_SUCCEEDED:
      return {
        ...state,
        users: action.data,
      };
    case SEARCH_USER_FAILED:
      return {
        ...state,
      };
    case ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
        },
        addUserStatus: 'request',
      };
    case GET_USER_DETAIL:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          loading: true,
        },
      };
    case GET_USER_DETAIL_SUCCEEDED:
      return {
        ...state,
        userDetail: action.data,
      };
    case GET_USER_DETAIL_FAILED:
      return {
        ...state,
        userDetail: {
          loading: false,
          error: action.error,
        },
      };
    case ADD_USER_SUCCEEDED:
      // window.location.reload();
      return {
        ...state,
        users: {
          ...state.users,
          data: [action.data.success, ...state.users.data],
          loading: false,
        },
        helperObj: {
          ...state.helperObj,
          showAddPartnerStaff: false,
        },
        register: {
          status: true,
          error: {},
        },
        addUserStatus: 'success',
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
        },
        register: {
          status: false,
          error: action.error,
        },
        addUserStatus: 'failed',
      };
    case EDIT_USER:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          status: false,
        },
        updateUserStatus: 'request',
      };
    case EDIT_USER_SUCCEEDED:
      const oldUser = state.users.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        users: {
          ...state.users,
          data: [action.data.data, ...oldUser],
          loading: false,
          status: true,
        },
        userDetail: {
          data: {
            ...state.userDetail.data,
            ...action.data.data,
          },
        },
        register: {
          status: true,
          error: {},
        },
        updateUserStatus: 'success',
      };
    case EDIT_USER_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          status: false,
        },
        register: {
          status: false,
          error: action.error,
        },
        updateUserStatus: 'failure',
      };

    case EDIT_TUTOR:
      return {
        ...state,
        updateUserStatus: 'request',
      };
    case EDIT_TUTOR_SUCCEEDED:
      const oldTutor = state.users.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        users: {
          ...state.users,
          data: [action.data.data, ...oldTutor],
          loading: false,
          status: true,
        },
        updateUserStatus: 'success',
      };
    case EDIT_TUTOR_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          status: false,
        },
        updateUserStatus: 'failure',
      };

    case DELETE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case DELETE_USER_SUCCEEDED:
      const filterUser = state.users.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        users: {
          ...state.users,
          data: filterUser,
          loading: false,
        },
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
      };
    case GET_USER_REVIEWS:
      return {
        ...state,
        userReviews: {
          ...state.userReviews,
          loading: true,
        },
      };
    case GET_USER_REVIEWS_SUCCEEDED:
      return {
        ...state,
        userReviews: {
          ...state.userReviews,
          data: action.data,
          loading: false,
        },
      };
    case GET_USER_REVIEWS_FAILED:
      return {
        ...state,
        userReviews: {
          ...state.userReviews,
          loading: false,
        },
      };
    case ADD_TUTOR_EDUCATION_INFO:
      return {
        ...state,
        addEducationStatus: 'loading',
      };
    case ADD_TUTOR_EDUCATION_INFO_SUCCEEDED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            education: [...state.userDetail.data.education, action.data.success],
          },
        },
        helperObj: {
          ...state.helperObj,
          addEducation: false,
        },
        error: { ...state.error, addEducation: {} },
        addEducationStatus: 'success',
      };

    case ADD_TUTOR_EDUCATION_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, addEducation: action.error },
        addEducationStatus: 'failure',
      };

    case EDIT_TUTOR_EDUCATION_INFO_SUCCEEDED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            education: state.userDetail.data.education.map((each: any) =>
              each.id == action.data.data.id ? action.data.data : each
            ),
          },
        },
        helperObj: {
          ...state.helperObj,
          editEducation: false,
        },
        error: { ...state.error, editEducation: {} },
        editEducationStatus: 'success',
      };

    case EDIT_TUTOR_EDUCATION_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, editEducation: action.error },
        editEducationStatus: 'failure',
      };
    case DELETE_TUTOR_EDUCATION_INFO_SUCCEEDED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            education: state.userDetail.data.education.filter(
              (each: any) => each.id !== action.data.data.id
            ),
          },
        },
        deleteEducationStatus: 'success',
      };

    case DELETE_TUTOR_EDUCATION_INFO_FAILED:
      return {
        ...state,
        deleteEducationStatus: 'failure',
      };

    case ADD_TUTOR_EXPERIENCE_INFO:
      return {
        ...state,
        addExperienceStatus: 'loading',
      };
    case ADD_TUTOR_EXPERIENCE_INFO_SUCCEEDED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            experience: [...state.userDetail.data.experience, action.data.success],
          },
        },
        helperObj: {
          ...state.helperObj,
          addExperience: false,
        },
        error: { ...state.error, addExperience: {} },
        addExperienceStatus: 'success',
      };

    case ADD_TUTOR_EXPERIENCE_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, addExperience: action.error },
        addExperienceStatus: 'failure',
      };

    case EDIT_TUTOR_EXPERIENCE_INFO_SUCCEEDED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            experience: state.userDetail.data.experience.map((each: any) =>
              each.id == action.data.data.id ? action.data.data : each
            ),
          },
        },
        helperObj: {
          ...state.helperObj,
          editExperience: false,
        },
        error: { ...state.error, editExperience: {} },
        editExperienceStatus: 'success',
      };

    case EDIT_TUTOR_EXPERIENCE_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, editExperience: action.error },
        editExperienceStatus: 'failure',
      };
    case DELETE_TUTOR_EXPERIENCE_INFO_SUCCEEDED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            experience: state.userDetail.data.experience.filter(
              (each: any) => each.id !== action.data.data.id
            ),
          },
        },
        deleteExperienceStatus: 'success',
      };

    case DELETE_TUTOR_EXPERIENCE_INFO_FAILED:
      return {
        ...state,
        deleteExperienceStatus: 'failure',
      };

    case SET_USER_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_USER_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
      };
    case SET_USER_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    case GET_CLIENT_TASKS_REQUEST:
      return {
        ...state,
        clientTask: {
          ...state.clientTask,
          loading: true,
          error: {},
        },
      };
    case GET_CLIENT_TASKS_SUCCEEDED:
      return {
        ...state,
        clientTask: {
          ...state.clientTask,
          loading: false,
          data: {
            ...state?.data,
            data: action.data?.data,
            meta: action.data?.meta,
          },
          error: {},
        },
      };
    case GET_CLIENT_TASKS_FAILED:
      return {
        ...state,
        clientTask: {
          ...state.clientTask,
          loading: false,
          error: action.error,
        },
      };
    case ADD_CLIENT_TASKS_REQUEST:
      return {
        ...state,
        error: {
          ...state.error,
          addTask: {},
        },
        addTaskStatus: 'request',
      };
    case ADD_CLIENT_TASKS_SUCCEEDED:
      return {
        ...state,
        clientTask: {
          ...state?.clientTask,
          data: {
            ...state.clientTask.data,
            data: [action?.data?.data, ...state?.clientTask?.data?.data],
          },
        },
        error: {
          ...state.error,
          addTask: {},
        },
        helperObj: {
          ...state.helperObj,
          addTask: false,
        },
        addTaskStatus: 'success',
      };
    case ADD_CLIENT_TASKS_FAILED:
      return {
        ...state,
        error: {
          ...state.error,
          addTask: action.error,
        },
        addTaskStatus: 'failed',
      };
    case EDIT_CLIENT_TASKS_REQUEST:
      return {
        ...state,
        error: {
          ...state.error,
          editTask: {},
        },
        editTaskStatus: 'request',
      };
    case EDIT_CLIENT_TASKS_SUCCEEDED:
      return {
        ...state,
        clientTask: {
          ...state?.clientTask,
          data: {
            ...state.clientTask?.data,
            data: state?.clientTask?.data?.data?.map((each: any) =>
              each.id === action.data.data.id ? action?.data?.data : each
            ),
          },
        },
        error: {
          ...state?.error,
          editTask: {},
        },
        helperObj: {
          ...state?.helperObj,
          editTask: false,
        },
        editTaskStatus: 'success',
      };
    case EDIT_CLIENT_TASKS_FAILED:
      return {
        ...state,
        error: {
          ...state.error,
          editTask: action.error,
        },
        editTaskStatus: 'failed',
      };
    case DELETE_CLIENT_TASKS_REQUEST:
      return {
        ...state,
        deleteTaskStatus: 'request',
      };
    case DELETE_CLIENT_TASKS_SUCCEEDED:
      return {
        ...state,
        clientTask: {
          ...state?.clientTask,
          data: {
            ...state.clientTask?.data,
            data: state?.clientTask?.data?.data?.filter(
              (each: any) => each.id !== action.data.data.id
            ),
          },
        },
        deleteTaskStatus: 'success',
      };
    case DELETE_CLIENT_TASKS_FAILED:
      return {
        ...state,
        deleteTaskStatus: 'failed',
      };
    case GET_CLIENT_NOTES_REQUEST:
      return {
        ...state,
        clientNote: {
          ...state.clientNote,
          loading: true,
          error: {},
        },
      };
    case GET_CLIENT_NOTES_SUCCEEDED:
      return {
        ...state,
        clientNote: {
          ...state.clientNote,
          loading: false,
          data: {
            ...state?.data,
            data: action.data?.data,
            meta: action.data?.meta,
          },
          error: {},
        },
      };
    case GET_CLIENT_NOTES_FAILED:
      return {
        ...state,
        clientNote: {
          ...state.clientNote,
          loading: false,
          error: action.error,
        },
      };
    case ADD_CLIENT_NOTES_REQUEST:
      return {
        ...state,
        error: {
          ...state.error,
          addNote: {},
        },
        addNoteStatus: 'request',
      };
    case ADD_CLIENT_NOTES_SUCCEEDED:
      return {
        ...state,
        clientNote: {
          ...state?.clientNote,
          data: {
            ...state.clientNote.data,
            data: [action?.data?.data, ...state?.clientNote?.data?.data],
          },
        },
        error: {
          ...state.error,
          addNote: {},
        },
        helperObj: {
          ...state.helperObj,
          addNote: false,
        },
        addNoteStatus: 'success',
      };
    case ADD_CLIENT_NOTES_FAILED:
      return {
        ...state,
        error: {
          ...state.error,
          addNote: action.error,
        },
        addNoteStatus: 'failed',
      };
    case EDIT_CLIENT_NOTES_REQUEST:
      return {
        ...state,
        error: {
          ...state.error,
          editNote: {},
        },
        editNoteStatus: 'request',
      };
    case EDIT_CLIENT_NOTES_SUCCEEDED:
      return {
        ...state,
        clientNote: {
          ...state?.clientNote,
          data: {
            ...state.clientNote?.data,
            data: state?.clientNote?.data?.data?.map((each: any) =>
              each.id === action.data.data.id ? action?.data?.data : each
            ),
          },
        },
        error: {
          ...state?.error,
          editNote: {},
        },
        helperObj: {
          ...state?.helperObj,
          editNote: false,
        },
        editNoteStatus: 'success',
      };
    case EDIT_CLIENT_NOTES_FAILED:
      return {
        ...state,
        error: {
          ...state.error,
          editNote: action.error,
        },
        editNoteStatus: 'failed',
      };
    case DELETE_CLIENT_NOTES_REQUEST:
      return {
        ...state,
        deleteNoteStatus: 'request',
      };
    case DELETE_CLIENT_NOTES_SUCCEEDED:
      return {
        ...state,
        clientNote: {
          ...state?.clientNote,
          data: {
            ...state.clientNote?.data,
            data: state?.clientNote?.data?.data?.filter(
              (each: any) => each.id !== action.data.data.id
            ),
          },
        },
        deleteNoteStatus: 'success',
      };
    case DELETE_CLIENT_NOTES_FAILED:
      return {
        ...state,
        deleteNoteStatus: 'failed',
      };
    case GET_CLIENT_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          loading: true,
          error: {},
        },
      };
    case GET_CLIENT_PAYMENT_HISTORY_SUCCEEDED:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          loading: false,
          data: {
            ...state?.data,
            data: action.data?.data,
            meta: action.data?.meta,
          },
          error: {},
        },
      };
    case GET_CLIENT_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          loading: false,
          error: action.error,
        },
      };
    case CLEAR_USER_STATUS:
      return {
        ...state,
        updateUserStatus: '',
        addUserStatus: '',
      };
    default:
      return state;
  }
};

export default userReducer;
