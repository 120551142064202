export const ADD_CONTACT_COMMENTS_REQUEST: any = 'ADD_CONTACT_COMMENTS_REQUEST';
export const ADD_CONTACT_COMMENTS_SUCCEEDED: any = 'ADD_CONTACT_COMMENTS_SUCCEEDED';
export const ADD_CONTACT_COMMENTS_FAILED: any = 'ADD_CONTACT_COMMENTS_FAILED';

// Admin view and edit contact inquiries

export const GET_CONTACT_INQUIRIES: any = 'GET_CONTACT_INQUIRIES';
export const GET_CONTACT_INQUIRIES_SUCCEEDED: any =
  'GET_CONTACT_INQUIRIES_SUCCEEDED';
export const GET_CONTACT_INQUIRIES_FAILED: any = 'GET_CONTACT_INQUIRIES_FAILED';

export const GET_CONTACT_INQUIRIES_BY_ID: any = 'GET_CONTACT_INQUIRIES_BY_ID';
export const GET_CONTACT_INQUIRIES_BY_ID_SUCCEEDED: any =
  'GET_CONTACT_INQUIRIES_BY_ID_SUCCEEDED';
export const GET_CONTACT_INQUIRIES_BY_ID_FAILED: any =
  'GET_CONTACT_INQUIRIES_BY_ID_FAILED';

export const EDIT_CONTACT_INQUIRIES: any = 'EDIT_CONTACT_INQUIRIES';
export const EDIT_CONTACT_INQUIRIES_SUCCEEDED: any =
  'EDIT_CONTACT_INQUIRIES_SUCCEEDED';

export const EDIT_CONTACT_INQUIRIES_FAILED: any = 'EDIT_CONTACT_INQUIRIES_FAILED';

//Contact Inquiries Add
export const ADD_CONTACT_INQUIRIES_REQUEST: any = 'ADD_CONTACT_INQUIRIES_REQUEST';
export const ADD_CONTACT_INQUIRIES_SUCCEEDED: any =
  'ADD_CONTACT_INQUIRIES_SUCCEEDED';
export const ADD_CONTACT_INQUIRIES_FAILED: any = 'ADD_CONTACT_INQUIRIES_FAILED';

//Contact Iquiries comments
export const GET_CONTACT_COMMENTS_REQUEST: any = 'GET_CONTACT_COMMENTS_REQUEST';
export const GET_CONTACT_COMMENTS_SUCCEEDED: any = 'GET_CONTACT_COMMENTS_SUCCEEDED';
export const GET_CONTACT_COMMENTS_FAILED: any = 'GET_CONTACT_COMMENTS_FAILED';

export const EDIT_CONTACT_COMMENTS_REQUEST: any = 'EDIT_CONTACT_COMMENTS_REQUEST';
export const EDIT_CONTACT_COMMENTS_SUCCEEDED: any =
  'EDIT_CONTACT_COMMENTS_SUCCEEDED';
export const EDIT_CONTACT_COMMENTS_FAILED: any = 'EDIT_CONTACT_COMMENTS_FAILED';

export const DELETE_CONTACT_COMMENTS_REQUEST: any =
  'DELETE_CONTACT_COMMENTS_REQUEST';
export const DELETE_CONTACT_COMMENTS_SUCCEEDED: any =
  'DELETE_CONTACT_COMMENTS_SUCCEEDED';
export const DELETE_CONTACT_COMMENTS_FAILED: any = 'DELETE_CONTACT_COMMENTS_FAILED';

//Contact Inquiries tasks
export const GET_CONTACT_TASK_REQUEST: any = 'GET_CONTACT_TASK_REQUEST';
export const GET_CONTACT_TASK_SUCCEEDED: any = 'GET_CONTACT_TASK_SUCCEEDED';
export const GET_CONTACT_TASK_FAILED: any = 'GET_CONTACT_TASK_FAILED';

export const ADD_CONTACT_TASK_REQUEST: any = 'ADD_CONTACT_TASK_REQUEST';
export const ADD_CONTACT_TASK_SUCCEEDED: any = 'ADD_CONTACT_TASK_SUCCEEDED';
export const ADD_CONTACT_TASK_FAILED: any = 'ADD_CONTACT_TASK_FAILED';

export const EDIT_CONTACT_TASK_REQUEST: any = 'EDIT_CONTACT_TASK_REQUEST';
export const EDIT_CONTACT_TASK_SUCCEEDED: any = 'EDIT_CONTACT_TASK_SUCCEEDED';
export const EDIT_CONTACT_TASK_FAILED: any = 'EDIT_CONTACT_TASK_FAILED';

export const DELETE_CONTACT_TASK_REQUEST: any = 'DELETE_CONTACT_TASK_REQUEST';
export const DELETE_CONTACT_TASK_SUCCEEDED: any = 'DELETE_CONTACT_TASK_SUCCEEDED';
export const DELETE_CONTACT_TASK_FAILED: any = 'DELETE_CONTACT_TASK_FAILED';

//Set Lead || Contact Inquiries
export const SET_CONTACT_INQUIRIES: any = 'SET_CONTACT_INQUIRIES';
