// FAQ
export const GET_FAQ: any = 'GET_FAQ';
export const GET_FAQ_SUCCEEDED: any = 'GET_FAQ_SUCCEEDED';
export const GET_FAQ_FAILED: any = 'GET_FAQ_FAILED';

export const ADD_FAQ: any = 'ADD_FAQ';
export const ADD_FAQ_SUCCEEDED: any = 'ADD_FAQ_SUCCEEDED';
export const ADD_FAQ_FAILED: any = 'ADD_FAQ_FAILED';

export const EDIT_FAQ: any = 'EDIT_FAQ';
export const EDIT_FAQ_SUCCEEDED: any = 'EDIT_FAQ_SUCCEEDED';
export const EDIT_FAQ_FAILED: any = 'EDIT_FAQ_FAILED';

export const DELETE_FAQ: any = 'DELETE_FAQ';
export const DELETE_FAQ_SUCCEEDED: any = 'DELETE_FAQ_SUCCEEDED';
export const DELETE_FAQ_FAILED: any = 'DELETE_FAQ_FAILED';
