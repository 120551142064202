//company
export const GET_COMPANY: any = 'GET_COMPANY';
export const GET_COMPANY_SUCCEEDED: any = 'GET_COMPANY_SUCCEEDED';
export const GET_COMPANY_FAILED: any = 'GET_COMPANY_FAILED';

export const ADD_COMPANY: any = 'ADD_COMPANY';
export const ADD_COMPANY_SUCCEEDED: any = 'ADD_COMPANY_SUCCEEDED';
export const ADD_COMPANY_FAILED: any = 'ADD_COMPANY_FAILED';

export const EDIT_COMPANY: any = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCEEDED: any = 'EDIT_COMPANY_SUCCEEDED';
export const EDIT_COMPANY_FAILED: any = 'EDIT_COMPANY_FAILED';

export const DELETE_COMPANY: any = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCEEDED: any = 'DELETE_COMPANY_SUCCEEDED';
export const DELETE_COMPANY_FAILED: any = 'DELETE_COMPANY_FAILED';
