import { SET_LIVE_DATA } from '../../src/actions';
import { ILive } from '../interfaces/live';
import { getLocalStore } from 'next-persist';

const initialState: ILive = {
  chatStatus: true,
  socket: null,
  meetingStatus: null,
  pinnedUserData: {
    pin_status: false,
  },
  screenSharing: false,
  localStream: null,
  screenStream: null,
  localAudioStatus: false,
  localVideoStatus: false,
  fullScreenView: { status: false, id: null },

  activeChatClass: 'hide',
  activeMemberClass: 'hide',
  chatFocus: false,
  unReadChat: null,
  usersConnected: [],
  videoAvailable: false,
  audioAvailable: false,
  screenAvailable: false,
  meetingDetails: {},
  hostId: null,
  host: false,
  stream: null,
};
const persistedState = getLocalStore('live', initialState);

const live = (state = persistedState, action) => {
  switch (action.type) {
    case SET_LIVE_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};

export default live;
