//Social Media
export const GET_SOCIAL_MEDIA: any = 'GET_SOCIAL_MEDIA';
export const GET_SOCIAL_MEDIA_SUCCEEDED: any = 'GET_SOCIAL_MEDIA_SUCCEEDED';
export const GET_SOCIAL_MEDIA_FAILED: any = 'GET_SOCIAL_MEDIA_FAILED';

export const ADD_SOCIAL_MEDIA: any = 'ADD_SOCIAL_MEDIA';
export const ADD_SOCIAL_MEDIA_SUCCEEDED: any = 'ADD_SOCIAL_MEDIA_SUCCEEDED';
export const ADD_SOCIAL_MEDIA_FAILED: any = 'ADD_SOCIAL_MEDIA_FAILED';

export const EDIT_SOCIAL_MEDIA: any = 'EDIT_SOCIAL_MEDIA';
export const EDIT_SOCIAL_MEDIA_SUCCEEDED: any = 'EDIT_SOCIAL_MEDIA_SUCCEEDED';
export const EDIT_SOCIAL_MEDIA_FAILED: any = 'EDIT_SOCIAL_MEDIA_FAILED';

export const DELETE_SOCIAL_MEDIA: any = 'DELETE_SOCIAL_MEDIA';
export const DELETE_SOCIAL_MEDIA_SUCCEEDED: any = 'DELETE_SOCIAL_MEDIA_SUCCEEDED';
export const DELETE_SOCIAL_MEDIA_FAILED: any = 'DELETE_SOCIAL_MEDIA_FAILED';

export const SET_SOCIAL_MEDIA_DATA: any = 'SET_SOCIAL_MEDIA_DATA';
export const SET_SOCIAL_MEDIA_EDIT_DATA: any = 'SET_SOCIAL_MEDIA_EDIT_DATA';
export const SET_SOCIAL_MEDIA_ERROR_DATA: any = 'SET_SOCIAL_MEDIA_ERROR_DATA';
