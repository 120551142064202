import {
  USER_LOGIN_SUCCEEDED,
  USER_LOGIN_FAILED,
  USER_REGISTER,
  USER_REGISTER_SUCCEEDED,
  USER_REGISTER_FAILED,
  USER_VERIFICATION,
  USER_VERIFICATION_SUCCEEDED,
  USER_VERIFICATION_FAILED,
  USER_VERIFICATION_REQUEST,
  USER_VERIFICATION_REQUEST_SUCCEEDED,
  USER_VERIFICATION_REQUEST_FAILED,
  GET_PROFILE_SUCCEEDED,
  GET_PROFILE_FAILED,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCEEDED,
  UPDATE_PROFILE_FAILED,
  USER_LOGOUT,
  USER_LOGOUT_SUCCEEDED,
  SET_DATA,
  ADD_DATA,
  CHANGE_USER_PASSWORD_FAILED,
  CHANGE_USER_PASSWORD_SUCCEEDED,
  ADD_JOB_EXPERIENCE,
  ADD_JOB_EXPERIENCE_SUCCEEDED,
  ADD_JOB_EXPERIENCE_FAILED,
  EDIT_JOB_EXPERIENCE,
  EDIT_JOB_EXPERIENCE_SUCCEEDED,
  EDIT_JOB_EXPERIENCE_FAILED,
  DELETE_JOB_EXPERIENCE,
  DELETE_JOB_EXPERIENCE_SUCCEEDED,
  DELETE_JOB_EXPERIENCE_FAILED,
  ADD_EDUCATION_SUCCEEDED,
  ADD_EDUCATION,
  ADD_EDUCATION_FAILED,
  EDIT_EDUCATION,
  EDIT_EDUCATION_SUCCEEDED,
  EDIT_EDUCATION_FAILED,
  DELETE_EDUCATION,
  DELETE_EDUCATION_SUCCEEDED,
  DELETE_EDUCATION_FAILED,
  REQUEST_TOKEN,
  REQUEST_TOKEN_SUCCEEDED,
  REQUEST_TOKEN_FAILED,
  TOKEN_VALIDATION_SUCCEEDED,
  TOKEN_VALIDATION_FAILED,
  RESET_PASSWORD_SUCCEEDED,
  RESET_PASSWORD_FAILED,
  TOKEN_VALIDATION,
  RESET_PASSWORD,
  GET_PROFILE,
  CLEAR_AUTH_DATA,
  SET_AUTH_NESTED_DATA,
  GET_SIGNUP_PARTNER_DETAIL_REQUEST,
  GET_SIGNUP_PARTNER_DETAIL_SUCCEEDED,
  GET_SIGNUP_PARTNER_DETAIL_FAILED,
  GET_USER_DASHBOARD,
  GET_USER_DASHBOARD_SUCCEEDED,
  GET_USER_DASHBOARD_FAILED,
  GET_ADDRESS_FROM_IPADDRESS_REQUEST,
  GET_ADDRESS_FROM_IPADDRESS_SUCCEEDED,
  GET_ADDRESS_FROM_IPADDRESS_FAILED,
  NEW_DEVICE_VERIFICATION_SUCCEEDED,
  NEW_DEVICE_VERIFICATION_REQUEST,
  NEW_DEVICE_VERIFICATION_FAILED,
  RESEND_VERIFICATION_DEVICE_REQUEST,
  RESEND_VERIFICATION_DEVICE_SUCCEEDED,
  RESEND_VERIFICATION_DEVICE_FAILED,
  GET_SIGNUP_OTP,
  GET_SIGNUP_OTP_SUCCEEDED,
  GET_SIGNUP_OTP_FAILED,
  VERIFY_SIGNUP_OTP,
  VERIFY_SIGNUP_OTP_SUCCEEDED,
  VERIFY_SIGNUP_OTP_FAILED,
} from '../../src/actions/auth/types';
import { IAuth } from '../interfaces/auth';
import { getLocalStore } from 'next-persist';
import router from 'next/router';

const initialState: IAuth = {
  creds: {
    user_data: {
      is_verified: false,
      phone_number: '',
    },
    status: false,
  },
  isLoggedIn: false,
  fromLogOut: false,
  logoutStatus: '',
  user: {
    status: false,
    error: {},
  },
  userDashboard: {},
  register: {
    status: false,
    error: {},
  },
  changePassword: {
    status: false,
    error: {},
  },
  helperObj: {
    loginModal: false,
    registerModal: false,
    changePassword: false,
  },
  profile: {
    data: {},
  },
  editProfile: {},
  updateProfile: {
    status: false,
    error: {},
  },
  verification: {
    status: false,
    error: {},
    resend: false,
  },
  deviceVerification: {
    status: '',
    error: '',
    resend: '',
  },
  perviousPagePathName: '',
  jobExperience: {
    data: [],
    error: null,
    status: false,
  },
  education: {
    data: [],
    status: false,
    error: null,
  },
  tokenRequested: {
    status: false,
    error: {},
    loading: false,
  },
  tokenValidation: {
    status: false,
    error: {},
    loading: false,
  },
  resetPassword: {
    status: false,
    error: {},
    loading: false,
  },
  partner: {
    data: {},
    loading: false,
    error: {},
  },
  address: {
    data: {},
    loading: false,
    error: {},
  },
  updateProfileStatus: '',
  getProfileStatus: '',
  getUserDashboardStatus: '',
  updateEducationStatus: '',
  getEducationStatus: '',
  updateExperienceStatus: '',
  getExperienceStatus: '',
  signUpStatus: '',
  courseData: {},
  isCurriculumRedirect: false,
  currency: '',
  getSignupOtpStatus: '',
  verifySignupOtpStatus: '',
};
const persistedState = getLocalStore('auth', initialState);

const authReducer = (state = persistedState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCEEDED:
      const { access, user_data } = action?.data;
      const { courseData, isCurriculumRedirect } = state;
      window.localStorage.removeItem('myPartner');
      window.localStorage.removeItem('partner_code');

      if (window.location.pathname.includes('/room')) {
        window.location.reload();
      } else if (isCurriculumRedirect && Object.keys(courseData).length !== 0) {
        router.push({
          pathname: `/course/lesson/${courseData?.courseSlug}/`,
          query: {
            subtab: courseData?.subjectId,
            chaptab: courseData?.chapterId,
            activetab: courseData?.contentId,
          },
        });
      } else if (user_data.user_type == 'staff') {
        window.location.pathname = '/dashboard/home';
      } else if (user_data.user_type == 'client') {
        window.location.reload();
      } else if (user_data.user_type == 'instructor') {
        if (user_data.status === 'PENDING') {
          window.location.pathname = '/tutor/verify';
        } else {
          window.location.pathname = '/';
        }
      } else {
        window.location.pathname = '/';
      }
      window.localStorage.setItem('user', JSON.stringify(user_data));
      return {
        ...state,
        helperObj: { registerModal: false, loginModal: false },
        creds: {
          isLoggedIn: true,
          access,
          user_data,
          message: '',
          status: true,
        },
        isLoggedIn: true,
        user: {},
        perviousPagePathName: window.location.pathname,
        changePassword: {
          ...state.changePassword,
          status: false,
        },
        // isCurriculumRedirect: false,
        // courseData: {},
      };

    case USER_LOGIN_FAILED:
      return {
        ...state,
        creds: {
          isLoggedIn: false,
          loginFailed: true,
          message:
            action?.error?.data?.error?.non_field_errors || action?.error?.remark
              ? action?.error?.remark?.is_device_verified
                ? action?.error?.remark
                : JSON.parse(action?.error?.remark)
              : 'Unable to log in with provided credentials. Please try again',
          notification: true,
          status: false,
        },
        isLoggedIn: false,
      };

    case USER_LOGOUT:
      // return state.delete('creds');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      window.location.pathname = '/';

      return {
        ...state,
        helperObj: { registerModal: false, loginModal: false },
        // creds: {},
        isLoggedIn: false,
        fromLogOut: true,
        logoutStatus: 'request',
      };
    case USER_LOGOUT_SUCCEEDED:
      window.localStorage.setItem('from_logout', 'true');
      return {
        ...state,
        fromLogOut: true,
        logoutStatus: 'success',
      };
    case USER_REGISTER:
      return {
        ...state,
        register: {
          status: true,
          error: {},
        },
        signUpStatus: 'request',
      };

    case USER_REGISTER_SUCCEEDED:
      // const { accesss, user_datas } = action?.data;
      window.localStorage.setItem('user', JSON.stringify(action?.data?.user_data));
      if (action?.data?.user_data.user_type === 'staff') {
        window.location.pathname = '/dashboard/';
      } else if (action?.data?.user_data?.user_type == 'instructor') {
        window.location.pathname = '/tutor/verify/';
      } else if (
        window.location.pathname === '/' ||
        window.location.pathname === '/signup'
      ) {
        window.location.pathname = '/';
      } else {
        window.location.reload();
      }

      // if (action?.data?.user_data?.is_verified === false){
      //   window.location.pathname = '/verify';
      // }

      return {
        ...state,
        helperObj: { registerModal: false, loginModal: false },
        creds: {
          isLoggedIn: true,
          access: action?.data?.access,
          user_data: action?.data?.user_data,
          status: true,
        },
        isLoggedIn: true,
        register: {
          status: true,
          error: {},
        },
        user: {},
        perviousPagePathName: window.location.pathname,
        signUpStatus: 'success',
      };

    case USER_REGISTER_FAILED:
      return {
        ...state,
        register: {
          status: false,
          error: action.error,
        },
        signUpStatus: 'failure',
      };

    case CHANGE_USER_PASSWORD_SUCCEEDED:
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      window.location.pathname = '/login';
      return {
        ...state,
        changePassword: {
          status: true,
          error: {},
        },
        helperObj: {
          ...state.helperObj,
          changePassword: false,
        },
      };
    case CHANGE_USER_PASSWORD_FAILED:
      return {
        ...state,
        changePassword: {
          status: false,
          error: action.error,
        },
      };

    case NEW_DEVICE_VERIFICATION_REQUEST:
      return {
        ...state,
        deviceVerification: {
          ...state.deviceVerification,
          status: 'request',
        },
      };

    case NEW_DEVICE_VERIFICATION_SUCCEEDED:
      return {
        ...state,
        deviceVerification: {
          ...state.deviceVerification,
          status: 'success',
        },
      };

    case NEW_DEVICE_VERIFICATION_FAILED:
      return {
        ...state,
        deviceVerification: {
          ...state.deviceVerification,
          status: 'failed',
          error: action.error,
        },
      };

    case RESEND_VERIFICATION_DEVICE_REQUEST:
      return {
        ...state,
        deviceVerification: {
          ...state.deviceVerification,
          resend: 'request',
        },
      };

    case RESEND_VERIFICATION_DEVICE_SUCCEEDED:
      return {
        ...state,
        deviceVerification: {
          ...state.deviceVerification,
          resend: 'success',
        },
      };

    case RESEND_VERIFICATION_DEVICE_FAILED:
      return {
        ...state,
        deviceVerification: {
          ...state.deviceVerification,
          resend: 'failed',
        },
      };

    case USER_VERIFICATION:
      return {
        ...state,
        verification: {
          ...state.verification,
          status: false,
          error: {},
          resend: false,
        },
      };

    case USER_VERIFICATION_SUCCEEDED:
      window.location.pathname = '/verify/personal';

      return {
        ...state,
        creds: {
          ...state.creds,
          user_data: {
            ...state.creds.user_data,
            is_verified: true,
          },
        },
        verification: {
          ...state.verification,
          status: false,
          error: {},
          resend: false,
        },
      };

    case USER_VERIFICATION_FAILED:
      return {
        ...state,
        verification: {
          ...state.verification,
          status: true,
          error: action.error,
          resend: false,
        },
      };

    case USER_VERIFICATION_REQUEST_SUCCEEDED:
      return {
        ...state,
        verification: {
          ...state.verification,
          status: false,
          error: {},
          resend: false,
        },
      };

    case USER_VERIFICATION_REQUEST_SUCCEEDED:
      return {
        ...state,
        verification: { ...state.verification, resend: true },
      };

    case USER_VERIFICATION_REQUEST_FAILED:
      return {
        ...state,
      };
    case GET_PROFILE:
      return {
        ...state,
        getProfileStatus: 'request',
      };

    case GET_PROFILE_SUCCEEDED:
      return {
        ...state,
        getProfileStatus: 'success',
        profile: action.data,
        editProfile: action.data.data,
        creds: {
          ...state.creds,
          user_data: {
            ...state.creds.user_data,
            status: action.data.data.status,
          },
        },
      };

    case GET_PROFILE_FAILED:
      return {
        getProfileStatus: 'failure',
        ...state,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        updateProfileStatus: 'request',
        user: {
          ...state.user,
          status: false,
          error: {},
          loading: true,
          profileUpdated: false,
        },
      };

    case UPDATE_PROFILE_SUCCEEDED:
      // window.location.pathname = '/profile/my-course';
      return {
        ...state,
        updateProfileStatus: 'success',
        profile: action.data,
        creds: {
          ...state.creds,
          user_data: {
            ...state.creds.user_data,
            phone_number: action.data.data.phone_number,
            first_name: action.data.data.first_name,
            last_name: action.data.data.last_name,
            avatar: action.data.data.avatar,
          },
        },
        user: {
          ...state.user,
          loading: false,
          status: true,
          error: null,
          profileUpdated: true,
        },
        editProfile: Object.assign(state.editProfile, action.data.data),
      };

    case UPDATE_PROFILE_FAILED:
      return {
        ...state,
        updateProfileStatus: 'failure',
        user: {
          ...state.user,
          status: false,
          error: action.error,
          loading: false,
          profileUpdated: false,
        },
      };

    // Add Job Experience

    case ADD_JOB_EXPERIENCE:
      return {
        ...state,
        updateExperienceStatus: 'request',
      };

    case ADD_JOB_EXPERIENCE_SUCCEEDED:
      return {
        ...state,
        jobExperience: {
          ...state.jobExperience,
          data: [action.data.success, ...state.jobExperience.data],
          error: null,
          status: true,
        },
        editProfile: Object.assign(state.editProfile, action.data.data),
        updateExperienceStatus: 'success',
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            experience: [...state.profile.data.experience, action.data.success],
          },
        },
      };

    case ADD_JOB_EXPERIENCE_FAILED:
      return {
        ...state,
        jobExperience: {
          ...state.jobExperience,
          error: action.error,
          status: false,
        },
        updateExperienceStatus: 'failure',
      };

    case EDIT_JOB_EXPERIENCE_SUCCEEDED:
      return {
        ...state,
        jobExperience: {
          ...state.jobExperience,
          data: state.jobExperience.data?.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            experience: state.profile?.data?.experience?.map((each: any) =>
              each.id == action.data.data.id ? action.data.data : each
            ),
          },
        },
      };
    case EDIT_JOB_EXPERIENCE_FAILED:
      return {
        ...state,
      };
    case DELETE_JOB_EXPERIENCE_SUCCEEDED:
      return {
        ...state,
        jobExperience: {
          ...state.jobExperience,
          data: state.jobExperience.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            experience: state.profile?.data?.experience?.filter(
              (each: any) => each.id !== action.data.data.id
            ),
          },
        },
      };
    case DELETE_JOB_EXPERIENCE_FAILED:
      return {
        ...state,
      };

    // Add Education

    case ADD_EDUCATION:
      return {
        ...state,
        updateEducationStatus: 'request',
      };

    case ADD_EDUCATION_SUCCEEDED:
      return {
        ...state,
        education: {
          ...state.education,
          data: [action.data.success, ...state.education.data],
          status: true,
        },
        updateEducationStatus: 'success',
        editProfile: Object.assign(state.editProfile, action.data.data),
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            education: [...state.profile.data.education, action.data.success],
          },
        },
      };
    case ADD_EDUCATION_FAILED:
      return {
        ...state,
        updateEducationStatus: 'failure',
        education: {
          ...state.education,
          status: false,
          error: action.error,
        },
      };

    case EDIT_EDUCATION_SUCCEEDED:
      return {
        ...state,
        education: {
          ...state.education,
          data: state.education?.data?.map((each: any) =>
            each.id == action?.data?.data?.id ? action.data?.data : each
          ),
        },
        profile: {
          ...state.profile,
          data: {
            ...state.profile?.data,
            education: state.profile?.data?.education?.map((each: any) =>
              each.id == action.data?.data?.id ? action?.data?.data : each
            ),
          },
        },
      };
    case EDIT_EDUCATION_FAILED:
      return {
        ...state,
        education: {
          ...state.education,
          error: action.error,
        },
      };
    case DELETE_EDUCATION_SUCCEEDED:
      return {
        ...state,
        education: {
          ...state.education,
          data: state.education.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        profile: {
          ...state.profile,
          data: {
            ...state.profile.data,
            education: state.profile?.data?.education?.filter(
              (each: any) => each.id !== action.data.data.id
            ),
          },
        },
      };
    case DELETE_EDUCATION_FAILED:
      return {
        ...state,
      };

    case GET_USER_DASHBOARD:
      return {
        ...state,
        getUserDashboardStatus: 'request',
      };

    case GET_USER_DASHBOARD_SUCCEEDED:
      return {
        ...state,
        getUserDashboardStatus: 'success',
        userDashboard: action.data.data,
      };

    case GET_USER_DASHBOARD_FAILED:
      return {
        getUserDashboardStatus: 'failure',
        ...state,
      };

    case SET_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_AUTH_NESTED_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: {
            ...state[action.payload.data.name][action.payload.data.key],
            [action.payload.data.innerKey]: action.payload.data.value,
          },
        },
      };

    case ADD_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
      };
    // Forgot Password
    case REQUEST_TOKEN:
      return {
        ...state,
        tokenRequested: {
          ...state.tokenRequested,
          loading: true,
          status: false,
        },
      };
    case REQUEST_TOKEN_SUCCEEDED:
      return {
        ...state,
        tokenRequested: {
          ...state.tokenRequested,
          status: true,
          error: {},
          loading: false,
        },
      };
    case REQUEST_TOKEN_FAILED:
      return {
        ...state,
        tokenRequested: {
          ...state.tokenRequested,
          status: false,
          error: action.error,
          loading: false,
        },
      };

    case TOKEN_VALIDATION:
      return {
        ...state,
        tokenValidation: {
          ...state.tokenValidation,
          status: false,
          error: {},
          loading: true,
        },
      };
    case TOKEN_VALIDATION_SUCCEEDED:
      return {
        ...state,
        tokenValidation: {
          ...state.tokenValidation,
          status: true,
          error: {},
          loading: false,
        },
      };

    case TOKEN_VALIDATION_FAILED:
      return {
        ...state,
        tokenValidation: {
          ...state.tokenValidation,
          status: false,
          error: action.error,
          loading: false,
        },
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          status: false,
          error: {},
          loading: true,
        },
      };
    case RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          status: true,
          error: null,
          loading: false,
        },
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          status: false,
          error: action.error,
          loading: false,
        },
      };
    case CLEAR_AUTH_DATA:
      return {
        ...state,
        updateProfileStatus: '',
        getProfileStatus: '',
        getEducationStatus: '',
        updateEducationStatus: '',
        updateExperienceStatus: '',
        getExperienceStatus: '',
      };
    case GET_SIGNUP_PARTNER_DETAIL_REQUEST:
      return {
        ...state,
        partner: {
          ...state.partner,
          loading: true,
          error: {},
        },
      };
    case GET_SIGNUP_PARTNER_DETAIL_SUCCEEDED:
      action.data.data
        ? window.localStorage.setItem('myPartner', JSON.stringify(action.data.data))
        : window.localStorage.removeItem('myPartner');

      return {
        ...state,
        partner: {
          ...state.partner,
          loading: false,
          data: action?.data?.data,
          error: {},
        },
      };
    case GET_SIGNUP_PARTNER_DETAIL_FAILED:
      window.localStorage.removeItem('myPartner');
      return {
        ...state,
        partner: {
          ...state.partner,
          data: {},
          loading: false,
          error: action?.error,
        },
      };
    case GET_ADDRESS_FROM_IPADDRESS_REQUEST:
      return {
        ...state,
        address: {
          ...state.address,
          error: {},
          loading: true,
        },
      };
    case GET_ADDRESS_FROM_IPADDRESS_SUCCEEDED:
      action.data.data
        ? window.localStorage.setItem(
            'currency',
            action?.data?.data?.countryCode === 'NP' ? 'NPR' : 'USD'
          )
        : window.localStorage.removeItem('currency');
      return {
        ...state,
        address: {
          ...state.address,
          data: action.data.data,
          error: {},
          loading: false,
        },
        currency: action?.data?.data?.countryCode === 'NP' ? 'NPR' : 'USD',
      };
    case GET_ADDRESS_FROM_IPADDRESS_FAILED:
      return {
        ...state,
        address: {
          ...state.address,
          error: action.error,
          loading: false,
        },
      };

    //signup OTP
    case GET_SIGNUP_OTP:
      return {
        ...state,
        getSignupOtpStatus: 'request',
      };
    case GET_SIGNUP_OTP_SUCCEEDED:
      return {
        ...state,
        getSignupOtpStatus: 'success',
      };
    case GET_SIGNUP_OTP_FAILED:
      return {
        ...state,
        getSignupOtpStatus: 'failure',
      };

    case VERIFY_SIGNUP_OTP:
      return {
        ...state,
        verifySignupOtpStatus: 'request',
      };
    case VERIFY_SIGNUP_OTP_SUCCEEDED:
      return {
        ...state,
        verifySignupOtpStatus: 'success',
      };
    case VERIFY_SIGNUP_OTP_FAILED:
      return {
        ...state,
        verifySignupOtpStatus: 'failure',
      };
    default:
      return state;
  }
};

export default authReducer;
