import {
  GET_SEO_SUCCEEDED,
  GET_SEO_FAILED,
  DELETE_SEO_FAILED,
  DELETE_SEO_SUCCEEDED,
  ADD_SEO_SUCCEEDED,
  ADD_SEO_FAILED,
  EDIT_SEO_SUCCEEDED,
  EDIT_SEO_FAILED,
} from '../../actions/core/seo/types';
import { ISeo } from '../../interfaces/seo';
import { getLocalStore } from 'next-persist';

const initialState: ISeo = {
  seo: {
    data: {},
  },
};
const persistedState = getLocalStore('seo', initialState);

const seo = (state = persistedState, action) => {
  switch (action.type) {
    case GET_SEO_SUCCEEDED:
      return {
        ...state,
        seo: action.data,
      };

    case GET_SEO_FAILED:
      return {
        ...state,
      };
    case ADD_SEO_SUCCEEDED:
      return {
        ...state,
        seo: action.data,
      };

    case ADD_SEO_FAILED:
      return {
        ...state,
      };
    case EDIT_SEO_SUCCEEDED:
      return {
        ...state,
        seo: {
          data: action.data.data,
        },
      };

    case EDIT_SEO_FAILED:
      return {
        ...state,
      };
    case DELETE_SEO_SUCCEEDED:
      return {
        ...state,
        seo: {
          data: {},
        },
      };

    case DELETE_SEO_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default seo;
