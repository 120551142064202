import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/auth/types';
import { deleteData, fetchData, postData, putData } from './genericSagas';

function* authWatcher() {
  yield takeLatest(actions.USER_LOGIN, postData);
  yield takeLatest(actions.USER_REGISTER, postData);
  yield takeLatest(actions.USER_VERIFICATION, postData);
  yield takeLatest(actions.USER_VERIFICATION_REQUEST, postData);
  yield takeLatest(actions.GET_PROFILE, fetchData);
  yield takeLatest(actions.UPDATE_PROFILE, putData);
  yield takeLatest(actions.CHANGE_USER_PASSWORD, putData);
  yield takeLatest(actions.GET_USER_DASHBOARD, fetchData);
  yield takeLatest(actions.NEW_DEVICE_VERIFICATION_REQUEST, postData);
  yield takeLatest(actions.RESEND_VERIFICATION_DEVICE_REQUEST, postData);

  //For unauthorized partner detail
  yield takeLatest(actions.GET_SIGNUP_PARTNER_DETAIL_REQUEST, fetchData);

  // Forgot Password
  yield takeLatest(actions.REQUEST_TOKEN, postData);
  yield takeLatest(actions.TOKEN_VALIDATION, postData);
  yield takeLatest(actions.RESET_PASSWORD, postData);

  // Job Experience
  yield takeLatest(actions.ADD_JOB_EXPERIENCE, postData);
  yield takeLatest(actions.EDIT_JOB_EXPERIENCE, putData);
  yield takeLatest(actions.DELETE_JOB_EXPERIENCE, deleteData);

  // Tutor Education
  yield takeLatest(actions.ADD_EDUCATION, postData);
  yield takeLatest(actions.EDIT_EDUCATION, putData);
  yield takeLatest(actions.DELETE_EDUCATION, deleteData);

  //get Address(country, city, country-code) from ip
  yield takeLatest(actions.GET_ADDRESS_FROM_IPADDRESS_REQUEST, fetchData);

  //signup otp
  yield takeLatest(actions.GET_SIGNUP_OTP, postData);
  yield takeLatest(actions.VERIFY_SIGNUP_OTP, postData);
}

export default authWatcher;
