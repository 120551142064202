import * as actions from '../actions/index';
import { ITutor } from '../interfaces/tutor';
import { getLocalStore } from 'next-persist';

const initialState: ITutor = {
  requestForm: {
    success: false,
    status: '',
  },
  tutorDetail: {
    data: [],
    loading: false,
    status: false,
  },
  termsConditions: {
    data: {},
    loading: false,
  },
  addCourseRequest: {},
};
const persistedState = getLocalStore('tutorReducer', initialState);

const tutorReducer = (state = persistedState, action) => {
  switch (action.type) {
    case actions.ADD_REQUEST_COURSE_FORM:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          success: false,
          status: 'request',
        },
      };
    case actions.ADD_REQUEST_COURSE_FORM_SUCCEEDED:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          success: true,
          status: 'success',
        },
      };
    case actions.ADD_REQUEST_COURSE_FORM_FAILED:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          success: false,
          status: 'failure',
        },
      };
    case actions.GET_TUTOR_DETAIL:
      return {
        ...state,
        tutorDetail: {
          ...state.tutorDetail,
          loading: true,
        },
      };
    case actions.GET_TUTOR_DETAIL_SUCCEEDED:
      return {
        ...state,
        tutorDetail: { ...action.data, status: true },
      };
    case actions.GET_TUTOR_DETAIL_FAILED:
      return {
        ...state,
        tutorDetail: {
          ...state.tutorDetail,
          loading: false,
          status: false,
        },
      };
    case actions.GET_TUTOR_TERMS_CONDITIONS:
      return {
        ...state,
        termsConditions: {
          ...state.termsConditions,
          loading: true,
        },
      };
    case actions.GET_TUTOR_TERMS_CONDITIONS_SUCCEEDED:
      return {
        ...state,
        termsConditions: { ...action.data, loading: false },
      };
    case actions.GET_TUTOR_TERMS_CONDITIONS_FAILED:
      return {
        ...state,
        termsConditions: {
          ...state.termsConditions,
          loading: false,
        },
      };
    case actions.CLEAR_TUTOR_STATE:
      return {
        ...state,
        requestForm: initialState.requestForm,
      };
    case actions.SET_TUTOR_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case actions.SET_TUTOR_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: {
          ...state[action.payload.data.value],
        },
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export default tutorReducer;
