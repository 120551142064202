import {
  GET_CHAPTERS_SUCCEEDED,
  GET_CHAPTERS_FAILED,
  ADD_CHAPTER_SUCCEEDED,
  ADD_CHAPTER_FAILED,
  DELETE_CHAPTER_SUCCEEDED,
  DELETE_CHAPTER_FAILED,
  EDIT_CHAPTER_SUCCEEDED,
  EDIT_CHAPTER_FAILED,
  GET_CHAPTER_BY_SLUG_SUCCEEDED,
  GET_CHAPTER_BY_SLUG_FAILED,
  GET_CHAPTERS,
  ADD_CHAPTER,
  EDIT_CHAPTER,
  SET_CHAPTER_DATA,
} from '../../src/actions';
import { IChapters } from '../interfaces/chapters';
import { getLocalStore } from 'next-persist';

const initialState: IChapters = {
  chapters: {
    data: [],
  },
  chapter: {},
  loading: false,
  chapterBySlugLoading: false,
  addChapterStatus: '',
  editChapterStatus: '',
};
const persistedState = getLocalStore('chapters', initialState);

const chapters = (state = persistedState, action) => {
  switch (action.type) {
    case GET_CHAPTERS:
      return {
        ...state,
        loading: true,
      };
    case GET_CHAPTERS_SUCCEEDED:
      return {
        ...state,
        chapters: action.data,
        loading: false,
      };

    case GET_CHAPTERS_FAILED:
      return {
        ...state,
        laoding: false,
      };
    case GET_CHAPTER_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        chapter: action.data,
        chapterBySlugLoading: true,
      };

    case GET_CHAPTER_BY_SLUG_FAILED:
      return {
        ...state,
        chapterBySlugLoading: false,
      };
    case ADD_CHAPTER:
      return {
        ...state,
        addChapterStatus: 'request',
      }
    case ADD_CHAPTER_SUCCEEDED:
      const myChapterArr = [action.data.data, ...state.chapters.data];
      const sortedChapters = myChapterArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });
      return {
        ...state,
        chapters: {
          ...state.chapters,
          data: sortedChapters,
        },
        addChapterStatus: 'success',
      };

    case ADD_CHAPTER_FAILED:
      return {
        ...state,
        addChapterStatus: 'failure',
      };
    case EDIT_CHAPTER:
      return {
        ...state,
        editChapterStatus: 'request'
      }
    case EDIT_CHAPTER_SUCCEEDED:
      const myChaptersEditArr = state.chapters.data.map((each: any) =>
        each.id == action.data.data.id ? action.data.data : each
      );
      const sortedEditChapters = myChaptersEditArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      })
      return {
        ...state,
        chapters: {
          ...state.chapters,
          data: sortedEditChapters,
        },
        editChapterStatus: 'success'
      };

    case EDIT_CHAPTER_FAILED:
      return {
        ...state,
        editChapterStatus: 'failure'
      };
    case DELETE_CHAPTER_SUCCEEDED:
      return {
        ...state,
        chapters: {
          ...state.chapters,
          data: state.chapters.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_CHAPTER_FAILED:
      return {
        ...state,
      };
    case SET_CHAPTER_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.value
        }
      };

    default:
      return state;
  }
};

export default chapters;
