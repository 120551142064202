export const isLogin = () => {
  try {
    const token: any =
      typeof window !== 'undefined' && window.localStorage.getItem('token');
    if (token && token !== null) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log('Error from login', e);
  }
};
