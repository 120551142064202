import { applyMiddleware, createStore } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './src/sagas';
import rootReducer from './src/reducers';

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export let store;

export const makeStore = (initialStore) => {
  const isClient = typeof window !== 'undefined';

  const sagaMiddleware = createSagaMiddleware();

  if (isClient) {
    // const { persistReducer } = require('redux-persist');
    // const storage = require('redux-persist/lib/storage').default;
    // const persistConfig = {
    //   key: 'root',
    //   storage,
    //   // whitelist: ['auth'],
    // };

    store = createStore(
      rootReducer,
      // persistReducer(persistConfig, rootReducer),
      initialStore,
      bindMiddleware([sagaMiddleware])
    );
  } else {
    store = createStore(rootReducer, initialStore, bindMiddleware([sagaMiddleware]));
  }

  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
};

export const wrapper = createWrapper(makeStore, {
  debug: false,
});
