//blog dashboard
export const GET_BLOGS: any = 'GET_BLOGS';
export const GET_BLOGS_SUCCEEDED: any = 'GET_BLOGS_SUCCEEDED';
export const GET_BLOGS_FAILED: any = 'GET_BLOGS_FAILED';

export const GET_BLOG_BY_SLUG: any = 'GET_BLOG_BY_SLUG';
export const GET_BLOG_BY_SLUG_SUCCEEDED: any = 'GET_BLOG_BY_SLUG_SUCCEEDED';
export const GET_BLOG_BY_SLUG_FAILED: any = 'GET_BLOG_BY_SLUG_FAILED';

export const GET_BLOG_DETAIL_DASHBOARD: any = 'GET_BLOG_DETAIL_DASHBOARD';
export const GET_BLOG_DETAIL_DASHBOARD_SUCCEEDED: any =
  'GET_BLOG_DETAIL_DASHBOARD_SUCCEEDED';
export const GET_BLOG_DETAIL_DASHBOARD_FAILED: any =
  'GET_BLOG_DETAIL_DASHBOARD_FAILED';

export const ADD_BLOG: any = 'ADD_BLOG';
export const ADD_BLOG_SUCCEEDED: any = 'ADD_BLOG_SUCCEEDED';
export const ADD_BLOG_FAILED: any = 'ADD_BLOG_FAILED';

export const EDIT_BLOG: any = 'EDIT_BLOG';
export const EDIT_BLOG_SUCCEEDED: any = 'EDIT_BLOG_SUCCEEDED';
export const EDIT_BLOG_FAILED: any = 'EDIT_BLOG_FAILED';

export const DELETE_BLOG: any = 'DELETE_BLOG';
export const DELETE_BLOG_SUCCEEDED: any = 'DELETE_BLOG_SUCCEEDED';
export const DELETE_BLOG_FAILED: any = 'DELETE_BLOG_FAILED';

//blog category
export const GET_BLOG_CATEGORY: any = 'GET_BLOG_CATEGORY';
export const GET_BLOG_CATEGORY_SUCCEEDED: any = 'GET_BLOG_CATEGORY_SUCCEEDED';
export const GET_BLOG_CATEGORY_FAILED: any = 'GET_BLOG_CATEGORY_FAILED';

export const ADD_BLOG_CATEGORY: any = 'ADD_BLOG_CATEGORY';
export const ADD_BLOG_CATEGORY_SUCCEEDED: any = 'ADD_BLOG_CATEGORY_SUCCEEDED';
export const ADD_BLOG_CATEGORY_FAILED: any = 'ADD_BLOG_CATEGORY_FAILED';

export const EDIT_BLOG_CATEGORY: any = 'EDIT_BLOG_CATEGORY';
export const EDIT_BLOG_CATEGORY_SUCCEEDED: any = 'EDIT_BLOG_CATEGORY_SUCCEEDED';
export const EDIT_BLOG_CATEGORY_FAILED: any = 'EDIT_BLOG_CATEGORY_FAILED';

export const DELETE_BLOG_CATEGORY: any = 'DELETE_BLOG_CATEGORY';
export const DELETE_BLOG_CATEGORY_SUCCEEDED: any = 'DELETE_BLOG_CATEGORY_SUCCEEDED';
export const DELETE_BLOG_CATEGORY_FAILED: any = 'DELETE_BLOG_CATEGORY_FAILED';
