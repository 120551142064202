import { put, call } from 'redux-saga/effects';
import {
  ADD_CONTENT_SUCCEEDED,
  ADD_COURSE_SUCCEEDED,
  DELETE_COURSE_SUCCEEDED,
  DELETE_ROOM_INSTRUCTOR_SUCCEEDED,
  EDIT_CONTENT_SUCCEEDED,
  EDIT_COURSE_SUCCEEDED,
  EDIT_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED,
  RESPOND_TO_INVITATION_REQUEST_SUCCEEDED,
} from '../actions';

import { get, post, del, patch, edit } from '../apiUtils/api';
import Router from 'next/router';
import isDev from '@/helpers/devDetect';

export function* fetchData({ payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put({ type: payload.onSuccess, data });
  } catch (error) {
    if (isDev()) {
      console.log('Error occured', error);
    }
    yield put({ type: payload.onFailure, error });
  }
}

export function* postData({ payload }) {
  try {
    const { data } = yield call(post, payload);
    switch (payload.onSuccess) {
      case RESPOND_TO_INVITATION_REQUEST_SUCCEEDED:
        if (data.roommember.member_status === 2) {
          Router.push('/room');
        }
        break;
      case ADD_COURSE_SUCCEEDED:
        Router.push('/dashboard/courses/course-list');
        break;
      case ADD_CONTENT_SUCCEEDED:
        Router.push(
          `/dashboard/courses/course-list/subjects/chapters/contents/${data?.data?.chapter?.slug}`
        );
        break;
      // case ADD_JOB_EXPERIENCE_SUCCEEDED:
      //   window.location.pathname = '/profile';
      //   break;
    }
    yield put({ type: payload.onSuccess, data });
    // console.log("Success from saga", data);
  } catch (error) {
    // console.log("Fail from saga", error);
    yield put({ type: payload.onFailure, error: error?.response?.data?.error });
  }
}

export function* deleteData({ payload }) {
  try {
    const { data } = yield call(del, payload);
    switch (payload.onSuccess) {
      case DELETE_COURSE_SUCCEEDED:
        Router.push('/dashboard/courses/course-list');
        break;
      case DELETE_ROOM_INSTRUCTOR_SUCCEEDED:
        Router.push('/room');
        break;
    }
    yield put({ type: payload.onSuccess, data });
  } catch (error) {
    yield put({ type: payload.onFailure, error: error?.response?.data?.error });
  }
}

export function* patchData({ payload }) {
  try {
    const { data } = yield call(patch, payload);
    yield put({ type: payload.onSuccess, data });
  } catch (error) {
    yield put({ type: payload.onFailure, error: error?.response?.data?.error });
  }
}

export function* putData({ payload }) {
  try {
    const { data } = yield call(edit, payload);
    switch (payload.onSuccess) {
      case EDIT_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED:
        if (data.data.status === 1) {
          Router.push(`/room/live/${data.data.slug}`);
        }
        break;
      case EDIT_COURSE_SUCCEEDED:
        Router.push(`/dashboard/courses/course-list/${data.data.slug}`);
        break;
      case EDIT_CONTENT_SUCCEEDED:
        Router.push(
          `/dashboard/courses/course-list/subjects/chapters/contents/${data?.data?.chapter?.slug}`
        );
        break;
    }
    yield put({ type: payload.onSuccess, data });
  } catch (error) {
    yield put({ type: payload.onFailure, error: error?.response?.data?.error });
  }
}
