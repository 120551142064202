import { getLocalStore } from 'next-persist';
import { IUserTrack } from 'src/interfaces/userTrack';
import * as actions from '../actions/index';

const initialState: IUserTrack = {
  isLive: false,
  liveUserStat: {},
};

const persistedState = getLocalStore('userTrackReducer', initialState);

const userTrackReducer = (state = persistedState, action) => {
  switch (action.type) {
    case actions.TOGGLE_USER_LIVE:
      return {
        ...state,
        isLive: action.payload,
      };
    case actions.LIVE_USER_COUNT:
      return {
        ...state,
        liveUserStat: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default userTrackReducer;
