import {
  GET_CONTENTS_SUCCEEDED,
  GET_CONTENTS_FAILED,
  DELETE_CONTENT_FAILED,
  DELETE_CONTENT_SUCCEEDED,
  ADD_CONTENT_SUCCEEDED,
  ADD_CONTENT_FAILED,
  EDIT_CONTENT_SUCCEEDED,
  EDIT_CONTENT_FAILED,
  GET_VIDEO_BY_SLUG,
  GET_VIDEO_BY_SLUG_SUCCEEDED,
  GET_VIDEO_BY_SLUG_FAILED,
  ADD_VIDEO,
  ADD_VIDEO_SUCCEEDED,
  ADD_VIDEO_FAILED,
  CLEAR_VIDEO_DATA,
  GET_CONTENTS,
  SET_CONTENT_EDIT_DATA,
  GET_CONTENT_BY_SLUG_SUCCEEDED,
  GET_CONTENT_BY_SLUG_FAILED,
  EDIT_CONTENT,
  SET_CONTENT_DATA,
  ADD_CONTENT,
} from '../../src/actions';
import { IContents } from '../interfaces/contents';
import { getLocalStore } from 'next-persist';

const initialState: IContents = {
  contents: {
    data: [],
  },
  content: {},

  video: {
    status: false,
    upload: false,
    data: {},
  },
  video_by_slug: {
    status: false,
    data: {},
  },
  error: {
    content: '',
    video: '',
    video_by_slug: '',
  },
  loading: false,
  contentBySlugLoading: false,
  editContentStatus: '',
  addContentStatus: '',
};
const persistedState = getLocalStore('contents', initialState);

const contents = (state = persistedState, action) => {
  switch (action.type) {
    case GET_CONTENTS:
      return {
        ...state,
        loading: true,
        contents: {},
      };
    case GET_CONTENTS_SUCCEEDED:
      return {
        ...state,
        contents: action.data,
        loading: false,
      };

    case GET_CONTENTS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case GET_CONTENT_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        content: action.data,
        contentBySlugLoading: true,
      };

    case GET_CONTENT_BY_SLUG_FAILED:
      return {
        ...state,
        contentBySlugLoading: false,
      };
    case ADD_CONTENT:
      return {
        ...state,
        addContentStatus: 'request',
      };
    case ADD_CONTENT_SUCCEEDED:
      return {
        ...state,
        contents: {
          ...state.contents,
          data: [action.data.data, ...state.contents.data],
        },
        video: {
          status: false,
          upload: false,
          data: {},
        },
        addContentStatus: 'success',
      };

    case ADD_CONTENT_FAILED:
      return {
        ...state,
        addContentStatus: 'failed',

        // error: {
        //   ...state.error,
        //   content: action.data.error,
        // },
      };
    case EDIT_CONTENT:
      return {
        ...state,
        editContentStatus: 'request',
      };
    case EDIT_CONTENT_SUCCEEDED:
      return {
        ...state,
        contents: {
          ...state.contents,
          data: state.contents.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        editContentStatus: 'success',
      };

    case EDIT_CONTENT_FAILED:
      return {
        ...state,
        editContentStatus: 'failure',
        // error: {
        //   ...state.error,
        //   content: action.data.error,
        // },
      };
    case DELETE_CONTENT_SUCCEEDED:
      return {
        ...state,
        contents: {
          ...state.contents,
          data: state.contents.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_CONTENT_FAILED:
      return {
        ...state,
      };

    case GET_VIDEO_BY_SLUG:
      return {
        ...state,
        video_by_slug: {
          status: false,
          data: {},
        },
      };

    case GET_VIDEO_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        video_by_slug: {
          status: true,
          data: action.data,
        },
      };

    case GET_VIDEO_BY_SLUG_FAILED:
      return {
        ...state,
        video_by_slug: {
          status: false,
          ...state.video_by_slug,
        },
      };

    case ADD_VIDEO:
      return {
        ...state,
        video: {
          status: false,
          upload: true,
          data: {},
        },
      };

    case ADD_VIDEO_SUCCEEDED:
      return {
        ...state,
        video: {
          status: true,
          upload: false,
          data: action.data.data,
        },
        // contents: {
        //   ...state.contents,
        //   data: [action.data.data, ...state.contents.data],
        // },
      };

    case ADD_VIDEO_FAILED:
      return {
        ...state,
        video: {
          status: false,
          upload: false,
          data: {},
          error: action.error,
        },
      };

    case CLEAR_VIDEO_DATA:
      return {
        ...state,
        video: {
          ...state.video,
          status: false,
          upload: false,
        },
      };
    case SET_CONTENT_EDIT_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };
    case SET_CONTENT_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
};

export default contents;
