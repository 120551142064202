import {
  GET_BLOGS_SUCCEEDED,
  GET_BLOGS_FAILED,
  ADD_BLOG_SUCCEEDED,
  ADD_BLOG_FAILED,
  EDIT_BLOG_SUCCEEDED,
  EDIT_BLOG_FAILED,
  DELETE_BLOG_SUCCEEDED,
  DELETE_BLOG_FAILED,
  GET_BLOG_CATEGORY_SUCCEEDED,
  GET_BLOG_CATEGORY_FAILED,
  ADD_BLOG_CATEGORY_SUCCEEDED,
  ADD_BLOG_CATEGORY_FAILED,
  EDIT_BLOG_CATEGORY_SUCCEEDED,
  EDIT_BLOG_CATEGORY_FAILED,
  DELETE_BLOG_CATEGORY_SUCCEEDED,
  DELETE_BLOG_CATEGORY_FAILED,
  GET_BLOG_BY_SLUG_SUCCEEDED,
  GET_BLOG_BY_SLUG_FAILED,
  GET_BLOG_BY_SLUG,
  ADD_BLOG,
  DELETE_BLOG,
  GET_BLOGS,
} from '@/actions/information/blog/types';

import {
  GET_COMPANY,
  GET_COMPANY_SUCCEEDED,
  GET_COMPANY_FAILED,
  DELETE_COMPANY_FAILED,
  DELETE_COMPANY_SUCCEEDED,
  ADD_COMPANY_SUCCEEDED,
  ADD_COMPANY_FAILED,
  EDIT_COMPANY_SUCCEEDED,
  EDIT_COMPANY_FAILED,
} from '@/actions/information/company/types';

import {
  GET_FAQ,
  GET_FAQ_SUCCEEDED,
  GET_FAQ_FAILED,
  ADD_FAQ,
  ADD_FAQ_SUCCEEDED,
  ADD_FAQ_FAILED,
  EDIT_FAQ,
  EDIT_FAQ_SUCCEEDED,
  EDIT_FAQ_FAILED,
  DELETE_FAQ,
  DELETE_FAQ_SUCCEEDED,
  DELETE_FAQ_FAILED,
} from '@/actions/information/faq/types';

import {
  GET_LANDING_SUCCEEDED,
  GET_LANDING_FAILED,
  ADD_LANDING_SUCCEEDED,
  ADD_LANDING_FAILED,
  EDIT_LANDING_SUCCEEDED,
  EDIT_LANDING_FAILED,
  DELETE_LANDING_SUCCEEDED,
  DELETE_LANDING_FAILED,
  GET_LANDING_SECTION_SUCCEEDED,
  GET_LANDING_SECTION_FAILED,
  ADD_LANDING_SECTION_SUCCEEDED,
  ADD_LANDING_SECTION_FAILED,
  EDIT_LANDING_SECTION_SUCCEEDED,
  EDIT_LANDING_SECTION_FAILED,
  DELETE_LANDING_SECTION_SUCCEEDED,
  DELETE_LANDING_SECTION_FAILED,
} from '@/actions/information/landing/types';

import {
  SET_INFORMATION_DATA,
  SET_INFORMATION_EDIT_DATA,
  SET_INFORMATION_ERROR_DATA,
  CLEAR_INFORMATION_DATA,
} from '@/actions/information/information/types';

import {
  GET_TERMS_CONDITIONS,
  GET_TERMS_CONDITIONS_SUCCEEDED,
  GET_TERMS_CONDITIONS_FAILED,
  DELETE_TERMS_CONDITIONS_FAILED,
  DELETE_TERMS_CONDITIONS_SUCCEEDED,
  ADD_TERMS_CONDITIONS_SUCCEEDED,
  ADD_TERMS_CONDITIONS_FAILED,
  EDIT_TERMS_CONDITIONS_SUCCEEDED,
  EDIT_TERMS_CONDITIONS_FAILED,
} from '@/actions/information/terms-conditions/types';

import {
  GET_TESTIMONIALS_SUCCEEDED,
  GET_TESTIMONIALS_FAILED,
  ADD_TESTIMONIAL_SUCCEEDED,
  ADD_TESTIMONIAL_FAILED,
  EDIT_TESTIMONIAL_SUCCEEDED,
  EDIT_TESTIMONIAL_FAILED,
  DELETE_TESTIMONIAL_SUCCEEDED,
  DELETE_TESTIMONIAL_FAILED,
  ADD_TESTIMONIAL,
  EDIT_TESTIMONIAL,
} from '@/actions/information/testimonial/types';

import { IInformation } from '../interfaces/information';
import { getLocalStore } from 'next-persist';

const initialState: IInformation = {
  company: [],
  testimonials: {
    data: [],
  },
  landings: {
    data: [],
  },
  landingSections: {
    data: [],
  },
  blogs: {
    data: [],
  },
  blog: {},
  blogCategory: {
    data: [],
  },
  helperObj: {
    addCompany: false,
    editCompany: false,
    addCompanyStatus: '',
    addBlog: false,
    editBlog: false,
    addTermsConditions: false,
    editTermsConditions: false,
    addBlogStatus: '',
    deleteBlogStatus: '',
  },
  error: {
    addCompany: {},
    editCompany: {},
    addBlog: {},
    editBlog: {},
    addTermsConditions: {},
    editTermsConditions: {},
    testimonial: {},
  },
  loading: {
    getCompany: false,
    addTermsConditionsStatus: '',
    getTermsConditionsStatus: '',
    editTermsConditionsStatus: '',
    deleteTermsConditionsStatus: '',
    blogBySlugLoading: false,
    getAllBlogsLoading: false,
    addTestimonialLoading: false,
    editTestimonialLoading: false,
  },
  addCompany: {},
  addBlog: {},
  editBlog: {},
  addTermsConditions: {},
  faq: {
    data: [],
    loading: false,
    status: false,
  },
  termsConditions: [],
};
const persistedState = getLocalStore('information', initialState);

const information = (state = persistedState as unknown as IInformation, action) => {
  switch (action.type) {
    //company
    case GET_COMPANY:
      return {
        ...state,
        loading: { ...state.loading, getCompany: true },
      };
    case GET_COMPANY_SUCCEEDED:
      return {
        ...state,
        company: action.data.data,
        loading: { ...state.loading, getCompany: false },
      };

    case GET_COMPANY_FAILED:
      return {
        ...state,
        loading: { ...state.loading, getCompany: false },
      };

    case ADD_COMPANY_SUCCEEDED:
      return {
        ...state,
        company: [action.data.data, ...state.company],
        error: { ...state.error, addCompany: {} },
        helperObj: {
          ...state.helperObj,
          addCompany: false,
          addCompanyStatus: 'success',
        },
        addCompany: {},
      };

    case ADD_COMPANY_FAILED:
      return {
        ...state,
        error: { ...state.error, addCompany: action.error },
      };

    case EDIT_COMPANY_SUCCEEDED:
      return {
        ...state,
        company: state.company.map((each: any) =>
          each.id == action.data.data.id ? action.data.data : each
        ),
        helperObj: { ...state.helperObj, editCompany: false },
        error: { ...state.error, editCompany: {} },
        addCompany: {},
        editCompany: {},
      };

    case EDIT_COMPANY_FAILED:
      return {
        ...state,
        error: { ...state.error, editCompany: action.error },
      };

    case DELETE_COMPANY_SUCCEEDED:
      return {
        ...state,
        company: state.company.filter(
          (each: any) => each.id !== action.data.data.id
        ),
      };

    case DELETE_COMPANY_FAILED:
      return {
        ...state,
      };

    //testimonials
    case GET_TESTIMONIALS_SUCCEEDED:
      console.log(state);
      return {
        ...state,
        testimonials: action.data,
        error: {
          ...state.error,
          testimonial: {},
        },
      };

    case GET_TESTIMONIALS_FAILED:
      return {
        ...state,
        error: {
          ...state.error,
          testimonial: action.error.message[0] || 'Failed to add testimonial',
        },
      };

    case ADD_TESTIMONIAL:
      return {
        ...state,
        loading: {
          ...state.loading,
          addTestimonialLoading: true,
        },
        error: {
          ...state.error,
          testimonial: {},
        },
      };

    case ADD_TESTIMONIAL_SUCCEEDED:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          data: [action.data.data, ...state.testimonials.data],
        },
        loading: {
          ...state.loading,
          addTestimonialLoading: false,
        },
        error: {
          ...state.error,
          testimonial: {},
        },
      };

    case ADD_TESTIMONIAL_FAILED:
      return {
        ...state,
        error: {
          ...state.error,
          testimonial: action.error,
        },
        loading: {
          ...state.loading,
          addTestimonialLoading: false,
        },
      };

    case EDIT_TESTIMONIAL:
      return {
        ...state,
        loading: {
          ...state.loading,
          editTestimonialLoading: true,
        },
        error: {
          testimonial: {},
        },
      };

    case EDIT_TESTIMONIAL_SUCCEEDED:
      return {
        ...state,
        loading: {
          ...state.loading,
          editTestimonialLoading: false,
        },
        error: {
          ...state.error,
          testimonial: {},
        },
        testimonials: {
          ...state.testimonials,
          data: state.testimonials.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_TESTIMONIAL_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          editTestimonialLoading: false,
        },
        error: {
          ...state.error,
          testimonial: action.error,
        },
      };

    case DELETE_TESTIMONIAL_SUCCEEDED:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          data: state.testimonials.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_TESTIMONIAL_FAILED:
      return {
        ...state,
      };

    //landings
    case GET_LANDING_SUCCEEDED:
      return {
        ...state,
        landings: action.data,
      };

    case GET_LANDING_FAILED:
      return {
        ...state,
      };

    case ADD_LANDING_SUCCEEDED:
      return {
        ...state,
        landings: {
          ...state.landings,
          data: [action.data.data, ...state.landings.data],
        },
      };

    case ADD_LANDING_FAILED:
      return {
        ...state,
      };

    case EDIT_LANDING_SUCCEEDED:
      return {
        ...state,
        landings: {
          ...state.landings,
          data: state.landings.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_LANDING_FAILED:
      return {
        ...state,
      };

    case DELETE_LANDING_SUCCEEDED:
      return {
        ...state,
        landings: {
          ...state.landings,
          data: state.landings.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_LANDING_FAILED:
      return {
        ...state,
      };

    //landing sections
    case GET_LANDING_SECTION_SUCCEEDED:
      return {
        ...state,
        landingSections: action.data,
      };

    case GET_LANDING_SECTION_FAILED:
      return {
        ...state,
      };

    case ADD_LANDING_SECTION_SUCCEEDED:
      return {
        ...state,
        landingSections: {
          ...state.landingSections,
          data: [action.data.data, ...state.landingSections.data],
        },
      };

    case ADD_LANDING_SECTION_FAILED:
      return {
        ...state,
      };

    case EDIT_LANDING_SECTION_SUCCEEDED:
      return {
        ...state,
        landingSections: {
          ...state.landingSections,
          data: state.landingSections.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_LANDING_SECTION_FAILED:
      return {
        ...state,
      };

    case DELETE_LANDING_SECTION_SUCCEEDED:
      return {
        ...state,
        landingSections: {
          ...state.landingSections,
          data: state.landingSections.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_LANDING_SECTION_FAILED:
      return {
        ...state,
      };

    //blogs

    case GET_BLOGS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getAllBlogsLoading: true,
        },
      };

    case GET_BLOGS_SUCCEEDED:
      return {
        ...state,
        blogs: action.data,
        loading: {
          ...state.loading,
          getAllBlogsLoading: false,
        },
      };

    case GET_BLOGS_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          getAllBlogsLoading: false,
        },
      };

    case GET_BLOG_BY_SLUG:
      return {
        ...state,
        loading: {
          ...state.loading,
          blogBySlugLoading: true,
        },
      };
    case GET_BLOG_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        blog: action.data,
        loading: {
          ...state.loading,
          blogBySlugLoading: false,
        },
      };
    case GET_BLOG_BY_SLUG_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          blogBySlugLoading: false,
        },
      };
    case ADD_BLOG:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          addBlogStatus: 'request',
        },
      };
    case ADD_BLOG_SUCCEEDED:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          data: [action.data.data, ...state.blogs.data],
        },
        error: { ...state.error, addBlog: {} },
        helperObj: {
          ...state.helperObj,
          addBlog: false,
          addBlogStatus: 'success',
        },
        addBlog: {},
      };

    case ADD_BLOG_FAILED:
      return {
        ...state,
        error: { ...state.error, addBlog: action.error },
        helperObj: {
          ...state.helperObj,
          addBlogStatus: 'failure',
        },
      };

    case EDIT_BLOG_SUCCEEDED:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          data: state.blogs.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        error: { ...state.error, editBlog: {} },
        helperObj: {
          ...state.helperObj,
          editBlog: false,
          editBlogStatus: 'success',
        },
        addBlog: {},
        editBlog: {},
      };

    case EDIT_BLOG_FAILED:
      return {
        ...state,
      };
    case DELETE_BLOG:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          deleteBlogStatus: 'request',
        },
      };
    case DELETE_BLOG_SUCCEEDED:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          data: state.blogs.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        helperObj: {
          ...state.helperObj,
          deleteBlogStatus: 'success',
        },
      };

    case DELETE_BLOG_FAILED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          deleteBlogStatus: 'failure',
        },
      };

    //blog category
    case GET_BLOG_CATEGORY_SUCCEEDED:
      return {
        ...state,
        blogCategory: action.data,
      };

    case GET_BLOG_CATEGORY_FAILED:
      return {
        ...state,
      };

    case ADD_BLOG_CATEGORY_SUCCEEDED:
      return {
        ...state,
        blogCategory: {
          ...state.blogCategory,
          data: [action.data.data, ...state.blogCategory.data],
        },
      };

    case ADD_BLOG_CATEGORY_FAILED:
      return {
        ...state,
      };

    case EDIT_BLOG_CATEGORY_SUCCEEDED:
      return {
        ...state,
        blogCategory: {
          ...state.blogCategory,
          data: state.blogCategory.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_BLOG_CATEGORY_FAILED:
      return {
        ...state,
      };

    case DELETE_BLOG_CATEGORY_SUCCEEDED:
      return {
        ...state,
        blogCategory: {
          ...state.blogCategory,
          data: state.blogCategory.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_BLOG_CATEGORY_FAILED:
      return {
        ...state,
      };

    case SET_INFORMATION_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_INFORMATION_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
        //  {
        //   ...state[action.payload.data.value],
        // },
      };
    case SET_INFORMATION_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    // Get all FAQ Permission admin
    case GET_FAQ:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: true,
        },
      };
    case GET_FAQ_SUCCEEDED:
      return {
        ...state,
        faq: { ...action.data, loading: false },
      };
    case GET_FAQ_FAILED:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: false,
        },
      };
    case ADD_FAQ:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: true,
          status: false,
        },
      };
    case ADD_FAQ_SUCCEEDED:
      return {
        ...state,
        faq: {
          ...state.faq,
          data: [action.data.data, ...state.faq.data],
          loading: false,
          status: true,
        },
      };
    case ADD_FAQ_FAILED:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: false,
          status: false,
        },
      };
    case EDIT_FAQ:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: true,
        },
      };
    case EDIT_FAQ_SUCCEEDED:
      const oldFaq = state.faq.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        faq: {
          ...state.faq,
          data: [action.data.data, ...oldFaq],
          loading: false,
          updateStatus: true,
        },
      };
    case EDIT_FAQ_FAILED:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: false,
          updateStatus: false,
        },
      };
    case DELETE_FAQ:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: true,
        },
      };
    case DELETE_FAQ_SUCCEEDED:
      return {
        ...state,
        faq: {
          ...state.faq,
          data: state.faq.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
          loading: false,
        },
      };
    case DELETE_FAQ_FAILED:
      return {
        ...state,
        loading: false,
      };

    //terms and conditions
    case GET_TERMS_CONDITIONS:
      return {
        ...state,
        loading: { ...state.loading, getTermsConditionsStatus: 'request' },
      };
    case GET_TERMS_CONDITIONS_SUCCEEDED:
      return {
        ...state,
        termsConditions: action.data.data,
        loading: { ...state.loading, getTermsConditionsStatus: 'success' },
      };

    case GET_TERMS_CONDITIONS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, getTermsConditionsStatus: 'failure' },
      };

    case ADD_TERMS_CONDITIONS_SUCCEEDED:
      return {
        ...state,
        termsConditions: [action.data.data, ...state.termsConditions],
        error: { ...state.error, addTermsConditions: {} },
        helperObj: {
          ...state.helperObj,
          addTermsConditions: false,
        },
        loading: {
          ...state.loading,
          addTermsConditionsStatus: 'success',
        },
      };

    case ADD_TERMS_CONDITIONS_FAILED:
      return {
        ...state,
        error: { ...state.error, addTermsConditions: action.error },
        loading: {
          ...state.loading,
          addTermsConditionsStatus: 'failure',
        },
      };

    case EDIT_TERMS_CONDITIONS_SUCCEEDED:
      return {
        ...state,
        termsConditions: state.termsConditions.map((each: any) =>
          each.id == action.data.data.id ? action.data.data : each
        ),
        helperObj: { ...state.helperObj, editTermsConditions: false },
        error: { ...state.error, editTermsConditions: {} },
        loading: {
          ...state.loading,
          editTermsConditionsStatus: 'success',
        },
      };

    case EDIT_TERMS_CONDITIONS_FAILED:
      return {
        ...state,
        error: { ...state.error, editTermsConditions: action.error },
        loading: {
          ...state.loading,
          editTermsConditionsStatus: 'failure',
        },
      };

    case DELETE_TERMS_CONDITIONS_SUCCEEDED:
      return {
        ...state,
        termsConditions: state.termsConditions.filter(
          (each: any) => each.id !== action.data.data.id
        ),
        helperObj: { ...state.helperObj, deleteTermsConditions: false },
        loading: {
          ...state.loading,
          deleteTermsConditionsStatus: 'success',
        },
      };

    case DELETE_TERMS_CONDITIONS_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteTermsConditionsStatus: 'failure',
        },
      };

    case CLEAR_INFORMATION_DATA:
      return {
        ...state,
        helperObj: {
          addCompany: false,
          editCompany: false,
          addCompanyStatus: '',
          addBlog: false,
          editBlog: false,
          addTermsConditions: false,
          editTermsConditions: false,
          addBlogStatus: '',
        },
        error: {
          addCompany: {},
          editCompany: {},
          addBlog: {},
          editBlog: {},
          addTermsConditions: {},
          editTermsConditions: {},
        },
        loading: {
          getCompany: false,
          addTermsConditionsStatus: '',
          getTermsConditionsStatus: '',
          editTermsConditionsStatus: '',
          deleteTermsConditionsStatus: '',
        },
      };
    default:
      return state;
  }
};

export default information;
