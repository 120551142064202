import {
  ADD_VIDEO_DATA,
  CLEAR_HELPER_STATE,
  GET_WEBSITE_INFO,
  GET_WEBSITE_INFO_FAILED,
  GET_WEBSITE_INFO_SUCCEEDED,
  MODIFY_HELPER_REDUCER,
  QUIZ_ENDED,
  SET_CURRENT_TIME,
  SET_HELPER_EDIT_DATA,
  SET_TIME_FOR_VIDEO_TO_PLAY,
  SET_VIDEO_DATA,
  TOGGLE_BOTTOM_PALETTE,
  TOGGLE_VIDEO_PAUSE,
  VIDEO_ENDED,
} from '../actions/index';
import { IHelper } from '../interfaces/helperAction';
import { getLocalStore } from 'next-persist';

const initialState: IHelper = {
  paletteUp: false,
  time: null,
  videoPause: false,
  videoEnd: null,
  videoTime: null,
  quizEnd: null,
  websiteInfo: {
    data: {},
    getWebsiteInfoStatus: '',
  },
};
const persistedState = getLocalStore('helperReducer', initialState);

const helperReducer = (state = persistedState, action) => {
  switch (action.type) {
    case TOGGLE_BOTTOM_PALETTE:
      // console.log(state.paletteUp)
      return {
        ...state,
        paletteUp: !state.paletteUp,
      };
    case SET_CURRENT_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case TOGGLE_VIDEO_PAUSE:
      // console.log(state.videoPause)
      return {
        ...state,
        videoPause: !state.videoPause,
      };
    case VIDEO_ENDED:
      return {
        ...state,
        videoEnd: action.payload,
      };
    case QUIZ_ENDED:
      return {
        ...state,
        quizEnd: action.payload,
      };
    case SET_TIME_FOR_VIDEO_TO_PLAY:
      return {
        ...state,
        videoTime: action.payload,
      };
    case SET_VIDEO_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_HELPER_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
      };
    case MODIFY_HELPER_REDUCER:
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.value,
        },
      };
    case GET_WEBSITE_INFO:
      return {
        ...state,
        websiteInfo: {
          ...state.websiteInfo,
          getWebsiteInfoStatus: 'request',
        },
      };
    case GET_WEBSITE_INFO_SUCCEEDED:
      return {
        ...state,
        websiteInfo: {
          ...state.websiteInfo,
          getWebsiteInfoStatus: 'success',
          data: action.data.data,
        },
      };
    case GET_WEBSITE_INFO_FAILED:
      return {
        ...state,
        websiteInfo: {
          ...state.websiteInfo,
          getWebsiteInfoStatus: 'failure',
        },
      };
    case CLEAR_HELPER_STATE:
      return {
        ...state,
        websiteInfo: persistedState.websiteInfo,
      };
    // case ADD_VIDEO_DATA:
    //     return {
    //         ...state,
    //         [action.payload.data.key]: action.payload.data.value
    //     }
    default:
      return state;
  }
};

export default helperReducer;
