import isDev from '@/helpers/devDetect';
import { notification } from 'antd';
import axios from 'axios';

export const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.response.use(
  (response) => {
    if (typeof window !== 'undefined')
      if (response?.data?.access) {
        window.localStorage.setItem('token', 'Token ' + response?.data?.access);
      }
    return response;
  },
  (error) => {
    const { response } = error;
    if (typeof window !== 'undefined')
      if (response?.status === 401) {
        window.localStorage.removeItem('token');
        window.location.pathname = '/login';
      }
    if (response?.status === 403) {
      window.location.pathname = '/';
    }
    if (response?.status === 403) {
      window.location.pathname = '/';
    }
    if (isDev() && response?.status === 403) {
      // Only for dev and test server
      notification.error({ message: 'User does not have permission.' });
    }
    if (response?.status === 404) {
      window.location.pathname = '/error';
    }
    return Promise.reject(error);
  }
);

http.interceptors.request.use((config) => {
  if (typeof window !== 'undefined')
    if (!!window.localStorage.getItem('token'))
      config.headers.Authorization = localStorage.getItem('token');
  return config;
});

export const loginUser = (payload) => http.post('/login/', payload);

export const del = ({ url }) => http.delete(url);

export const get = ({ url }) => http.get(url);

export const edit = ({ url, data }) => http.put(url, data);

export const post = ({ url, data }) => http.post(url, data);

export const patch = ({ url, data }) => http.patch(url, data);

const sicHttp = axios.create({
  // baseURL: process.env.NEXT_PUBLIC_SIC_URL,
  baseURL: process.env.NEXT_PUBLIC_SIC_TEST_URL,
  timeout: 100000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const getSic = ({ url }) => sicHttp.get(url);
