import { combineReducers } from 'redux';

import auth from './authReducers';
import courses from './courses';
import tests from './tests';
import subjects from './subjects';
import chapters from './chapters';
import home from './home';
import contents from './contents';
import information from './information';
import quizReducer from './quizReducer';
import modelTests from './model_tests/model_tests';
import modelTestSections from './model_tests/sections';
import questions from './model_tests/questions';
import answers from './model_tests/answers';
import groupAnswers from './model_tests/group_answers';
import helperReducer from './helperReducer';
import doubtReducer from './doubts';

import tutorReducer from './tutor';
import userReducer from './admin/user/user';

import quiz from './quiz';
import contactReducer from './admin/contact/contact';
import advertisementReducer from './advertisement';
import notificationReducer from './notifications';
import dashboard from './dashboard';
import profile from './profile';
import referReducer from './refer';
import seo from './core/seo';
import office from './core/office';
import socialmedia from './core/socialmedia';
import role from './core/role';
import roomReducer from './room';
import classReducer from './class';
import MockTestReducer from './mock-test';
import live from './live';
import dropdown from './dropdown';
import payment from './payment';
import finance from './finance';
import email from './core/email';
import notes from './notes';
import userTrackReducer from './userTrack';
import partner from './partner';
import segments from './segments';
import physicalClass from './physical-class';
import chat from './chat';
import ledger from './ledger';
import task from './task';
import allAssessmentReducer from './assessmentReducer';
import college from './college';

export default combineReducers({
  auth,
  courses,
  tests,
  subjects,
  chapters,
  home,
  allAssessmentReducer,
  quizReducer,
  contents,
  information,
  modelTests,
  modelTestSections,
  questions,
  answers,
  groupAnswers,
  helperReducer,
  doubtReducer,
  tutorReducer,
  userReducer,
  quiz,
  contactReducer,
  advertisementReducer,
  notificationReducer,
  dashboard,
  profile,
  referReducer,
  seo,
  office,
  socialmedia,
  role,
  MockTestReducer,
  roomReducer,
  classReducer,
  live,
  dropdown,
  payment,
  finance,
  email,
  notes,
  userTrackReducer,
  partner,
  segments,
  physicalClass,
  chat,
  ledger,
  task,
  college,
});
