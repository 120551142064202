//testimonial
export const GET_TESTIMONIALS: any = 'GET_TESTIMONIALS';
export const GET_TESTIMONIALS_SUCCEEDED: any = 'GET_TESTIMONIALS_SUCCEEDED';
export const GET_TESTIMONIALS_FAILED: any = 'GET_TESTIMONIALS_FAILED';

export const ADD_TESTIMONIAL: any = 'ADD_TESTIMONIAL';
export const ADD_TESTIMONIAL_SUCCEEDED: any = 'ADD_TESTIMONIAL_SUCCEEDED';
export const ADD_TESTIMONIAL_FAILED: any = 'ADD_TESTIMONIAL_FAILED';

export const EDIT_TESTIMONIAL: any = 'EDIT_TESTIMONIAL';
export const EDIT_TESTIMONIAL_SUCCEEDED: any = 'EDIT_TESTIMONIAL_SUCCEEDED';
export const EDIT_TESTIMONIAL_FAILED: any = 'EDIT_TESTIMONIAL_FAILED';

export const DELETE_TESTIMONIAL: any = 'DELETE_TESTIMONIAL';
export const DELETE_TESTIMONIAL_SUCCEEDED: any = 'DELETE_TESTIMONIAL_SUCCEEDED';
export const DELETE_TESTIMONIAL_FAILED: any = 'DELETE_TESTIMONIAL_FAILED';
