import {
  CLEAR_EMAIL_DATA,
  SEND_COURSE_CLIENT_EMAIL,
  SEND_COURSE_CLIENT_EMAIL_FAILED,
  SEND_COURSE_CLIENT_EMAIL_SUCCEEDED,
  SET_EMAIL_DATA,
  SET_EMAIL_EDIT_DATA,
} from '../../../src/actions/core/email/types';
import { getLocalStore } from 'next-persist';
import { IEmail } from 'src/interfaces/core/email';

const initialState: IEmail = {
  email: {},
  helperObj: {
    sendEmail: false,
    sendStatus: '',
  },
  error: {},
};
const persistedState = getLocalStore('email', initialState);

const email = (state = persistedState, action) => {
  switch (action.type) {
    case SET_EMAIL_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.value,
        },
      };
    case SET_EMAIL_EDIT_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };
    case CLEAR_EMAIL_DATA:
      return {
        ...state,
        email: {},
        helperObj: {
          sendEmail: false,
          sendStatus: '',
        },
        error: {},
      };
    case SEND_COURSE_CLIENT_EMAIL:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          sendStatus: 'request',
        },
      };
    case SEND_COURSE_CLIENT_EMAIL_SUCCEEDED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          sendStatus: 'success',
        },
        error: {},
      };
    case SEND_COURSE_CLIENT_EMAIL_FAILED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          sendStatus: 'failure',
        },
        error: action.error,
      };
    default:
      return state;
  }
};

export default email;
