import {
  GET_NOTES_COURSE_REQUEST,
  GET_NOTES_COURSE_SUCCEEDED,
  GET_NOTES_COURSE_FAILED,
  ADD_NOTES_COURSE_REQUEST,
  ADD_NOTES_COURSE_SUCCEEDED,
  ADD_NOTES_COURSE_FAILED,
  EDIT_NOTES_COURSE_REQUEST,
  EDIT_NOTES_COURSE_SUCCEEDED,
  EDIT_NOTES_COURSE_FAILED,
  DELETE_NOTES_COURSE_REQUEST,
  DELETE_NOTES_COURSE_SUCCEEDED,
  DELETE_NOTES_COURSE_FAILED,
  SET_NOTES_DATA,
  SET_NOTES_EDIT_DATA,
  SET_NOTES_ERROR_DATA,
  GET_NOTES_SUBJECT_REQUEST,
  GET_NOTES_SUBJECT_SUCCEEDED,
  GET_NOTES_SUBJECT_FAILED,
  ADD_NOTES_SUBJECT_REQUEST,
  ADD_NOTES_SUBJECT_SUCCEEDED,
  ADD_NOTES_SUBJECT_FAILED,
  EDIT_NOTES_SUBJECT_REQUEST,
  EDIT_NOTES_SUBJECT_SUCCEEDED,
  EDIT_NOTES_SUBJECT_FAILED,
  DELETE_NOTES_SUBJECT_REQUEST,
  DELETE_NOTES_SUBJECT_SUCCEEDED,
  DELETE_NOTES_SUBJECT_FAILED,
  GET_NOTES_COURSE_BY_SLUG_REQUEST,
  GET_NOTES_COURSE_BY_SLUG_SUCCEEDED,
  GET_NOTES_COURSE_BY_SLUG_FAILED,
  GET_NOTES_SUBJECT_BY_SLUG_REQUEST,
  GET_NOTES_SUBJECT_BY_SLUG_SUCCEEDED,
  GET_NOTES_SUBJECT_BY_SLUG_FAILED,
  GET_NOTES_CHAPTER_REQUEST,
  GET_NOTES_CHAPTER_SUCCEEDED,
  GET_NOTES_CHAPTER_FAILED,
  ADD_NOTES_CHAPTER_REQUEST,
  ADD_NOTES_CHAPTER_SUCCEEDED,
  ADD_NOTES_CHAPTER_FAILED,
  EDIT_NOTES_CHAPTER_REQUEST,
  EDIT_NOTES_CHAPTER_SUCCEEDED,
  EDIT_NOTES_CHAPTER_FAILED,
  DELETE_NOTES_CHAPTER_REQUEST,
  DELETE_NOTES_CHAPTER_SUCCEEDED,
  DELETE_NOTES_CHAPTER_FAILED,
  GET_NOTES_CHAPTER_BY_SLUG_REQUEST,
  GET_NOTES_CHAPTER_BY_SLUG_SUCCEEDED,
  GET_NOTES_CHAPTER_BY_SLUG_FAILED,
  GET_NOTES_CONTENT_REQUEST,
  GET_NOTES_CONTENT_SUCCEEDED,
  GET_NOTES_CONTENT_FAILED,
  GET_NOTES_CONTENT_BY_SLUG_REQUEST,
  GET_NOTES_CONTENT_BY_SLUG_SUCCEEDED,
  GET_NOTES_CONTENT_BY_SLUG_FAILED,
  ADD_NOTES_CONTENT_REQUEST,
  ADD_NOTES_CONTENT_SUCCEEDED,
  ADD_NOTES_CONTENT_FAILED,
  EDIT_NOTES_CONTENT_REQUEST,
  EDIT_NOTES_CONTENT_FAILED,
  DELETE_NOTES_CONTENT_REQUEST,
  DELETE_NOTES_CONTENT_SUCCEEDED,
  DELETE_NOTES_CONTENT_FAILED,
  CLEAR_NOTES_ERROR,
  EDIT_NOTES_CONTENT_SUCCEEDED,
  GET_LANDING_NOTES_COURSE_REQUEST,
  GET_LANDING_NOTES_COURSE_SUCCEEDED,
  GET_LANDING_NOTES_COURSE_FAILED,
  GET_LANDING_NOTES_COURSE_DETAIL_REQUEST,
  GET_LANDING_NOTES_COURSE_DETAIL_SUCCEEDED,
  GET_LANDING_NOTES_COURSE_DETAIL_FAILED,
  GET_LANDING_NOTES_SUBJECT_DETAIL_REQUEST,
  GET_LANDING_NOTES_SUBJECT_DETAIL_SUCCEEDED,
  GET_LANDING_NOTES_SUBJECT_DETAIL_FAILED,
  GET_LANDING_NOTES_COURSE_BY_PAGE,
  GET_LANDING_NOTES_COURSE_BY_PAGE_SUCCEEDED,
  GET_LANDING_NOTES_COURSE_BY_PAGE_FAILED,
  GET_NOTES_CATEGORY_REQUEST,
  GET_NOTES_CATEGORY_SUCCEEDED,
  GET_NOTES_CATEGORY_FAILED,
  ADD_NOTES_CATEGORY_REQUEST,
  ADD_NOTES_CATEGORY_SUCCEEDED,
  ADD_NOTES_CATEGORY_FAILED,
  EDIT_NOTES_CATEGORY_REQUEST,
  EDIT_NOTES_CATEGORY_SUCCEEDED,
  EDIT_NOTES_CATEGORY_FAILED,
  DELETE_NOTES_CATEGORY_REQUEST,
  DELETE_NOTES_CATEGORY_SUCCEEDED,
  DELETE_NOTES_CATEGORY_FAILED,
  SEARCH_VIDEO,
  SEARCH_VIDEO_SUCCEEDED,
  SEARCH_VIDEO_FAILED,
  CLEAR_NOTES_COURSE_QUERY,
} from '../../src/actions';
import { getLocalStore } from 'next-persist';
import { INotes } from 'src/interfaces/notes';

const initialState: INotes = {
  noteCategories: {},
  noteCourses: {},
  noteSubjects: {},
  noteChapters: {},
  noteContents: {},
  noteCourse: {},
  noteSubject: {},
  noteChapter: {},
  noteContent: {},
  noteContentBySlug: {},
  landingNoteCourses: {
    data: [],
  },
  landingNoteCourseDetail: {},
  landingNoteSubjectDetail: {},
  noteVideos: {},
  loader: {
    noteCategoriesLoading: false,
    noteCoursesLoading: false,
    noteCourseBySlugLoading: false,
    noteSubjectsLoading: false,
    noteSubjectBySlugLoading: false,
    noteChaptersLoading: false,
    noteChapterBySlugLoading: false,
    noteContentsLoading: false,
    noteContentBySlugLoading: false,
    landingNoteCourseLoading: false,
    landingNoteCourseDetailLoading: false,
    landingNoteSubjectDetailLoading: false,
    getMoreLandingCourseStatus: '',
    searchVideo: false,
    searchMoreVideosStatus: '',
  },
  error: {
    addNotesCategory: {},
    addNotesCourse: {},
    editNotesCourse: {},
    addNotesSubject: {},
    editNotesSubject: {},
    addNotesChapter: {},
    editNotesChapter: {},
    addNotesContent: {},
    editNotesContent: {},
  },
  helperObj: {
    addNotesCategory: false,
    addNotesCourse: false,
    editNotesCourse: false,
    addNotesSubject: false,
    editNotesSubject: false,
    addNotesChapter: false,
    editNotesChapter: false,
  },
  status: {
    addNotesCourseStatus: '',
    editNotesCourseStatus: '',
    deleteNotesCourseStatus: '',
    addNotesSubjectStatus: '',
    editNotesSubjectStatus: '',
    deleteNotesSubjectStatus: '',
    addNotesChapterStatus: '',
    editNotesChapterStatus: '',
    deleteNotesChapterStatus: '',
    addNotesContentStatus: '',
    editNotesContentStatus: '',
    deleteNotesContentStatus: '',
    addNotesCategoryStatus: '',
    editNotesCategoryStatus: '',
    deleteNotesCategoryStatus: '',
  },
  query: {
    noteCoursesLanding: {
      page: 1,
      per_page: 10,
    },
    searchVideos: {
      page: 1,
      per_page: 5,
    },
  },
};
const persistedState = getLocalStore('notes', initialState);

const notes = (state = persistedState, action) => {
  switch (action.type) {
    //<<<<<<<START-NOTES-COURSE>>>>>>>>>
    case GET_NOTES_COURSE_REQUEST:
      return {
        ...state,
        loader: {
          noteCoursesLoading: true,
        },
      };
    case GET_NOTES_COURSE_SUCCEEDED:
      return {
        ...state,
        noteCourses: action.data,
        loader: {
          noteCoursesLoading: false,
        },
      };
    case GET_NOTES_COURSE_FAILED:
      return {
        ...state,
        loader: {
          noteCoursesLoading: false,
        },
      };
    case GET_NOTES_COURSE_BY_SLUG_REQUEST:
      return {
        ...state,
        noteCourseBySlugLoading: true,
      };
    case GET_NOTES_COURSE_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        noteCourse: action.data,
        noteCourseBySlugLoading: false,
      };
    case GET_NOTES_COURSE_BY_SLUG_FAILED:
      return {
        ...state,
        noteCourseBySlugLoading: false,
      };
    case ADD_NOTES_COURSE_REQUEST:
      return {
        ...state,
        status: { ...state.status, addNotesCourseStatus: 'request' },
      };
    case ADD_NOTES_COURSE_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, addNotesCourseStatus: 'success' },
        noteCourses: {
          ...state.noteCourses,
          data: [action.data.data, ...state.noteCourses.data],
        },
        helperObj: {
          ...state.helperObj,
          addNotesCourse: false,
        },
        error: { ...state.error, addNotesCourse: {} },
      };

    case ADD_NOTES_COURSE_FAILED:
      return {
        ...state,
        status: { ...state.status, addNotesCourseStatus: 'failure' },
        error: { ...state.error, addNotesCourse: action.error },
      };
    case EDIT_NOTES_COURSE_REQUEST:
      return {
        ...state,
        status: { ...state.status, editNotesCourseStatus: 'request' },
      };
    case EDIT_NOTES_COURSE_SUCCEEDED:
      return {
        ...state,
        noteCourses: {
          ...state.noteCourses,
          data: state.noteCourses.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          editNotesCourse: false,
        },
        status: { ...state.status, editNotesCourseStatus: 'success' },
        error: { ...state.error, editNotesCourse: {} },
      };

    case EDIT_NOTES_COURSE_FAILED:
      return {
        ...state,
        error: { ...state.error, editNotesCourse: action.error },
        status: { ...state.status, editNotesCourseStatus: 'failure' },
      };
    case DELETE_NOTES_COURSE_REQUEST:
      return {
        ...state,
        status: { ...state.status, deleteNotesCourseStatus: 'request' },
      };
    case DELETE_NOTES_COURSE_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, deleteNotesCourseStatus: 'success' },
        noteCourses: {
          ...state.noteCourses,
          data: state.noteCourses.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_NOTES_COURSE_FAILED:
      return {
        ...state,
        status: { ...state.status, deleteNotesCourseStatus: 'failure' },
      };

    //<<<<<<END-NOTES-COURSE>>>>>

    // <<<<<<START-NOTES-SUBJECT>>>>>>>>>>>

    case GET_NOTES_SUBJECT_REQUEST:
      return {
        ...state,
        loader: {
          noteSubjectsLoading: true,
        },
      };
    case GET_NOTES_SUBJECT_SUCCEEDED:
      return {
        ...state,
        noteSubjects: action.data,
        loader: {
          noteSubjectsLoading: false,
        },
      };
    case GET_NOTES_SUBJECT_FAILED:
      return {
        ...state,
        loader: {
          noteSubjectsLoading: false,
        },
      };
    case GET_NOTES_SUBJECT_BY_SLUG_REQUEST:
      return {
        ...state,
        noteSubjectBySlugLoading: true,
      };
    case GET_NOTES_SUBJECT_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        noteSubject: action.data,
        noteSubjectBySlugLoading: false,
      };
    case GET_NOTES_SUBJECT_BY_SLUG_FAILED:
      return {
        ...state,
        noteSubjectBySlugLoading: false,
      };
    case ADD_NOTES_SUBJECT_REQUEST:
      return {
        ...state,
        status: { ...state.status, addNotesSubjectStatus: 'request' },
      };
    case ADD_NOTES_SUBJECT_SUCCEEDED:
      const mySubjectArr = [action.data.data, ...state.noteSubjects.data];
      const sortedSubjects = mySubjectArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });

      return {
        ...state,
        status: { ...state.status, addNotesSubjectStatus: 'success' },
        noteSubjects: {
          ...state.noteSubjects,
          data: sortedSubjects,
        },
        helperObj: {
          ...state.helperObj,
          addNotesSubject: false,
        },
        error: { ...state.error, addNotesSubject: {} },
      };

    case ADD_NOTES_SUBJECT_FAILED:
      return {
        ...state,
        status: { ...state.status, addNotesSubjectStatus: 'failure' },
        error: { ...state.error, addNotesSubject: action.error },
      };
    case EDIT_NOTES_SUBJECT_REQUEST:
      return {
        ...state,
        status: { ...state.status, editNotesSubjectStatus: 'request' },
      };
    case EDIT_NOTES_SUBJECT_SUCCEEDED:
      const mySubjectEditArr = state.noteSubjects.data.map((each: any) =>
        each.id == action.data.data.id ? action.data.data : each
      );
      const sortedEditSubjects = mySubjectEditArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });
      return {
        ...state,
        noteSubjects: {
          ...state.noteSubjects,
          data: sortedEditSubjects,
        },
        helperObj: {
          ...state.helperObj,
          editNotesSubject: false,
        },
        status: { ...state.status, editNotesSubjectStatus: 'success' },
        error: { ...state.error, editNotesSubject: {} },
      };

    case EDIT_NOTES_SUBJECT_FAILED:
      return {
        ...state,
        error: { ...state.error, editNotesSubject: action.error },
        status: { ...state.status, editNotesSubjectStatus: 'failure' },
      };
    case DELETE_NOTES_SUBJECT_REQUEST:
      return {
        ...state,
        status: { ...state.status, deleteNotesSubjectStatus: 'request' },
      };
    case DELETE_NOTES_SUBJECT_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, deleteNotesSubjectStatus: 'success' },
        noteSubjects: {
          ...state.noteSubjects,
          data: state.noteSubjects.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_NOTES_SUBJECT_FAILED:
      return {
        ...state,
        status: { ...state.status, deleteNotesSubjectStatus: 'failure' },
      };
    //<<<<<<END-NOTES-SUBJECT>>>>>>>>>>>>>

    // <<<<<<START-NOTES-CHAPTER>>>>>>>>>>>

    case GET_NOTES_CHAPTER_REQUEST:
      return {
        ...state,
        loader: {
          noteChaptersLoading: true,
        },
      };
    case GET_NOTES_CHAPTER_SUCCEEDED:
      return {
        ...state,
        noteChapters: action.data,
        loader: {
          noteChaptersLoading: false,
        },
      };
    case GET_NOTES_CHAPTER_FAILED:
      return {
        ...state,
        loader: {
          noteChaptersLoading: false,
        },
      };
    case GET_NOTES_CHAPTER_BY_SLUG_REQUEST:
      return {
        ...state,
        noteChapterBySlugLoading: true,
      };
    case GET_NOTES_CHAPTER_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        noteChapter: action.data,
        noteChapterBySlugLoading: false,
      };
    case GET_NOTES_CHAPTER_BY_SLUG_FAILED:
      return {
        ...state,
        noteChapterBySlugLoading: false,
      };
    case ADD_NOTES_CHAPTER_REQUEST:
      return {
        ...state,
        status: { ...state.status, addNotesChapterStatus: 'request' },
      };
    case ADD_NOTES_CHAPTER_SUCCEEDED:
      const myChapterArr = [action.data.data, ...state.noteChapters.data];
      const sortedChapters = myChapterArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });

      return {
        ...state,
        status: { ...state.status, addNotesChapterStatus: 'success' },
        noteChapters: {
          ...state.noteChapters,
          data: sortedChapters,
        },
        helperObj: {
          ...state.helperObj,
          addNotesChapter: false,
        },
        error: { ...state.error, addNotesChapter: {} },
      };

    case ADD_NOTES_CHAPTER_FAILED:
      return {
        ...state,
        status: { ...state.status, addNotesChapterStatus: 'failure' },
        error: { ...state.error, addNotesChapter: action.error },
      };
    case EDIT_NOTES_CHAPTER_REQUEST:
      return {
        ...state,
        status: { ...state.status, editNotesChapterStatus: 'request' },
      };
    case EDIT_NOTES_CHAPTER_SUCCEEDED:
      const myChapterEditArr = state.noteChapters.data.map((each: any) =>
        each.id == action.data.data.id ? action.data.data : each
      );
      const sortedEditChapters = myChapterEditArr.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });
      return {
        ...state,
        noteChapters: {
          ...state.noteChapters,
          data: sortedEditChapters,
        },
        helperObj: {
          ...state.helperObj,
          editNotesChapter: false,
        },
        status: { ...state.status, editNotesChapterStatus: 'success' },
        error: { ...state.error, editNotesChapter: {} },
      };

    case EDIT_NOTES_CHAPTER_FAILED:
      return {
        ...state,
        error: { ...state.error, editNotesChapter: action.error },
        status: { ...state.status, editNotesChapterStatus: 'failure' },
      };
    case DELETE_NOTES_CHAPTER_REQUEST:
      return {
        ...state,
        status: { ...state.status, deleteNotesChapterStatus: 'request' },
      };
    case DELETE_NOTES_CHAPTER_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, deleteNotesChapterStatus: 'success' },
        noteChapters: {
          ...state.noteChapters,
          data: state.noteChapters.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_NOTES_CHAPTER_FAILED:
      return {
        ...state,
        status: { ...state.status, deleteNotesChapterStatus: 'failure' },
      };
    //<<<<<<END-NOTES-SUBJECT>>>>>>>>>>>>>

    // <<<<<<START-NOTES-CONTENT>>>>>>>>>>>

    case GET_NOTES_CONTENT_REQUEST:
      return {
        ...state,
        loader: {
          noteContentsLoading: true,
        },
      };
    case GET_NOTES_CONTENT_SUCCEEDED:
      return {
        ...state,
        noteContents: action.data,
        loader: {
          noteContentsLoading: false,
        },
      };
    case GET_NOTES_CONTENT_FAILED:
      return {
        ...state,
        loader: {
          noteContentsLoading: false,
        },
      };
    case GET_NOTES_CONTENT_BY_SLUG_REQUEST:
      return {
        ...state,
        noteContentBySlugLoading: true,
      };
    case GET_NOTES_CONTENT_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        noteContentBySlug: action.data,
        noteContentBySlugLoading: false,
      };
    case GET_NOTES_CONTENT_BY_SLUG_FAILED:
      return {
        ...state,
        noteContentBySlugLoading: false,
      };
    case ADD_NOTES_CONTENT_REQUEST:
      return {
        ...state,
        status: { ...state.status, addNotesContentStatus: 'request' },
      };
    case ADD_NOTES_CONTENT_SUCCEEDED:
      try {
        // console.log(state.noteContents)
        // const myContentArr = [action?.data?.data, ...state.noteContents.data];
        // const sortedContents = myContentArr?.sort(function (a, b) {
        //   if (a?.order === null) {
        //     return;
        //   }
        //   return a?.order - b?.order;
        // });
        return {
          ...state,
          status: { ...state.status, addNotesContentStatus: 'success' },
          noteContents: {
            ...state.noteContents,
            data: action.data.data,
          },
          helperObj: {
            ...state.helperObj,
            addNotesContent: false,
          },
          error: { ...state.error, addNotesContent: {} },
          noteContent: initialState.noteContent,
        };
      } catch (err) {
        console.log(err);
      }

    case ADD_NOTES_CONTENT_FAILED:
      return {
        ...state,
        status: { ...state.status, addNotesContentStatus: 'failure' },
        error: { ...state.error, addNotesContent: action.error },
      };
    case EDIT_NOTES_CONTENT_REQUEST:
      return {
        ...state,
        status: { ...state.status, editNotesContentStatus: 'request' },
      };
    case EDIT_NOTES_CONTENT_SUCCEEDED:
      const myContentEditArr = state?.noteContents?.data?.map((each: any) =>
        each.id == action.data?.data.id ? action?.data?.data : each
      );
      const sortedEditContents = myContentEditArr?.sort(function (a, b) {
        if (a?.order === null) {
          return;
        }
        return a?.order - b?.order;
      });
      return {
        ...state,
        status: { ...state.status, editNotesContentStatus: 'success' },
        noteContents: {
          ...state.noteContents,
          data: sortedEditContents,
        },
        helperObj: {
          ...state.helperObj,
          editNotesContent: false,
        },
        error: { ...state.error, editNotesContent: {} },
      };
    case EDIT_NOTES_CONTENT_FAILED:
      return {
        ...state,
        error: { ...state.error, editNotesContent: action.error },
        status: { ...state.status, editNotesContentStatus: 'failure' },
      };
    case DELETE_NOTES_CONTENT_REQUEST:
      return {
        ...state,
        status: { ...state.status, deleteNotesContentStatus: 'request' },
      };
    case DELETE_NOTES_CONTENT_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, deleteNotesContentStatus: 'success' },
        noteContents: {
          ...state.noteContents,
          data: state.noteContents.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_NOTES_CONTENT_FAILED:
      return {
        ...state,
        status: { ...state.status, deleteNotesContentStatus: 'failure' },
      };
    //<<<<<<END-NOTES-SUBJECT>>>>>>>>>>>>>

    // <<<<<<START-NOTES-CATEGORY>>>>>>>>>>>

    case GET_NOTES_CATEGORY_REQUEST:
      return {
        ...state,
        loader: {
          noteCategoriesLoading: true,
        },
      };
    case GET_NOTES_CATEGORY_SUCCEEDED:
      return {
        ...state,
        noteCategories: action.data,
        loader: {
          noteCategoriesLoading: false,
        },
      };
    case GET_NOTES_CATEGORY_FAILED:
      return {
        ...state,
        loader: {
          noteCategoriesLoading: false,
        },
      };

    case ADD_NOTES_CATEGORY_REQUEST:
      return {
        ...state,
        status: { ...state.status, addNotesCategoryStatus: 'request' },
      };
    case ADD_NOTES_CATEGORY_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, addNotesCategoryStatus: 'success' },
        noteCategories: {
          ...state.noteCategories,
          data: [action?.data?.data, ...state?.noteCategories?.data],
        },
        helperObj: {
          ...state.helperObj,
          addNotesCategory: false,
        },
        error: { ...state.error, addNotesCategory: {} },
      };

    case ADD_NOTES_CATEGORY_FAILED:
      return {
        ...state,
        status: { ...state.status, addNotesCategoryStatus: 'failure' },
        error: { ...state.error, addNotesCategory: action.error },
      };
    case EDIT_NOTES_CATEGORY_REQUEST:
      return {
        ...state,
        status: { ...state.status, editNotesCategoryStatus: 'request' },
      };
    case EDIT_NOTES_CATEGORY_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, editNotesCategoryStatus: 'success' },
        noteCategories: {
          ...state.noteCategories,
          data: state?.noteCategories?.data?.map((each: any) =>
            each.id == action.data?.data.id ? action?.data?.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          editNotesCategory: false,
        },
        error: { ...state.error, editNotesCategory: {} },
      };
    case EDIT_NOTES_CATEGORY_FAILED:
      return {
        ...state,
        error: { ...state.error, editNotesCategory: action.error },
        status: { ...state.status, editNotesCategoryStatus: 'failure' },
      };
    case DELETE_NOTES_CATEGORY_REQUEST:
      return {
        ...state,
        status: { ...state.status, deleteNotesCategoryStatus: 'request' },
      };
    case DELETE_NOTES_CATEGORY_SUCCEEDED:
      return {
        ...state,
        status: { ...state.status, deleteNotesCategoryStatus: 'success' },
        noteCategories: {
          ...state.noteCategories,
          data: state.noteCategories.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_NOTES_CATEGORY_FAILED:
      return {
        ...state,
        status: { ...state.status, deleteNotesCategoryStatus: 'failure' },
      };
    //<<<<<<END-NOTES-CATEGORY>>>>>>>>>>>>>

    // <<<<<<<<NOTES-LANDING-START>>>>>>>>>>
    case GET_LANDING_NOTES_COURSE_REQUEST:
      return {
        ...state,
        loader: {
          landingNoteCourseLoading: true,
        },
      };
    case GET_LANDING_NOTES_COURSE_SUCCEEDED:
      return {
        ...state,
        landingNoteCourses: action.data,
        loader: {
          landingNoteCourseLoading: false,
        },
      };
    case GET_LANDING_NOTES_COURSE_FAILED:
      return {
        ...state,
        loader: {
          landingNoteCourseLoading: false,
        },
      };

    case GET_LANDING_NOTES_COURSE_BY_PAGE:
      return {
        ...state,
        loader: {
          ...state.loader,
          getMoreLandingCourseStatus: 'request',
        },
      };
    case GET_LANDING_NOTES_COURSE_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        loader: {
          ...state.loader,
          getMoreLandingCourseStatus: 'success',
        },
        landingNoteCourses: {
          ...state.landingNoteCourses,
          data: [...state.landingNoteCourses.data, ...action.data.data],
          meta: action.data.meta,
          category: action.data.category,
        },
      };
    case GET_LANDING_NOTES_COURSE_BY_PAGE_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          getMoreLandingCourseStatus: 'failure',
        },
      };

    case GET_LANDING_NOTES_COURSE_DETAIL_REQUEST:
      return {
        ...state,
        landingNoteCourseDetailLoading: 'request',
      };
    case GET_LANDING_NOTES_COURSE_DETAIL_SUCCEEDED:
      return {
        ...state,
        landingNoteCourseDetail: action.data,
        landingNoteCourseDetailLoading: 'success',
      };
    case GET_LANDING_NOTES_COURSE_DETAIL_FAILED:
      return {
        ...state,
        landingNoteCourseDetailLoading: 'failure',
      };

    case GET_LANDING_NOTES_SUBJECT_DETAIL_REQUEST:
      return {
        ...state,
        landingNoteSubjectDetailLoading: true,
      };
    case GET_LANDING_NOTES_SUBJECT_DETAIL_SUCCEEDED:
      return {
        ...state,
        landingNoteSubjectDetail: action.data,
        landingNoteSubjectDetailLoading: false,
      };
    case GET_LANDING_NOTES_SUBJECT_DETAIL_FAILED:
      return {
        ...state,
        landingNoteSubjectDetailLoading: false,
      };
    //<<<<<<<<<<<<NOTE-LANDING-END>>>>>>>>>>>>

    // Search Course Videos
    case SEARCH_VIDEO:
      return {
        ...state,
        loader: {
          ...state.loader,
          searchVideo: true,
        },
      };
    case SEARCH_VIDEO_SUCCEEDED:
      return {
        ...state,
        noteVideos: action.data,
        loader: {
          ...state.loader,
          searchVideo: false,
        },
      };
    case SEARCH_VIDEO_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          searchVideo: false,
        },
      };
    // Search Course Videos end

    case SET_NOTES_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_NOTES_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
      };
    case SET_NOTES_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };

    case CLEAR_NOTES_ERROR:
      return {
        ...state,
        error: {
          addNotesCourse: {},
          editNotesCourse: {},
          addNotesSubject: {},
          editNotesSubject: {},
          addNotesChapter: {},
          editNotesChapter: {},
          addNotesContent: {},
          editNotesContent: {},
        },
      };

    case CLEAR_NOTES_COURSE_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          noteCoursesLanding: initialState?.query?.noteCoursesLanding,
        },
      };

    default:
      return state;
  }
};

export default notes;
