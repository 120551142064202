import { getLocalStore } from 'next-persist';
import { IPartner } from 'src/interfaces/partner';
import {
  ADD_PARTNER_FAILED,
  ADD_PARTNER_REQUEST,
  ADD_PARTNER_SUCCEEDED,
  EDIT_PARTNER_FAILED,
  EDIT_PARTNER_REQUEST,
  EDIT_PARTNER_SUCCEEDED,
  GET_PARTNER_CLIENT_FAILED,
  GET_PARTNER_CLIENT_PAYMENT_FAILED,
  GET_PARTNER_CLIENT_PAYMENT_REQUEST,
  GET_PARTNER_CLIENT_PAYMENT_SUCCEEDED,
  GET_PARTNER_CLIENT_REQUEST,
  GET_PARTNER_CLIENT_SUCCEEDED,
  GET_PARTNER_INFO_FAILED,
  GET_PARTNER_INFO_REQUEST,
  GET_PARTNER_INFO_SUCCEEDED,
  GET_PARTNER_REQUEST,
  GET_PARTNER_REQUEST_FAILED,
  GET_PARTNER_REQUEST_SUCCEEDED,
  SET_PARTNER_DATA,
  SET_PARTNER_ERROR_DATA,
} from '../actions';

const initialState: IPartner = {
  helperObj: {
    showAddPartner: false,
    showEditPartner: false,
  },
  partner: {},
  partnerClient: {},
  partnerInfo: {},
  partnerClientPayment: {},
  loader: {
    getPartnerLoading: false,
    addPartnerLoading: false,
    editPartnerLoading: false,
    getPartnerClientLoading: false,
    getPartnerInfoLoading: false,
    getPartnerClientPaymentLoading: false,
  },
  error: {
    getPartnerError: {},
    addPartnerError: {},
    editPartnerError: {},
    getPartnerClientError: {},
    getPartnerInfoError: {},
    getPartnerClientPaymentError: {},
  },
  status: {
    getPartnerStatus: '',
    addPartnerStatus: '',
    editPartnerStatus: '',
    getPartnerClientStatus: '',
    getPartnerInfoStatus: '',
    getPartnerClientPaymentStatus: '',
  },
};

const persistedState = getLocalStore('partner', initialState);

const partner = (state = persistedState, action) => {
  switch (action.type) {
    case GET_PARTNER_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerStatus: 'request',
        },
        loader: {
          ...state.loader,
          getPartnerLoading: true,
        },
      };
    case GET_PARTNER_REQUEST_SUCCEEDED:
      return {
        ...state,
        partner: action.data,
        status: {
          ...state.status,
          getPartnerStatus: 'success',
        },
        loader: {
          ...state.loader,
          getPartnerLoading: false,
        },
        error: {
          ...state.error,
          getPartnerError: {},
        },
      };
    case GET_PARTNER_REQUEST_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerStatus: 'failed',
        },
        loader: {
          ...state.loaders,
          getPartnerLoading: false,
        },
        error: {
          ...state.error,
          getPartnerError: action.error,
        },
      };
    case SET_PARTNER_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_PARTNER_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    case ADD_PARTNER_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          addPartnerStatus: 'request',
        },
        loader: {
          ...state.loader,
          addPartnerLoading: true,
        },
      };
    case ADD_PARTNER_SUCCEEDED:
      return {
        ...state,
        partner: {
          ...state.partner,
          data: [action?.data?.data, ...state.partner.data],
        },
        status: {
          ...state.status,
          addPartnerStatus: 'success',
        },
        loader: {
          ...state.loader,
          addPartnerLoading: false,
        },
        helperObj: {
          ...state.helperObj,
          showAddPartner: false,
        },
      };
    case ADD_PARTNER_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          addPartnerLoading: false,
        },
        error: {
          ...state.error,
          addPartnerError: action.error,
        },
        status: {
          ...state.status,
          addPartnerStatus: 'failed',
        },
      };
    case EDIT_PARTNER_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          status: { ...state.status, editPartnerStatus: 'request' },
        },
        loader: {
          ...state.loader,
          editPartnerLoading: true,
        },
      };
    case EDIT_PARTNER_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          status: { ...state.status, editPartnerStatus: 'success' },
        },
        loader: {
          ...state.loader,
          editPartnerLoading: false,
        },
        partner: {
          ...state.partner,
          data: state?.partner?.data?.map((each: any) =>
            each.id === action.data?.data.id ? action?.data?.data : each
          ),
        },
        partnerInfo:
          state.partnerInfo.id === action.data.data.id
            ? action.data.data
            : state.partnerInfo,

        helperObj: {
          ...state.helperObj,
          showEditPartner: false,
        },
        error: { ...state.error, editPartnerError: {} },
      };
    case EDIT_PARTNER_FAILED:
      return {
        ...state,
        error: { ...state.error, errorEditPartner: action.error },
        status: { ...state.status, editPartnerStatus: 'failure' },
      };
    case GET_PARTNER_CLIENT_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerClientStatus: 'request',
        },
        loader: {
          ...state.loader,
          getPartnerClientLoading: true,
        },
      };
    case GET_PARTNER_CLIENT_SUCCEEDED:
      return {
        ...state,
        partnerClient: action.data,
        status: {
          ...state.status,
          getPartnerStatus: 'success',
        },
        loader: {
          ...state.loader,
          getPartnerClientLoading: false,
        },
        error: {
          ...state.error,
          getPartnerClientError: {},
        },
      };
    case GET_PARTNER_CLIENT_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerClientStatus: 'failed',
        },
        loader: {
          ...state.loader,
          getPartnerClientLoading: false,
        },
        error: {
          ...state.error,
          getPartnerClientError: action.error,
        },
      };
    case GET_PARTNER_INFO_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerInfoStatus: 'request',
        },
        loader: {
          ...state.loader,
          getPartnerInfoLoading: true,
        },
      };
    case GET_PARTNER_INFO_SUCCEEDED:
      return {
        ...state,
        partnerInfo: action.data,
        status: {
          ...state.status,
          getPartnerInfoStatus: 'success',
        },
        loader: {
          ...state.loader,
          getPartnerInfoLoading: false,
        },
        error: {
          ...state.error,
          getPartnerInfoError: {},
        },
      };
    case GET_PARTNER_INFO_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerInfoStatus: 'failed',
        },
        loader: {
          ...state.loader,
          getPartnerInfoLoading: false,
        },
        error: {
          ...state.error,
          getPartnerInfoError: action.error,
        },
      };
    case GET_PARTNER_CLIENT_PAYMENT_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerClientPaymentStatus: 'request',
        },
        loader: {
          ...state.loader,
          getPartnerClientPaymentLoading: true,
        },
      };
    case GET_PARTNER_CLIENT_PAYMENT_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerClientPaymentStatus: 'success',
        },
        loader: {
          ...state.loader,
          getPartnerClientPaymentLoading: false,
        },
        partnerClientPayment: action.data,
      };
    case GET_PARTNER_CLIENT_PAYMENT_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          getPartnerClientPaymentStatus: 'failed',
        },
        loader: {
          ...state.loader,
          getPartnerClientPaymentLoading: false,
        },
      };
    default:
      return state;
  }
};
export default partner;
