import {
  GET_DOUBT_POST,
  GET_DOUBT_POST_SUCCEEDED,
  GET_DOUBT_POST_FAILED,
  GET_DOUBT_POST_BY_PAGE,
  GET_DOUBT_POST_BY_PAGE_SUCCEEDED,
  GET_DOUBT_DETAIL_POST,
  GET_DOUBT_DETAIL_POST_SUCCEEDED,
  GET_DOUBT_DETAIL_POST_FAILED,
  ADD_DOUBT_POST_SUCCEEDED,
  ADD_DOUBT_POST_FAILED,
  ADD_DOUBT_POLL,
  ADD_DOUBT_POLL_SUCCEEDED,
  ADD_DOUBT_POLL_FAILED,
  EDIT_DOUBT_POST_SUCCEEDED,
  EDIT_DOUBT_POST_FAILED,
  DELETE_DOUBT_POST_SUCCEEDED,
  DELETE_DOUBT_POST_FAILED,
  ADD_DOUBT_POST_LIKE_SUCCEEDED,
  ADD_DOUBT_POST_LIKE_FAILED,
  GET_DOUBT_COMMENT,
  GET_DOUBT_COMMENT_SUCCEEDED,
  GET_DOUBT_COMMENT_FAILED,
  GET_LATEST_COURSE_SUCCEEDED,
  GET_LATEST_COURSE_FAILED,
  GET_DOUBT_TOPIC_SUCCEEDED,
  GET_DOUBT_TOPIC_FAILED,
  ADD_DOUBT_COMMENT_SUCCEEDED,
  ADD_DOUBT_COMMENT_FAILED,
  EDIT_DOUBT_COMMENT_SUCCEEDED,
  EDIT_DOUBT_COMMENT_FAILED,
  DELETE_DOUBT_COMMENT_SUCCEEDED,
  DELETE_DOUBT_COMMENT_FAILED,
  REPORT_DOUBT_COMMENT_SUCCEEDED,
  REPORT_DOUBT_COMMENT_FAILED,
  REPORT_DOUBT_POST_FAILED,
  REPORT_DOUBT_POST_SUCCEEDED,
  LINK_COPIED,
  ADD_DOUBT_POST_LIKE,
  SEARCH_DOUBT_QUESTION,
  SEARCH_DOUBT_QUESTION_SUCCEEDED,
  SEARCH_DOUBT_QUESTION_FAILED,
  VOTE_DOUBT_POLL,
  VOTE_DOUBT_POLL_SUCCEEDED,
  VOTE_DOUBT_POLL_FAILED,
  GET_POST_REPORTS,
  GET_POST_REPORTS_SUCCEEDED,
  GET_POST_REPORTS_FAILED,
  GET_POST_REQUESTS,
  GET_POST_REQUESTS_SUCCEEDED,
  GET_POST_REQUESTS_FAILED,
  EDIT_DOUBT_REQUESTS_ADMIN,
  EDIT_DOUBT_REQUESTS_ADMIN_SUCCEEDED,
  EDIT_DOUBT_REQUESTS_ADMIN_FAILED,
  GET_TOP_TOPICS,
  GET_TOP_TOPICS_SUCCEEDED,
  GET_TOP_TOPICS_FAILED,
  EDIT_POST_REPORTS,
  EDIT_POST_REPORTS_SUCCEEDED,
  EDIT_POST_REPORTS_FAILED,
  GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_SUCCEEDED,
  GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_FAILED,
  GET_DOUBT_COMMENT_FOR_DETAIL_PAGE,
  GET_COMMENT_REPORTS,
  GET_COMMENT_REPORTS_SUCCEEDED,
  GET_COMMENT_REPORTS_FAILED,
  EDIT_COMMENT_REPORTS,
  EDIT_COMMENT_REPORTS_SUCCEEDED,
  EDIT_COMMENT_REPORTS_FAILED,
  GET_USER_DOUBTS,
  GET_USER_DOUBTS_SUCCEEDED,
  GET_USER_DOUBTS_FAILED,
  ADD_DOUBT_TOPIC,
  ADD_DOUBT_TOPIC_FAILED,
  ADD_DOUBT_TOPIC_SUCCEEDED,
  ADD_DOUBT_POST,
  GET_ROOM_DISCUSSION,
  GET_ROOM_DISCUSSION_SUCCEEDED,
  GET_ROOM_DISCUSSION_FAILED,
  // GET_ROOM_DISCUSSION_BY_PAGE,
  // GET_ROOM_DISCUSSION_BY_PAGE_SUCCEEDED,
  // GET_ROOM_DISCUSSION_BY_PAGE_FAILED,
  SET_DOUBT_EDIT_DATA,
} from '../actions/index';
import { IDoubts } from '../interfaces/doubts';
import { getLocalStore } from 'next-persist';
import { store } from '../../store';
// import { injectStore } from '../apiUtils/api';

const initialState: IDoubts = {
  doubts: {
    data: [],
    loading: false,
    liking: false,
    voting: false,
    moreLoading: false,
    status: false,
  },
  doubtDetail: {
    data: [],
    loading: false,
  },
  comment: {
    data: [],
    loading: false,
  },
  detailPageComment: {
    data: [],
  },
  doubtTopic: {},
  addDoubtTopic: {
    status: false,
    data: {},
  },
  isDeleted: false,
  isUpdated: false,
  isLiked: false,
  likeCount: 0,
  isReported: false,
  reportPost: {
    data: [],
  },
  reportComment: {
    data: [],
  },
  isLinkCopied: false,
  currentAddedDoubt: null,
  poll: {
    data: [],
    loading: false,
  },
  reports: {
    data: [],
    loading: false,
  },
  doubtRequest: {
    data: [],
    loading: false,
  },
  topTopics: {
    data: [],
    loading: false,
  },
  commentReports: {
    data: [],
    loading: false,
  },
  userDoubts: {
    data: [],
    loading: false,
  },
  // Room discussion
  discussion: {
    data: [],
    loading: false,
    moreLoading: false,
  },
  addDoubtPostStatus: '',
};
const persistedState = getLocalStore('doubtReducer', initialState);

const doubtReducer = (state = persistedState, action) => {
  switch (action.type) {
    case GET_DOUBT_POST:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          loading: true,
          moreLoading: false,
          // isLinkCopied:false
        },
      };
    // Posts
    case GET_DOUBT_POST_SUCCEEDED:
      // console.log("Store,", injectStore(store))
      return {
        ...state,
        doubts: action.data,
        isDeleted: false,
        isUpdated: false,
        isReported: false,
        loading: false,
        moreLoading: false,
      };
    case GET_DOUBT_POST_BY_PAGE:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          moreLoading: true,
        },
      };
    case GET_DOUBT_POST_BY_PAGE_SUCCEEDED:
      // console.log('Action', action)
      // console.log('Doubts', state.doubts)
      return {
        ...state,
        doubts: {
          ...state.doubts,
          data: [...state.doubts.data, ...action.data.data],
          link: action.data.link,
          meta: action.data.meta,
          moreLoading: false,
        },
      };
    case GET_DOUBT_POST_FAILED:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          moreLoading: false,
        },
      };
    case GET_DOUBT_DETAIL_POST:
      return {
        ...state,
        doubtDetail: {
          ...state.doubtDetail,
          loading: true,
        },
      };
    case GET_DOUBT_DETAIL_POST_SUCCEEDED:
      return {
        ...state,
        doubtDetail: {
          loading: false,
          data: action.data,
        },
      };
    case GET_DOUBT_DETAIL_POST_FAILED:
      return {
        ...state,
        doubtDetail: {
          loading: false,
        },
      };

    case SEARCH_DOUBT_QUESTION:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          loading: true,
        },
      };
    case SEARCH_DOUBT_QUESTION_SUCCEEDED:
      return {
        ...state,
        doubts: action.data,
      };
    case SEARCH_DOUBT_QUESTION_FAILED:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          status: false,
        },
      };
    case ADD_DOUBT_POST:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          status: false,
        },
        addDoubtPostStatus: 'request',
      };
    case ADD_DOUBT_POST_SUCCEEDED:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          // data: [action.data.data, ...state.doubts.data],
          data: state.doubts.data,
          // currentAddedDoubt: action.data.data,
          status: true,
          // lessonQuestion:[action.data.data, ...state.doubts.data]
        },
        discussion: {
          ...state.discussion,
          data: [action.data.data, ...state.discussion.data],
        },
        addDoubtPostStatus: 'success',

        // currentAddedDoubt: action.data.data
      };
    case ADD_DOUBT_POST_FAILED:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          status: false,
        },
        addDoubtPostStatus: 'failure',
      };
    case ADD_DOUBT_POLL_SUCCEEDED:
      return {
        ...state,
        poll: {
          ...state.poll,
          data: [action.data.data, ...state.poll.data],
        },
      };
    case ADD_DOUBT_POLL_FAILED:
      return {
        ...state,
      };
    case EDIT_DOUBT_POST_SUCCEEDED:
      const oldDoubt = state.doubts.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        doubts: {
          ...state.doubts,
          data: state.doubts.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        discussion: {
          ...state.discussion,
          data: state.discussion.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        isUpdated: true,
      };
    case EDIT_DOUBT_POST_FAILED:
      return {
        ...state,
      };
    case DELETE_DOUBT_POST_SUCCEEDED:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          data: state.doubts.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        discussion: {
          ...state.discussion,
          data: state.discussion.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        isDeleted: true,
      };
    case DELETE_DOUBT_POST_FAILED:
      return {
        ...state,
      };

    case REPORT_DOUBT_POST_SUCCEEDED:
      return {
        ...state,
        reportPost: {
          ...state.reportPost,
          data: [action.data.data, ...state.reportPost.data],
          doubtId: action.data.data.doubtId,
          isReported: true,
        },
      };
    case REPORT_DOUBT_POST_FAILED:
      return {
        ...state,
      };
    // Comments
    case GET_DOUBT_COMMENT:
      return {
        ...state,
        comment: {
          ...state.comment,
          loading: true,
        },
      };
    case GET_DOUBT_COMMENT_SUCCEEDED:
      // console.log(state.comment.data)
      const getDoubtCommentArray: any = [...state.doubts.data];
      if (action.data?.data?.length > 0) {
        const Doubtindex = state.doubts.data.findIndex(
          (each: any) => each.id === action.data?.data[0]?.doubt
        );
        getDoubtCommentArray[Doubtindex]['comments'] = action.data;
      }
      return {
        ...state,
        comment: {
          ...state.comment,
          data: action.data,
          loading: false,
        },
        doubts: {
          ...state.doubts,
          data: getDoubtCommentArray,
          loading: false,
        },
      };
    case GET_DOUBT_COMMENT_FAILED:
      return {
        ...state,
        comment: {
          ...state.comment,
          loading: false,
        },
      };
    case GET_DOUBT_COMMENT_FOR_DETAIL_PAGE:
      return {
        ...state,
        detailPageComment: {
          ...state.detailPageComment,
          detailPageCommentLoading: true,
        },
      };
    case GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_SUCCEEDED:
      // console.log(action.data)
      return {
        ...state,
        detailPageComment: action.data,
        detailPageCommentLoading: false,
      };
    case GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_FAILED:
      return {
        ...state,
        detailPageComment: {
          ...state.detailPageComment,
          detailPageCommentLoading: false,
        },
      };
    case ADD_DOUBT_COMMENT_SUCCEEDED:
      const addDoubtCommentArray: any = [...state.doubts.data];
      if (action.data?.data) {
        const Doubtindex = state.doubts.data.findIndex(
          (each: any) => each.id === action.data?.data?.doubt
        );
        // console.log("Doubtindex", Doubtindex)
        if (addDoubtCommentArray[Doubtindex]?.comments) {
          addDoubtCommentArray[Doubtindex].comments.data = [
            action.data.data,
            ...addDoubtCommentArray[Doubtindex].comments.data,
          ];
        } else {
          addDoubtCommentArray[Doubtindex]['comments'] = {};
          addDoubtCommentArray[Doubtindex]['comments']['data'] = [action.data.data];
        }
        addDoubtCommentArray[Doubtindex].no_comment =
          addDoubtCommentArray[Doubtindex].no_comment + 1;
      }
      return {
        ...state,
        comment: {
          ...state.comment,
          data: [action.data.data, ...state.comment.data],
        },
        doubts: {
          ...state.doubts,
          data: addDoubtCommentArray,
        },
      };
    case ADD_DOUBT_COMMENT_FAILED:
      return {
        ...state,
      };
    case EDIT_DOUBT_COMMENT_SUCCEEDED:
      const oldComment = state.comment.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      const editDoubtCommentArray: any = [...state.doubts.data];
      if (action.data?.data) {
        const Doubtindex = state.doubts.data.findIndex(
          (each: any) => each.id === action.data?.data?.doubt
        );
        if (editDoubtCommentArray[Doubtindex]?.comments) {
          // const commentArray = editDoubtCommentArray[Doubtindex].comments.data
          const commentIndex = editDoubtCommentArray[
            Doubtindex
          ].comments.data.findIndex(
            (each: any) => each.id === action.data?.data?.id
          );
          editDoubtCommentArray[Doubtindex].comments.data[commentIndex] =
            action.data.data;
        }
      }
      return {
        ...state,
        comment: {
          ...state.comment,
          data: state.doubts.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
          isUpdated: true,
        },
        doubts: {
          ...state.doubts,
          data: editDoubtCommentArray,
        },
      };
    case EDIT_DOUBT_COMMENT_FAILED:
      return {
        ...state,
      };
    case DELETE_DOUBT_COMMENT_SUCCEEDED:
      const deleteDoubtCommentArray: any = [...state.doubts.data];
      if (action.data?.data) {
        const Doubtindex = state.doubts.data.findIndex(
          (each: any) => each.id === action.data?.data?.doubt
        );
        if (deleteDoubtCommentArray[Doubtindex]?.comments) {
          const newCommentData = deleteDoubtCommentArray[
            Doubtindex
          ].comments.data.filter((each: any) => each.id !== action.data?.data?.id);
          deleteDoubtCommentArray[Doubtindex].comments.data = newCommentData;
          deleteDoubtCommentArray[Doubtindex].no_comment =
            deleteDoubtCommentArray[Doubtindex].no_comment - 1;
        }
      }
      return {
        ...state,
        comment: {
          ...state.comment,
          data: state.comment.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        doubts: {
          ...state.doubts,
          data: deleteDoubtCommentArray,
        },
        isDeleted: true,
      };
    case REPORT_DOUBT_COMMENT_SUCCEEDED:
      return {
        ...state,
        reportComment: {
          ...state.reportComment,
          data: [action.data.data, ...state.reportComment.data],
          isReported: true,
        },
      };
    case REPORT_DOUBT_COMMENT_FAILED:
      return {
        ...state,
      };
    // Latest Course
    case GET_LATEST_COURSE_SUCCEEDED:
      return {
        ...state,
        latest_course: action.data,
      };
    case GET_LATEST_COURSE_FAILED:
      return {
        ...state,
      };
    // Doubt Post Like
    case ADD_DOUBT_POST_LIKE:
      return {
        ...state,
        likeDoubtPost: 'request',
        doubts: {
          ...state.doubts,
          liking: true,
        },
      };
    case ADD_DOUBT_POST_LIKE_SUCCEEDED:
      // const oldDoubts = state.doubts.data.filter(
      //     (each: any) => each.id !== action.data.data.id
      // )

      const index = state.doubts.data.findIndex(
        (each: any) => each.id === action.data.data.id
      );

      const newArray: any = [...state.doubts.data];
      if (newArray[index].like) {
        newArray[index].like = false;
        newArray[index].no_of_likes = newArray[index].no_of_likes - 1;
      } else {
        newArray[index].like = true;
        newArray[index].no_of_likes = newArray[index].no_of_likes + 1;
      }

      return {
        ...state,
        likeDoubtPost: 'success',
        doubts: {
          ...state.doubts,
          // data: [action.data.data, ...oldDoubts],
          data: newArray,
          liking: false,
        },
      };
    case ADD_DOUBT_POST_LIKE_FAILED:
      return {
        ...state,
        likeDoubtPost: 'failure',
        doubts: {
          ...state.doubts,
          liking: false,
        },
      };

    // Vote Poll
    case VOTE_DOUBT_POLL:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          voting: true,
        },
      };
    case VOTE_DOUBT_POLL_SUCCEEDED:
      const oldDoubts = state.doubts.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        doubts: {
          ...state.doubts,
          data: [action.data.data, ...oldDoubts],
          voting: false,
        },
      };

    case VOTE_DOUBT_POLL_FAILED:
      return {
        ...state,
        doubts: {
          ...state.doubts,
          voting: false,
        },
      };

    case ADD_DOUBT_TOPIC:
      return {
        ...state,
        addDoubtTopic: {
          status: false,
          data: {},
        },
      };

    // Doubt Topic
    case ADD_DOUBT_TOPIC_SUCCEEDED:
      return {
        ...state,
        addDoubtTopic: {
          status: true,
          data: action.data.success,
        },
      };

    case ADD_DOUBT_TOPIC_FAILED:
      return {
        ...state,
        addDoubtTopic: {
          status: false,
          data: {},
        },
      };
    case GET_DOUBT_TOPIC_SUCCEEDED:
      return {
        ...state,
        doubtTopic: action.data,
      };
    case GET_DOUBT_TOPIC_FAILED:
      return {
        ...state,
      };
    // Share Link
    case LINK_COPIED:
      return {
        ...state,
        isLinkCopied: !state.isLinkCopied,
      };
    // Admin dashboard getDoubts
    case GET_POST_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
      };
    case GET_POST_REPORTS_SUCCEEDED:
      return {
        ...state,
        reports: { ...action.data, loading: false },
      };
    case GET_POST_REPORTS_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
        },
      };
    case EDIT_POST_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          updateLoading: true,
          status: false,
        },
      };
    case EDIT_POST_REPORTS_SUCCEEDED:
      const oldreport = state.reports.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        reports: {
          ...state.reports,
          data: [...oldreport],
          updateLoading: false,
          status: true,
        },
      };
    case EDIT_POST_REPORTS_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          updateLoading: false,
          status: false,
        },
      };
    case GET_COMMENT_REPORTS:
      // commentReports
      return {
        ...state,
        commentReports: {
          ...state.commentReports,
          loading: true,
        },
      };
    case GET_COMMENT_REPORTS_SUCCEEDED:
      return {
        ...state,
        commentReports: { ...action.data, loading: false },
      };
    case GET_COMMENT_REPORTS_FAILED:
      return {
        ...state,
        commentReports: {
          ...state.commentReports,
          loading: false,
        },
      };
    case EDIT_COMMENT_REPORTS:
      return {
        ...state,
        commentReports: {
          ...state.commentReports,
          updateLoading: true,
          status: false,
        },
      };
    case EDIT_COMMENT_REPORTS_SUCCEEDED:
      const oldcommentReport = state.commentReports.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        commentReports: {
          ...state.commentReports,
          data: [...oldcommentReport],
          updateLoading: false,
          status: true,
        },
      };
    case EDIT_COMMENT_REPORTS_FAILED:
      return {
        ...state,
        commentReports: {
          ...state.commentReports,
          updateLoading: false,
          status: false,
        },
      };
    case GET_POST_REQUESTS:
      return {
        ...state,
        doubtRequest: {
          ...state.doubtRequest,
          loading: true,
        },
      };
    case GET_POST_REQUESTS_SUCCEEDED:
      return {
        ...state,
        doubtRequest: action.data,
      };
    case GET_POST_REQUESTS_FAILED:
      return {
        ...state,
        doubtRequest: {
          ...state.doubtRequest,
          loading: false,
        },
      };
    case EDIT_DOUBT_REQUESTS_ADMIN:
      return {
        ...state,
        doubtRequest: {
          ...state.doubtRequest,
          updateLoading: true,
        },
      };
    case EDIT_DOUBT_REQUESTS_ADMIN_SUCCEEDED:
      const oldDoubtRequest = state.doubtRequest.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        doubtRequest: {
          data: state.doubtRequest.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };
    case EDIT_DOUBT_REQUESTS_ADMIN_FAILED:
      return {
        ...state,
        doubtRequest: {
          ...state.doubtRequest,
          updateLoading: false,
        },
      };
    case GET_TOP_TOPICS:
      return {
        ...state,
        topTopics: {
          ...state.topTopics,
          loading: true,
        },
      };
    case GET_TOP_TOPICS_SUCCEEDED:
      return {
        ...state,
        topTopics: action.data,
      };
    case GET_TOP_TOPICS_FAILED:
      return {
        ...state,
      };
    case GET_USER_DOUBTS:
      return {
        ...state,
        userDoubts: {
          ...state.userDoubts,
          loading: true,
        },
      };
    case GET_USER_DOUBTS_SUCCEEDED:
      return {
        ...state,
        userDoubts: {
          ...state.userDoubts,
          loading: false,
          data: action.data,
        },
      };
    case GET_USER_DOUBTS_FAILED:
      return {
        ...state,
        userDoubts: {
          ...state.userDoubts,
          loading: false,
        },
      };

    // Room Discussion
    case GET_ROOM_DISCUSSION:
      return {
        ...state,
        disscussion: {
          ...state.disscussion,
          loading: true,
          moreLoading: false,
        },
      };
    case GET_ROOM_DISCUSSION_SUCCEEDED:
      return {
        ...state,
        discussion: action.data,
      };
    case GET_ROOM_DISCUSSION_FAILED:
      return {
        ...state,
      };
    // case GET_ROOM_DISCUSSION_BY_PAGE:
    //   return {
    //     ...state,
    //     discussion: {
    //       ...state.discussion,
    //       moreLoading: true
    //     }
    //   }
    // case GET_ROOM_DISCUSSION_BY_PAGE_SUCCEEDED:
    //   return {
    //     ...state,
    //     discussion: {
    //       ...state.discussion,
    //       data: [...state.discussion.data, ...action.data.data],
    //       link: action.data.link,
    //       meta: action.data.meta,
    //       moreLoading: false,
    //     },
    //   };
    // case GET_ROOM_DISCUSSION_BY_PAGE_FAILED:
    //   return {
    //     ...state,
    //     discussion: {
    //       ...state.discussion,
    //       moreLoading: false,
    //     },
    //   };

    case SET_DOUBT_EDIT_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.value,
        },
      };

    default:
      return { ...state };
  }
};

export default doubtReducer;

// status: false
// },
// doubtDetail: {
//     data: [],
//     loading: false,
// },
// comment: {
//     data: [],
//     loading: false,
// },
// detailPageComment: {
//     data: [],
// },
// doubtTopic: {},
// isDeleted: false,
// isUpdated: false,
// isLiked: false,
// likeCount: 0,
// isReported: false,
// reportPost: {
//     data: []
// },
// reportComment: {
//     data: []
// },
// isLinkCopied: false,
// currentAddedDoubt: null,
// poll: {
//     data: [],
//     loading: false,
// },
// reports: {
//     data: [],
//     loading: false,
// },
// doubtRequest: {
//     data: [],
//     loading: false,
// },
// topTopics: {
//     data: [],
//     loading: false
// },
// commentReports: {
//     data: [],
//     loading: false,
// },
// userDoubts: {
//     data: [],
//     loading: false,
// }
// }

// const doubtReducer = (state = initialState, action) => {
// switch (action.type) {
//     case GET_DOUBT_POST:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 loading: true,
//                 moreLoading: false,
//                 // isLinkCopied:false
//             }
//         }
//     // Posts
//     case GET_DOUBT_POST_SUCCEEDED:
//         return {
//             ...state,
//             doubts: action.data,
//             isDeleted: false,
//             isUpdated: false,
//             isReported: false,
//             loading: false,
//             moreLoading: false
//         }
//     case GET_DOUBT_POST_BY_PAGE:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 moreLoading: true,
//             }
//         }
//     case GET_DOUBT_POST_BY_PAGE_SUCCEEDED:
//         // console.log('Action', action)
//         // console.log('Doubts', state.doubts)
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 data: [...state.doubts.data, ...action.data.data],
//                 link: action.data.link,
//                 meta: action.data.meta,
//                 moreLoading: false,
//             }
//         }
//     case GET_DOUBT_POST_FAILED:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 moreLoading: false
//             }
//         }
//     case GET_DOUBT_DETAIL_POST:
//         return {
//             ...state,
//             doubtDetail: {
//                 ...state.doubtDetail, loading: true
//             }
//         }
//     case GET_DOUBT_DETAIL_POST_SUCCEEDED:
//         return {
//             ...state,
//             doubtDetail: {
//                 loading: false,
//                 data: action.data
//             }
//         }
//     case GET_DOUBT_DETAIL_POST_FAILED:
//         return {
//             ...state
//         }

//     case SEARCH_DOUBT_QUESTION:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 loading: true
//             }
//         }
//     case SEARCH_DOUBT_QUESTION_SUCCEEDED:
//         return {
//             ...state,
//             doubts: action.data,
//         }
//     case SEARCH_DOUBT_QUESTION_FAILED:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 status: false
//             }
//         }
//     case ADD_DOUBT_POST:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 status: false
//             }
//         }
//     case ADD_DOUBT_POST_SUCCEEDED:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 // data: [action.data.data, ...state.doubts.data],
//                 data: state.doubts.data,
//                 // currentAddedDoubt: action.data.data,
//                 status: true,
//                 // lessonQuestion:[action.data.data, ...state.doubts.data]
//             },
//             // currentAddedDoubt: action.data.data
//         }
//     case ADD_DOUBT_POST_FAILED:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 status: false
//             }
//         }
//     case ADD_DOUBT_POLL_SUCCEEDED:
//         return {
//             ...state,
//             poll: {
//                 ...state.poll,
//                 data: [action.data.data, ...state.poll.data],
//             }
//         }
//     case ADD_DOUBT_POLL_FAILED:
//         return {
//             ...state
//         }
//     case EDIT_DOUBT_POST_SUCCEEDED:
//         const oldDoubt = state.doubts.data.filter(
//             (each: any) => each.id !== action.data.data.id
//         )
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 data: [action.data.data, ...oldDoubt]
//             },
//             isUpdated: true
//         }
//     case EDIT_DOUBT_POST_FAILED:
//         return {
//             ...state
//         };
//     case DELETE_DOUBT_POST_SUCCEEDED:
//         return {
//             ...state,
//             doubts: {
//                 ...state.doubts,
//                 data: state.doubts.data.filter(
//                     (each: any) => each.id !== action.data.data.id
//                 )
//             },
//             isDeleted:
//                 true
//         }
//     case DELETE_DOUBT_POST_FAILED:
//         return {
//             ...state,
//         }

//     case REPORT_DOUBT_POST_SUCCEEDED:
//         return {
//             ...state,
//             reportPost: {
//                 ...state.reportPost,
//                 data: [action.data.data, ...state.reportPost.data],
//                 doubtId: action.data.data.doubtId,
//                 isReported: true
//             }
//         }
//     case REPORT_DOUBT_POST_FAILED:
//         return {
//             ...state
//         }
//     // Comments
//     case GET_DOUBT_COMMENT:
//         return {
//             ...state,
//             comment: {
//                 ...state.comment,
//                 loading: true,
//             }
//         }
//     case GET_DOUBT_COMMENT_SUCCEEDED:
//         // console.log(state.comment.data)
//         const getDoubtCommentArray: any = [...state.doubts.data]
//         if (action.data?.data?.length > 0) {
//             const Doubtindex = state.doubts.data.findIndex(
//                 (each: any) => each.id === action.data?.data[0]?.doubt
//             )
//             getDoubtCommentArray[Doubtindex]['comments'] = action.data
//         }
//         return {
//             ...state,
//             comment: {
//                 ...state.comment,
//                 data: action.data,
//                 loading: false
//             },
//             doubts: {
//                 ...state.doubts,
//                 data: getDoubtCommentArray,
//                 loading: false
//             },
//         }
//     case GET_DOUBT_COMMENT_FAILED:
//         return {
//             ...state,
//             comment: {
//                 ...state.comment,
//                 loading: false
//             }
//         }
//     case GET_DOUBT_COMMENT_FOR_DETAIL_PAGE:
//         return {
//             ...state,
//             detailPageComment: {
//                 ...state.detailPageComment,
//                 detailPageCommentLoading: true
//             }
//         }
//     case GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_SUCCEEDED:
//         // console.log(action.data)
//         return {
//             ...state,
//             detailPageComment: action.data,
//             detailPageCommentLoading: false
//         }
//     case GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_FAILED:
//         return {
//             ...state,
//             detailPageComment: {
//                 ...state.detailPageComment,
//                 detailPageCommentLoading: false
//             }
