import {
  GET_MODEL_TEST_SECTIONS_SUCCEEDED,
  GET_MODEL_TEST_SECTIONS_FAILED,
  ADD_MODEL_TEST_SECTION_SUCCEEDED,
  ADD_MODEL_TEST_SECTION_FAILED,
  DELETE_MODEL_TEST_SECTION_SUCCEEDED,
  DELETE_MODEL_TEST_SECTION_FAILED,
  EDIT_MODEL_TEST_SECTION_SUCCEEDED,
  EDIT_MODEL_TEST_SECTION_FAILED,
  GET_MODEL_SECTION_BY_ID_SUCCEEDED,
  GET_MODEL_SECTION_BY_ID_FAILED,
  GET_MODEL_SECTION_BY_ID,
  GET_MODEL_TEST_SECTIONS,
  ADD_MODEL_TEST_SECTION,
  SET_MODEL_TEST_SECTION_DATA,
} from '../../../src/actions';
import { ISections } from '../../interfaces/model_tests/sections';
import { getLocalStore } from 'next-persist';

const initialState: ISections = {
  sections: {
    data: [],
  },
  sectionById: {},
  loading: null,
  addModelSectionStatus: '',
};
const persistedState = getLocalStore('modelTestSections', initialState);

const sections = (state = persistedState, action) => {
  switch (action.type) {
    case GET_MODEL_TEST_SECTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_MODEL_TEST_SECTIONS_SUCCEEDED:
      return {
        ...state,
        sections: action.data,
        loading: false,
      };

    case GET_MODEL_TEST_SECTIONS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_MODEL_SECTION_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_MODEL_SECTION_BY_ID_SUCCEEDED:
      return {
        ...state,
        sectionById: action.data,
        loading: false,
      };
    case GET_MODEL_SECTION_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
      };
    case ADD_MODEL_TEST_SECTION:
      return {
        ...state,
        addModelSectionStatus: 'request',
      };
    case ADD_MODEL_TEST_SECTION_SUCCEEDED:
      return {
        ...state,
        sections: {
          ...state.sections,
          data: [action.data.data, ...state.sections.data],
        },
        addModelSectionStatus: 'success',
      };

    case ADD_MODEL_TEST_SECTION_FAILED:
      return {
        ...state,
        addModelSectionStatus: 'failure',
      };
    case EDIT_MODEL_TEST_SECTION_SUCCEEDED:
      return {
        ...state,
        sections: {
          ...state.sections,
          data: state.sections.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_MODEL_TEST_SECTION_FAILED:
      return {
        ...state,
      };
    case DELETE_MODEL_TEST_SECTION_SUCCEEDED:
      return {
        ...state,
        sections: {
          ...state.sections,
          data: state.sections.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_MODEL_TEST_SECTION_FAILED:
      return {
        ...state,
      };
    case SET_MODEL_TEST_SECTION_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default sections;
