import {
  GET_ANSWERS_SUCCEEDED,
  GET_ANSWERS_FAILED,
  ADD_ANSWER_SUCCEEDED,
  ADD_ANSWER_FAILED,
  DELETE_ANSWER_SUCCEEDED,
  DELETE_ANSWER_FAILED,
  EDIT_ANSWER_SUCCEEDED,
  EDIT_ANSWER_FAILED,
  ADD_ANSWER,
  EDIT_ANSWER,
  DELETE_ANSWER,
  GET_ANSWERS,
} from '../../../src/actions';
import { IAnswers } from '../../interfaces/model_tests/answers';
import { getLocalStore } from 'next-persist';

const initialState: IAnswers = {
  answers: {
    data: [],
  },
  saveAnswerStatus: '',
  deleteAnswerStatus: '',
  getAnswerStatus: '',
};
const persistedState = getLocalStore('answers', initialState);

const sections = (state = persistedState, action) => {
  switch (action.type) {
    case GET_ANSWERS:
      return {
        ...state,
        getAnswerStatus: 'request',
      };
    case GET_ANSWERS_SUCCEEDED:
      return {
        ...state,
        answers: action.data,
        getAnswerStatus: 'success',
      };

    case GET_ANSWERS_FAILED:
      return {
        ...state,
        getAnswerStatus: 'failure',
      };
    case ADD_ANSWER:
      return {
        ...state,
        saveAnswerStatus: 'request',
      };
    case ADD_ANSWER_SUCCEEDED:
      return {
        ...state,
        answers: {
          ...state.answers,
          data: [action.data.data, ...state.answers.data],
        },
        saveAnswerStatus: 'success',
      };

    case ADD_ANSWER_FAILED:
      return {
        ...state,
        saveAnswerStatus: 'failure',
      };
    case EDIT_ANSWER:
      return {
        ...state,
        saveAnswerStatus: 'request',
      };
    case EDIT_ANSWER_SUCCEEDED:
      return {
        ...state,
        answers: {
          ...state.answers,
          data: state.answers.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        saveAnswerStatus: 'success',
      };
    case EDIT_ANSWER_FAILED:
      return {
        ...state,
        saveAnswerStatus: 'failure',
      };
    case DELETE_ANSWER:
      return {
        ...state,
        deleteAnswerStatus: 'request',
      };
    case DELETE_ANSWER_SUCCEEDED:
      return {
        ...state,
        answers: {
          ...state.answers,
          data: state.answers.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        deleteAnswerStatus: 'success',
      };

    case DELETE_ANSWER_FAILED:
      return {
        ...state,
        deleteAnswerStatus: 'failure',
      };
    default:
      return state;
  }
};

export default sections;
