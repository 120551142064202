import {
  GET_COURSES,
  GET_COURSES_SUCCEEDED,
  GET_COURSES_FAILED,
  ADD_COURSE_SUCCEEDED,
  ADD_COURSE_FAILED,
  DELETE_COURSE_SUCCEEDED,
  DELETE_COURSE_FAILED,
  EDIT_COURSE_SUCCEEDED,
  EDIT_COURSE_FAILED,
  GET_COURSE_LIST,
  GET_COURSE_LIST_SUCCEEDED,
  GET_COURSE_LIST_FAILED,
  GET_COURSE_CATEGORY_LIST,
  GET_COURSE_CATEGORY_LIST_SUCCEEDED,
  GET_COURSE_CATEGORY_LIST_FAILED,
  GET_COURSE_DETAIL,
  GET_COURSE_DETAIL_SUCCEEDED,
  GET_COURSE_DETAIL_FAILED,
  ADD_COURSE_ENROL_CONTENT_SUCCEEDED,
  ADD_COURSE_ENROL_CONTENT_FAILED,
  GET_COURSE_ENROL_DETAIL,
  GET_COURSE_ENROL_DETAIL_SUCCEEDED,
  GET_COURSE_ENROL_DETAIL_FAILED,
  GET_COURSE_VIDEO_SUCCEEDED,
  GET_COURSE_VIDEO_FAILED,
  ADD_COURSE_ENROLL_SUCCEEDED,
  ADD_COURSE_ENROLL_FAILED,
  GET_COURSE_ENROLL_SUCCEEDED,
  GET_COURSE_ENROLL_FAILED,
  GET_NOTES_SUCCEEDED,
  GET_NOTES_FAILED,
  ADD_NOTES_SUCCEEDED,
  ADD_NOTES_FAILED,
  EDIT_NOTES_FAILED,
  EDIT_NOTES_SUCCEEDED,
  DELETE_NOTES_SUCCEEDED,
  DELETE_NOTES_FAILED,
  GET_NOTES,
  GET_QUESTION_DOUBTS_SUCCEEDED,
  GET_QUESTION_DOUBTS_FAILED,
  GET_QUESTION_DOUBTS,
  SET_COURSE_DATA,
  SET_COURSE_ERROR_DATA,
  SET_COURSE_EDIT_DATA,
  GET_COURSE_ENROLL,
  GET_COURSE_REVIEW,
  GET_COURSE_REVIEW_SUCCEEDED,
  GET_COURSE_REVIEW_FAILED,
  GET_COURSE_REVIEW_BY_PAGE,
  GET_COURSE_REVIEW_BY_PAGE_SUCCEEDED,
  GET_COURSE_REVIEW_BY_PAGE_FAILED,
  ADD_COURSE_REVIEW,
  ADD_COURSE_REVIEW_SUCCEEDED,
  ADD_COURSE_REVIEW_FAILED,
  EDIT_COURSE_REVIEW,
  EDIT_COURSE_REVIEW_SUCCEEDED,
  EDIT_COURSE_REVIEW_FAILED,
  DELETE_COURSE_REVIEW,
  DELETE_COURSE_REVIEW_SUCCEEDED,
  DELETE_COURSE_REVIEW_FAILED,
  GET_COURSE_VIDEO,
  GET_REQUESTED_COURSE_INQUIRY,
  GET_REQUESTED_COURSE_INQUIRY_SUCCEEDED,
  GET_REQUESTED_COURSE_INQUIRY_FAILED,
  GET_RELATED_COURSE,
  GET_RELATED_COURSE_SUCCEEDED,
  GET_RELATED_COURSE_FAILED,
  GET_COURSE_MATERIAL,
  GET_COURSE_MATERIAL_FAILED,
  GET_COURSE_MATERIAL_SUCCEEDED,
  LIKE_COURSE_REVIEW,
  LIKE_COURSE_REVIEW_SUCCEEDED,
  LIKE_COURSE_REVIEW_FAILED,
  DISLIKE_COURSE_REVIEW,
  DISLIKE_COURSE_REVIEW_SUCCEEDED,
  DISLIKE_COURSE_REVIEW_FAILED,
  GET_COURSE_CLIENT,
  GET_COURSE_CLIENT_SUCCEEDED,
  GET_COURSE_CLIENT_FAILED,
  GET_MOCK_TEST_CLIENT,
  GET_MOCK_TEST_CLIENT_SUCCEEDED,
  GET_MOCK_TEST_CLIENT_FAILED,
  GET_COURSE_BY_SLUG,
  GET_COURSE_BY_SLUG_SUCCEEDED,
  GET_COURSE_BY_SLUG_FAILED,
  GET_COURSE_DOUBTS,
  GET_COURSE_DOUBTS_SUCCEEDED,
  GET_COURSE_DOUBTS_FAILED,
  GET_COURSE_DOUBT_COMMENTS,
  GET_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  GET_COURSE_DOUBT_COMMENTS_FAILED,
  GET_COURSE_CLIENTS,
  GET_COURSE_CLIENTS_SUCCEEDED,
  GET_COURSE_CLIENTS_FAILED,
  GET_COURSE_FEEDBACKS,
  GET_COURSE_FEEDBACKS_SUCCEEDED,
  GET_COURSE_FEEDBACKS_FAILED,
  ADD_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  ADD_COURSE_DOUBT_COMMENTS_FAILED,
  GET_COURSE_LIVE_CLASS,
  GET_COURSE_LIVE_CLASS_SUCCEEDED,
  GET_COURSE_LIVE_CLASS_FAILED,
  ADD_COURSE_LIVE_CLASS_SUCCEEDED,
  ADD_COURSE_LIVE_CLASS_FAILED,
  EDIT_COURSE_LIVE_CLASS_SUCCEEDED,
  EDIT_COURSE_LIVE_CLASS_FAILED,
  DELETE_COURSE_LIVE_CLASS_SUCCEEDED,
  DELETE_COURSE_LIVE_CLASS_FAILED,
  EDIT_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  EDIT_COURSE_DOUBT_COMMENTS_FAILED,
  DELETE_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  DELETE_COURSE_DOUBT_COMMENTS_FAILED,
  LIKE_COURSE_QUESTION,
  LIKE_COURSE_QUESTION_SUCCEEDED,
  LIKE_COURSE_QUESTION_FAILED,
  ADD_QUESTION_DOUBTS,
  ADD_QUESTION_DOUBTS_SUCCEEDED,
  ADD_QUESTION_DOUBTS_FAILED,
  GET_QUESTION_DOUBTS_DETAIL,
  GET_QUESTION_DOUBTS_DETAIL_SUCCEEDED,
  GET_QUESTION_DOUBTS_DETAIL_FAILED,
  GET_QUESTION_DOUBT_COMMENT,
  GET_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  GET_QUESTION_DOUBT_COMMENT_FAILED,
  ADD_QUESTION_DOUBT_COMMENT,
  ADD_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  ADD_QUESTION_DOUBT_COMMENT_FAILED,
  CLEAR_COURSE_ERROR,
  GET_QUESTION_DOUBTS_BY_PAGE,
  GET_QUESTION_DOUBTS_BY_PAGE_SUCCEEDED,
  GET_QUESTION_DOUBTS_BY_PAGE_FAILED,
  GET_QUESTION_DOUBT_COMMENT_BY_PAGE,
  GET_QUESTION_DOUBT_COMMENT_BY_PAGE_SUCCEEDED,
  GET_QUESTION_DOUBT_COMMENT_BY_PAGE_FAILED,
  EDIT_QUESTION_DOUBT_COMMENT,
  EDIT_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  EDIT_QUESTION_DOUBT_COMMENT_FAILED,
  DELETE_QUESTION_DOUBT_COMMENT,
  DELETE_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  DELETE_QUESTION_DOUBT_COMMENT_FAILED,
  LIKE_QUESTION_DOUBT_COMMENT,
  LIKE_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  LIKE_QUESTION_DOUBT_COMMENT_FAILED,
  EDIT_QUESTION_DOUBTS,
  EDIT_QUESTION_DOUBTS_SUCCEEDED,
  EDIT_QUESTION_DOUBTS_FAILED,
  DELETE_QUESTION_DOUBTS,
  DELETE_QUESTION_DOUBTS_SUCCEEDED,
  DELETE_QUESTION_DOUBTS_FAILED,
  CLEAR_COURSE_DATA,
  EDIT_COURSE,
  ADD_COURSE_CLIENTS,
  ADD_COURSE_CLIENTS_SUCCEEDED,
  ADD_COURSE_CLIENTS_FAILED,
  EDIT_COURSE_CLIENTS,
  EDIT_COURSE_CLIENTS_SUCCEEDED,
  EDIT_COURSE_CLIENTS_FAILED,
  SET_COURSE_ENROLL_EDIT_DATA,
  SET_COURSE_QUERY_DATA,
  CLEAR_COURSE_QUERY,
  GET_COURSE_MATERIAL_BY_ID,
  GET_COURSE_MATERIAL_BY_ID_SUCCEEDED,
  GET_COURSE_MATERIAL_BY_ID_FAILED,
  GET_COURSE_FEEDBACK_BY_ID,
  GET_COURSE_FEEDBACK_BY_ID_SUCCEEDED,
  GET_COURSE_FEEDBACK_BY_ID_FAILED,
  GET_COURSES_BY_PAGE,
  GET_COURSES_BY_PAGE_SUCCEEDED,
  GET_COURSES_BY_PAGE_FAILED,
  ADD_COURSE_TO_WISHLIST,
  ADD_COURSE_TO_WISHLIST_SUCCEEDED,
  ADD_COURSE_TO_WISHLIST_FAILED,
  EDIT_REQUESTED_COURSE_INQUIRY_STATUS,
  EDIT_REQUESTED_COURSE_INQUIRY_STATUS_SUCCEEDED,
  EDIT_REQUESTED_COURSE_INQUIRY_STATUS_FAILED,
  GET_COURSE_COMMENTS_REQUEST,
  GET_COURSE_COMMENTS_SUCCEEDED,
  GET_COURSE_COMMENTS_FAILED,
  ADD_COURSE_COMMENTS_REQUEST,
  ADD_COURSE_COMMENTS_SUCCEEDED,
  ADD_COURSE_COMMENTS_FAILED,
  EDIT_COURSE_COMMENTS_REQUEST,
  EDIT_COURSE_COMMENTS_SUCCEEDED,
  EDIT_COURSE_COMMENTS_FAILED,
  DELETE_COURSE_COMMENTS_REQUEST,
  DELETE_COURSE_COMMENTS_SUCCEEDED,
  UPDATE_COURSE_ENROL_DETAIL,
  UPDATE_COURSE_ENROL_DETAIL_SUCCEEDED,
  UPDATE_COURSE_ENROL_DETAIL_FAILED,
  GET_COURSE_FREE_TRIALS,
  GET_COURSE_FREE_TRIALS_SUCCEEDED,
  GET_COURSE_FREE_TRIALS_FAILED,
  ADD_COURSE_LIVE_CLASS,
  EDIT_COURSE_LIVE_CLASS,
} from '../../src/actions';
import { DELETE_CONTACT_COMMENTS_FAILED } from '@/actions/contact/types';
import { ICourses } from '../interfaces/courses';
// const initialState = fromJS({});
import { getLocalStore } from 'next-persist';

const initialState: ICourses = {
  courses: {
    data: [],
    meta: {},
  },
  courseLoading: null,
  course: {
    loading: null,
    data: [],
  },
  courseDetail: {
    loading: null,
    data: [],
  },
  courseEnrolDetail: {
    loading: null,
    data: {},
    updateCourseEnrollDetailProgress: '',
  },
  courseEnroll: {
    data: [],
    loading: false,
  },
  questionDetail: {},
  courseReviewStatus: '',
  relatedCourse: {
    data: [],
    loading: false,
  },
  courseCategory: {
    data: [],
  },
  courseReview: {
    data: [],
    loading: false,
  },
  courseReviewDetail: {
    data: [],
  },
  courseInquiryComments: {
    data: {},
    loading: {
      getComment: false,
      addComment: false,
      editComment: false,
      deleteComment: false,
    },
    error: {
      getComment: {},
      addComment: {},
      editComment: {},
      deleteComment: {},
    },
  },
  currentAddedReview: '',
  video: '',
  videoLoading: false,
  enroll: '',
  notes: {
    data: [],
    loading: false,
    isDeleted: false,
    isUpdated: false,
  },
  questions: {
    data: [],
    loading: false,
    meta: {},
    moreLoading: false,
  },
  questionComment: {
    data: [],
    loading: false,
    meta: {},
    moreLoading: false,
  },
  courseEnquiry: {
    data: [],
    loading: false,
  },
  helperObj: {
    addCourse: false,
    editCourse: false,
    addCourseStatus: '',
    editCourseStatus: '',
    addLiveClass: false,
    editLiveClass: false,
    addLiveClassStatus: '',
    editLiveClassStatus: '',
    courseReviewAddStatus: '',
  },
  addCourse: {},
  editCourse: {},
  error: {
    addCourse: {},
    editCourse: {},
    addLiveClass: {},
    editLiveClass: {},
    courseQuestions: {},
    courseQuestionComment: {},
    addCourseClient: {},
  },
  courseMaterial: {
    data: [],
    loading: false,
  },
  courseMaterialById: {
    data: [],
    loading: false,
  },
  courseClient: {
    data: [],
    loading: false,
  },
  courseFreeTrial: {
    data: [],
    loading: false,
    error: {},
  },
  mockTestClient: {
    data: [],
    loading: false,
  },
  courseBySlug: {},
  courseBySlugLoading: false,
  courseDoubts: { data: [] },
  courseDoubtComments: { data: [] },
  courseClients: { data: [] },
  courseFeedbacks: { data: [] },
  courseDoubtsLoading: false,
  courseDoubtCommentsLoading: false,
  courseClientsLoading: false,
  courseFeedbacksLoading: false,
  liveClasses: { data: [] },
  liveClassLoading: false,
  addLiveClass: {},
  editLiveClass: {},
  questionCommentStatus: '',
  questionCommentAddStatus: '',
  updateCommentStatus: '',
  deleteCommentStatus: '',
  likeCommentStatus: '',
  deleteQuestionStatus: '',
  updateQuestionStatus: '',
  addClientStatus: '',
  editClientStatus: '',
  getVideoStatus: '',
  activeSubjectId: null,
  query: {
    courseQuestion: {
      page: 1,
      per_page: 10,
      text: '',
      course: '',
      subject: '',
    },
    courses: {
      page: 1,
      per_page: 12,
    },
  },
  addDoubtPostStatus: '',
  getCourseFeedbackDetailStatus: '',
  getMoreCoursesStatus: '',
  addCourseWishlistStatus: '',
};
const persistedState = getLocalStore('courses', initialState);

const courses = (state = persistedState, action) => {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: true,
        },
        courseLoading: true,
      };
    case GET_COURSES_SUCCEEDED:
      return {
        ...state,
        courses: action.data,
        courseLoading: false,
      };

    case GET_COURSES_FAILED:
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: false,
        },
        courseLoading: false,
      };
    case GET_COURSES_BY_PAGE:
      return {
        ...state,
        getMoreCoursesStatus: 'request',
      };
    case GET_COURSES_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: [...state.courses.data, ...action.data.data],
          meta: action.data.meta,
        },
        course: {
          ...state.course,
          data: [...state.course.data, ...action.data.data],
          meta: action.data.meta,
          summary: action.data.summary,
        },

        getMoreCoursesStatus: 'success',
      };

    case GET_COURSES_BY_PAGE_FAILED:
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: false,
        },
        getMoreCoursesStatus: 'failure',
      };
    case GET_COURSE_BY_SLUG:
      return {
        ...state,
        courseBySlugLoading: true,
      };
    case GET_COURSE_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        courseBySlug: action.data,
        courseBySlugLoading: false,
      };
    case GET_COURSE_BY_SLUG_FAILED:
      return {
        ...state,
        courseBySlugLoading: false,
      };

    case ADD_COURSE_SUCCEEDED:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: [action.data.data, ...state.courses.data],
        },
        helperObj: {
          ...state.helperObj,
          addCourse: false,
          addCourseStatus: 'success',
        },
        addCourse: {},
        error: { ...state.error, addCourse: {} },
      };

    case ADD_COURSE_FAILED:
      return {
        ...state,
        error: { ...state.error, addCourse: action.error },
        helperObj: { ...state.helperObj, addCourseStatus: 'failure' },
      };
    case EDIT_COURSE:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          editCourseStatus: 'request',
        },
      };
    case EDIT_COURSE_SUCCEEDED:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: state.courses.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        courseBySlug: action.data.data,
        helperObj: {
          ...state.helperObj,
          editCourse: false,
          editCourseStatus: 'success',
        },
        error: { ...state.error, editCourse: {} },
      };

    case EDIT_COURSE_FAILED:
      return {
        ...state,
        error: { ...state.error, editCourse: action.error },
        helperObj: { ...state.helperObj, editCourseStatus: 'failure' },
      };
    case DELETE_COURSE_SUCCEEDED:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: state.courses.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_COURSE_FAILED:
      return {
        ...state,
      };

    case GET_COURSE_LIST:
      return {
        ...state,
        course: { ...state.course, loading: true },
      };

    case GET_COURSE_LIST_SUCCEEDED:
      return {
        ...state,
        course: { loading: false, ...action.data },
      };

    case GET_COURSE_LIST_FAILED:
      return {
        ...state,
      };

    case GET_COURSE_CATEGORY_LIST:
      return {
        ...state,
        courseCategory: { ...state.course, loading: true },
      };

    case GET_COURSE_CATEGORY_LIST_SUCCEEDED:
      return {
        ...state,
        courseCategory: { loading: false, data: action.data.data },
      };

    case GET_COURSE_CATEGORY_LIST_FAILED:
      return {
        ...state,
      };

    case GET_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: { ...state.courseDetail, loading: true },
      };

    case GET_COURSE_DETAIL_SUCCEEDED:
      return {
        ...state,
        courseDetail: { loading: false, data: action.data },
      };

    case GET_COURSE_DETAIL_FAILED:
      return {
        ...state,
        courseDetail: { ...state.courseDetail, loading: false },
      };
    case ADD_COURSE_TO_WISHLIST:
      return {
        ...state,
        addCourseWishlistStatus: 'request',
      };
    case ADD_COURSE_TO_WISHLIST_SUCCEEDED:
      return {
        ...state,
        addCourseWishlistStatus: 'success',
        courseDetail: {
          ...state.courseDetail,
          data: { ...state.courseDetail.data, wishlist: action.data.data.wishlist },
        },
        course: {
          ...state.course,
          data: state.course.data.map((each: any) =>
            each.slug === action.data.data.slug
              ? { ...each, wishlist: action.data.data.wishlist }
              : each
          ),
        },
      };
    case ADD_COURSE_TO_WISHLIST_FAILED:
      return {
        ...state,
        addCourseWishlistStatus: 'failure',
      };
    case GET_COURSE_ENROL_DETAIL:
      return {
        ...state,
        courseEnrolDetail: { ...state.courseDetail, loading: true },
      };

    case GET_COURSE_ENROL_DETAIL_SUCCEEDED:
      return {
        ...state,
        courseEnrolDetail: {
          loading: false,
          data: action.data,
        },
      };

    case GET_COURSE_ENROL_DETAIL_FAILED:
      return {
        ...state,
      };

    case UPDATE_COURSE_ENROL_DETAIL:
      return {
        ...state,
        courseEnrolDetail: { ...state.courseDetail, loading: true },
      };

    case UPDATE_COURSE_ENROL_DETAIL_SUCCEEDED:
      return {
        ...state,
        courseEnrolDetail: {
          loading: false,
          data: {
            ...state.courseEnrolDetail.data,
            completed_on: action.data.data.completed_on,
            is_complete: action.data.data.is_complete,
          },
        },
      };

    case UPDATE_COURSE_ENROL_DETAIL_FAILED:
      return {
        ...state,
      };

    // Course Material

    case GET_COURSE_MATERIAL:
      return {
        ...state,
        courseMaterial: {
          ...state.courseMaterial,
          loading: true,
        },
      };
    case GET_COURSE_MATERIAL_SUCCEEDED:
      return {
        ...state,
        courseMaterial: { loading: false, data: action.data },
      };

    case GET_COURSE_MATERIAL_FAILED:
      return {
        ...state,
      };

    case GET_COURSE_MATERIAL_BY_ID:
      return {
        ...state,
        courseMaterialById: {
          ...state.courseMaterialById,
          loading: true,
        },
      };
    case GET_COURSE_MATERIAL_BY_ID_SUCCEEDED:
      return {
        ...state,
        courseMaterialById: { loading: false, data: action.data },
      };

    case GET_COURSE_MATERIAL_BY_ID_FAILED:
      return {
        ...state,
      };

    case ADD_COURSE_ENROL_CONTENT_SUCCEEDED:
      const subFindIndex = state.courseEnrolDetail?.data?.subject?.findIndex(
        (sub) => sub?.id === action.data?.data?.subject
      );
      const chapterFindIndex = state.courseEnrolDetail?.data?.subject[
        subFindIndex
      ].chapter?.findIndex((each) => each.id === action.data?.data?.chapter);
      const contentFindIndex = state.courseEnrolDetail.data.subject[
        subFindIndex
      ].chapter[chapterFindIndex]?.content?.findIndex(
        (each) => each?.id === action.data?.data?.content
      );

      return {
        ...state,
        // courseEnrolDetail: {
        //   ...state.courseEnrolDetail,
        //   data: {
        //     ...state.courseEnrolDetail?.data,
        //     total_content_complete: !state.courseEnrolDetail.data.subject[
        //       subFindIndex
        //     ].chapter[chapterFindIndex].content[contentFindIndex].is_complete
        //       ? state.courseEnrolDetail.data.total_content_complete + 1
        //       : state.courseEnrolDetail.data.total_content_complete,
        //   },
        // },
        courseEnrolDetail: {
          ...state.courseEnrolDetail,
          updateCourseEnrollDetailProgress: 'success',
          data: {
            ...state.courseEnrolDetail?.data,
            total_content_complete: !state.courseEnrolDetail.data.subject[
              subFindIndex
            ].chapter[chapterFindIndex].content[contentFindIndex].is_complete
              ? state.courseEnrolDetail.data.total_content_complete + 1
              : state.courseEnrolDetail.data.total_content_complete,
          },
        },
      };

    case GET_COURSE_VIDEO:
      return {
        ...state,
        videoLoading: true,
        getVideoStatus: 'request',
      };

    case GET_COURSE_VIDEO_SUCCEEDED:
      return {
        ...state,
        video: action.data,
        videoLoading: false,
        getVideoStatus: 'success',
      };

    case GET_COURSE_VIDEO_FAILED:
      return {
        ...state,
        videoLoading: false,
        getVideoStatus: 'failure',
      };

    case ADD_COURSE_ENROLL_SUCCEEDED:
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          data: { ...state.courseDetail.data, is_enroll: true },
        },
      };

    case ADD_COURSE_ENROLL_FAILED:
      return {
        ...state,
      };

    case GET_COURSE_ENROLL:
      return {
        ...state,
        courseEnroll: {
          ...state.courseEnroll,
          loading: true,
          // isLinkCopied:false
        },
      };
    case GET_COURSE_ENROLL_SUCCEEDED:
      return {
        ...state,
        courseEnroll: {
          data: action.data,
          loading: false,
        },
      };

    case GET_COURSE_ENROLL_FAILED:
      return {
        ...state,
        courseEnroll: {
          ...state.courseEnroll,
          loading: false,
        },
      };
    // Related Course
    case GET_RELATED_COURSE:
      return {
        ...state,
        relatedCourse: {
          ...state.relatedCourse,
          loading: true,
        },
      };
    case GET_RELATED_COURSE_SUCCEEDED:
      return {
        ...state,
        relatedCourse: action.data,
      };
    case GET_RELATED_COURSE_FAILED:
      return {
        ...state,
      };

    // Notes reducer
    case GET_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };
    case GET_NOTES_SUCCEEDED:
      return {
        ...state,
        notes: action.data,
      };
    case GET_NOTES_FAILED:
      return {
        ...state,
      };
    case ADD_NOTES_SUCCEEDED:
      return {
        ...state,
        notes: {
          ...state.notes,
          data: [action.data.data, ...state.notes.data],
        },
      };
    case ADD_NOTES_FAILED:
      return {
        ...state,
      };
    case EDIT_NOTES_SUCCEEDED:
      return {
        ...state,
        notes: {
          ...state.notes,
          data: state.notes.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };
    case EDIT_NOTES_FAILED:
      return {
        ...state,
      };
    case DELETE_NOTES_SUCCEEDED:
      const noteToDelete = state.notes.data.filter(
        (each: any) => each.id != action.data.data.id
      );
      return {
        ...state,
        notes: {
          ...state.notes,
          data: noteToDelete,
        },
      };
    case DELETE_NOTES_FAILED:
      return {
        ...state,
      };
    case GET_QUESTION_DOUBTS:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: true,
        },
      };
    case GET_QUESTION_DOUBTS_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: action.data.data,
          meta: action.data.meta,
          loading: false,
        },
      };
    case GET_QUESTION_DOUBTS_FAILED:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: false,
        },
      };
    case GET_QUESTION_DOUBTS_BY_PAGE:
      return {
        ...state,
        questions: {
          ...state.questions,
          moreLoading: true,
        },
      };
    case GET_QUESTION_DOUBTS_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          moreLoading: false,
          data: [...state.questions.data, ...action.data.data],
          meta: action.data.meta,
        },
      };
    case GET_QUESTION_DOUBTS_BY_PAGE_FAILED:
      return {
        ...state,
        questions: {
          ...state.questions,
          moreLoading: false,
        },
        error: {
          ...state.error,
          courseQuestions: action.error,
        },
      };
    // Question Comment
    case GET_QUESTION_DOUBT_COMMENT:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          loading: true,
        },
      };
    case GET_QUESTION_DOUBT_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          data: action.data.data,
          meta: action.data.meta,
          loading: false,
        },
      };
    case GET_QUESTION_DOUBT_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          loading: false,
        },
      };

    case GET_QUESTION_DOUBT_COMMENT_BY_PAGE:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          moreLoading: true,
        },
      };
    case GET_QUESTION_DOUBT_COMMENT_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          moreLoading: false,
          data: [...state.questions.data, ...action.data.data],
          meta: action.data.meta,
        },
      };
    case GET_QUESTION_DOUBT_COMMENT_BY_PAGE_FAILED:
      return {
        ...state,
        moreQuestionCommentStatus: 'failure',
        questionComment: {
          ...state.questionComment,
          loading: false,
        },
        error: {
          ...state.error,
          courseQuestionComment: action.error,
        },
      };
    case ADD_QUESTION_DOUBT_COMMENT:
      return {
        ...state,
        questionCommentAddStatus: 'request',
        questionComment: {
          ...state.questionComment,
          status: false,
        },
      };
    case ADD_QUESTION_DOUBT_COMMENT_SUCCEEDED:
      var newComments = [action.data.data, ...state.questionComment.data];
      return {
        ...state,
        questionCommentAddStatus: 'success',
        questionComment: {
          ...state.questionComment,
          data: newComments,
        },
        questionDetail: {
          ...state.questionDetail,
          data: {
            ...state.questionDetail.data,
            no_comment: newComments.length,
          },
        },
      };
    case ADD_QUESTION_DOUBT_COMMENT_FAILED:
      return {
        ...state,
        questionCommentAddStatus: 'failed',
        questionComment: {
          ...state.questionComment,
        },
      };

    // Course Comment edit delete
    case EDIT_QUESTION_DOUBT_COMMENT:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
        },
        updateCommentStatus: 'request',
      };
    case EDIT_QUESTION_DOUBT_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          data: state.questionComment.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        updateCommentStatus: 'success',
      };
    case EDIT_QUESTION_DOUBT_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
        },
        updateCommentStatus: 'failure',
      };
    case LIKE_QUESTION_DOUBT_COMMENT:
      return {
        ...state,
        likeCommentStatus: 'request',
      };
    case LIKE_QUESTION_DOUBT_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          data: state.questionComment.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        likeCommentStatus: 'success',
      };
    case LIKE_QUESTION_DOUBT_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
        },
        likeCommentStatus: 'failure',
      };
    case DELETE_QUESTION_DOUBT_COMMENT:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
        },
        deleteCommentStatus: 'request',
      };
    case DELETE_QUESTION_DOUBT_COMMENT_SUCCEEDED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
          data: state.questionComment.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        deleteCommentStatus: 'success',
      };
    case DELETE_QUESTION_DOUBT_COMMENT_FAILED:
      return {
        ...state,
        questionComment: {
          ...state.questionComment,
        },
        deleteCommentStatus: 'failure',
      };

    // ends
    case ADD_QUESTION_DOUBTS:
      return {
        ...state,
        questions: {
          ...state.questions,
          status: false,
        },
        addDoubtPostStatus: 'request',
      };
    case ADD_QUESTION_DOUBTS_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: [action.data.data, ...state.questions.data],
          status: true,
        },
        addDoubtPostStatus: 'success',
      };
    case ADD_QUESTION_DOUBTS_FAILED:
      return {
        ...state,
        questions: {
          ...state.questions,
          status: false,
        },
        addDoubtPostStatus: 'failure',
      };
    // Course question edit delte
    case EDIT_QUESTION_DOUBTS:
      return {
        ...state,
        questions: {
          ...state.questions,
        },
        updateQuestionStatus: 'request',
      };
    case EDIT_QUESTION_DOUBTS_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        questionDetail: action.data.data,
        updateQuestionStatus: 'success',
      };
    case EDIT_QUESTION_DOUBTS_FAILED:
      return {
        ...state,
        questions: {
          ...state.questions,
        },
        updateQuestionStatus: 'failure',
      };
    case DELETE_QUESTION_DOUBTS:
      return {
        ...state,
        questions: {
          ...state.questions,
        },
        deleteQuestionStatus: 'request',
      };
    case DELETE_QUESTION_DOUBTS_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
        deleteQuestionStatus: 'success',
      };
    case DELETE_QUESTION_DOUBTS_FAILED:
      return {
        ...state,
        questions: {
          ...state.questions,
        },
        deleteQuestionStatus: 'failure',
      };
    // End
    case LIKE_COURSE_QUESTION:
      return {
        ...state,
        likeDoubtPost: 'request',
        questions: {
          ...state.questions,
          liking: true,
        },
      };
    case LIKE_COURSE_QUESTION_SUCCEEDED:
      var index1 = state.questions.data.findIndex(
        (each: any) => each.id === action.data.data.id
      );

      const newArray1: any = [...state.questions.data];
      if (newArray1[index1].like) {
        newArray1[index1].like = false;
        newArray1[index1].no_of_likes = newArray1[index1].no_of_likes - 1;
      } else {
        newArray1[index1].like = true;
        newArray1[index1].no_of_likes = newArray1[index1].no_of_likes + 1;
      }
      return {
        ...state,
        likeDoubtPost: 'success',
        questions: {
          ...state.questions,
          data: newArray1,
        },
        questionDetail: {
          ...state.questionDetail,
          data: {
            ...state.questionDetail.data,
            no_of_likes: action.data.data.no_of_likes,
            like: action.data.data.like,
          },
        },
      };
    case LIKE_COURSE_QUESTION_FAILED:
      return {
        ...state,
        likeDoubtPost: 'failure',
        questions: {
          ...state.questions,
        },
      };
    case SET_COURSE_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_COURSE_ENROLL_EDIT_DATA:
      return {
        ...state,
        courseEnrolDetail: {
          ...state.courseEnrolDetail,
          data: {
            ...state.courseEnrolDetail.data,
            [action.payload.data.name]: {
              ...state.courseEnrolDetail.data[action.payload.data.name],
              [action.payload.data.key]: action.payload.data.value,
            },
          },
        },
      };
    case SET_COURSE_EDIT_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SET_COURSE_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    // Course Review
    case GET_COURSE_REVIEW:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          loading: true,
          moreLoading: false,
        },
      };
    case GET_COURSE_REVIEW_SUCCEEDED:
      return {
        ...state,
        courseReview: action.data,
      };
    case GET_COURSE_REVIEW_FAILED:
      return {
        ...state,
      };
    case GET_COURSE_REVIEW_BY_PAGE:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          moreLoading: true,
        },
      };

    case GET_COURSE_REVIEW_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          data: [...state.courseReview.data, ...action.data.data],
          link: action.data.link,
          meta: action.data.meta,
          moreLoading: false,
        },
      };
    case GET_COURSE_REVIEW_BY_PAGE_FAILED:
      return {
        ...state,
      };
    case ADD_COURSE_REVIEW:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          courseReviewAddStatus: 'request',
        },
        courseReview: {
          ...state.courseReview,
          loading: true,
        },
      };
    case ADD_COURSE_REVIEW_SUCCEEDED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          courseReviewAddStatus: 'success',
        },
        courseReview: {
          ...state.courseReview,
          data: [action.data, ...state.courseReview.data],
          // currentAddedReview: action.data.success,
          loading: false,
        },
        currentAddedReview: action.data.success,
        courseEnrolDetail: {
          ...state.courseEnrolDetail,
          data: {
            ...state.courseEnrolDetail.data,
            is_reviewed: true,
            review: {
              id: action.data.success.id,
              course_rating: action.data.success.course_rating,
              feedback: action.data.success.feedback
                ? action.data.success.feedback
                : state.courseEnrolDetail.data.review.feedback,
            },
          },
        },
      };
    case ADD_COURSE_REVIEW_FAILED:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          courseReviewAddStatus: 'failed',
        },
        courseReview: {
          ...state.courseReview,
          loading: false,
          error: action.error,
        },
      };
    case EDIT_COURSE_REVIEW:
      return {
        ...state,
        courseReviewStatus: 'request',
        courseReview: {
          ...state.courseReview,
          loading: true,
        },
      };
    case EDIT_COURSE_REVIEW_SUCCEEDED:
      // const oldReview = state.courseReview.data.filter(
      //   (each: any) => each.id !== action.data.data.id
      // );
      return {
        ...state,
        courseReviewStatus: 'success',
        courseReview: {
          ...state.courseReview,
          // data: [action.data.data, ...oldReview],
          data: [action.data.data],
          loading: false,
        },
        // courseEnroll: {
        //   ...state.courseEnroll,
        //   data: state.courseEnroll.data.map((each: any) =>
        //     each.id == action.data.data.id ? action.data.data : each
        //   ),
        // },
        courseEnrolDetail: {
          ...state.courseEnrolDetail,
          data: {
            ...state.courseEnrolDetail.data,
            review: {
              ...state?.courseEnrolDetail?.data?.review,
              course_rating: action.data.data.course_rating,
              feedback: action.data.data.feedback
                ? action.data.data.feedback
                : state.courseEnrolDetail.data.review.feedback,
            },
          },
        },
      };
    case EDIT_COURSE_REVIEW_FAILED:
      return {
        ...state,
        courseReviewStatus: 'failure',
        courseReview: {
          ...state.courseReview,
          loading: false,
        },
      };
    case DELETE_COURSE_REVIEW:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          loading: true,
        },
      };
    case DELETE_COURSE_REVIEW_SUCCEEDED:
      const filterReview = state.courseReview.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          data: filterReview,
          loading: false,
        },
      };
    case DELETE_COURSE_REVIEW_FAILED:
      return {
        ...state,
      };
    // Like and dislike course review
    case LIKE_COURSE_REVIEW:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          liking: true,
        },
      };
    case LIKE_COURSE_REVIEW_SUCCEEDED:
      let likeArray = [];
      const like = state.courseReview.data.findIndex(
        (each: any) => each.id === action.data.data.id
      );

      likeArray = [...state.courseReview.data];
      if (likeArray[like].like_count >= 0) {
        if (!action.data.data.liked && !action.data.data.disliked) {
          if (!action.data.data.liked) {
            if (likeArray[like].like_count > 0) {
              likeArray[like].like_count -= 1;
            }
          } else if (!action.data.data.disliked) {
            if (likeArray[like].dislike_count > 0) {
              likeArray[like].dislike_count -= 1;
            }
          }
        } else if (action.data.data.liked && !action.data.data.disliked) {
          if (action.data.data.liked) {
            likeArray[like].like_count += 1;
            if (likeArray[like].dislike_count > 0) {
              likeArray[like].dislike_count -= 1;
            }
          } else if (action.data.data.disliked) {
            if (likeArray[like].dislike_count > 0) {
              likeArray[like].dislike_count -= 1;
            }
          }
        } else if (action.data.data.liked) {
          likeArray[like].like_count += 1;
        } else {
          if (likeArray[like].like_count > 0) {
            likeArray[like].like_count -= 1;
          }
        }
      }
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          data: likeArray,
          liking: false,
        },
      };
    case LIKE_COURSE_REVIEW_FAILED:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          liking: false,
        },
      };

    case DISLIKE_COURSE_REVIEW:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          disliking: true,
        },
      };
    case DISLIKE_COURSE_REVIEW_SUCCEEDED:
      let newArray = [];
      const index = state.courseReview.data.findIndex(
        (each: any) => each.id === action.data.data.id
      );

      newArray = [...state.courseReview.data];
      if (newArray[index].dislike_count >= 0) {
        if (!action.data.data.liked && !action.data.data.disliked) {
          if (!action.data.data.disliked) {
            if (newArray[index].dislike_count > 0) {
              newArray[index].dislike_count -= 1;
            }
          } else if (!action.data.data.liked) {
            newArray[index].like_count -= 1;
          }
        } else if (action.data.data.disliked && !action.data.data.liked) {
          if (action.data.data.disliked) {
            newArray[index].dislike_count += 1;
            if (newArray[index].like_count > 0) {
              newArray[index].like_count -= 1;
            }
          } else if (action.data.data.liked) {
            if (newArray[index].like_count > 0) {
              newArray[index].like_count -= 1;
            }
          }
        } else if (action.data.data.disliked) {
          newArray[index].dislike_count += 1;
        } else {
          if (newArray[index].dislike_count > 0) {
            newArray[index].dislike_count -= 1;
          }
        }
      }
      // if (likeArray[like].like_count > 0) {
      //   if (!action.data.data.liked && !action.data.data.disliked) {
      //     if (!action.data.data.liked) {
      //       likeArray[like].like_count -= 1
      //     } else if (!action.data.data.disliked) {
      //       likeArray[like].dislike_count -= 1
      //     }
      //   }
      //   else if (action.data.data.liked && !action.data.data.disliked) {
      //     if (action.data.data.liked) {
      //       likeArray[like].like_count += 1
      //     } else {
      //       likeArray[like].dislike_count -= 1
      //       likeArray[like].like_count += 1
      //     }
      //   }
      //   else if (action.data.data.liked) {
      //     likeArray[like].like_count += 1
      //   } else {
      //     likeArray[like].like_count -= 1
      //   }
      // }
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          data: newArray,
          disliking: false,
        },
      };
    case DISLIKE_COURSE_REVIEW_FAILED:
      return {
        ...state,
        courseReview: {
          ...state.courseReview,
          disliking: false,
        },
      };

    // Admin Dashboard view Request Course Form
    case GET_REQUESTED_COURSE_INQUIRY:
      return {
        ...state,
        courseEnquiry: {
          ...state.courseEnquiry,
          loading: true,
        },
      };
    case GET_REQUESTED_COURSE_INQUIRY_SUCCEEDED:
      return {
        ...state,
        courseEnquiry: action.data,
      };
    case GET_REQUESTED_COURSE_INQUIRY_FAILED:
      return {
        ...state,
      };
    case GET_COURSE_CLIENT:
      return {
        ...state,
        courseClient: {
          ...state.courseClient,
          loading: true,
        },
      };
    case GET_COURSE_CLIENT_SUCCEEDED:
      return {
        ...state,
        courseClient: {
          ...state.courseClient,
          data: action.data,
          loading: false,
        },
      };
    case GET_COURSE_CLIENT_FAILED:
      return {
        ...state,
        courseClient: {
          ...state.courseClient,
          loading: false,
        },
      };
    case GET_COURSE_FREE_TRIALS:
      return {
        ...state,
        courseFreeTrial: {
          ...state.courseFreeTrial,
          loading: true,
        },
      };
    case GET_COURSE_FREE_TRIALS_SUCCEEDED:
      return {
        ...state,
        courseFreeTrial: {
          ...state.courseFreeTrial,
          data: action.data,
          loading: false,
        },
      };
    case GET_COURSE_FREE_TRIALS_FAILED:
      return {
        ...state,
        courseFreeTrial: {
          ...state.courseFreeTrial,
          error: action.error,
          loading: false,
        },
      };
    case GET_MOCK_TEST_CLIENT:
      return {
        ...state,
        mockTestClient: {
          ...state.mockTestClient,
          loading: true,
        },
      };
    case GET_MOCK_TEST_CLIENT_SUCCEEDED:
      return {
        ...state,
        mockTestClient: {
          ...state.mockTestClient,
          data: action.data,
          loading: false,
        },
      };
    case GET_MOCK_TEST_CLIENT_FAILED:
      return {
        ...state,
        mockTestClient: {
          ...state.mockTestClient,
          loading: false,
        },
      };

    case GET_COURSE_DOUBTS:
      return {
        ...state,
        courseDoubtsLoading: true,
      };
    case GET_COURSE_DOUBTS_SUCCEEDED:
      return {
        ...state,
        courseDoubts: action.data,
        courseDoubtsLoading: false,
      };

    case GET_COURSE_DOUBTS_FAILED:
      return {
        ...state,
        courseDoubtsLoading: false,
      };

    case GET_COURSE_DOUBT_COMMENTS:
      return {
        ...state,
        courseDoubtCommentsLoading: true,
      };
    case GET_COURSE_DOUBT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseDoubtComments: action.data,
        courseDoubtCommentsLoading: false,
      };

    case GET_COURSE_DOUBT_COMMENTS_FAILED:
      return {
        ...state,
        courseDoubtCommentsLoading: false,
      };
    case ADD_COURSE_DOUBT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseDoubtComments: {
          ...state.courseDoubtComments,
          data: [...state.courseDoubtComments.data, action.data.data],
        },
      };

    case ADD_COURSE_DOUBT_COMMENTS_FAILED:
      return {
        ...state,
      };
    case EDIT_COURSE_DOUBT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseDoubtComments: {
          ...state.courseDoubtComments,
          data: state.courseDoubtComments.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_COURSE_DOUBT_COMMENTS_FAILED:
      return {
        ...state,
      };
    case DELETE_COURSE_DOUBT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseDoubtComments: {
          ...state.courseDoubtComments,
          data: state.courseDoubtComments.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_COURSE_DOUBT_COMMENTS_FAILED:
      return {
        ...state,
      };
    case GET_COURSE_CLIENTS:
      return {
        ...state,
        courseClientsLoading: true,
      };
    case GET_COURSE_CLIENTS_SUCCEEDED:
      return {
        ...state,
        courseClients: action.data,
        courseClientsLoading: false,
      };

    case GET_COURSE_CLIENTS_FAILED:
      return {
        ...state,
        courseClientsLoading: false,
      };
    case ADD_COURSE_CLIENTS:
      return {
        ...state,
        addClientStatus: 'request',
      };
    case ADD_COURSE_CLIENTS_SUCCEEDED:
      return {
        ...state,
        courseClients: {
          ...state.courseClients,
          data: [action.data.data, ...state.courseClients.data],
        },
        addClientStatus: 'success',
      };
    case ADD_COURSE_CLIENTS_FAILED:
      return {
        ...state,
        addClientStatus: 'failure',
        error: {
          ...state.error,
          addCourseClient: action.error,
        },
      };
    case EDIT_COURSE_CLIENTS:
      return {
        ...state,
        editClientStatus: 'request',
      };
    case EDIT_COURSE_CLIENTS_SUCCEEDED:
      return {
        ...state,
        courseClients: {
          ...state.courseClients,
          data: state.courseClients.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        editClientStatus: 'success',
      };

    case EDIT_COURSE_CLIENTS_FAILED:
      return {
        ...state,
        editClientStatus: 'failure',
      };
    case GET_COURSE_FEEDBACKS:
      return {
        ...state,
        courseFeedbacksLoading: true,
      };
    case GET_COURSE_FEEDBACKS_SUCCEEDED:
      return {
        ...state,
        courseFeedbacks: action.data,
        courseFeedbacksLoading: false,
      };

    case GET_COURSE_FEEDBACKS_FAILED:
      return {
        ...state,
        courseFeedbacksLoading: false,
      };
    case GET_COURSE_FEEDBACK_BY_ID:
      return {
        ...state,
        getCourseFeedbackDetailStatus: 'request',
      };
    case GET_COURSE_FEEDBACK_BY_ID_SUCCEEDED:
      return {
        ...state,
        getCourseFeedbackDetailStatus: 'success',
        courseReviewDetail: {
          ...state.courseReviewDetail,
          data: action.data,
        },
      };
    case GET_COURSE_FEEDBACK_BY_ID_FAILED:
      return {
        ...state,
        getCourseFeedbackDetailStatus: 'failure',
      };

    case GET_COURSE_LIVE_CLASS:
      return {
        ...state,
        liveClassLoading: true,
      };
    case GET_COURSE_LIVE_CLASS_SUCCEEDED:
      return {
        ...state,
        liveClasses: action.data,
        liveClassLoading: false,
      };
    case GET_COURSE_LIVE_CLASS_FAILED:
      return {
        ...state,
        liveClassLoading: false,
      };

    case ADD_COURSE_LIVE_CLASS:
      return {
        ...state,
        helperObj: { ...state.helperObj, addLiveClassStatus: 'request' },
      };
    case ADD_COURSE_LIVE_CLASS_SUCCEEDED:
      return {
        ...state,
        liveClasses: {
          ...state.liveClasses,
          data: [action.data.data, ...state.liveClasses.data],
        },
        helperObj: {
          ...state.helperObj,
          addLiveClass: false,
          addLiveClassStatus: 'success',
        },
        addLiveClass: {},
        error: { ...state.error, addLiveClass: {} },
      };

    case ADD_COURSE_LIVE_CLASS_FAILED:
      return {
        ...state,
        error: { ...state.error, addLiveClass: action.error },
        helperObj: { ...state.helperObj, addLiveClassStatus: 'failure' },
      };

    case EDIT_COURSE_LIVE_CLASS:
      return {
        ...state,
        helperObj: { ...state.helperObj, editLiveClassStatus: 'request' },
      };
    case EDIT_COURSE_LIVE_CLASS_SUCCEEDED:
      return {
        ...state,
        liveClasses: {
          ...state.liveClasses,
          data: state.liveClasses.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          editLiveClass: false,
          editLiveClassStatus: 'success',
        },
        error: { ...state.error, editLiveClass: {} },
      };

    case EDIT_COURSE_LIVE_CLASS_FAILED:
      return {
        ...state,
        error: { ...state.error, editLiveClass: action.error },
        helperObj: { ...state.helperObj, editLiveClassStatus: 'failure' },
      };
    case DELETE_COURSE_LIVE_CLASS_SUCCEEDED:
      return {
        ...state,
        liveClasses: {
          ...state.liveClasses,
          data: state.liveClasses.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_COURSE_LIVE_CLASS_FAILED:
      return {
        ...state,
      };
    case CLEAR_COURSE_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          editCourse: initialState.error.editCourse,
          addCourseClient: initialState.error.addCourseClient,
        },
      };
    case CLEAR_COURSE_DATA:
      return {
        ...state,
        // Initital state of course
        courses: {
          ...initialState.courses,
        },
        courseMaterial: {
          ...state.courseMaterial,
          loading: false,
        },
        courseDetail: {
          ...state.courseDetail,
          data: persistedState.courseDetail.data,
        },
      };

    case SET_COURSE_QUERY_DATA:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.name]: {
            ...action.payload.value,
          },
        },
      };
    case CLEAR_COURSE_QUERY:
      return {
        ...state,
        query: initialState.query,
      };
    case EDIT_REQUESTED_COURSE_INQUIRY_STATUS:
      return {
        ...state,
      };
    case EDIT_REQUESTED_COURSE_INQUIRY_STATUS_SUCCEEDED:
      return {
        ...state,
        courseEnquiry: {
          ...state.courseEnquiry,
          data: state?.courseEnquiry?.data?.map((each: any) =>
            each.id === action.data.data.id ? action.data.data : each
          ),
        },
      };
    case EDIT_REQUESTED_COURSE_INQUIRY_STATUS_FAILED:
      return {
        ...state,
      };
    case GET_COURSE_COMMENTS_REQUEST:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            getComment: true,
          },
          error: {
            ...state.courseInquiryComments.error,
            getComment: {},
          },
        },
      };
    case GET_COURSE_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            getComment: false,
          },
          data: action.data,
          error: {
            ...state.courseInquiryComments.error,
            getComment: {},
          },
        },
      };
    case GET_COURSE_COMMENTS_FAILED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            getComment: false,
          },
          error: {
            ...state.courseInquiryComments.error,
            getComment: action.error,
          },
        },
      };
    case ADD_COURSE_COMMENTS_REQUEST:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            addComment: true,
          },
          error: {
            ...state.courseInquiryComments.error,
            addComment: {},
          },
        },
      };
    case ADD_COURSE_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            addComment: false,
          },
          data: {
            ...state.courseInquiryComments.data,
            data: [action?.data?.data, ...state.courseInquiryComments.data?.data],
          },
          error: {
            ...state.courseInquiryComments.error,
            addComment: {},
          },
        },
      };
    case ADD_COURSE_COMMENTS_FAILED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            addComment: false,
          },
          error: {
            ...state.courseInquiryComments.error,
            addComment: action.error,
          },
        },
      };
    case EDIT_COURSE_COMMENTS_REQUEST:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            editComment: true,
          },
          error: {
            ...state.courseInquiryComments.error,
            editComment: {},
          },
        },
      };
    case EDIT_COURSE_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            editComment: false,
          },
          data: {
            ...state.courseInquiryComments.data,
            data: state?.courseInquiryComments?.data?.data?.map((each: any) =>
              each.id === action.data?.data.id ? action?.data?.data : each
            ),
          },
          error: {
            ...state.courseInquiryComments.error,
            editComment: {},
          },
        },
      };
    case EDIT_COURSE_COMMENTS_FAILED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            editComment: false,
          },
          error: {
            ...state.courseInquiryComments.error,
            editComment: action.error,
          },
        },
      };
    case DELETE_COURSE_COMMENTS_REQUEST:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            editComment: true,
          },
          error: {
            ...state.courseInquiryComments.error,
            editComment: {},
          },
        },
      };
    case DELETE_COURSE_COMMENTS_SUCCEEDED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            deleteComment: false,
          },
          data: {
            ...state.courseInquiryComments.data,
            data: state?.courseInquiryComments?.data?.data?.filter(
              (each: any) => each.id !== action.data?.data.id
            ),
          },
          error: {
            ...state.courseInquiryComments.error,
            deleteComment: {},
          },
        },
      };
    case DELETE_CONTACT_COMMENTS_FAILED:
      return {
        ...state,
        courseInquiryComments: {
          ...state.courseInquiryComments,
          loading: {
            ...state.courseInquiryComments.loading,
            deleteComment: false,
          },
          error: {
            ...state.courseInquiryComments.error,
            deleteComment: action.error,
          },
        },
      };
    default:
      return state;
  }
};

export default courses;
