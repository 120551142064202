import {
  ADD_ADVERTISEMENT,
  ADD_ADVERTISEMENT_FAILED,
  ADD_ADVERTISEMENT_SUCCEEDED,
  DELETE_ADVERTISEMENT,
  DELETE_ADVERTISEMENT_FAILED,
  DELETE_ADVERTISEMENT_SUCCEEDED,
  EDIT_ADVERTISEMENT,
  EDIT_ADVERTISEMENT_FAILED,
  EDIT_ADVERTISEMENT_SUCCEEDED,
  GET_ADVERTISEMENT,
  GET_ADVERTISEMENT_FAILED,
  GET_ADVERTISEMENT_FOR_PAGE,
  GET_ADVERTISEMENT_FOR_PAGE_FAILED,
  GET_ADVERTISEMENT_FOR_PAGE_SUCCEEDED,
  GET_ADVERTISEMENT_SUCCEEDED,
} from '../actions';
import { IAdvertisement } from '../interfaces/advertisement';

import { getLocalStore } from 'next-persist';

const initialState: IAdvertisement = {
  advertisement: {
    data: [],
    loading: false,
  },
  adsData: {
    data: [],
    loading: false,
  },
};
const persistedState = getLocalStore('advertisementReducer', initialState);

const advertisementReducer = (state = persistedState, action) => {
  switch (action.type) {
    case GET_ADVERTISEMENT_FOR_PAGE:
      return {
        ...state,
        advertisement: {
          ...state.advertisement,
          loading: true,
        },
      };
    case GET_ADVERTISEMENT_FOR_PAGE_SUCCEEDED:
      return {
        ...state,
        advertisement: action.data,
      };
    case GET_ADVERTISEMENT_FOR_PAGE_FAILED:
      return {
        ...state,
      };
    // Advertisement for admin
    case GET_ADVERTISEMENT:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: true,
        },
      };
    case GET_ADVERTISEMENT_SUCCEEDED:
      return {
        ...state,
        adsData: action.data,
      };
    case GET_ADVERTISEMENT_FAILED:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: false,
        },
      };
    case ADD_ADVERTISEMENT:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: true,
        },
      };
    case ADD_ADVERTISEMENT_SUCCEEDED:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          data: [action.data.data, ...state.adsData.data],
          loading: false,
        },
      };
    case ADD_ADVERTISEMENT_FAILED:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: false,
        },
      };
    case EDIT_ADVERTISEMENT:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: true,
        },
      };
    case EDIT_ADVERTISEMENT_SUCCEEDED:
      const oldAds = state.adsData.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        adsData: {
          ...state.adsData,
          data: [action.data.data, ...oldAds],
          loading: false,
        },
      };
    case EDIT_ADVERTISEMENT_FAILED:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: false,
        },
      };
    case DELETE_ADVERTISEMENT:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: true,
        },
      };

    case DELETE_ADVERTISEMENT_SUCCEEDED:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          data: state.adsData.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
          loading: false,
        },
      };
    case DELETE_ADVERTISEMENT_FAILED:
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default advertisementReducer;
