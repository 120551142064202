import { takeLatest } from 'redux-saga/effects';
import * as blogActions from '../actions/information/blog/types';
import * as companyActions from '../actions/information/company/types';
import * as faqActions from '../actions/information/faq/types';
import * as landingActions from '../actions/information/landing/types';
import * as termsConditionsActions from '../actions/information/terms-conditions/types';
import * as testimonialActions from '../actions/information/testimonial/types';
import { deleteData, fetchData, postData, putData } from './genericSagas';

function* informationWatcher() {
  //blogs
  yield takeLatest(blogActions.GET_BLOG_DETAIL_DASHBOARD, fetchData);

  yield takeLatest(blogActions.GET_BLOGS, fetchData);
  yield takeLatest(blogActions.GET_BLOG_BY_SLUG, fetchData);
  yield takeLatest(blogActions.ADD_BLOG, postData);
  yield takeLatest(blogActions.EDIT_BLOG, putData);
  yield takeLatest(blogActions.DELETE_BLOG, deleteData);

  //blog category
  yield takeLatest(blogActions.GET_BLOG_CATEGORY, fetchData);
  yield takeLatest(blogActions.ADD_BLOG_CATEGORY, postData);
  yield takeLatest(blogActions.EDIT_BLOG_CATEGORY, putData);
  yield takeLatest(blogActions.DELETE_BLOG_CATEGORY, deleteData);

  //company
  yield takeLatest(companyActions.GET_COMPANY, fetchData);
  yield takeLatest(companyActions.ADD_COMPANY, postData);
  yield takeLatest(companyActions.EDIT_COMPANY, putData);
  yield takeLatest(companyActions.DELETE_COMPANY, deleteData);

  // FAQ
  yield takeLatest(faqActions.GET_FAQ, fetchData);
  yield takeLatest(faqActions.ADD_FAQ, postData);
  yield takeLatest(faqActions.EDIT_FAQ, putData);
  yield takeLatest(faqActions.DELETE_FAQ, deleteData);

  //landing
  yield takeLatest(landingActions.GET_LANDING, fetchData);
  yield takeLatest(landingActions.ADD_LANDING, postData);
  yield takeLatest(landingActions.EDIT_LANDING, putData);
  yield takeLatest(landingActions.DELETE_LANDING, deleteData);

  //landing sections
  yield takeLatest(landingActions.GET_LANDING_SECTION, fetchData);
  yield takeLatest(landingActions.ADD_LANDING_SECTION, postData);
  yield takeLatest(landingActions.EDIT_LANDING_SECTION, putData);
  yield takeLatest(landingActions.DELETE_LANDING_SECTION, deleteData);

  //terms and conditions
  yield takeLatest(termsConditionsActions.GET_TERMS_CONDITIONS, fetchData);
  yield takeLatest(termsConditionsActions.ADD_TERMS_CONDITIONS, postData);
  yield takeLatest(termsConditionsActions.EDIT_TERMS_CONDITIONS, putData);
  yield takeLatest(termsConditionsActions.DELETE_TERMS_CONDITIONS, deleteData);

  //testimonials
  yield takeLatest(testimonialActions.GET_TESTIMONIALS, fetchData);
  yield takeLatest(testimonialActions.ADD_TESTIMONIAL, postData);
  yield takeLatest(testimonialActions.EDIT_TESTIMONIAL, putData);
  yield takeLatest(testimonialActions.DELETE_TESTIMONIAL, deleteData);
}

export default informationWatcher;
