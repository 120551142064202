import { IPhysicalClass } from 'src/interfaces/physical-class';
import {
  GET_PHYSICAL_CLASS_SUCCEEDED,
  GET_PHYSICAL_CLASS_REQUEST,
  GET_PHYSICAL_CLASS_FAILED,
  SET_PHYSICAL_CLASS_DATA,
  SET_PHYSICAL_CLASS_ERROR_DATA,
  ADD_PHYSICAL_CLASS_REQUEST,
  ADD_PHYSICAL_CLASS_SUCCEEDED,
  ADD_PHYSICAL_CLASS_FAILED,
  EDIT_PHYSICAL_CLASS_REQUEST,
  EDIT_PHYSICAL_CLASS_SUCCEEDED,
  EDIT_PHYSICAL_CLASS_FAILED,
  DELETE_PHYSICAL_CLASS_SUCCEEDED,
  GET_PHYSICAL_CLASS_INFO_REQUEST,
  GET_PHYSICAL_CLASS_INFO_SUCCEEDED,
  GET_PHYSICAL_CLASS_INFO_FAILED,
  GET_PHYSICAL_CLASS_STUDENT_REQUEST,
  GET_PHYSICAL_CLASS_STUDENT_SUCCEEDED,
  GET_PHYSICAL_CLASS_STUDENT_FAILED,
  ADD_PHYSICAL_CLASS_STUDENT_REQUEST,
  ADD_PHYSICAL_CLASS_STUDENT_SUCCEEDED,
  ADD_PHYSICAL_CLASS_STUDENT_FAILED,
  EDIT_PHYSICAL_CLASS_STUDENT_REQUEST,
  EDIT_PHYSICAL_CLASS_STUDENT_SUCCEEDED,
  EDIT_PHYSICAL_CLASS_STUDENT_FAILED,
  DELETE_PHYSICAL_CLASS_STUDENT_SUCCEEDED,
  GET_PHYSICAL_CLASS_ATTENDANCE_REQUEST,
  GET_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED,
  GET_PHYSICAL_CLASS_ATTENDANCE_FAILED,
  ADD_PHYSICAL_CLASS_ATTENDANCE_REQUEST,
  ADD_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED,
  ADD_PHYSICAL_CLASS_ATTENDANCE_FAILED,
  EDIT_PHYSICAL_CLASS_ATTENDANCE_REQUEST,
  EDIT_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED,
  EDIT_PHYSICAL_CLASS_ATTENDANCE_FAILED,
  GET_PHYSICAL_CLASS_LIST_DETAIL_REQUEST,
  GET_PHYSICAL_CLASS_LIST_DETAIL_SUCCEEDED,
  GET_PHYSICAL_CLASS_LIST_FAILED,
  GET_PHYSICAL_CLASS_LIST_REQUEST,
  GET_PHYSICAL_CLASS_LIST_SUCCEEDED,
  GET_PHYSICAL_CLASS_LIST_DETAIL_FAILED,
  GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST,
  GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED,
  GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED,
  UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST,
  UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED,
  UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED,
  GET_PHYSICAL_CLASS_BATCHES_REQUEST,
  GET_PHYSICAL_CLASS_BATCHES_SUCCEEDED,
  GET_PHYSICAL_CLASS_BATCHES_FAILED,
  Add_PHYSICAL_CLASS_BATCHES_REQUEST,
  Add_PHYSICAL_CLASS_BATCHES_SUCCEEDED,
  Add_PHYSICAL_CLASS_BATCHES_FAILED,
  Edit_PHYSICAL_CLASS_BATCHES_REQUEST,
  Edit_PHYSICAL_CLASS_BATCHES_SUCCEEDED,
  Edit_PHYSICAL_CLASS_BATCHES_FAILED,
  Delete_PHYSICAL_CLASS_BATCHES_FAILED,
  Delete_PHYSICAL_CLASS_BATCHES_SUCCEEDED,
  GET_PHYSICAL_CLASS_STUDENT_TASK_REQUEST,
  GET_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED,
  GET_PHYSICAL_CLASS_STUDENT_TASK_FAILED,
  ADD_PHYSICAL_CLASS_STUDENT_TASK_REQUEST,
  ADD_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED,
  ADD_PHYSICAL_CLASS_STUDENT_TASK_FAILED,
  EDIT_PHYSICAL_CLASS_STUDENT_TASK_REQUEST,
  EDIT_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED,
  EDIT_PHYSICAL_CLASS_STUDENT_TASK_FAILED,
  DELETE_PHYSICAL_CLASS_STUDENT_TASK_REQUEST,
  DELETE_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED,
  DELETE_PHYSICAL_CLASS_STUDENT_TASK_FAILED,
  POST_PHYSICAL_CLASS_BOOK_REQUEST,
  POST_PHYSICAL_CLASS_BOOK_SUCCEEDED,
  POST_PHYSICAL_CLASS_BOOK_FAILED,
  GET_PHYSICAL_CLASS_STUDENT_LIST_REQUEST,
  GET_PHYSICAL_CLASS_STUDENT_LIST_SUCCEEDED,
  GET_PHYSICAL_CLASS_STUDENT_LIST_FAILED,
} from '../../src/actions';
import { getLocalStore } from 'next-persist';

const initialState: IPhysicalClass = {
  helperObj: {
    showAddPhysicalClass: false,
    showEditPhysicalClass: false,
    showAddPhysicalClassStudent: false,
    showEditPhysicalClassStudent: false,
    showAttendanceModal: false,
    showAddPhysicalClassLead: false,
    showPhysicalClassLead: false,
  },
  physicalClass: {},
  physicalClassStudents: {},
  physicalClassInfo: {},
  loader: {
    getPhysicalClassLoading: false,
    addPhysicalClassLoading: false,
    editPhysicalClassLoading: false,
    getPhysicalClassStudentLoading: false,
    addPhysicalClassStudentLoading: false,
    editPhysicalClassStudentLoading: false,
    getPhysicalClassInfoLoading: false,
    getAttendanceLoading: false,
    takeAttendanceLoading: false,
  },
  error: {
    addPhysicalClassError: {},
    editPhysicalClassError: {},
    addPhysicalClassStudentError: {},
    editPhysicalClassStudentError: {},
    takeAttendanceError: {},
    bookClassError: {},
  },
  status: {
    addStudentStatus: '',
    editStudentStatus: '',
    takeAttendanceStatus: '',
    bookClassStatus: '',
  },
  attendance: {},
  physicalClassList: {
    data: {},
    loading: false,
    error: {},
  },
  physicalClassDetail: {
    data: {},
    loading: false,
    error: {},
  },
  physicalClassRequestList: {
    data: {},
    loading: false,
    uploadLoading: false,
    error: {},
    updateError: {},
  },
  physicalClassBatches: {
    data: {},
    loading: false,
    createLoading: false,
    editLoading: false,
    deleteLoading: false,
    error: {},
  },
  task: {
    data: {},
    loading: false,
    error: {},
  },
};
const persistedState = getLocalStore('physicalClass', initialState);

const physicalClass = (state = persistedState, action) => {
  switch (action.type) {
    //PHYSICAL CLASS STARTS
    case GET_PHYSICAL_CLASS_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          getPhysicalClassLoading: true,
        },
      };
    case GET_PHYSICAL_CLASS_SUCCEEDED:
      return {
        ...state,
        physicalClass: action.data,
        loader: {
          ...state.loader,
          getPhysicalClassLoading: false,
        },
      };

    case GET_PHYSICAL_CLASS_FAILED:
      return {
        ...state,
        loader: {
          ...state.loaders,
          getPhysicalClassLoading: false,
        },
      };

    case ADD_PHYSICAL_CLASS_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          addPhysicalClassLoading: true,
        },
      };

    case ADD_PHYSICAL_CLASS_SUCCEEDED:
      return {
        ...state,
        physicalClass: {
          ...state.physicalClass,
          data: [action?.data?.data, ...state.physicalClass?.data],
        },
        loader: {
          ...state.loader,
          addPhysicalClassLoading: false,
        },
        helperObj: {
          ...state.helperObj,
          showAddPhysicalClass: false,
        },
        error: {
          ...state.error,
          addPhysicalClassError: {},
        },
      };

    case ADD_PHYSICAL_CLASS_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          addPhysicalClassLoading: false,
        },
        error: {
          ...state.error,
          addPhysicalClassError: action.error,
        },
      };

    case EDIT_PHYSICAL_CLASS_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          editPhysicalClassLoading: true,
        },
      };

    case EDIT_PHYSICAL_CLASS_SUCCEEDED:
      return {
        ...state,
        loader: {
          ...state.loader,
          editPhysicalClassLoading: false,
        },
        physicalClass: {
          ...state.physicalClass,
          data: state?.physicalClass?.data?.map((each: any) =>
            each.id === action.data?.data.id ? action?.data?.data : each
          ),
        },
        physicalClassInfo: action.data.data,
        helperObj: {
          ...state.helperObj,
          showEditPhysicalClass: false,
        },
        error: { ...state.error, editPhysicalClassError: {} },
      };

    case EDIT_PHYSICAL_CLASS_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          editPhysicalClassLoading: false,
        },
        error: { ...state.error, editPhysicalClassError: action.error },
      };

    case DELETE_PHYSICAL_CLASS_SUCCEEDED:
      return {
        ...state,
        physicalClass: {
          ...state.physicalClass,
          data: state.physicalClass.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };
    //PHYSICAL CLASS ENDS

    //PHYSICAL CLASS STUDENT STARTS
    case GET_PHYSICAL_CLASS_STUDENT_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          getPhysicalClassStudentLoading: true,
        },
      };

    case GET_PHYSICAL_CLASS_STUDENT_SUCCEEDED:
      return {
        ...state,
        physicalClassStudents: action.data,
        loader: {
          ...state.loader,
          getPhysicalClassStudentLoading: false,
        },
      };

    case GET_PHYSICAL_CLASS_STUDENT_FAILED:
      return {
        ...state,
        loader: {
          ...state.loaders,
          getPhysicalClassStudentLoading: false,
        },
      };

    case ADD_PHYSICAL_CLASS_STUDENT_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          addPhysicalClassStudentLoading: true,
        },
        status: {
          ...state.status,
          addStudentStatus: 'request',
        },
      };

    case ADD_PHYSICAL_CLASS_STUDENT_SUCCEEDED:
      return {
        ...state,
        physicalClassStudents: {
          ...state.physicalClassStudents,
          data: [action?.data?.data, ...state.physicalClassStudents?.data],
        },
        loader: {
          ...state.loader,
          addPhysicalClassStudentLoading: false,
        },
        helperObj: {
          ...state.helperObj,
          showAddPhysicalClassStudent: false,
          showAddPhysicalClassLead: false,
          showPhysicalClassLead: false,
        },
        error: {
          ...state.error,
          addPhysicalClassStudentError: {},
        },
        status: {
          ...state.status,
          addStudentStatus: 'success',
        },
      };

    case ADD_PHYSICAL_CLASS_STUDENT_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          addPhysicalClassStudentLoading: false,
        },
        error: {
          ...state.error,
          addPhysicalClassStudentError: action.error,
        },
        status: {
          ...state.status,
          addStudentStatus: 'failure',
        },
      };

    case EDIT_PHYSICAL_CLASS_STUDENT_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          editPhysicalClassStudentLoading: true,
        },
        status: {
          ...state.status,
          editStudentStatus: 'request',
        },
      };

    case EDIT_PHYSICAL_CLASS_STUDENT_SUCCEEDED:
      return {
        ...state,
        loader: {
          ...state.loader,
          editPhysicalClassStudentLoading: false,
        },
        physicalClassStudents: {
          ...state.physicalClassStudents,
          data: state?.physicalClassStudents?.data?.map((each: any) =>
            each?.id === action?.data?.data.id ? action?.data?.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          showEditPhysicalClassStudent: false,
        },
        status: {
          ...state.status,
          editStudentStatus: 'success',
        },
        error: { ...state.error, editPhysicalClassStudentError: {} },
      };

    case EDIT_PHYSICAL_CLASS_STUDENT_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          editPhysicalClassStudentLoading: false,
        },
        error: { ...state.error, editPhysicalClassStudentError: action.error },
        status: {
          ...state.status,
          editStudentStatus: 'failure',
        },
      };

    case DELETE_PHYSICAL_CLASS_STUDENT_SUCCEEDED:
      return {
        ...state,
        physicalClassStudents: {
          ...state.physicalClassStudents,
          data: state.physicalClassStudents.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };
    //PHYSICAL CLASS STUDENT ENDS

    //PHYSICAL CLASS DETAILS
    case GET_PHYSICAL_CLASS_INFO_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          getPhysicalClassInfoLoading: true,
        },
      };
    case GET_PHYSICAL_CLASS_INFO_SUCCEEDED:
      return {
        ...state,
        physicalClassInfo: action.data,
        loader: {
          ...state.loader,
          getPhysicalClassInfoLoading: false,
        },
      };

    case GET_PHYSICAL_CLASS_INFO_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          getPhysicalClassInfoLoading: false,
        },
      };

    //PHYSICAL CLASS ATTENDANCE STARTS
    case GET_PHYSICAL_CLASS_ATTENDANCE_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          getAttendanceLoading: true,
        },
      };
    case GET_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED:
      return {
        ...state,
        attendance: action.data,
        loader: {
          ...state.loader,
          getAttendanceLoading: false,
        },
      };

    case GET_PHYSICAL_CLASS_ATTENDANCE_FAILED:
      return {
        ...state,
        loader: {
          ...state.loaders,
          getAttendanceLoading: false,
        },
      };

    case ADD_PHYSICAL_CLASS_ATTENDANCE_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          takeAttendanceLoading: true,
        },
        status: {
          ...state.status,
          takeAttendanceStatus: 'request',
        },
      };

    case ADD_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED:
      return {
        ...state,
        loader: {
          ...state.loader,
          takeAttendanceLoading: false,
        },
        helperObj: {
          ...state.helperObj,
          showAttendanceModal: false,
        },
        error: {
          ...state.error,
          takeAttendanceError: {},
        },
        status: {
          ...state.status,
          takeAttendanceStatus: 'success',
        },
      };

    case ADD_PHYSICAL_CLASS_ATTENDANCE_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          takeAttendanceLoading: false,
        },
        error: {
          ...state.error,
          takeAttendanceError: action.error,
        },
        status: {
          ...state.status,
          takeAttendanceStatus: 'failure',
        },
      };

    case EDIT_PHYSICAL_CLASS_ATTENDANCE_REQUEST:
      return {
        ...state,
        loader: {
          ...state.loader,
          takeAttendanceLoading: true,
        },
        status: {
          ...state.status,
          takeAttendanceStatus: 'request',
        },
      };

    case EDIT_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED:
      return {
        ...state,
        loader: {
          ...state.loader,
          takeAttendanceLoading: false,
        },
        helperObj: {
          ...state.helperObj,
          showAttendanceModal: false,
        },
        status: {
          ...state.status,
          takeAttendanceStatus: 'success',
        },
        error: { ...state.error, takeAttendanceError: {} },
      };

    case EDIT_PHYSICAL_CLASS_ATTENDANCE_FAILED:
      return {
        ...state,
        loader: {
          ...state.loader,
          takeAttendanceLoading: false,
        },
        error: { ...state.error, takeAttendanceError: action.error },
        status: {
          ...state.status,
          takeAttendanceStatus: 'failure',
        },
      };

    //DATA SETTERS
    case SET_PHYSICAL_CLASS_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_PHYSICAL_CLASS_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    case GET_PHYSICAL_CLASS_LIST_REQUEST:
      return {
        ...state,
        physicalClassList: {
          ...state.physicalClassList,
          loading: true,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_LIST_SUCCEEDED:
      return {
        ...state,
        physicalClassList: {
          ...state.physicalClassList,
          loading: false,
          data: action.data,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_LIST_FAILED:
      return {
        ...state,
        physicalClassList: {
          ...state.physicalClassList,
          loading: false,
          error: action.error,
        },
      };
    case GET_PHYSICAL_CLASS_LIST_DETAIL_REQUEST:
      return {
        ...state,
        physicalClassDetail: {
          ...state.physicalClassDetail,
          loading: true,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_LIST_DETAIL_SUCCEEDED:
      return {
        ...state,
        physicalClassDetail: {
          ...state.physicalClassDetail,
          loading: false,
          data: action.data,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_LIST_DETAIL_FAILED:
      return {
        ...state,
        physicalClassDetail: {
          ...state.physicalClassDetail,
          loading: false,
          error: action.error,
        },
      };
    case GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST:
      return {
        ...state,
        physicalClassRequestList: {
          ...state.physicalClassRequestList,
          loading: true,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED:
      return {
        ...state,
        physicalClassRequestList: {
          ...state.physicalClassRequestList,
          data: action.data,
          loading: false,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED:
      return {
        ...state,
        physicalClassRequestList: {
          ...state.physicalClassRequestList,
          loading: false,
          error: action.error,
        },
      };
    case UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST:
      return {
        ...state,
        physicalClassRequestList: {
          ...state.physicalClassRequestList,
          uploadLoading: true,
        },
      };
    case UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED:
      return {
        ...state,
        physicalClassRequestList: {
          ...state.physicalClassRequestList,
          uploadLoading: false,
          data: {
            ...state.physicalClassRequestList.data,
            data: state?.physicalClassRequestList?.data?.data?.map((each: any) =>
              each.id == action.data?.data.id ? action?.data?.data : each
            ),
          },
        },
      };
    case UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED:
      return {
        ...state,
        physicalClassRequestList: {
          ...state.physicalClassRequestList,
          updateLoading: false,
          updateError: action.error,
        },
      };

    // GET STUDENT LIST OF PHYSICAL CLASS FILTERED
    case GET_PHYSICAL_CLASS_STUDENT_LIST_REQUEST:
      return {
        ...state,
        getPhysicalClassStudentListLoading: 'request',
      };
    case GET_PHYSICAL_CLASS_STUDENT_LIST_SUCCEEDED:
      return {
        ...state,
        studentList: action.data,
        getPhysicalClassStudentListLoading: 'success',
      };

    case GET_PHYSICAL_CLASS_STUDENT_LIST_FAILED:
      return {
        ...state,
        getPhysicalClassStudentListLoading: 'failed',
      };

    case GET_PHYSICAL_CLASS_BATCHES_REQUEST:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          loading: true,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_BATCHES_SUCCEEDED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          data: action.data,
          loading: false,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_BATCHES_FAILED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          loading: false,
          error: action.error,
        },
      };
    case Add_PHYSICAL_CLASS_BATCHES_REQUEST:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          loading: true,
          error: {},
        },
      };
    case Add_PHYSICAL_CLASS_BATCHES_SUCCEEDED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          data: {
            ...state.physicalClassBatches.data,
            data: [action.data?.data, ...state.physicalClassBatches.data?.data],
          },
          loading: false,
          error: {},
        },
      };
    case Add_PHYSICAL_CLASS_BATCHES_FAILED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          loading: false,
          error: action.error,
        },
      };
    case Edit_PHYSICAL_CLASS_BATCHES_REQUEST:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          editLoading: true,
          error: {},
        },
      };
    case Edit_PHYSICAL_CLASS_BATCHES_REQUEST:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          editLoading: true,
          error: {},
        },
      };
    case Edit_PHYSICAL_CLASS_BATCHES_SUCCEEDED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          editLoading: false,
          error: {},
          data: {
            ...state.physicalClassBatches.data,
            data: state?.physicalClassBatches?.data?.data?.map((each: any) =>
              each.id === action.data?.data.id ? action?.data?.data : each
            ),
          },
        },
      };
    case Edit_PHYSICAL_CLASS_BATCHES_FAILED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          editLoading: false,
          error: action.error,
        },
      };
    case Delete_PHYSICAL_CLASS_BATCHES_FAILED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          deleteLoading: true,
          error: {},
        },
      };
    case Delete_PHYSICAL_CLASS_BATCHES_SUCCEEDED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          editLoading: false,
          error: {},
          data: {
            ...state.physicalClassBatches.data,
            data: state?.physicalClassBatches?.data?.data?.filter(
              (each: any) => each.id !== action.data?.data.id
            ),
          },
        },
      };
    case Delete_PHYSICAL_CLASS_BATCHES_FAILED:
      return {
        ...state,
        physicalClassBatches: {
          ...state.physicalClassBatches,
          deleteLoading: false,
          error: action.error,
        },
      };
    case GET_PHYSICAL_CLASS_STUDENT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: true,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          data: action.data,
          error: {},
        },
      };
    case GET_PHYSICAL_CLASS_STUDENT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          error: action.error,
        },
      };
    case ADD_PHYSICAL_CLASS_STUDENT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: true,
          error: {},
        },
      };
    case ADD_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          data: {
            ...state.task.data,
            data: [action?.data?.data, ...state.task.data?.data],
          },
          error: {},
        },
      };
    case ADD_PHYSICAL_CLASS_STUDENT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          error: action.error,
        },
      };
    case EDIT_PHYSICAL_CLASS_STUDENT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: true,
          error: {},
        },
      };
    case EDIT_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          error: {},
          data: {
            ...state.task.data,
            data: state?.task?.data?.data?.map((each: any) =>
              each.id === action.data?.data.id ? action?.data?.data : each
            ),
          },
        },
      };
    case EDIT_PHYSICAL_CLASS_STUDENT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          error: action.error,
        },
      };
    case DELETE_PHYSICAL_CLASS_STUDENT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: true,
          error: {},
        },
      };
    case DELETE_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          data: {
            ...state.task.data,
            data: state?.task?.data?.data?.filter(
              (each: any) => each.id !== action.data?.data?.id
            ),
          },
        },
      };
    case DELETE_PHYSICAL_CLASS_STUDENT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: false,
          error: action.error,
        },
      };
    case POST_PHYSICAL_CLASS_BOOK_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          bookClassStatus: 'request',
          error: {
            bookClassError: {},
          },
        },
      };
    case POST_PHYSICAL_CLASS_BOOK_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          bookClassStatus: 'success',
          error: {
            bookClassError: {},
          },
        },
      };
    case POST_PHYSICAL_CLASS_BOOK_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          bookClassStatus: 'failed',
        },
        error: {
          bookClassError: action.error,
        },
      };
    default:
      return state;
  }
};

export default physicalClass;
