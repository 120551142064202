import {
  GET_HOME_SUCCEEDED,
  GET_HOME_FAILED,
  GET_INFO_SUCCEEDED,
  GET_INFO_FAILED,
  GET_COMPANY_INFO_SUCCEEDED,
  ADD_CONTACT_SUCCEEDED,
  GET_LANDING_FAQ,
  GET_LANDING_FAQ_SUCCEEDED,
  GET_LANDING_FAQ_FAILED,
  GET_HOME_SEARCH,
  GET_HOME_SEARCH_SUCCESS,
  GET_HOME_SEARCH_FAILURE,
  CLEAR_HOME_DROPDOWN,
  GET_HOME,
  ADD_CONTACT_FAILED,
  SET_HOME_DATA,
} from '../../src/actions';
import { IHome } from '../interfaces/home';
import { getLocalStore } from 'next-persist';

const initialState: IHome = {
  getHomeStatus: '',
  home: {
    data: [],
  },
  info: {
    status: true,
    social_media: [],
    office: [],
    company: {},
  },
  company: {},
  contact: {
    data: [],
    status: '',
  },
  faq: {
    data: [],
    loading: false,
  },
  courseData: {
    data: [],
    status: '',
  },
};
const persistedState = getLocalStore('home', initialState);

const home = (state = persistedState, action) => {
  switch (action.type) {
    case GET_HOME:
      return {
        ...state,
        getHomeStatus: 'request',
      };
    case GET_HOME_SUCCEEDED:
      return {
        ...state,
        getHomeStatus: 'success',
        home: action.data,
      };

    case GET_HOME_FAILED:
      return {
        ...state,
        getHomeStatus: 'failure',
      };

    case GET_HOME_SEARCH:
      return {
        ...state,
        courseData: {
          ...state.courseData,
          status: 'request',
        },
      };
    case GET_HOME_SEARCH_SUCCESS:
      return {
        ...state,
        courseData: {
          ...state.courseData,
          status: 'success',
          data: action.data.data,
        },
      };
    case GET_HOME_SEARCH_FAILURE:
      return {
        ...state,
        courseData: {
          ...state.courseData,
          status: 'failure',
        },
      };
    case GET_INFO_SUCCEEDED:
      return {
        ...state,
        info: {
          status: false,
          social_media: action.data.data.social_media,
          office: action.data.data.office,
          company: action.data.data.company,
        },
      };

    case GET_INFO_FAILED:
      return {
        ...state,
      };

    case GET_COMPANY_INFO_SUCCEEDED:
      return {
        ...state,
        company: action.data.data,
      };
    case ADD_CONTACT_SUCCEEDED:
      return {
        ...state,
        contact: {
          ...state.contact,
          data: [action.data.data, ...state.contact.data],
          status: 'success',
        },
      };
    case ADD_CONTACT_FAILED:
      return {
        ...state,
        contact: {
          ...state.contact,
          status: 'failure',
        },
      };
    // Get only faq published in landing section
    case GET_LANDING_FAQ:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: true,
        },
      };
    case GET_LANDING_FAQ_SUCCEEDED:
      return {
        ...state,
        faq: { ...action.data, loading: false },
      };
    case GET_LANDING_FAQ_FAILED:
      return {
        ...state,
        faq: {
          ...state.faq,
          loading: false,
        },
      };
    case CLEAR_HOME_DROPDOWN:
      return {
        ...state,
        courseData: initialState.courseData,
      };
    case SET_HOME_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    default:
      return state;
  }
};

export default home;
