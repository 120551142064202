import {
  GET_TESTS_SUCCEEDED,
  GET_TESTS_FAILED,
  DELETE_TEST_FAILED,
  DELETE_TEST_SUCCEEDED,
  ADD_TEST_SUCCEEDED,
  ADD_TEST_FAILED,
  EDIT_TEST_SUCCEEDED,
  EDIT_TEST_FAILED,
  GET_MY_ATTEMPTED_TESTS_SUCCEEDED,
  GET_MY_ATTEMPTED_TESTS_FAILED,
  ENROL_MOCK_TEST_SUCCEEDED,
  ENROL_MOCK_TEST_FAILED,
  GET_MY_ATTEMPTED_TESTS,
} from '../../src/actions';
import { ITests } from '../interfaces/tests';
import { getLocalStore } from 'next-persist';

const initialState: ITests = {
  tests: {
    data: [],
  },
  myAttemptedTests: {
    data: [],
  },
};
const persistedState = getLocalStore('tests', initialState);

const tests = (state = persistedState, action) => {
  switch (action.type) {
    case GET_TESTS_SUCCEEDED:
      return {
        ...state,
        tests: action.data,
      };

    case GET_TESTS_FAILED:
      return {
        ...state,
      };
    case ADD_TEST_SUCCEEDED:
      return {
        ...state,
        tests: {
          ...state.tests,
          data: [action.data.data, ...state.tests.data],
        },
      };

    case ADD_TEST_FAILED:
      return {
        ...state,
      };
    case EDIT_TEST_SUCCEEDED:
      return {
        ...state,
        tests: {
          ...state.tests,
          data: state.tests.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_TEST_FAILED:
      return {
        ...state,
      };
    case DELETE_TEST_SUCCEEDED:
      return {
        ...state,
        tests: {
          ...state.tests,
          data: state.tests.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_TEST_FAILED:
      return {
        ...state,
      };
    case GET_MY_ATTEMPTED_TESTS:
      return {
        ...state,
        myAttemptedTests: {
          loading: true,
        },
      };
    case GET_MY_ATTEMPTED_TESTS_SUCCEEDED:
      return {
        ...state,
        myAttemptedTests: {
          data: action.data,
          loading: false,
        },
      };
    case GET_MY_ATTEMPTED_TESTS_FAILED:
      return {
        ...state,
        myAttemptedTests: {
          loading: false,
        },
      };
    case ENROL_MOCK_TEST_SUCCEEDED:
      return {
        ...state,
      };

    case ENROL_MOCK_TEST_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default tests;
