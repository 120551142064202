import { getLocalStore } from 'next-persist';
import {
  ASSIGN_MULTIPLE_MOCK_TEST_FAILED,
  ASSIGN_MULTIPLE_MOCK_TEST_REQUEST,
  ASSIGN_MULTIPLE_MOCK_TEST_SUCCEEDED,
  CLEAR_MOCK_TEST_ASSIGN_ERROR,
  CLEAR_MOCK_TEST_QUERY,
  CLEAR_MOCK_TEST_STATE,
  COUNT_DOWN_TIMER,
  GET_MOCKTEST_LEADERBOARD,
  GET_MOCKTEST_LEADERBOARD_FAILED,
  GET_MOCKTEST_LEADERBOARD_SUCCEEDED,
  GET_MOCK_TEST,
  GET_MOCK_TEST_BY_SLUG,
  GET_MOCK_TEST_BY_SLUG_FAILED,
  GET_MOCK_TEST_BY_SLUG_SUCCEEDED,
  GET_MOCK_TEST_COURSES,
  GET_MOCK_TEST_COURSES_BY_PAGE,
  GET_MOCK_TEST_COURSES_BY_PAGE_FAILED,
  GET_MOCK_TEST_COURSES_BY_PAGE_SUCCEEDED,
  GET_MOCK_TEST_COURSES_FAILED,
  GET_MOCK_TEST_COURSES_SUCCEEDED,
  GET_MOCK_TEST_FAILED,
  GET_MOCK_TEST_LIST,
  GET_MOCK_TEST_LIST_BY_PAGE,
  GET_MOCK_TEST_LIST_BY_PAGE_FAILED,
  GET_MOCK_TEST_LIST_BY_PAGE_SUCCEEDED,
  GET_MOCK_TEST_LIST_BY_SLUG,
  GET_MOCK_TEST_LIST_BY_SLUG_FAILED,
  GET_MOCK_TEST_LIST_BY_SLUG_SUCCEEDED,
  GET_MOCK_TEST_LIST_FAILED,
  GET_MOCK_TEST_LIST_SUCCEEDED,
  GET_MOCK_TEST_REPORT,
  GET_MOCK_TEST_REPORT_ADMIN_PARTNER,
  GET_MOCK_TEST_REPORT_ADMIN_PARTNER_FAILED,
  GET_MOCK_TEST_REPORT_ADMIN_PARTNER_SUCCEEDED,
  GET_MOCK_TEST_REPORT_FAILED,
  GET_MOCK_TEST_REPORT_SUCCEEDED,
  GET_MOCK_TEST_SUCCEEDED,
  GET_WRITING_MOCK_TEST_ANSWER,
  SAVE_WRITING_MOCK_TEST_ANSWER,
  SET_MOCK_TEST_EDIT_DATA,
  SET_MOCK_TEST_QUERY_DATA,
  SUBMIT_MOCK_TEST,
  SUBMIT_MOCK_TEST_FAILED,
  SUBMIT_MOCK_TEST_SUCCEEDED,
  TEST_TIME_FINISHED,
  UPDATE_MOCK_TEST,
  UPDATE_MOCK_TEST_FAILED,
  UPDATE_MOCK_TEST_SUCCEEDED,
} from '../actions';
import { IMockTest } from '../interfaces/mock-test';

const initialState: IMockTest = {
  listening: {
    submit: {
      success: false,
      loading: false,
    },
  },
  writing: {
    submit: {
      success: false,
      loading: false,
    },
    answer: [],
    filteredAnswer: {},
  },
  test: { finished: false },
  mockTest: {
    loading: false,
    data: [],
    success: false,
    error: '',
  },
  mockTestReport: {
    loading: false,
    data: {},
    success: false,
  },
  mockTestLanding: {
    data: [],
    loading: false,
    success: false,
    total: 0,
  },
  mockTestCourses: {
    data: [],
    meta: {},
    loading: false,
    success: false,
    total: 0,
    getMoreCoursesStatus: '',
  },
  mockTestBySlug: {
    data: [],
    loading: false,
    success: false,
  },
  mockTestListBySlug: {
    data: [],
    loading: false,
    success: false,
  },
  query: {
    mockTestLanding: {
      page: 1,
      per_page: 10,
      title: '',
    },
    mockTestCourses: {
      page: 1,
      per_page: 12,
      title: '',
    },
  },
  currentTime: 0,
  multipleChoice: [],
  multiQuestionLimit: {
    id: '',
    count: 0,
    limit: '',
    ansIds: [],
  },
  mocktestLeaderboard: {
    data: [],
    loading: false,
    success: false,
    myScore: {},
  },
  mockTestAssign: {
    loading: false,
    success: false,
  },
  answeredList: [],
  submitMockTestStatus: '',
};
const persistedState = getLocalStore('MockTestReducer', initialState);

const MockTestReducer = (state = persistedState, action) => {
  switch (action.type) {
    case SUBMIT_MOCK_TEST:
      return {
        ...state,
        listening: {
          ...state.listening,
          submit: {
            ...state.submit,
            success: false,
            loading: true,
          },
        },
      };
    case SUBMIT_MOCK_TEST_SUCCEEDED:
      return {
        ...state,
        listening: {
          ...state.listening,
          submit: {
            ...state.submit,
            success: true,
            loading: false,
          },
        },
        writing: {
          ...state.writing,
          submit: {
            ...state.submit,
            success: true,
            loading: false,
          },
        },
      };
    case SUBMIT_MOCK_TEST_FAILED:
      return {
        ...state,
        listening: {
          ...state.listening,
          submit: {
            ...state.submit,
            success: false,
            loading: false,
            error: action.error,
          },
        },
        writing: {
          ...state.writing,
          submit: {
            ...state.submit,
            success: false,
            loading: false,
            error: action.error,
          },
        },
      };
    case TEST_TIME_FINISHED:
      return {
        ...state,
        test: {
          ...state.test,
          finished: true,
        },
      };
    case COUNT_DOWN_TIMER:
      return {
        ...state,
        currentTime: action.payload,
      };
    case GET_MOCK_TEST:
      return {
        ...state,
        mockTest: {
          ...state.mockTest,
          loading: true,
        },
      };
    case GET_MOCK_TEST_SUCCEEDED:
      return {
        ...state,
        mockTest: {
          ...state.mockTest,
          data: action.data,
          success: true,
          loading: false,
        },
      };
    case GET_MOCK_TEST_FAILED:
      console.log(action);

      return {
        ...state,
        mockTest: {
          ...state.mockTest,
          loading: false,
          error: action?.error?.response?.data?.error || 'Failed',
        },
      };
    case GET_MOCK_TEST_REPORT:
      return {
        ...state,
        mockTestReport: {
          ...state.mockTest,
          loading: true,
        },
      };
    case GET_MOCK_TEST_REPORT_SUCCEEDED:
      return {
        ...state,
        mockTestReport: {
          ...state.mockTest,
          data: action.data.data,
          success: true,
          loading: false,
        },
      };
    case GET_MOCK_TEST_REPORT_FAILED:
      return {
        ...state,
        mockTestReport: {
          ...state.mockTest,
          loading: false,
        },
      };
    case GET_MOCK_TEST_REPORT_ADMIN_PARTNER:
      return {
        ...state,
        mockTestReport: {
          ...state.mockTest,
          loading: true,
        },
      };
    case GET_MOCK_TEST_REPORT_ADMIN_PARTNER_SUCCEEDED:
      return {
        ...state,
        mockTestReport: {
          ...state.mockTest,
          data: action.data.data,
          success: true,
          loading: false,
        },
      };
    case GET_MOCK_TEST_REPORT_ADMIN_PARTNER_FAILED:
      return {
        ...state,
        mockTestReport: {
          ...state.mockTest,
          loading: false,
        },
      };
    case SAVE_WRITING_MOCK_TEST_ANSWER:
      const oldAnswer = state.writing.answer.filter(
        (each: any) => each?.sectionId !== action?.payload?.sectionId
      );
      // var checkIfIdExists = state?.writing?.answer?.some(function (ans) {
      //     return ans?.sectionId !== action.payload?.id
      // });
      // var idIndex = state?.writing?.answer?.findIndex((obj) => obj?.sectionId === action?.payload?.id)
      // var finalState = null;
      // if (!checkIfIdExists) {
      //     state.writing.answer[idIndex].answer = action.payload.answer
      // } else {
      //     finalState = [...state.writing.answer, action.payload]
      // }
      return {
        ...state,
        writing: {
          ...state.writing,
          answer: [action.payload, ...oldAnswer],
        },
      };
    case GET_WRITING_MOCK_TEST_ANSWER:
      return {
        ...state,
        writing: {
          ...state.writing,
          filteredAnswer: state.writing.answer.find(
            (ans) => ans?.sectionId == action?.payload?.id
          ),
        },
      };
    case GET_MOCK_TEST_LIST:
      return {
        ...state,
        mockTestLanding: {
          ...state.mockTestLanding,
          loading: true,
        },
      };
    case GET_MOCK_TEST_LIST_SUCCEEDED:
      return {
        ...state,
        mockTestLanding: {
          ...state.mockTestLanding,
          loading: false,
          data: action.data,
          success: true,
          total: action.data.meta?.total,
        },
      };
    case GET_MOCK_TEST_LIST_FAILED:
      return {
        ...state,
        mockTestLanding: {
          ...state.mockTestLanding,
          loading: false,
          success: false,
        },
      };

    case GET_MOCK_TEST_LIST_BY_PAGE:
      return {
        ...state,
        mockTestLanding: {
          ...state.mockTestLanding,
          getMoreMockTestStatus: 'request',
        },
      };
    case GET_MOCK_TEST_LIST_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        mockTestLanding: {
          ...state.mockTestLanding,
          data: {
            ...state.mockTestLanding.data,
            data: [...state.mockTestLanding.data.data, ...action.data.data],
            meta: action.data.meta,
          },
          getMoreMockTestStatus: 'success',
        },
      };
    case GET_MOCK_TEST_LIST_BY_PAGE_FAILED:
      return {
        ...state,
        mockTestLanding: {
          ...state.mockTestLanding,
          getMoreMockTestStatus: 'failure',
        },
      };

    case GET_MOCK_TEST_COURSES:
      return {
        ...state,
        mockTestCourses: {
          ...state.mockTestCourses,
          loading: true,
        },
      };
    case GET_MOCK_TEST_COURSES_SUCCEEDED:
      return {
        ...state,
        mockTestCourses: {
          ...state.mockTestCourses,
          loading: false,
          data: action.data.data,
          meta: action.data.meta,
          success: true,
          total: action.data.meta?.total,
        },
      };
    case GET_MOCK_TEST_COURSES_FAILED:
      return {
        ...state,
        mockTestCourses: {
          ...state.mockTestCourses,
          loading: false,
          success: false,
        },
      };
    case GET_MOCK_TEST_COURSES_BY_PAGE:
      return {
        ...state,
        mockTestCourses: {
          ...state.mockTestCourses,
          getMoreCoursesStatus: 'request',
        },
      };
    case GET_MOCK_TEST_COURSES_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        mockTestCourses: {
          ...state.mockTestCourses,
          data: [...state.mockTestCourses.data, ...action.data.data],
          meta: action.data.meta,
          total: action.data.meta?.total,
          getMoreCoursesStatus: 'success',
        },
      };
    case GET_MOCK_TEST_COURSES_BY_PAGE_FAILED:
      return {
        ...state,
        mockTestCourses: {
          ...state.mockTestCourses,
          getMoreCoursesStatus: 'failure',
        },
      };

    // Single Mock test buy
    case GET_MOCK_TEST_BY_SLUG:
      return {
        ...state,
        mockTestBySlug: {
          ...state.mockTestBySlug,
          loading: true,
        },
      };
    case GET_MOCK_TEST_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        mockTestBySlug: {
          ...state.mockTestBySlug,
          loading: false,
          data: action.data,
          success: true,
        },
      };
    case GET_MOCK_TEST_BY_SLUG_FAILED:
      return {
        ...state,
        mockTestBySlug: {
          ...state.mockTestBySlug,
          loading: false,
          success: false,
        },
      };

    // Whole mock test course buy
    case GET_MOCK_TEST_LIST_BY_SLUG:
      return {
        ...state,
        mockTestListBySlug: {
          ...state.mockTestListBySlug,
          loading: true,
        },
      };
    case GET_MOCK_TEST_LIST_BY_SLUG_SUCCEEDED:
      return {
        ...state,
        mockTestListBySlug: {
          ...state.mockTestListBySlug,
          loading: false,
          data: action.data,
          success: true,
        },
      };
    case GET_MOCK_TEST_LIST_BY_SLUG_FAILED:
      return {
        ...state,
        mockTestListBySlug: {
          ...state.mockTestListBySlug,
          loading: false,
          success: false,
        },
      };

    case GET_MOCKTEST_LEADERBOARD:
      return {
        ...state,
        mocktestLeaderboard: {
          ...state.mocktestLeaderboard,
          loading: true,
        },
      };
    case GET_MOCKTEST_LEADERBOARD_SUCCEEDED:
      return {
        ...state,
        mocktestLeaderboard: {
          ...state.mocktestLeaderboard,
          data: action.data?.data,
          myScore: action?.data?.my_score,
          success: true,
          loading: false,
        },
      };
    case GET_MOCKTEST_LEADERBOARD_FAILED:
      return {
        ...state,
        mocktestLeaderboard: {
          ...state.mocktestLeaderboard,
          loading: false,
        },
      };

    // Assign Mock Test
    case ASSIGN_MULTIPLE_MOCK_TEST_REQUEST:
      return {
        ...state,
        mockTestAssign: {
          ...state.mockTestAssign,
          loading: true,
        },
      };
    case ASSIGN_MULTIPLE_MOCK_TEST_SUCCEEDED:
      return {
        ...state,
        mockTestAssign: {
          loading: false,
          success: true,
        },
      };
    case ASSIGN_MULTIPLE_MOCK_TEST_FAILED:
      return {
        ...state,
        mockTestAssign: {
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case UPDATE_MOCK_TEST:
      return {
        ...state,
        submitMockTestStatus: 'request',
      };
    case UPDATE_MOCK_TEST_SUCCEEDED:
      return {
        ...state,
        submitMockTestStatus: 'success',
      };
    case UPDATE_MOCK_TEST_FAILED:
      return {
        ...state,
        submitMockTestStatus: 'failure',
      };
    case CLEAR_MOCK_TEST_STATE:
      return {
        ...state,
        currentTime: 0,
      };
    case SET_MOCK_TEST_QUERY_DATA:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.name]: {
            ...action.payload.value,
          },
        },
      };

    case CLEAR_MOCK_TEST_QUERY:
      return {
        ...state,
        query: initialState.query,
        mockTestLanding: initialState.mockTestLanding,
        mockTestAssign: initialState.mockTestAssign,
      };

    case CLEAR_MOCK_TEST_ASSIGN_ERROR:
      return {
        ...state,
        mockTestLanding: {
          ...state.mockTestLanding,
          success: false,
        },
        mockTestAssign: {
          ...state.mockTestAssign,
          error: '',
        },
      };
    case SET_MOCK_TEST_EDIT_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return {
        ...state,
      };
  }
};
export default MockTestReducer;
