import { getLocalStore } from 'next-persist';
import { DHelper } from 'src/interfaces/dropdown';
import {
  CLEAR_DROPDOWN_DATA,
  GET_COURSE_DROPDOWN,
  GET_COURSE_DROPDOWN_FAILED,
  GET_COURSE_DROPDOWN_SUCCEEDED,
  GET_SUBJECT_CONTENT_DROPDOWN,
  GET_SUBJECT_CONTENT_DROPDOWN_FAILED,
  GET_SUBJECT_CONTENT_DROPDOWN_SUCCEEDED,
  GET_USER_DROPDOWN,
  GET_USER_DROPDOWN_CLIENT,
  GET_USER_DROPDOWN_CLIENT_FAILED,
  GET_USER_DROPDOWN_CLIENT_SUCCEEDED,
  GET_USER_DROPDOWN_FAILED,
  GET_USER_DROPDOWN_SUCCEEDED,
  SET_DROPDOWN_DATA,
  SET_DROPDOWN_EDIT_DATA,
} from '../actions';

const initialState: DHelper = {
  user: {
    data: [],
    userDropdownStatus: '',
    meta: {},
    moreLoading: '',
  },
  clients: {},
  clientsStatus: '',
  course: {
    data: [],
    courseDropdownStatus: '',
    meta: {},
    moreLoading: '',
  },
  subjectContent: {
    data: [],
    status: '',
    meta: {},
  },
  courseId: '',
};

const persistedState = getLocalStore('dropdown', initialState);

const dropdown = (state = persistedState, action) => {
  switch (action.type) {
    case GET_USER_DROPDOWN:
      return {
        ...state,
        user: {
          ...state.user,
          userDropdownStatus: 'request',
        },
      };
    case GET_USER_DROPDOWN_SUCCEEDED:
      return {
        ...state,
        user: {
          ...state.user,
          data: [...action.data.data],
          userDropdownStatus: 'success',
          meta: action.data.meta,
          moreLoading: state.user.moreLoading === 'request' ? 'success' : '',
        },
      };
    case GET_USER_DROPDOWN_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          userDropdownStatus: 'failure',
          moreLoading: state.user.moreLoading === 'request' ? 'failure' : '',
        },
      };
    case GET_USER_DROPDOWN_CLIENT:
      return {
        ...state,
        clients: {
          ...state.clients,
        },
        clientsStatus: 'request',
      };
    case GET_USER_DROPDOWN_CLIENT_SUCCEEDED:
      return {
        ...state,
        clients: action.data,
        clientsStatus: 'success',
      };
    case GET_USER_DROPDOWN_CLIENT_FAILED:
      return {
        ...state,
        clients: {
          ...state.clients,
        },
        clientsStatus: 'failure',
      };

    case GET_COURSE_DROPDOWN:
      return {
        ...state,
        course: {
          ...state.course,
          courseDropdownStatus: 'request',
        },
      };
    case GET_COURSE_DROPDOWN_SUCCEEDED:
      return {
        ...state,
        course: {
          ...state.course,
          data: [...state.course.data, ...action.data.data],
          courseDropdownStatus: 'success',
          meta: action.data.meta,
          moreLoading: state.course.moreLoading === 'request' ? 'success' : '',
        },
      };
    case GET_COURSE_DROPDOWN_FAILED:
      return {
        ...state,
        course: {
          ...state.course,
          courseDropdownStatus: 'failure',
          moreLoading: state.course.moreLoading === 'request' ? 'failure' : '',
        },
      };

    case GET_SUBJECT_CONTENT_DROPDOWN:
      return {
        ...state,
        subjectContent: {
          ...state.subjectContent,
          status: 'request',
        },
      };
    case GET_SUBJECT_CONTENT_DROPDOWN_SUCCEEDED:
      return {
        ...state,
        subjectContent: {
          ...state.subjectContent,
          data: action.data.data,
          status: 'success',
          meta: action.data.meta,
        },
      };
    case GET_SUBJECT_CONTENT_DROPDOWN_FAILED:
      return {
        ...state,
        subjectContent: {
          ...state.subjectContent,
          status: 'failure',
        },
      };
    case CLEAR_DROPDOWN_DATA:
      return {
        ...state,
        user: persistedState.user,
        course: persistedState.course,
      };
    case SET_DROPDOWN_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SET_DROPDOWN_EDIT_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default dropdown;
