import { takeLatest } from 'redux-saga/effects';
import * as emailActions from '../actions/core/email/types';
import * as officeActions from '../actions/core/office/types';
import * as roleActions from '../actions/core/role/types';
import * as socialMediaActions from '../actions/core/socialmedia/types';
import * as seoActions from '../actions/core/seo/types';
import { deleteData, fetchData, postData, putData } from './genericSagas';

function* coreWatcher() {
  //Email
  yield takeLatest(emailActions.SEND_COURSE_CLIENT_EMAIL, postData);

  //Office
  yield takeLatest(officeActions.GET_OFFICE, fetchData);
  yield takeLatest(officeActions.ADD_OFFICE, postData);
  yield takeLatest(officeActions.EDIT_OFFICE, putData);
  yield takeLatest(officeActions.DELETE_OFFICE, deleteData);

  //Role
  yield takeLatest(roleActions.GET_ROLES, fetchData);
  yield takeLatest(roleActions.GET_SINGLE_ROLE, fetchData);
  yield takeLatest(roleActions.ADD_ROLE, postData);
  yield takeLatest(roleActions.EDIT_ROLE, putData);
  yield takeLatest(roleActions.DELETE_ROLE, deleteData);

  //section wise roles
  yield takeLatest(roleActions.GET_SECTION_WISE_PERMISSIONS, fetchData);
  yield takeLatest(roleActions.GET_ROLE_WISE_SECTION_PERMISSIONS, fetchData);
  yield takeLatest(roleActions.EDIT_ROLE_WISE_SECTION_PERMISSIONS, postData);
  yield takeLatest(roleActions.GET_ROLE_SECTION_LABELS, fetchData);
  yield takeLatest(roleActions.EDIT_USER_ROLE, putData);

  //Social Media
  yield takeLatest(socialMediaActions.GET_SOCIAL_MEDIA, fetchData);
  yield takeLatest(socialMediaActions.ADD_SOCIAL_MEDIA, postData);
  yield takeLatest(socialMediaActions.EDIT_SOCIAL_MEDIA, putData);
  yield takeLatest(socialMediaActions.DELETE_SOCIAL_MEDIA, deleteData);

  //SEO
  yield takeLatest(seoActions.GET_SEO, fetchData);
  yield takeLatest(seoActions.ADD_SEO, postData);
  yield takeLatest(seoActions.EDIT_SEO, putData);
  yield takeLatest(seoActions.DELETE_SEO, deleteData);
}

export default coreWatcher;
