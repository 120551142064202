import {
  GET_OFFICE_SUCCEEDED,
  GET_OFFICE_FAILED,
  DELETE_OFFICE_FAILED,
  DELETE_OFFICE_SUCCEEDED,
  ADD_OFFICE_SUCCEEDED,
  ADD_OFFICE_FAILED,
  EDIT_OFFICE_SUCCEEDED,
  EDIT_OFFICE_FAILED,
} from '../../actions/core/office/types';
import { IOffice } from '../../interfaces/office';
import { getLocalStore } from 'next-persist';

const initialState: IOffice = {
  office: {
    data: [],
  },
};
const persistedState = getLocalStore('office', initialState);

const office = (state = persistedState, action) => {
  switch (action.type) {
    case GET_OFFICE_SUCCEEDED:
      return {
        ...state,
        office: action.data,
      };

    case GET_OFFICE_FAILED:
      return {
        ...state,
      };
    case ADD_OFFICE_SUCCEEDED:
      return {
        ...state,
        office: {
          ...state.office,
          data: [action.data.data, ...state.office.data],
        },
      };

    case ADD_OFFICE_FAILED:
      return {
        ...state,
      };
    case EDIT_OFFICE_SUCCEEDED:
      return {
        ...state,
        office: {
          ...state.office,
          data: state.office.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
      };

    case EDIT_OFFICE_FAILED:
      return {
        ...state,
      };
    case DELETE_OFFICE_SUCCEEDED:
      return {
        ...state,
        office: {
          ...state.office,
          data: state.office.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_OFFICE_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default office;
