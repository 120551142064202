import { GET_LIVE_CLASS,
     GET_LIVE_CLASS_FAILED,
     GET_LIVE_CLASS_SUCCEEDED
     } from "../actions";
import { 
    IClass 
} from "../interfaces/class";


const initialState: IClass = {
    classList: {
        data: [],
        loading: false,
    }
}

const classReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_LIVE_CLASS:
            return {
                ...state,
                classList:{
                    ...state.classList,
                    loading: true
                }
            };
        case GET_LIVE_CLASS_SUCCEEDED:
            return {
                ...state,
                classList:action.data
            }
        case GET_LIVE_CLASS_FAILED:
            return {
                ...state,
                classList:{
                    ...state.classList,
                    loading: false
                }
            }
        default:
            return {
                ...state
            }
    }
}

export default classReducer;

