import {
  GET_SOCIAL_MEDIA_SUCCEEDED,
  GET_SOCIAL_MEDIA_FAILED,
  DELETE_SOCIAL_MEDIA_FAILED,
  DELETE_SOCIAL_MEDIA_SUCCEEDED,
  ADD_SOCIAL_MEDIA_SUCCEEDED,
  ADD_SOCIAL_MEDIA_FAILED,
  EDIT_SOCIAL_MEDIA_SUCCEEDED,
  EDIT_SOCIAL_MEDIA_FAILED,
  SET_SOCIAL_MEDIA_DATA,
  SET_SOCIAL_MEDIA_EDIT_DATA,
  SET_SOCIAL_MEDIA_ERROR_DATA,
} from '../../actions/core/socialmedia/types';
import { ISocialMedia } from '../../interfaces/socialmedia';
import { getLocalStore } from 'next-persist';

const initialState: ISocialMedia = {
  socialmedia: {
    data: [],
  },
  error: {
    addSocialMedia: {},
    editSocialMedia: {},
  },
  helperObj: {
    addSocialMedia: false,
    editSocialMedia: false,
    addSocialMediaStatus: '',
  },
};
const persistedState = getLocalStore('socialmedia', initialState);

const socialmedia = (state = persistedState, action) => {
  switch (action.type) {
    case GET_SOCIAL_MEDIA_SUCCEEDED:
      return {
        ...state,
        socialmedia: action.data,
      };

    case GET_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
      };
    case ADD_SOCIAL_MEDIA_SUCCEEDED:
      return {
        ...state,
        socialmedia: {
          ...state.socialmedia,
          data: [action.data.data, ...state.socialmedia.data],
        },
        helperObj: {
          ...state.helperObj,
          addSocialMedia: false,
          addSocialMediaStatus: 'success',
        },
        error: { ...state.error, addSocialMedia: {} },
      };

    case ADD_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
        error: { ...state.error, addSocialMedia: action.error },
        helperObj: {
          ...state.helperObj,
          addSocialMediaStatus: 'failure',
        },
      };
    case EDIT_SOCIAL_MEDIA_SUCCEEDED:
      return {
        ...state,
        socialmedia: {
          ...state.socialmedia,
          data: state.socialmedia.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          editSocialMedia: false,
          editSocialMediaStatus: 'success',
        },
        error: { ...state.error, editSocialMedia: {} },
      };

    case EDIT_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
        error: { ...state.error, editSocialMedia: action.error },
        helperObj: { ...state.helperObj, editSocialMediaStatus: 'failure' },
      };
    case DELETE_SOCIAL_MEDIA_SUCCEEDED:
      return {
        ...state,
        socialmedia: {
          ...state.socialmedia,
          data: state.socialmedia.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
      };
    case SET_SOCIAL_MEDIA_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_SOCIAL_MEDIA_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: {
          ...state[action.payload.data.value],
        },
      };
    case SET_SOCIAL_MEDIA_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    default:
      return state;
  }
};

export default socialmedia;
