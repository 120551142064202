//Role
export const GET_ROLES: any = 'GET_ROLES';
export const GET_ROLES_SUCCEEDED: any = 'GET_ROLES_SUCCEEDED';
export const GET_ROLES_FAILED: any = 'GET_ROLES_FAILED';

export const GET_SINGLE_ROLE: any = 'GET_SINGLE_ROLE';
export const GET_SINGLE_ROLE_SUCCEEDED: any = 'GET_SINGLE_ROLE_SUCCEEDED';
export const GET_SINGLE_ROLE_FAILED: any = 'GET_SINGLE_ROLE_FAILED';

export const ADD_ROLE: any = 'ADD_ROLE';
export const ADD_ROLE_SUCCEEDED: any = 'ADD_ROLE_SUCCEEDED';
export const ADD_ROLE_FAILED: any = 'ADD_ROLE_FAILED';

export const EDIT_ROLE: any = 'EDIT_ROLE';
export const EDIT_ROLE_SUCCEEDED: any = 'EDIT_ROLE_SUCCEEDED';
export const EDIT_ROLE_FAILED: any = 'EDIT_ROLE_FAILED';

export const DELETE_ROLE: any = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCEEDED: any = 'DELETE_ROLE_SUCCEEDED';
export const DELETE_ROLE_FAILED: any = 'DELETE_ROLE_FAILED';

export const SET_ROLE_DATA: any = 'SET_ROLE_DATA';
export const SET_ROLE_EDIT_DATA: any = 'SET_ROLE_EDIT_DATA';
export const SET_ROLE_ERROR_DATA: any = 'SET_ROLE_ERROR_DATA';

export const GET_ROLE_SECTION_LABELS: any = 'GET_ROLE_SECTION_LABELS';
export const GET_ROLE_SECTION_LABELS_SUCCEEDED: any =
  'GET_ROLE_SECTION_LABELS_SUCCEEDED';
export const GET_ROLE_SECTION_LABELS_FAILED: any = 'GET_ROLE_SECTION_LABELS_FAILED';

export const GET_SECTION_WISE_PERMISSIONS: any = 'GET_SECTION_WISE_PERMISSIONS';
export const GET_SECTION_WISE_PERMISSIONS_SUCCEEDED: any =
  'GET_SECTION_WISE_PERMISSIONS_SUCCEEDED';
export const GET_SECTION_WISE_PERMISSIONS_FAILED: any =
  'GET_SECTION_WISE_PERMISSIONS_FAILED';

export const GET_ROLE_WISE_SECTION_PERMISSIONS: any =
  'GET_ROLE_WISE_SECTION_PERMISSIONS';
export const GET_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED: any =
  'GET_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED';
export const GET_ROLE_WISE_SECTION_PERMISSIONS_FAILED: any =
  'GET_ROLE_WISE_SECTION_PERMISSIONS_FAILED';

export const EDIT_ROLE_WISE_SECTION_PERMISSIONS: any =
  'EDIT_ROLE_WISE_SECTION_PERMISSIONS';
export const EDIT_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED: any =
  'EDIT_ROLE_WISE_SECTION_PERMISSIONS_SUCCEEDED';
export const EDIT_ROLE_WISE_SECTION_PERMISSIONS_FAILED: any =
  'EDIT_ROLE_WISE_SECTION_PERMISSIONS_FAILED';

export const EDIT_USER_ROLE: any = 'EDIT_USER_ROLE';
export const EDIT_USER_ROLE_SUCCEEDED: any = 'EDIT_USER_ROLE_SUCCEEDED';
export const EDIT_USER_ROLE_FAILED: any = 'EDIT_USER_ROLE_FAILED';
