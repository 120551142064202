import {
  ADD_CONTACT_COMMENTS_FAILED,
  ADD_CONTACT_COMMENTS_REQUEST,
  ADD_CONTACT_COMMENTS_SUCCEEDED,
  ADD_CONTACT_INQUIRIES_FAILED,
  ADD_CONTACT_INQUIRIES_REQUEST,
  ADD_CONTACT_INQUIRIES_SUCCEEDED,
  ADD_CONTACT_TASK_FAILED,
  ADD_CONTACT_TASK_REQUEST,
  ADD_CONTACT_TASK_SUCCEEDED,
  DELETE_CONTACT_COMMENTS_FAILED,
  DELETE_CONTACT_COMMENTS_REQUEST,
  DELETE_CONTACT_COMMENTS_SUCCEEDED,
  DELETE_CONTACT_TASK_FAILED,
  DELETE_CONTACT_TASK_REQUEST,
  DELETE_CONTACT_TASK_SUCCEEDED,
  EDIT_CONTACT_COMMENTS_FAILED,
  EDIT_CONTACT_COMMENTS_REQUEST,
  EDIT_CONTACT_COMMENTS_SUCCEEDED,
  EDIT_CONTACT_INQUIRIES,
  EDIT_CONTACT_INQUIRIES_FAILED,
  EDIT_CONTACT_INQUIRIES_SUCCEEDED,
  EDIT_CONTACT_TASK_FAILED,
  EDIT_CONTACT_TASK_REQUEST,
  EDIT_CONTACT_TASK_SUCCEEDED,
  GET_CONTACT_COMMENTS_FAILED,
  GET_CONTACT_COMMENTS_REQUEST,
  GET_CONTACT_COMMENTS_SUCCEEDED,
  GET_CONTACT_INQUIRIES,
  GET_CONTACT_INQUIRIES_BY_ID,
  GET_CONTACT_INQUIRIES_BY_ID_FAILED,
  GET_CONTACT_INQUIRIES_BY_ID_SUCCEEDED,
  GET_CONTACT_INQUIRIES_FAILED,
  GET_CONTACT_INQUIRIES_SUCCEEDED,
  GET_CONTACT_TASK_FAILED,
  GET_CONTACT_TASK_REQUEST,
  GET_CONTACT_TASK_SUCCEEDED,
  SET_CONTACT_INQUIRIES,
} from '../../../actions/contact/types';
import { IContact } from '../../../interfaces/admin/contacts';
import { getLocalStore } from 'next-persist';

const initialState: IContact = {
  contacts: {
    data: [],
    loading: false,
    updateLoading: false,
    error: {},
  },
  contactById: {},
  contactComments: {
    data: [],
    loading: {
      getComment: false,
      addComment: false,
      editComment: false,
      deleteComment: false,
    },
    error: {
      getComment: {},
      addComment: {},
      editComment: {},
      deleteComment: {},
    },
  },
  task: {
    data: {},
    loading: {
      getTask: false,
      addTask: false,
      editTask: false,
      deleteTask: false,
    },
    error: {
      getTask: {},
      addTask: {},
      editTask: {},
      deleteTask: {},
    },
  },
};
const persistedState = getLocalStore('contactReducer', initialState);

const contactReducer = (state = persistedState, action) => {
  switch (action.type) {
    case GET_CONTACT_INQUIRIES:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
        },
      };
    case GET_CONTACT_INQUIRIES_SUCCEEDED:
      return {
        ...state,
        contacts: action.data,
      };
    case GET_CONTACT_INQUIRIES_FAILED:
      return {
        ...state,
      };

    case GET_CONTACT_INQUIRIES_BY_ID:
      return {
        ...state,
        contactById: {
          loading: true,
        },
      };
    case GET_CONTACT_INQUIRIES_BY_ID_SUCCEEDED:
      return {
        ...state,
        contactById: action.data,
      };
    case GET_CONTACT_INQUIRIES_BY_ID_FAILED:
      return {
        ...state,
      };

    case EDIT_CONTACT_INQUIRIES:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          updateLoading: true,
        },
      };
    case EDIT_CONTACT_INQUIRIES_SUCCEEDED:
      const oldInquiry = state.contacts.data.filter(
        (each: any) => each.id !== action.data.data.id
      );
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: [action.data.data, ...oldInquiry],
          updateLoading: false,
        },
      };
    case EDIT_CONTACT_INQUIRIES_FAILED:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          updateLoading: false,
        },
      };
    case GET_CONTACT_COMMENTS_REQUEST:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            getComment: true,
          },
          error: {
            ...state.contactComments.error,
            getComment: {},
          },
        },
      };
    case GET_CONTACT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            getComment: false,
          },
          data: action.data,
          error: {
            ...state.contactComments.error,
            getComment: {},
          },
        },
      };
    case GET_CONTACT_COMMENTS_FAILED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            getComment: false,
          },
          error: {
            ...state.contactComments.error,
            getComment: action.error,
          },
        },
      };
    case ADD_CONTACT_COMMENTS_REQUEST:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            addComment: true,
          },
          error: {
            ...state.contactComments.error,
            addComment: {},
          },
        },
      };
    case ADD_CONTACT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            addComment: false,
          },
          data: {
            ...state.contactComments.data,
            data: [action?.data?.data, ...state.contactComments.data?.data],
          },
          error: {
            ...state.contactComments.error,
            addComment: {},
          },
        },
      };
    case ADD_CONTACT_COMMENTS_FAILED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            addComment: false,
          },
          error: {
            ...state.contactComments.error,
            addComment: action.error,
          },
        },
      };
    case EDIT_CONTACT_COMMENTS_REQUEST:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            editComment: true,
          },
          error: {
            ...state.contactComments.error,
            editComment: {},
          },
        },
      };
    case EDIT_CONTACT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            editComment: false,
          },
          data: {
            ...state.contactComments.data,
            data: state?.contactComments?.data?.data?.map((each: any) =>
              each.id === action.data?.data.id ? action?.data?.data : each
            ),
          },
          error: {
            ...state.contactComments.error,
            editComment: {},
          },
        },
      };
    case EDIT_CONTACT_COMMENTS_FAILED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            editComment: false,
          },
          error: {
            ...state.contactComments.error,
            editComment: action.error,
          },
        },
      };
    case DELETE_CONTACT_COMMENTS_REQUEST:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            editComment: true,
          },
          error: {
            ...state.contactComments.error,
            editComment: {},
          },
        },
      };
    case DELETE_CONTACT_COMMENTS_SUCCEEDED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            deleteComment: false,
          },
          data: {
            ...state.contactComments.data,
            data: state?.contactComments?.data?.data?.filter(
              (each: any) => each.id !== action.data?.data.id
            ),
          },
          error: {
            ...state.contactComments.error,
            deleteComment: {},
          },
        },
      };
    case DELETE_CONTACT_COMMENTS_FAILED:
      return {
        ...state,
        contactComments: {
          ...state.contactComments,
          loading: {
            ...state.contactComments.loading,
            deleteComment: false,
          },
          error: {
            ...state.contactComments.error,
            deleteComment: action.error,
          },
        },
      };
    case ADD_CONTACT_INQUIRIES_REQUEST:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
        },
      };
    case ADD_CONTACT_INQUIRIES_SUCCEEDED:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: [action.data.data, ...state.contacts.data],
          loading: false,
          error: {},
        },
      };
    case ADD_CONTACT_INQUIRIES_FAILED:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: action.error,
        },
      };
    case GET_CONTACT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            getTask: true,
          },
          error: {
            ...state.task.error,
            getTask: {},
          },
        },
      };

    case GET_CONTACT_TASK_SUCCEEDED:
      return {
        ...state,
        task: {
          ...state.task,
          data: action.data,
          loading: {
            ...state.task.loading,
            getTask: false,
          },
          error: {
            ...state.task.error,
            getTask: {},
          },
        },
      };
    case GET_CONTACT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            getTask: false,
          },
          error: {
            ...state.task.error,
            getTask: action.error,
          },
        },
      };
    case ADD_CONTACT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            addTask: true,
          },
          error: {
            ...state.task.error,
            addTask: {},
          },
        },
      };
    case ADD_CONTACT_TASK_SUCCEEDED:
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            data: [action.data.data, ...state.task.data?.data],
          },
          loading: {
            ...state.task.loading,
            addTask: false,
          },
          error: {
            ...state.task.error,
            addTask: {},
          },
        },
      };
    case ADD_CONTACT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            addTask: false,
          },
          error: {
            ...state.task.error,
            addTask: action.error,
          },
        },
      };
    case EDIT_CONTACT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            editTask: true,
          },
          error: {
            ...state.task.error,
            editTask: {},
          },
        },
      };
    case EDIT_CONTACT_TASK_SUCCEEDED:
      // const oldDatas = state.task.data;
      // const updatedData = action.data.data;
      // const newDatas = oldDatas.map((each, index) =>
      //   each.id == updatedData.id ? updatedData : each
      // );
      return {
        ...state,
        task: {
          ...state.task,
          // data: newDatas,
          data: {
            ...state.task?.data,
            data: state?.task?.data?.data?.map((each: any) =>
              each?.id === action.data.data.id ? action?.data?.data : each
            ),
          },
          loading: {
            ...state.task.loading,
            editTask: false,
          },
          error: {
            ...state.task.error,
            editTask: {},
          },
        },
      };
    case EDIT_CONTACT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            editTask: false,
          },
          error: {
            ...state.task.error,
            editTask: action.error,
          },
        },
      };
    case DELETE_CONTACT_TASK_REQUEST:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            deleteTask: true,
          },
          error: {
            ...state.task.error,
            deleteTask: {},
          },
        },
      };
    case DELETE_CONTACT_TASK_SUCCEEDED:
      return {
        ...state,
        task: {
          ...state.task,
          data: {
            ...state.task?.data,
            data: state?.task?.data?.data?.filter(
              (each: any) => each?.id !== action.data.data.id
            ),
          },
          loading: {
            ...state.task.loading,
            deleteTask: false,
          },
          error: {
            ...state.task.error,
            deleteTask: {},
          },
        },
      };
    case DELETE_CONTACT_TASK_FAILED:
      return {
        ...state,
        task: {
          ...state.task,
          loading: {
            ...state.task.loading,
            deleteTask: false,
          },
          error: {
            ...state.task.error,
            deleteTask: action.error,
          },
        },
      };
    case SET_CONTACT_INQUIRIES:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default contactReducer;
