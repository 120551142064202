import {
  GET_QUESTIONS_SUCCEEDED,
  GET_QUESTIONS_FAILED,
  ADD_QUESTION_SUCCEEDED,
  ADD_QUESTION_FAILED,
  DELETE_QUESTION_SUCCEEDED,
  DELETE_QUESTION_FAILED,
  EDIT_QUESTION_SUCCEEDED,
  EDIT_QUESTION_FAILED,
  SET_QUESTION_DATA,
  SET_QUESTION_EDIT_DATA,
  SET_QUESTION_ERROR_DATA,
  GET_QUESTIONS,
  GET_MODEL_TEST_QUESTION_BY_ID,
  GET_MODEL_TEST_QUESTION_BY_ID_SUCCEEDED,
  GET_MODEL_TEST_QUESTION_BY_ID_FAILED,
  EDIT_QUESTION,
  ADD_QUESTION,
} from '../../../src/actions';
import { IQuestions } from '../../interfaces/model_tests/questions';
import { getLocalStore } from 'next-persist';

const initialState: IQuestions = {
  questions: {
    data: [],
  },
  question: {},
  helperObj: {
    addQuestion: false,
    editQuestion: false,
    addQuestionStatus: '',
  },
  addQuestion: {},
  error: {
    addQuestion: {},
    editQuestion: {},
  },
  loading: false,
  getQuestionById: false,
  editQuestionStatus: '',
};
const persistedState = getLocalStore('questions', initialState);

const questions = (state = persistedState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_QUESTIONS_SUCCEEDED:
      return {
        ...state,
        questions: action.data,
        loading: false,
      };

    case GET_QUESTIONS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_MODEL_TEST_QUESTION_BY_ID:
      return {
        ...state,
        getQuestionById: true,
      };
    case GET_MODEL_TEST_QUESTION_BY_ID_SUCCEEDED:
      return {
        ...state,
        question: action.data,
        getQuestionById: false,
      };

    case GET_MODEL_TEST_QUESTION_BY_ID_FAILED:
      return {
        ...state,
        getQuestionById: false,
      };
    case ADD_QUESTION:
      return {
        ...state,
        helperObj: {
          ...state.helperObj,
          addQuestionStatus: 'request',
        },
      };
    case ADD_QUESTION_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: [action.data.data, ...state.questions.data],
        },
        helperObj: {
          ...state.helperObj,
          addQuestion: false,
          addQuestionStatus: 'success',
        },
        error: { ...state.error, addQuestion: {} },
        addQuestion: {},
      };

    case ADD_QUESTION_FAILED:
      return {
        ...state,
        error: { ...state.error, addQuestion: action.error },
        helperObj: {
          ...state.helperObj,
          addQuestionStatus: 'failure',
        },
      };
    case EDIT_QUESTION:
      return {
        ...state,
        editQuestionStatus: 'request',
      };
    case EDIT_QUESTION_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        helperObj: { ...state.helperObj, editQuestion: 'success' },
        error: { ...state.error, editQuestion: {} },
        addQuestion: {},
        editQuestionStatus: 'success',
      };

    case EDIT_QUESTION_FAILED:
      return {
        ...state,
        error: { ...state.error, editQuestion: action.error },
        editQuestionStatus: 'failure',
      };
    case DELETE_QUESTION_SUCCEEDED:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: state.questions.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_QUESTION_FAILED:
      return {
        ...state,
      };
    case SET_QUESTION_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_QUESTION_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
        //  {
        //   ...state[action.payload.data.value],
        // },
      };
    case SET_QUESTION_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    default:
      return state;
  }
};

export default questions;
